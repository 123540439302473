import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError, throwError } from "rxjs";
import { utility } from "../../../util";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})

export class WelderQualificationService {
    private apiBaseUrl: string;
    private selectedInfo = new BehaviorSubject(null);
    getSelectedInfo = this.selectedInfo.asObservable();
    private environmentConfig = utility.getEnvironmentData();
    requester_id: any;
    site_id: any;
    welderQualApiEndPoint: string = "welderqualificationinfo/"
    weldApiBaseUrl: any;

    constructor(private httpClient: HttpClient, private router: Router) {
        //this.apiBaseUrl = environment.apiBase;
        var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
        this.requester_id = loggedInUser.logon_id;
        this.site_id = loggedInUser.site_id;
        this.weldApiBaseUrl = this.environmentConfig.weldApiBase;
        this.apiBaseUrl = this.environmentConfig.apiBase;
    }

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }

    setSelectedInfo(paramData: any) {
        this.selectedInfo.next(paramData);
    }

    public async getAllActiveResourceList(logon_id: any, site_id: any) {
        const params = new HttpParams()
        .set('requester_id',this.requester_id)
        .set('site_id',this.site_id)
        .set('is_active',true)
        .set('res_type_id',1);
        var apiMethod = "resourceinfo/GetAll";
        var url = this.weldApiBaseUrl + apiMethod;
        return this.httpClient.get(url,{params: params});
    }

    public async getAllWelderQualification(resource_id: any){
        const params = new HttpParams()
          .set('requester_id',this.requester_id)
          .set('site_id',this.site_id)
          .set('resource_id',resource_id);
    
        var apiMethod = this.welderQualApiEndPoint + "getall";
        var url = (this.weldApiBaseUrl +  apiMethod); // + param;
        return this.httpClient.get(url,{params: params});
    }

    public async getWelderQualificationDetail(welder_qual_id: any){
        const params = new HttpParams()
          .set('requester_id',this.requester_id)
          .set('site_id',this.site_id)
          .set('welder_qual_id',welder_qual_id);
    
        var apiMethod = this.welderQualApiEndPoint + "detail";
        var url = (this.weldApiBaseUrl +  apiMethod); // + param;
        return this.httpClient.get(url,{params: params});
    }


    public async deleteWelderQualification(del: any) {
        var apiMethod = this.welderQualApiEndPoint + "delete";
        var url = (this.weldApiBaseUrl + apiMethod);
        return this.httpClient.put(url, JSON.stringify(del), this.httpOptions)
        .pipe(
            catchError(this.errorHandler)
        );
    }

    public async getDisciplineList() {
      var apiMethod = "Lookup/Discipline";
      const params = new HttpParams()
          .set('requester_id', this.requester_id)
          .set('site_id', this.site_id)
          .set('is_active', true);

      var url = this.apiBaseUrl + apiMethod;
      return this.httpClient.get(url, { params: params }).pipe(
          catchError(this.errorHandler)
      );
  }

  public getActiveWeldProcedureList(){
    const params = new HttpParams()
      .set('requester_id', this.requester_id)
      .set('site_id', this.site_id)
      .set('is_active', true);

    var apiMethod = "weldProcedureinfo/getall";
    var url = (this.weldApiBaseUrl +  apiMethod); // + param;
    return this.httpClient.get(url,{params: params});
  }

    getActiveProcessList(){
      const params = new HttpParams()
        .set('requester_id',this.requester_id)
        .set('site_id',this.site_id)
  
      var apiMethod = "processInfo/getall";
      var url = (this.weldApiBaseUrl + apiMethod);
      return this.httpClient.get(url,{params: params});
    }

    public async createWelderQualification(post: any) {
      var apiMethod = "WelderQualificationInfo/Create";
      var url = (this.weldApiBaseUrl + apiMethod)
      return this.httpClient.post(url, JSON.stringify(post), this.httpOptions)
      .pipe(
          catchError(this.errorHandler)
      );
    }

    public async updateWelderQualification(post: any) {
      var apiMethod = "WelderQualificationInfo/Update";
      var url = (this.weldApiBaseUrl + apiMethod)
      return this.httpClient.put(url, JSON.stringify(post), this.httpOptions)
      .pipe(
          catchError(this.errorHandler)
      );
    }

     
    errorHandler(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          errorMessage = error.error.message;
        } else {
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
      }
}