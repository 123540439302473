import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ProjectJobService } from '../../../APIServices/ProjectJobService';
import { CommonModule } from '@angular/common';
import { utility } from '../../../util';
import { MaterialModule } from '../../../core/material-module';
import { Router } from '@angular/router';
import { OnlyAlphanumericDirective } from '../../../directives/only-alphanumeric.directive';

@Component({
  selector: 'app-add-edit-job',
  standalone: true,
  imports: [MaterialModule, ReactiveFormsModule, CommonModule, OnlyAlphanumericDirective],
  templateUrl: './add-edit-job.component.html',
  styleUrl: './add-edit-job.component.scss'
})
export class AddEditJobComponent implements OnInit {
  pJobForm: FormGroup;
  pJobValidation: any[];
  activeStatus = utility.activeStatus;
  selectedInfo: any;
  isEditMode:any;
  isFormChanged: boolean = false;
  initialFormValues: any;

  constructor(private dialogRef: MatDialogRef<AddEditJobComponent>,
    private router: Router, private projectJobService: ProjectJobService
  ) {
    this.initializeForm(null);
  }
  async ngOnInit() {
    await this.projectJobService.getSelectedInfo.subscribe(x =>
      this.selectedInfo = x);
    
      if (this.selectedInfo.job_id == null || this.selectedInfo.job_id == 0
        || this.selectedInfo.job_id == undefined) {
        this.isEditMode = false;
      } else {
        this.isEditMode = true;
      }  

    if (this.selectedInfo != null) {
      this.initializeForm(this.selectedInfo);
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
  saveJobInfo() {
    this.pJobForm.markAllAsTouched();
    const isFormValid = this.pJobForm.valid;
    if (isFormValid) {
      var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
      this.pJobForm.controls['requester_id'].setValue(loggedInUser.logon_id);
      const formData = this.pJobForm.getRawValue();
      formData.job_code = formData.job_code ? formData.job_code.toUpperCase() : null;
      if (this.pJobForm.value['job_id'] == null) {
        this.createJob(formData);
      }
      else {
        this.updateJob(formData);
      }
      this.isFormChanged = false;
    } else {
      return;
    }
  }
  async createJob(formDataReq: any) {
    (await this.projectJobService.createProjectJob(formDataReq)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.closeDialog();
    });
  }
  async updateJob(formDataReq: any) {
    (await this.projectJobService.updateProjectJob(formDataReq)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.closeDialog();
    });
  }
  async initializeForm(pJobInfo: any) {
    if (pJobInfo != null) {
      this.pJobForm = new FormGroup({
        job_id: new FormControl(pJobInfo?.job_id),
        site_id: new FormControl(pJobInfo?.site_id),
        project_id: new FormControl(pJobInfo?.project_id),
        job_code: new FormControl({value: pJobInfo?.job_code, disabled:this.isEditMode}, [Validators.required]),
        job_title: new FormControl(pJobInfo?.job_title, [Validators.required]),
        is_active: new FormControl({value: pJobInfo?.is_active, disabled: pJobInfo?.is_active }, [Validators.required]),        
        requester_id: new FormControl('')
      });
      this.initialFormValues = this.pJobForm.getRawValue();
      this.detectFormValueChanges(this.initialFormValues);
    }
    else {
      this.pJobForm = new FormGroup({
        job_id: new FormControl(''),
        site_id: new FormControl(''),
        project_id: new FormControl(''),
        job_code: new FormControl({value: '', disabled:this.isEditMode}, [Validators.required]),
        job_title: new FormControl('', [Validators.required]),
        is_active: new FormControl(true, [Validators.required]),       
        requester_id: new FormControl('')
      });
      this.detectFormValueChanges(this.initialFormValues);
    }
  }

  detectFormValueChanges(initialFormValues?: any) {
    this.pJobForm.valueChanges.subscribe(() => {
      this.isFormChanged = utility.checkIfFormChanged(this.pJobForm, initialFormValues);
    });
  }

  isSaveDisabled() {
    return this.isFormChanged && this.pJobForm.valid;
  }
}
