import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError, throwError } from "rxjs";
import { utility } from "../../../util";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})

export class InspectorService {
    private apiWeldNDTUrl: string;
    private apiBaseUrl: string;
    private apiWeldBaseUrl: string;
    private selectedInfo = new BehaviorSubject(null);
    private selectedFilterInfo = new BehaviorSubject(null);
    getSelectedInfo = this.selectedInfo.asObservable();
    getSelectedFilterInfo = this.selectedFilterInfo.asObservable();
    private environmentConfig = utility.getEnvironmentData();
    requester_id: any;
    site_id: any;
    apiEndPoint: string = "InspectorInfo/"

    constructor(private httpClient: HttpClient, private router: Router) {
        var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
        this.requester_id = loggedInUser.logon_id
        this.site_id = loggedInUser.site_id
        this.apiWeldNDTUrl = this.environmentConfig.weldNDTApiUrl;
        this.apiBaseUrl = this.environmentConfig.apiBase;
        this.apiWeldBaseUrl = this.environmentConfig.weldApiBase;
    }

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }

    setSelectedInfo(paramData: any) {
        this.selectedInfo.next(paramData);
    }
    setSelectedFilterInfo(paramData: any) {
        this.selectedFilterInfo.next(paramData);
    }

    public async getAllInspectorList() {
        var apiMethod = this.apiEndPoint + "GetAll";

        const params = new HttpParams()
            .set('requester_id', this.requester_id)
            .set('site_id', this.site_id)

        var url = this.apiWeldNDTUrl + apiMethod;
        return this.httpClient.get(url, { params: params }).pipe(
            catchError(this.errorHandler)
        );
    }

    public async getSelectedInspector(inspector_id: any) {
        const params = new HttpParams()
            .set('requester_id', this.requester_id)
            .set('site_id', this.site_id)
            .set('inspector_id', inspector_id);

        var apiMethod = this.apiEndPoint + "detail";
        var url = (this.apiWeldNDTUrl + apiMethod);
        return this.httpClient.get(url, { params: params }).pipe(
            catchError(this.errorHandler)
        );
    }

    public async createInspector(post: any) {
        post.requester_id = this.requester_id;
        post.site_id = this.site_id;

        var apiMethod = this.apiEndPoint + "Create";
        var url = (this.apiWeldNDTUrl + apiMethod)
        return this.httpClient.post(url, JSON.stringify(post), this.httpOptions)
            .pipe(
                catchError(this.errorHandler)
            );
    }

    public async updateInspector(put: any) {
        put.requester_id = this.requester_id;
        put.site_id = this.site_id;

        var apiMethod = this.apiEndPoint + "Update";
        var url = (this.apiWeldNDTUrl + apiMethod)
        return this.httpClient.put(url, JSON.stringify(put), this.httpOptions)
            .pipe(
                catchError(this.errorHandler)
            );
    }

    public async deleteInspector(del: any) {
        var apiMethod = this.apiEndPoint + "Delete";
        var url = (this.apiWeldNDTUrl + apiMethod);
        return this.httpClient.put(url, JSON.stringify(del), this.httpOptions)
            .pipe(
                catchError(this.errorHandler)
            );
    }

    errorHandler(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message;
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }
}