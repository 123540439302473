<div class="card">
    <mat-card appearance="outlined">
        <mat-card-header class="example-headers-align">
            <span class="card-title">Weld Details</span>
            <button matTooltip="Search" matTooltipPosition='above' mat-icon-button class="mat_icon_btn_search"
                (click)="openSearchDialog()">
                <mat-icon>search</mat-icon>
            </button>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="weldDetailsForm" novalidate class="form">
                <div class="flex-container">
                    <div class="side-container">

                        <div class="item-container disNone">
                            <mat-label class="matLabel">ID</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtpsID" name="text" class="matInputCls"
                                    formControlName="weld_id">
                            </mat-form-field>
                        </div>
                        <!-- Job -->
                        <div class="item-container mt-1e">
                            <mat-label class="matLabel mt-1e">Job</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtjobcode" name="text" class="matInputCls"
                                    formControlName="job_code" Maxlength="2">
                            </mat-form-field>
                        </div>

                        <!-- Item -->
                        <div class="item-container">
                            <mat-label class="matLabel">Item</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtitemcode" name="text" class="matInputCls"
                                    formControlName="item_code" maxlength="20">

                                <!--  <mat-error class="text-danger" *ngIf="weldDetailsForm.controls['item_code'].errors?.['required']">
                                        This is required!
                                    </mat-error> -->
                            </mat-form-field>
                        </div>

                        <!-- Drawing -->
                        <div class="item-container">
                            <mat-label class="matLabel">Drawing</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtDrawingCode" name="text" class="matInputCls"
                                    formControlName="mcd_drawing_code">
                            </mat-form-field>
                        </div>
                        <!-- NDT Ref -->
                        <div class="item-container">
                            <mat-label class="matLabel">NDT Ref</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtndtref" name="text" class="matInputCls"
                                    formControlName="ndt_ref" maxlength="20">
                                <!-- <mat-error class="text-danger" *ngIf="weldDetailsForm.controls['ndt_ref'].errors?.['required']">
                                        This is required!
                                    </mat-error> -->
                            </mat-form-field>
                        </div>

                        <!-- Spool / Recap -->
                        <div class="item-container">
                            <mat-label class="matLabel">Spool / Recap</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtspoolrecap" name="text" class="matInputCls"
                                    formControlName="recap_spool" maxlength="20">
                                <!--  <mat-error class="text-danger" *ngIf="weldDetailsForm.controls['spool_recaf'].errors?.['required']">
                                        This is required!
                                    </mat-error> -->
                            </mat-form-field>
                        </div>

                        <!-- Weld -->
                        <div class="item-container">
                            <mat-label class="matLabel asterix">Weld</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtWeld" name="text" class="matInputCls"
                                    formControlName="weld_code" maxlength="20">
                                <mat-error class="text-danger"
                                    *ngIf="weldDetailsForm.controls['weld_code'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!-- Suffix -->
                        <div class="item-container">
                            <mat-label class="matLabel">Suffix</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtsuffix" name="text" class="matInputCls"
                                    formControlName="suffix" maxlength="20">
                                <!-- <mat-error class="text-danger" *ngIf="weldDetailsForm.controls['suffix'].errors?.['required']">
                                        This is required!
                                    </mat-error> -->
                            </mat-form-field>
                        </div>

                        <!-- S/R -->
                        <div class="item-container">
                            <mat-label class="matLabel">S/R</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtsr" name="text" class="matInputCls"
                                    formControlName="sr_code" maxlength="20">
                                <!--  <mat-error class="text-danger" *ngIf="weldDetailsForm.controls['s_r'].errors?.['required']">
                                        This is required!
                                    </mat-error> -->
                            </mat-form-field>
                        </div>
                        <!-- Discipline -->
                        <div class="item-container">
                            <mat-label class="matLabel">Discipline</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="disc_id">
                                    <ng-container>
                                        @for (item of lookupDisciplineList; track item) {
                                        <mat-option [value]="item.disc_id">{{item.disc_title}}</mat-option>
                                        }
                                    </ng-container>
                                </mat-select>
                                <!-- <mat-error class="text-danger" *ngIf="weldDetailsForm.controls['disc_id'].errors?.['required']">
                                    This is required!
                                </mat-error> -->
                            </mat-form-field>
                        </div>

                        <!-- Type -->
                        <div class="item-container">
                            <mat-label class="matLabel">Type</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="type">
                                    <ng-container>
                                        @for (item of weldTypeList; track item) {
                                        <mat-option
                                            [value]="item.master_id">{{item.code}}-{{item.description}}</mat-option>
                                        }
                                    </ng-container>
                                </mat-select>
                                <!-- <mat-error class="text-danger" *ngIf="weldDetailsForm.controls['type'].errors?.['required']">
                                    This is required!
                                </mat-error> -->
                            </mat-form-field>
                        </div>

                        <!-- Category -->
                        <div class="item-container">
                            <mat-label class="matLabel">Category</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="category_id">
                                    <ng-container>
                                        @for (item of categoryList; track item) {
                                        <mat-option [value]="item.weld_category_id">{{item.category_title}}</mat-option>
                                        }
                                    </ng-container>
                                </mat-select>
                                <!-- <mat-error class="text-danger" *ngIf="weldDetailsForm.controls['category'].errors?.['required']">
                                    This is required!
                                </mat-error> -->
                            </mat-form-field>
                        </div>

                        <!-- Joint Type -->
                        <div class="item-container">
                            <mat-label class="matLabel">Joint Type</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="joint_type_id">
                                    <ng-container>
                                        @for (item of jointTypeList; track item) {
                                        <mat-option
                                            [value]="item.master_id">{{item.code}}-{{item.description}}</mat-option>
                                        }
                                    </ng-container>
                                </mat-select>
                                <!-- <mat-error class="text-danger" *ngIf="weldDetailsForm.controls['joint_type'].errors?.['required']">
                                    This is required!
                                </mat-error> -->
                            </mat-form-field>
                        </div>



                        <div class="item-container">
                            <mat-label class="chkMatLabel">Is Active?</mat-label>
                            <mat-checkbox formControlName="is_active" class="matInputCls chkMatCheckbox"
                                id="chkIsActive">
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="side-container">

                        <!-- Nom.Size -->
                        <div class="item-container mt-1e">
                            <mat-label class="matLabel">Nom.Size</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="nom_size" (selectionChange)="populatePipeSchedule($event)">
                                    <ng-container>
                                        @for (item of pipeBoreList; track item) {
                                        <mat-option [value]="item.pipe_bore">{{item.pipe_bore}}</mat-option>
                                        }
                                    </ng-container>
                                </mat-select>
                                <!-- <mat-error class="text-danger" *ngIf="weldDetailsForm.controls['nom_size'].errors?.['required']">
                                    This is required!
                                </mat-error> -->
                            </mat-form-field>
                        </div>

                        <!-- Pipe Schdl -->
                        <div class="item-container">
                            <mat-label class="matLabel">Pipe Schdl</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="pipe_schedl"
                                    (selectionChange)="populateThknsDiamtrLen($event)">
                                    <ng-container>
                                        @for (item of pipeScheduleList; track item) {
                                        <mat-option [value]="item.pipe_schedule">{{item.pipe_schedule}}</mat-option>
                                        }
                                    </ng-container>
                                </mat-select>
                                <!--  <mat-error class="text-danger" *ngIf="weldDetailsForm.controls['pipe_schdl'].errors?.['required']">
                                    This is required!
                                </mat-error> -->
                            </mat-form-field>
                        </div>
                        <!-- Attachment -->
                        <div class="item-container">
                            <mat-label class="matLabel">Attachment</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="attachment_id">
                                    <ng-container>
                                        @for (item of lookupDisciplineList; track item) {
                                        <mat-option [value]="item.disc_id">{{item.disc_title}}</mat-option>
                                        }
                                    </ng-container>
                                </mat-select>
                                <!-- <mat-error class="text-danger" *ngIf="weldDetailsForm.controls['attachment'].errors?.['required']">
                                    This is required!
                                </mat-error> -->
                            </mat-form-field>
                        </div>

                        <!-- Thickness -->
                        <div class="item-container">
                            <mat-label class="matLabel">Thickness</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtthickness" class="matInputCls" name="text"
                                    formControlName="thickness" maxlength="11" pattern="^\d{1,5}(\.\d{1,5})?$">
                                <!--  <mat-error class="text-danger" *ngIf="weldDetailsForm.controls['thickness'].errors?.['required']">
                                    This is required!
                                </mat-error> -->
                                <mat-error class="text-danger"
                                    *ngIf="weldDetailsForm.controls['thickness'].errors?.['pattern']">
                                    Invalid Thickness!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!-- Diameter -->
                        <div class="item-container">
                            <mat-label class="matLabel">Diameter</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtdiameter" class="matInputCls" name="text"
                                    formControlName="diameter" maxlength="11" pattern="^\d{1,5}(\.\d{1,5})?$">
                                <mat-error class="text-danger"
                                    *ngIf="weldDetailsForm.controls['diameter'].errors?.['pattern']">
                                    Invalid Diameter!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!-- Length -->
                        <div class="item-container">
                            <mat-label class="matLabel">Length</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtlength" class="matInputCls" name="text"
                                    formControlName="length" maxlength="11" pattern="^\d{1,5}(\.\d{1,5})?$">
                                <mat-error class="text-danger"
                                    *ngIf="weldDetailsForm.controls['length'].errors?.['pattern']">
                                    Invalid Length!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="ndt-container">
                            <fieldset class="p-0">
                                <div class="item-container">
                                    <mat-label class="chkMatLabel">PMI</mat-label>
                                    <mat-checkbox formControlName="pmi" class="matInputCls" id="chkIsActive">
                                    </mat-checkbox>
                                    <mat-label class="chkMatLabel">HT</mat-label>
                                    <mat-checkbox formControlName="ht" class="matInputCls" id="chkIsActive">
                                    </mat-checkbox>
                                    <mat-label class="chkMatLabel">Ferr. Count</mat-label>
                                    <mat-checkbox formControlName="ferr_count" class="matInputCls" id="chkIsActive">
                                    </mat-checkbox>
                                </div>
                            </fieldset>
                        </div>
                        <!-- MAterial I and Material II -->
                        <div class="ndt-container">
                            <fieldset>
                                <legend>Material I</legend>
                                <!-- Material Type -->
                                <div class="item-container">
                                    <mat-label class="matLabel">Type</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <mat-select formControlName="material_type1_id"
                                            (selectionChange)="mType1SelectionChange($event)">
                                            <ng-container>
                                                @for (item of materialTypeList; track item) {
                                                <mat-option
                                                    [value]="item.master_id">{{item.code}}-{{item.description}}</mat-option>
                                                }
                                            </ng-container>
                                        </mat-select>
                                        <!-- <mat-error class="text-danger" *ngIf="weldDetailsForm.controls['material_type_id'].errors?.['required']">
                                            This is required!
                                        </mat-error> -->
                                    </mat-form-field>
                                </div>
                                <!-- Material Grade -->
                                <div class="item-container">
                                    <mat-label class="matLabel">Grade</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <mat-select formControlName="material_grade1_id">
                                            <ng-container>
                                                @for (item of materialGradeList; track item) {
                                                <mat-option
                                                    [value]="item.master_id">{{item.code}}-{{item.description}}</mat-option>
                                                }
                                            </ng-container>
                                        </mat-select>
                                        <!--  <mat-error class="text-danger" *ngIf="weldDetailsForm.controls['material_grade_id'].errors?.['required']">
                                            This is required!
                                        </mat-error> -->
                                    </mat-form-field>
                                </div>
                            </fieldset>
                            <fieldset>
                                <legend>Material II</legend>
                                <!-- Material Type -->
                                <div class="item-container">
                                    <mat-label class="matLabel">Type</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <mat-select formControlName="material_type2_id"
                                            (selectionChange)="mType2SelectionChange($event)">
                                            <ng-container>
                                                @for (item of materialTypeList; track item) {
                                                <mat-option
                                                    [value]="item.master_id">{{item.code}}-{{item.description}}</mat-option>
                                                }
                                            </ng-container>
                                        </mat-select>
                                        <!--  <mat-error class="text-danger" *ngIf="weldDetailsForm.controls['material_type_id'].errors?.['required']">
                                            This is required!
                                        </mat-error> -->
                                    </mat-form-field>
                                </div>
                                <!-- Material Grade -->
                                <div class="item-container">
                                    <mat-label class="matLabel">Grade</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <mat-select formControlName="material_grade2_id">
                                            <ng-container>
                                                @for (item of materialGradeList2; track item) {
                                                <mat-option
                                                    [value]="item.master_id">{{item.code}}-{{item.description}}</mat-option>
                                                }
                                            </ng-container>
                                        </mat-select>
                                        <!-- <mat-error class="text-danger" *ngIf="weldDetailsForm.controls['material_grade_id'].errors?.['required']">
                                            This is required!
                                        </mat-error> -->
                                    </mat-form-field>
                                </div>
                            </fieldset>
                        </div>
                        <div class="item-container">
                            <mat-label class="matLabel">Narrative</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <textarea matInput id="txtNarrative" rows="3" class="matInputCls"
                                    formControlName="narrative" maxlength="500"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button (click)="backtoList()" class="bckBtn">Back</button>
            <button mat-raised-button color="primary" gap="20px" class="mainBtn" (click)="save()">Save</button>
        </mat-card-actions>
    </mat-card>
</div>

<!-- NDT Test Accordian:START -->
<div class="card">
    <mat-accordion class="example-headers-align" multi="true">
        <mat-expansion-panel [expanded]="step() === 0" (opened)="setStep(0)">
            <mat-expansion-panel-header>
                <mat-panel-title> NDT Test </mat-panel-title>
                <mat-divider></mat-divider>
                <mat-panel-description class="process_addBtn">
                    <button matTooltip="Add Process" matTooltipPosition='above' mat-icon-button class="mat_icon_btn"
                        (click)="addNDTTest($event)">
                        <mat-icon>add</mat-icon>
                    </button>
                    <button matTooltip="Delete Process" matTooltipPosition='above' mat-icon-button
                        class="mat_icon_btn_delete" (click)="deleteNDTTest($event)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <dx-data-grid width="100%" #WeldRegNDTTestDataGrid id="gridSelectedNDTTest"
                    [dataSource]="weldRegNDTTestModelList" keyExpr="test_id" [showBorders]="true"
                    [showColumnLines]="true" [showRowLines]="true" [rowAlternationEnabled]="true"
                    (onSelectionChanged)="onSelectionChanged($event)">
                    <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>
                    <dxi-column dataField="test_id" [width]="100" caption="Test ID" [visible]="false"></dxi-column>
                    <!-- <dxi-column dataField="weld_prcd_prss_map_code" [width]="100" caption="Map Code"></dxi-column> -->
                    <dxi-column dataField="test_type" [width]="100" caption="Test Type"></dxi-column>
                    <dxi-column dataField="percentage" [width]="100" caption="Percentage"></dxi-column>
                    <dxi-column dataField="test_procedure" [width]="100" caption="Test Procedure"></dxi-column>
                    <!-- <dxi-column dataField="is_active" [width]="100" caption="Is Active?"></dxi-column> -->
                </dx-data-grid>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<!-- NDT Test Accordian:END -->