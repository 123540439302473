<div class="card">
    <mat-card appearance="outlined">
        <mat-card-header>
            <span class="card-title">Project Information</span>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="pForm" novalidate class="form">
                <div class="flex-container">
                    <div class="side-container">
                        <div class="item-container mt-1e disNone">
                            <mat-label class="matLabel">Project ID</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtProjectID" name="text" class="matInputCls"
                                    formControlName="project_id" maxlength="120">
                            </mat-form-field>
                        </div>
                        
                        <div class="item-container mt-1e">
                            <mat-label class="matLabel asterix">Project Code</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtProjectCode" name="text" class="matInputCls toUpperCase"
                                    formControlName="project_code" maxlength="25" appOnlyAlphanumeric>
                                <mat-error class="text-danger" *ngIf="pForm.controls['project_code'].errors?.['required']">
                                    This is required!
                                </mat-error>
                                <!-- <mat-error class="text-danger" *ngIf="pForm.controls['project_code'].errors?.['pattern']">
                                    Alphanumeric only
                                </mat-error> -->
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel asterix">Project Title</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtProjectTitle" name="text" class="matInputCls"
                                    formControlName="project_title" maxlength="255">
                                <mat-error class="text-danger" *ngIf="pForm.controls['project_title'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        
                        <div class="item-container">
                            <mat-label class="matLabel">Parent Project</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="parent_project_id" class="matInputCls">
                                    @for (Item of pModelList; track Item) {
                                    <mat-option [value]="Item.project_id">{{Item.project_code}} -
                                        {{Item.project_title}}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>
                        
                        <div class="item-container">
                            <mat-label class="matLabel">Start Date</mat-label>
                            <mat-form-field appearance="outline" class="small matDateField">
                                <input matInput [matDatepicker]="picker" id="txtStartDate" name="textStartDate"
                                    formControlName="start_date" class="matInputCls">
                                 <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                                 <mat-datepicker-toggle style="width: 1em;" matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                 <mat-datepicker style="width: 20px;" #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        
                        <div class="item-container">
                            <mat-label class="matLabel">Project Site</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="site_id" class="matInputCls">
                                    @for (Item of sModelList; track Item) {
                                    <mat-option [value]="Item.site_id">{{Item.site_code}} -
                                        {{Item.site_description}}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="chkMatLabel">Is Active?</mat-label>
                            <mat-checkbox formControlName="is_active" class="matInputCls chkMatCheckbox" id="chkIsActive">
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="side-container">
                        
                        <div class="item-container mt-1e">
                            <mat-label class="matLabel">Project Status</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="project_status_code" class="matInputCls">
                                    @for (Item of L_ProjectStatus; track Item) {
                                    <mat-option [value]="Item.project_status_code">
                                        {{Item.project_status_Description}}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">Status Modified By</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtProjectTitle" name="text" class="matInputCls"
                                    formControlName="status_modified_by" maxlength="255">                                
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">Status Modified On</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtStartDate" name="textStartDate"
                                    formControlName="status_modified_dtm" class="matInputCls">
                                 <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->                                 
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button (click)="backtoList()" class="bckBtn">Back</button>
            <button mat-raised-button [disabled]="!isSaveDisabled()" color="primary" gap="20px" class="mainBtn" (click)="save()">Save</button>
        </mat-card-actions>
    </mat-card>
</div>