import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../APIServices/UserService';
import { utility } from '../../../util';
import { DevExtremeModule } from '../../../core/devExtreme.module'
import { ActivatedRoute, Router } from '@angular/router';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { MatDialog } from '@angular/material/dialog';
import { UserGroupMapComponent } from '../user-group-map/user-group-map.component';
import { UserSiteMapComponent } from '../user-site-map/user-site-map.component';
import { MaterialModule } from '../../../core/material-module';
import { FormsModule } from '@angular/forms';
import { siteModel } from '../../../models/siteModel';
import { SiteService } from '../../../APIServices/SiteService';
import { UserProjectMapComponent } from '../user-project-map/user-project-map.component';
import { ProjectService } from '../../../APIServices/ProjectService';
import { ToolbarButtonsComponent } from '../../../header/toolbar-buttons/toolbar-buttons.component';
import { UtilityService } from '../../../APIServices/UtilityService';
import { CommonModule } from '@angular/common';
import { SearchableDropdownComponent } from '../../common/searchable-dropdown/searchable-dropdown.component';

@Component({
  selector: 'app-user-list',
  standalone: true,
  imports: [DevExtremeModule, MaterialModule, FormsModule, ToolbarButtonsComponent,SearchableDropdownComponent, CommonModule],
  templateUrl: './user-list.component.html',
  styleUrl: './user-list.component.scss'
})
export class UserListComponent implements OnInit {
  recordlist: any;
  uModelList: any[] = [];
  uOriginalModelList: any[] = [];
  sModelList: any[] = [];
  selectedsite: any;
  sNew: any;
  dModelList: any[] = [];
  selectedInfo: any;
  dxSelectedRowsData: any;
  title: any = "User Management";

  dStatusFilter: any = [
    {
      valueMenber: "",
      displayMember: "All"
    },
    {
      valueMenber: true,
      displayMember: "Active"
    },
    {
      valueMenber: false,
      displayMember: "Inactive"
    }];
  selectedStatusFilter: any = "";
  currentUrl: string;
  toolbarItems:any; 
  buttonAccess: any;

  constructor(
    private userService: UserService, 
    private router: Router,
    private siteService: SiteService,
    private projectService: ProjectService,
    private matDialog: MatDialog,
    private utilService: UtilityService,
    private route: ActivatedRoute
  ) {}
  async ngOnInit() {
    this.sNew = new siteModel();
    this.sNew.site_id = 0;
    this.sNew.site_code = "UA";
    this.sNew.site_description = "Unassigned";
    this.currentUrl = this.route.snapshot.url[0].path;
    this.getButtonsAccess(this.currentUrl);
    await this.populateSite();
    await this.Index();
  }
  async getButtonsAccess(currRoute: any) {
      (await this.utilService.getAllButtonAccess(currRoute)).subscribe((apiResponse: any) => {
        let resData = utility.extractJsonData(apiResponse, 0);
        this.buttonAccess = resData ? resData[0] : '';
        this.buttonInitialization(this.buttonAccess);
      });
  }

   buttonInitialization(buttonAccess: any) {
    console.log('buttonAccess',buttonAccess.can_edit);
      this.toolbarItems = [
        { label: 'Map Site', path: '', iconName: '', action: () => this.openDialogForSiteMap(), hidden: false },
        { label: 'Map Group', path: '', iconName: '', action: () => this.openDialog(), hidden: false },
        { label: 'Map Project', path: '', iconName: '', action: () => this.openDialogProjectMap(), hidden: false },
        { label: 'Add User', path: '', iconName: '', action: () => this.RouteToCreateUser(), hidden: !buttonAccess?.can_add },
        { label: 'Inactivate User', path: '', iconName: '', action: () => this.DeleteUser(), hidden: !buttonAccess?.can_delete }
      ];
    }

  public async Index() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    // this.selectedsite = loggedInUser.site_id;
    (await this.userService.getAllUserList(loggedInUser.logon_id, loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.uModelList = utility.extractJsonData(apiResponse, 0);
      this.uOriginalModelList = this.uModelList;
      this.retrieveFilter();
      this.filterBySite();
    });
  }
  RouteToUserDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    let rowData = e.row?.data;
    if (rowData == null) {
      this.router.navigate(['./userdetails']);
    }
   // let dt = e.row?.data;
    rowData.can_edit = this.buttonAccess?.can_edit;
    rowData.can_view = this.buttonAccess?.can_view;
    // var user_id=this.userService.LoggedInUserEmail();
    var selectedUserID =rowData.user_id;

    let params = {
      user_id: selectedUserID
    };
    this.router.navigate(['./userdetails'], { queryParams: params });
    var uModel;
    if (rowData.site_id == undefined || rowData.site_id == null) {

      var localUser = JSON.stringify(rowData);
      uModel = <any>JSON.parse(localUser);

      var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

      uModel.site_id = loggedInUser.site_id;
      uModel.site_code = loggedInUser.site_code;
      uModel.site_description = loggedInUser.site_description;
      //uModel.can_edit = this.buttonAccess?.can_edit;
    }
    else {
      var localUser = JSON.stringify(rowData);
      uModel = <any>JSON.parse(localUser);
    }
    this.userService.setSelectedInfo(uModel);
    e.event?.preventDefault();
  };

  RouteToCreateUser() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    var uModel = {
      site_id: loggedInUser.site_id,
      site_code: loggedInUser.site_code,
      site_description: loggedInUser.site_description
    };

    this.userService.setSelectedInfo(uModel);
    this.router.navigate(['./userdetails']);
  }
  async MapUserToSite() {
    if (this.selectedInfo == null || this.selectedInfo.length == undefined) {
      alert("Please select a user");
      return;
    }
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    var userSiteMapInfo = {
      site_id: loggedInUser.site_id,
      site_code: loggedInUser.site_code,
      requester_id: loggedInUser.logon_id,
      userList: this.selectedInfo
    };

    //Call API to update mapping
    (await this.userService.mapUserToSite(userSiteMapInfo)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.Index();
    });

  }
  onSelectionChanged(e) {
    this.selectedInfo = e.selectedRowsData;
    this.userService.setSelectedInfo(e.selectedRowsData);
    if (e.selectedRowsData.length == 1) {
      this.populateUserProjects(e.selectedRowsData[0].user_id);
    }
    else {
      this.populateUserProjects(undefined);
    }
  }
  openDialog(): void {
    if (this.selectedInfo == null || this.selectedInfo.length == undefined
      || this.selectedInfo.length == 0
    ) {
      alert("Please select a user");
      return;
    }

    const dialogRef = this.matDialog.open(UserGroupMapComponent, {
      width: '300px',
      height: '200px',
      maxHeight: '600px',
      autoFocus: false,
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.Index();
    });
  }
  async DeleteUser() {
    if (this.selectedInfo != null && this.selectedInfo.length == undefined) {
      alert("Please select a user");
      return;
    }

    if (!confirm("Are you sure to Inactivate selected user?")) {
      return;
    }

    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    var userInfoDelete = {
      userList: this.selectedInfo,
      requester_id: loggedInUser.logon_id
    };

    (await this.userService.deleteUser(userInfoDelete)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.Index();
    });
  }
  openDialogForSiteMap(): void {
    if (this.selectedInfo == null || this.selectedInfo.length == undefined
      || this.selectedInfo.length == 0
    ) {
      alert("Please select a user");
      return;
    }

    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    if (loggedInUser.site_id == 1 || loggedInUser.site_code == 'GLB') {
      const dialogRef = this.matDialog.open(UserSiteMapComponent, {
        width: '300px',
        height: '250px',
        maxHeight: '600px',
        autoFocus: false,
        disableClose: true,
        data: {}
      });

      dialogRef.afterClosed().subscribe(result => {
        this.Index();
      });
    }
    else {
      if (!confirm("Are you sure to map the user in site - " + loggedInUser.site_description)) {
        return;
      }
      this.MapUserToSite();
    }
  }

  onItemSelect(selectedItem: any) {
    this.selectedsite = selectedItem['site_id'];
    this.storeFilter();
    this.filterBySite();
  }

  onItemSelectStatus(selectedItem: any) {
    this.selectedStatusFilter = selectedItem['valueMenber'];
    this.storeFilter();
    this.filterBySite();
  }

  filterBySite() {
    this.resetFilter();
    this.uModelList = this.uModelList.filter((v) => 
      v.site_id === (this.selectedsite == 0 ? null : this.selectedsite) &&
      ( this.selectedStatusFilter === "" || v.is_active === (this.selectedStatusFilter) )
    );
    
  }
  resetFilter() {
    this.uModelList = this.uOriginalModelList;
  }
  async populateSite() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    (await this.siteService.getActiveSiteList(loggedInUser.logon_id, loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.sModelList = utility.extractJsonData(apiResponse, 0);
      this.sModelList.push(this.sNew);

      if (this.sModelList != null && this.sModelList.length > 0)
        this.selectedsite = this.sModelList[0].site_id;
    });
  }

  openDialogProjectMap() {
    if (this.selectedInfo == null || this.selectedInfo.length == undefined) {
      alert("Please select a user");
      return;
    }

    const dialogRef = this.matDialog.open(UserProjectMapComponent, {
      width: '300px',
      height: '200px',
      maxHeight: '600px',
      autoFocus: false,
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      //this.animal = result;
    });
  }
  async populateUserProjects(user_id: any) {
    if (user_id == undefined) {
      this.dModelList = [];
      return;
    }
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    (await this.projectService.getUserWiseProjectList(loggedInUser.logon_id, loggedInUser.site_id, user_id)).subscribe((apiResponse: any) => {
      this.dModelList = utility.extractJsonData(apiResponse, 0);
    });
  }

  storeFilter() {
    var fProperty = {
      site_id: this.selectedsite,
      valueMenber: this.selectedStatusFilter
    };
    utility.setSelectedFilter(fProperty);
  }
  retrieveFilter() {
    var fProperty = utility.getSelectedFilter();
    this.selectedsite = fProperty.site_id;
    this.selectedStatusFilter = fProperty.valueMenber;
  }
  resetlcFilter() {
    utility.setSelectedFilter(null);
  }
}
