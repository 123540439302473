import { Component, OnInit, signal, viewChild } from '@angular/core';
import { MaterialModule } from '../../../../core/material-module';
import { CommonModule } from '@angular/common';
import { utility } from '../../../../util';
import { ToolbarButtonsComponent } from '../../../../header/toolbar-buttons/toolbar-buttons.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from '../../../../APIServices/UtilityService';
import { SearchableDropdownComponent } from '../../../common/searchable-dropdown/searchable-dropdown.component';
import { WeldProjectProcedureMappingsService } from '../../../../APIServices/weld/weldProjectProcedureMappings/weldProjectProcedureMappingsService';
import { MatAccordion } from '@angular/material/expansion';
import { DevExtremeModule } from '../../../../core/devExtreme.module';
import { AddProcedureToLineclassComponent } from '../add-procedure-to-lineclass/add-procedure-to-lineclass.component';
import { MatDialog } from '@angular/material/dialog';
import { WeldProcedureToLineClassService } from '../../../../APIServices/weld/weldProjectProcedureMappings/weldProcedureToLineclassService';
import { AddProcedureProjectComponent } from '../add-procedure-project/add-procedure-project.component';
import { WeldProcedureToProjectService } from '../../../../APIServices/weld/weldProjectProcedureMappings/weldProcedureToProjectService';
import { WeldProcedureToMaterialTypeService } from '../../../../APIServices/weld/weldProjectProcedureMappings/weldProcedureToMaterialTypeService';
import { AddProcedureMaterialtypeComponent } from '../add-procedure-materialtype/add-procedure-materialtype.component';

@Component({
  selector: 'app-weld-project-procedure-mappings',
  standalone: true,
  imports: [
    MaterialModule,
    CommonModule,
    ToolbarButtonsComponent,
    SearchableDropdownComponent,
  DevExtremeModule],
  templateUrl: './weld-project-procedure-mappings.component.html',
  styleUrl: './weld-project-procedure-mappings.component.scss'
})
export class WeldProjectProcedureMappingsComponent implements OnInit{
  buttonAccess: any;
  toolbarItems: { label: string; path: string; iconName: string; action: () => any; hidden: boolean; }[];
  loggedInUser: any;
  currentUrl: string;
  title = 'Project-Procedure Mappings'
  projectModelList: any;
  selectedProject: any;
  pProjectTitle: any;
  selectFilteredProject: any;
  accordion = viewChild.required(MatAccordion);
  selectedProcedureIndex: any;
  selectedProcedureInfo: any;
  weldProcedureModelList: any;
  mappedLineClassCodes: any;
  queryParams: undefined;
  weldProcedureLineClassModelList: any;
  selectedProcessLineclssInfo: any;
  mappedProceduresCodes: any;
  lastSelectedProcedure: any;
  weldProcedureMaterialTypeModelList: any;
  mappedMaterialTypeCodes: any;
  selectedMaterialTypeInfo: any;

  constructor(
    private router: Router,
    private utilService: UtilityService,
    private route: ActivatedRoute,
    private weldProjProcdMappService: WeldProjectProcedureMappingsService,
    private matDialog: MatDialog,
    private weldProcedureToLineclassService: WeldProcedureToLineClassService,
    private weldProcedureToProjectService: WeldProcedureToProjectService,
    private weldProcedureToMaterialTypeService: WeldProcedureToMaterialTypeService

  ) {}

  step = signal(0);

  setStep(index: number) {
    this.step.set(index);
  }

  nextStep() {
    this.step.update(i => i + 1);
  }

  prevStep() {
    this.step.update(i => i - 1);
  }
  
  async ngOnInit() {
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.currentUrl = this.route.snapshot.url[0].path;
    this.getButtonsAccess(this.currentUrl);
   // await this.index();
   // await this.populateResourceOptionList();
   this.getProjectListByUserID();
  }

  async getButtonsAccess(currRoute: any) {
    (await this.utilService.getAllButtonAccess(currRoute)).subscribe((apiResponse: any) => {
      let resData = utility.extractJsonData(apiResponse, 0);
      this.buttonAccess = resData ? resData[0] : '';
      this.buttonInitialization(this.buttonAccess);
    });
  }

  buttonInitialization(buttonAccess: any) {
    console.log('buttonAccess',buttonAccess.can_edit);
    this.toolbarItems = [
      /* { label: 'Add Weld Procedure', path: '', iconName: '', action: () => this.addWeldProcedure(), hidden: !buttonAccess?.can_add },
      { label: 'Inactivate Weld Procedure', path: '', iconName: '', action: () => this.deleteWeldProcedure(), hidden: !buttonAccess?.can_delete } */
      /* { label: 'Map Process', path: '', iconName: '', action: () => this.mapProcessToProcedure(), hidden: false } */
    ];
  }

  onItemSelect(selectedItem: any) {
    this.selectedProject = selectedItem['project_id'];
    this.pProjectTitle = selectedItem['project_title'];

    this.storeFilter();
    this.onSelectionChanged();
  }

  getProjectListByUserID() {
    this.weldProjProcdMappService.getUserWiseProjectList().subscribe((apiResponse)=> {
      this.projectModelList = utility.extractJsonData(apiResponse, 0);
      console.log('this.projectModelList',this.projectModelList);
    })
  }

  async onSelectionChanged() {
    if (this.selectedProject != null && this.selectedProject != undefined) {
      /* this.selectedJobIndex = null
      this.selectedItemIndex = null
      this.selectedDiscIndex = null
      this.selectedTestIndex = null */

      this.selectFilteredProject = this.projectModelList.filter((v) =>
        v.project_id === this.selectedProject);

      this.populateWeldProcedureByProject();
    }
  }

  onSelectionChangedLineClass(e) {
    this.selectedProcessLineclssInfo = e.selectedRowsData;
    this.weldProcedureToLineclassService.setSelectedInfo(e.selectedRowsData);
  }

  async onWeldProcedureSelectionChanged(e) {
    this.selectedProcedureIndex = e.selectedRowKeys;
    this.selectedProcedureInfo = e.selectedRowsData;
    var selectedProcedureInfoList = this.selectedProcedureInfo?.map((item) => {return item.weld_prcd_project_map_id});
    this.lastSelectedProcedure = selectedProcedureInfoList[selectedProcedureInfoList.length - 1];
    this.weldProjProcdMappService.setSelectedInfo(this.selectedProcedureInfo);
    this.weldProcedureLineClassModelList = null;
    this.weldProcedureMaterialTypeModelList = null;
    this.populateLineClassByWeldProcedure();
    this.populateMaterialTypeByWeldProcedure();
  }

  onSelectionChangedMaterialType(e) {
    this.selectedMaterialTypeInfo = e.selectedRowsData;
    this.weldProcedureToMaterialTypeService.setSelectedInfo(e.selectedRowsData);
  }

  onProcedureContentReadyHandler(e) {
    if (this.selectedProcedureIndex == null || this.selectedProcedureIndex == undefined) {
      e.component.selectRowsByIndexes(0);
    }
    else {
      e.component.selectRows(this.selectedProcedureIndex);
    }
  }

  populateWeldProcedureByProject() {
    //var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    if (this.selectedProject != null && this.selectedProject != undefined) {
      //var project_id = this.selectedpInfo[0].project_id;
       this.weldProjProcdMappService.getAllProjectProcedureList(this.selectedProject).subscribe((apiResponse: any) => {
        this.weldProcedureModelList = utility.extractJsonData(apiResponse, 0);
        if (this.weldProcedureModelList) {
          this.mappedProceduresCodes = this.weldProcedureModelList.map(item => item.weld_procedure_id);
        } else {
          this.mappedProceduresCodes.length = 0;
        }
      });
    }
  }

  addProcedureToProject(event: MouseEvent) {
    event.stopPropagation();
    this.openProcedureDialog(true);
  }

  deleteProcedureFromProject(event: MouseEvent) {
    event.stopPropagation();
    if (!this.selectedProcedureInfo || this.selectedProcedureInfo.length === 0) {
      alert("Please select Procedure to delete !!");
      return;
    }

    if (!confirm("Are you sure to Delete selected Procedure?")) {
      return;
    }

    var procedureInfoDelete = {
      weld_prcd_project_map_list: this.selectedProcedureInfo,
      site_id: this.loggedInUser.site_id
    };

    this.weldProcedureToProjectService.deleteProceduresFromProject(procedureInfoDelete).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.populateWeldProcedureByProject();
    });
  }

  openProcedureDialog(isNew: boolean): void {
    const dialogRef = this.matDialog.open(AddProcedureProjectComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        project_id: this.selectedProject,
        availableItems: this.mappedProceduresCodes,
        selectedProcedureList: this.selectedProcedureIndex
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.populateWeldProcedureByProject();
    });
  }

  populateLineClassByWeldProcedure() {
    if (this.lastSelectedProcedure != undefined) {
      this.weldProcedureToLineclassService.getLineClassByProcdureID(this.lastSelectedProcedure).subscribe(data =>{
        this.weldProcedureLineClassModelList = utility.extractJsonData(data, 0);
        if (this.weldProcedureLineClassModelList) {
          this.mappedLineClassCodes = this.weldProcedureLineClassModelList.map(item => item.lineclass_id);
        } else {
          this.mappedLineClassCodes.length = 0;
        }
      });
    }
  }

  addLineClassToProcedure(event: MouseEvent) {
    event.stopPropagation();
    if (this.weldProcedureModelList && this.selectedProcedureInfo.length != 0){
      this.openLineClassDialog(true);
    } else {
      alert("Please add / select Weld Procedure !!");
      return;
    }
  }

  deleteLineClassFromProcedure(event: MouseEvent) {
    event.stopPropagation();
    if (!this.selectedProcessLineclssInfo) {
      alert("Please select Line Class to delete !!");
      return;
    }

    if (!confirm("Are you sure to Delete selected Line Class?")) {
      return;
    }

    var lineclassInfoDelete = {
      weld_prcd_project_line_class_map_list: this.selectedProcessLineclssInfo,
      site_id: this.loggedInUser.site_id
    };

    this.weldProcedureToLineclassService.deleteLineClassFromProcedure(lineclassInfoDelete).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.populateLineClassByWeldProcedure();
    });
  }

  openLineClassDialog(isNew: boolean): void {
    const dialogRef = this.matDialog.open(AddProcedureToLineclassComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        availableItems: this.mappedLineClassCodes,
        selectedWeldProcedureList: this.selectedProcedureInfo
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.populateLineClassByWeldProcedure();
    });
  }


  populateMaterialTypeByWeldProcedure() {
    if (this.lastSelectedProcedure != undefined) {
      this.weldProcedureToMaterialTypeService.getMaterialTypeByProcdureID(this.lastSelectedProcedure).subscribe(data =>{
        this.weldProcedureMaterialTypeModelList = utility.extractJsonData(data, 0);
        if (this.weldProcedureMaterialTypeModelList) {
          this.mappedMaterialTypeCodes = this.weldProcedureMaterialTypeModelList.map(item => item.lineclass_id);
        } else {
          this.mappedMaterialTypeCodes.length = 0;
        }
      });
    }
  }

  addMaterialTypeToProcedure(event: MouseEvent) {
    event.stopPropagation();
    if (this.weldProcedureModelList && this.selectedProcedureInfo.length != 0){
      this.openMaterialTypeDialog(true);
    } else {
      alert("Please add / select Weld Procedure !!");
      return;
    }
  }
  
  openMaterialTypeDialog(isNew: boolean): void {
    const dialogRef = this.matDialog.open(AddProcedureMaterialtypeComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        availableItems: this.mappedMaterialTypeCodes,
        selectedWeldProcedureList: this.selectedProcedureInfo
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.populateMaterialTypeByWeldProcedure();
    });
  }

  deleteMaterialTypeFromProcedure(event: MouseEvent) {
    event.stopPropagation();
    if (!this.selectedMaterialTypeInfo) {
      alert("Please select Material Type to delete !!");
      return;
    }

    if (!confirm("Are you sure to Delete selected Material Type?")) {
      return;
    }

    var materialTypeInfoDelete = {
      weld_prcd_project_mat_map_list: this.selectedMaterialTypeInfo,
      site_id: this.loggedInUser.site_id
    };

    this.weldProcedureToMaterialTypeService.deleteMaterialTypeFromProcedure(materialTypeInfoDelete).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.populateMaterialTypeByWeldProcedure();
    });
  }

  storeFilter() {
    var fProperty = {
      project_id: this.selectedProject
    };
    utility.setSelectedFilter(fProperty);
  }
  retrieveFilter() {
    var fProperty = utility.getSelectedFilter();
    this.selectedProject = fProperty.project_id;
  }
  resetlcFilter() {
    utility.setSelectedFilter(null);
  }

}
