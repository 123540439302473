import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import { MaterialModule } from '../../../../core/material-module';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { utility } from '../../../../util';
import { DevExtremeModule } from '../../../../core/devExtreme.module';
import { MatDialog } from '@angular/material/dialog';
import { AddNDTTestComponent } from '../add-ndt-test/add-ndt-test.component';
import { WeldMasterService } from '../../../../APIServices/master/weld/WeldMasterService';
import { WeldRegisterService } from '../../../../APIServices/WeldNDT/WeldRegister/WeldRegisterService';
import { WeldSearchComponent } from '../../../common/weld-search/weld-search.component';
import { CommonService } from '../../../../APIServices/CommonService';

@Component({
  selector: 'app-weld-register-details',
  standalone: true,
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, DevExtremeModule],
  templateUrl: './weld-register-details.component.html',
  styleUrl: './weld-register-details.component.scss'
})
export class WeldRegisterDetailsComponent {
  weldDetailsForm: FormGroup;
  lookupDisciplineList: any[] = [];
  loggedInUser: any;
  materialTypeList: any;
  materialGradeList: any;
  materialTypeList2: any;
  materialGradeList2: any;
  selectedMaterialType: any;
  bEditMode: boolean = false;
  jointTypeList: any;
  weldRegNDTTestModelList: any;
  selectedTestInfo: any;
  weldTypeList: any;
  categoryList: any;
  pipeDimensionList: any;
  pipeBoreList: any[] = [];
  pipeScheduleList: any;
  selectedInfo: any;
  drawing_id: any;
  weld_id: any

  constructor(private router: Router,
    private route: ActivatedRoute,
    private weldRegisterService: WeldRegisterService,
    private matDialog: MatDialog,
    private weldMasterService: WeldMasterService,
    private commonService: CommonService
  ) {
    this.initializeForm(null);
  }

  step = signal(0);

  setStep(index: number) {
    this.step.set(index);
  }
  async ngOnInit() {
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    await this.populateDisciplineList();
    await this.populatePipeDimensionList();
    await this.index();
    await this.populateCommonMasters();
  }

  async index() {
    await this.weldRegisterService.getSelectedInfo.subscribe(x =>
      this.selectedInfo = x);

    if (this.selectedInfo == null) {
      this.drawing_id = this.route.snapshot.queryParams['drawing_id'];
      this.weld_id = this.route.snapshot.queryParams['weld_id'];

      if (this.drawing_id != undefined && this.weld_id != undefined) {
        this.bEditMode = true;

        (await this.weldRegisterService.getSelectedWeld(this.drawing_id, this.weld_id)).subscribe((apiResponse: any) => {
          const jExtract = utility.extractJsonData(apiResponse, 0);
          this.selectedInfo = jExtract[0];
          this.weldRegisterService.setSelectedInfo(this.selectedInfo);
          this.populateCategoryList();
          this.mapData();
          this.initializeForm(this.selectedInfo);
        });
      }
      else {
        console.log("create");
        this.bEditMode = false;
        if (this.selectedInfo == null) {
          var fltrData = utility.getSelectedFilter();
          this.selectedInfo = fltrData;
          this.selectedInfo.weld_id = null;
          this.selectedInfo.weld_code = null;
          this.initializeForm(this.selectedInfo);
        }
        await this.populateCategoryList();
      }
    }
    else {
      this.bEditMode = true;
      await this.populateCategoryList();
      await this.mapData();
      await this.initializeForm(this.selectedInfo);
    }

  }
  async mapData() {
    // if (this.selectedInfo.nom_size != null && this.selectedInfo.nom_size != undefined) {
    //   await this.populatePipeSchedule(this.selectedInfo.nom_size);
    // }
    if (this.selectedInfo.material_type1_id != null && this.selectedInfo.material_type1_id != undefined) {
      await this.mType1SelectionChange(this.selectedInfo.material_type1_id);
    }
    if (this.selectedInfo.material_type2_id != null && this.selectedInfo.material_type2_id != undefined) {
      await this.mType2SelectionChange(this.selectedInfo.material_type2_id);
    }
  }

  private async populateDisciplineList() {
    (await this.weldRegisterService.getDiscipline()).subscribe((apiResponse: any) => {
      this.lookupDisciplineList = utility.extractJsonData(apiResponse, 0);
    });
  }
  async populateCommonMasters() {
    var cMstrTypeId = 'WT,JT,MT';
    (await this.weldMasterService.getCommonMasterData(cMstrTypeId)).subscribe((apiResponse: any) => {
      var cList = utility.extractJsonData(apiResponse, 0);
      if (cList != null) {
        //set value for Weld Type Completed list
        this.weldTypeList = utility.extractRecordFromCommonMaster(cList, 'WT');

        //set value for Joint Type Completed list
        this.jointTypeList = utility.extractRecordFromCommonMaster(cList, 'JT');

        //set value for Material Type Completed list
        this.materialTypeList = utility.extractRecordFromCommonMaster(cList, 'MT');
      }
    });
  }
  private async populateCategoryList() {
    (await this.weldRegisterService.getCategoryList(this.selectedInfo.project_id)).subscribe((apiResponse: any) => {
      this.categoryList = utility.extractJsonData(apiResponse, 0);
    });
  }

  private async populatePipeDimensionList() {
    (await this.weldRegisterService.getAllActivePipeDimension()).subscribe((apiResponse: any) => {
      this.pipeDimensionList = utility.extractJsonData(apiResponse, 0);

      this.pipeDimensionList.forEach(entry => {
        var hasData = this.pipeBoreList.filter((v) =>
          v.pipe_bore == entry.pipe_bore
        );
        if (hasData != null && hasData.length <= 0) {
          this.pipeBoreList.push({ pipe_bore: entry.pipe_bore });
        }
      });

      if (this.selectedInfo.nom_size != null && this.selectedInfo.nom_size != undefined) {
        this.populatePipeSchedule(this.selectedInfo.nom_size);
      }
    });
  }

  async populatePipeSchedule(e) {
    var nomSize = e.value == undefined ? e : e.value;
    this.pipeScheduleList = this.pipeDimensionList.filter((v) =>
      v.pipe_bore == nomSize
    );
  }
  async populateThknsDiamtrLen(e) {
    var selectedSchdl = this.pipeScheduleList.filter((v) =>
      v.pipe_schedule == e.source.triggerValue
    );
    if (selectedSchdl != null && selectedSchdl.length > 0) {
      this.weldDetailsForm.get("thickness")?.setValue(selectedSchdl[0].thickness);
      this.weldDetailsForm.get("diameter")?.setValue(selectedSchdl[0].diameter);
      var r = selectedSchdl[0].diameter / 2;
      var lnth = Math.round(2 * 3.14 * r);
      this.weldDetailsForm.get("length")?.setValue(lnth);
    }
  }
  async filterByMaterialType(cMstrTypeId: any, parent_id: any, mType: any) {
    (await this.weldMasterService.getCommonMasterData(cMstrTypeId)).subscribe((apiResponse: any) => {
      var cList = utility.extractJsonData(apiResponse, 0);
      if (cList != null) {
        //set value for material Grade Completed list
        if (mType == '1') {
          this.materialGradeList = utility.extractRecordFromCommonMaster(cList, cMstrTypeId, parent_id);
        }
        else {
          this.materialGradeList2 = utility.extractRecordFromCommonMaster(cList, cMstrTypeId, parent_id);
        }
      }
    });
  }

  async mType1SelectionChange(e) {
    var matType1 = e.value == undefined ? e : e.value;
    if (matType1 != null && matType1 != undefined) {
      await this.filterByMaterialType('MG', matType1, '1');
    }
  }

  async mType2SelectionChange(e) {
    var matType2 = e.value == undefined ? e : e.value;
    if (matType2 != null && matType2 != undefined) {
      await this.filterByMaterialType('MG', matType2, '2');
    }
  }

  onSelectionChanged(e) {
    this.selectedTestInfo = e.selectedRowsData;
    this.weldRegisterService.setSelectedInfo(e.selectedRowsData);
  }

  openSearchDialog(): void {

    var dProperty = {
      width: '400px',
      height: '500px',
      maxHeight: '600px',
      autoFocus: false,
      disableClose: true,
      data: {}
    }

    this.commonService.showDialog<WeldSearchComponent>(WeldSearchComponent, dProperty)
      .subscribe(result => {
        if (result != null && result != undefined) {
          this.onSearch(result);
        }
      });
  }

  async onSearch(fProperty: any) {
    (await this.weldRegisterService.getSelectedWeld(fProperty.drawing_id, fProperty.weld_id)).subscribe((apiResponse: any) => {
      const jExtract = utility.extractJsonData(apiResponse, 0);
      this.selectedInfo = jExtract[0];
      this.initializeForm(this.selectedInfo);
    });
  }
  async initializeForm(uInfo: any) {
    console.log(uInfo);
    if (uInfo != null && (uInfo.weld_id != null || uInfo.weld_id != undefined)) {
      this.weldDetailsForm = new FormGroup({
        drawing_id: new FormControl(uInfo?.drawing_id),
        mcd_drawing_code: new FormControl({ value: uInfo?.mcd_drawing_code, disabled: true }),
        mcd_drawing_desc: new FormControl(uInfo?.mcd_drawing_desc),
        weld_id: new FormControl(uInfo?.weld_id),
        site_id: new FormControl(uInfo?.site_id),
        project_id: new FormControl(uInfo?.project_id),
        project_code: new FormControl(uInfo?.project_code),
        project_title: new FormControl(uInfo?.project_title),
        job_id: new FormControl(uInfo?.job_id),
        job_code: new FormControl({ value: uInfo?.job_code, disabled: true }),
        item_id: new FormControl(uInfo?.item_id),
        item_code: new FormControl({ value: uInfo?.item_code, disabled: true }),
        disc_id: new FormControl({ value: uInfo?.disc_id, disabled: this.bEditMode }),
        ndt_ref: new FormControl({ value: uInfo?.ndt_ref, disabled: this.bEditMode }),
        recap_spool: new FormControl(uInfo?.recap_spool),
        weld_code: new FormControl({ value: uInfo?.weld_code, disabled: true }, [Validators.required]),
        suffix: new FormControl(uInfo?.suffix),
        sr_code: new FormControl(uInfo?.sr_code),
        type: new FormControl(uInfo?.type),
        category_id: new FormControl(uInfo?.category_id),
        joint_type_id: new FormControl(uInfo?.joint_type_id),
        nom_size: new FormControl(uInfo?.nom_size),
        pipe_schedl: new FormControl(uInfo?.pipe_schedl),
        attachment_id: new FormControl(uInfo?.attachment_id),
        thickness: new FormControl(uInfo?.thickness),
        diameter: new FormControl(uInfo?.diameter),
        length: new FormControl(uInfo?.length),
        material_type1_id: new FormControl(uInfo?.material_type1_id),
        material_grade1_id: new FormControl(uInfo?.material_grade1_id),
        material_type2_id: new FormControl(uInfo?.material_type2_id),
        material_grade2_id: new FormControl(uInfo?.material_grade2_id),
        narrative: new FormControl(uInfo?.narrative),
        pmi: new FormControl(uInfo?.pmi),
        ht: new FormControl(uInfo?.ht),
        ferr_count: new FormControl(uInfo?.ferr_count),
        is_active: new FormControl({ value: uInfo?.is_active, disabled: uInfo?.is_active }),
        requester_id: new FormControl('')
      });
    }
    else {
      this.weldDetailsForm = new FormGroup({
        drawing_id: new FormControl(uInfo?.drawing_id),
        mcd_drawing_code: new FormControl({ value: uInfo?.mcd_drawing_code, disabled: true }),
        mcd_drawing_desc: new FormControl(uInfo?.mcd_drawing_desc),
        weld_id: new FormControl(null),
        site_id: new FormControl(uInfo?.site_id),
        project_id: new FormControl(uInfo?.project_id),
        project_code: new FormControl(uInfo?.project_code),
        project_title: new FormControl(uInfo?.project_title),
        job_id: new FormControl(uInfo?.job_id),
        job_code: new FormControl({ value: uInfo?.job_code, disabled: true }),
        item_id: new FormControl(uInfo?.item_id),
        item_code: new FormControl({ value: uInfo?.item_code, disabled: true }),
        disc_id: new FormControl({ value: null, disabled: this.bEditMode }),
        ndt_ref: new FormControl({ value: null, disabled: this.bEditMode }),
        recap_spool: new FormControl(null),
        weld_code: new FormControl({ value: null, disabled: false }, [Validators.required]),
        suffix: new FormControl(null),
        sr_code: new FormControl(null),
        type: new FormControl(null),
        category_id: new FormControl(null),
        joint_type_id: new FormControl(null),
        nom_size: new FormControl(null),
        pipe_schedl: new FormControl(null),
        attachment_id: new FormControl(null),
        thickness: new FormControl(null),
        diameter: new FormControl(null),
        length: new FormControl(null),
        material_type1_id: new FormControl(null),
        material_grade1_id: new FormControl(null),
        material_type2_id: new FormControl(null),
        material_grade2_id: new FormControl(null),
        narrative: new FormControl(null),
        pmi: new FormControl(false),
        ht: new FormControl(false),
        ferr_count: new FormControl(false),
        is_active: new FormControl({ value: true, disabled: uInfo?.is_active }),
        requester_id: new FormControl('')
      });
    }
  }

  save() {
    this.weldDetailsForm.markAllAsTouched();
    const isFormValid = this.weldDetailsForm.valid;
    if (isFormValid) {
      if (this.weldDetailsForm.value['weld_id'] == null) {
        this.createItem();
      } else {
        this.updateItem();
      }
    } else {
      return;
    }
  }

  async createItem() {
    console.log(this.weldDetailsForm.getRawValue());
    (await this.weldRegisterService.createWeld(this.weldDetailsForm.getRawValue())).subscribe((apiResponse: any) => {
      var iRes = utility.responseAlert(apiResponse);
      if (iRes) this.backtoList();
    });
  }
  async updateItem() {
    console.log(this.weldDetailsForm.getRawValue());
    (await this.weldRegisterService.updateWeld(this.weldDetailsForm.getRawValue())).subscribe((apiResponse: any) => {
      var iRes = utility.responseAlert(apiResponse);
      if (iRes) this.backtoList();
    });
  }

  addNDTTest(event: any) {
    event.stopPropagation();
    this.openDialog(true);
  }

  deleteNDTTest(event: any) {
    event.stopPropagation();
    if (!this.selectedTestInfo) {
      alert("Please select Test to delete !!");
      return;
    }

    if (!confirm("Are you sure to Delete selected Test?")) {
      return;
    }

    var testInfoDelete = {
      weld_prcd_prss_map_list: this.selectedTestInfo,
      site_id: this.loggedInUser.site_id
    };

    // this.weldRegisterService.deleteTestByTestID(testInfoDelete).subscribe((apiResponse: any) => {
    //   utility.responseAlert(apiResponse);
    //   //this.getMappedProcessList();
    // });
  }

  openDialog(isNew: boolean): void {
    const dialogRef = this.matDialog.open(AddNDTTestComponent, {

      autoFocus: false,
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.getMappedProcessList();
    });
  }

  backtoList() {
    this.router.navigate(['./weldregisterlist']);
  }
}
