import { Component, Inject, NO_ERRORS_SCHEMA, OnInit, signal, viewChild } from '@angular/core';
import { MaterialModule } from '../../../core/material-module';
import { Router } from '@angular/router';
import { utility } from '../../../util';
import { DevExtremeModule } from '../../../core/devExtreme.module';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule, DOCUMENT } from '@angular/common';
import { ProjectService } from '../../../APIServices/ProjectService';
import { ProjectJobService } from '../../../APIServices/ProjectJobService';
import { ProjectItemService } from '../../../APIServices/ProjectItemService';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { DxTreeListTypes } from 'devextreme-angular/ui/tree-list';
import { AddEditJobComponent } from '../add-edit-job/add-edit-job.component';
import { AddEditItemComponent } from '../add-edit-item/add-edit-item.component';
import { ProjectDisciplineService } from '../../../APIServices/project/ProjectDisciplineService';
import { LookupService } from '../../../APIServices/LookupService';
import { AddEditDisciplineComponent } from '../add-edit-discipline/add-edit-discipline.component';
import { ToolbarButtonsComponent } from '../../../header/toolbar-buttons/toolbar-buttons.component';
import { ToolbarItem } from '../../../header/toolbar-buttons/toolbar-item';
import { MatAccordion } from '@angular/material/expansion';
import { ProjectTestsService } from '../../../APIServices/ProjectTestsService';
import { AddEditTestsComponent } from '../add-edit-tests/add-edit-tests.component';
import { SearchableDropdownComponent } from '../../common/searchable-dropdown/searchable-dropdown.component';

@Component({
  selector: 'app-project-setup',
  standalone: true,
  imports: [MaterialModule, DevExtremeModule, ToolbarButtonsComponent, SearchableDropdownComponent, CommonModule],
  templateUrl: './project-setup.component.html',
  styleUrl: './project-setup.component.scss'
})
export class ProjectSetupComponent implements OnInit {
  title: any = "Project Setup";
  pModelList: any;
  selectedProject: any;
  selectedpInfo: any;
  selectedjInfo: any;
  selectediInfo: any;
  selecteddInfo: any;
  localStorage: any;
  pJobModelList: any;
  pJobModel: any;
  pItemModelList: any;
  pItemModel: any;
  pDisciplineModel: any;
  pDisciplineModelList: any;
  pProjectTitle: any;
  lookupDisciplineList: any = [];
  pTestsModelList: any;
  testTypes: any = [];
  defaultTestCategory: any;
  lookupTestCategory: any;
  accordion = viewChild.required(MatAccordion);
  selectedInfoTests: any;
  currentSelectdCategory: any;
  selectedJobIndex: any
  selectedItemIndex: any
  selectedDiscIndex: any
  selectedTestIndex: any
  selectFilteredProject: any;

  constructor(@Inject(DOCUMENT) private document: Document, private projectService: ProjectService
    , private router: Router
    , private projectJobService: ProjectJobService
    , private projectItemService: ProjectItemService
    , private matDialog: MatDialog
    , private projectDisciplineService: ProjectDisciplineService
    , private projectTestsService: ProjectTestsService
    , private lookupService: LookupService) {

    this.localStorage = document.defaultView?.localStorage;
  }

  step = signal(0);

  setStep(index: number) {
    this.step.set(index);
  }

  nextStep() {
    this.step.update(i => i + 1);
  }

  prevStep() {
    this.step.update(i => i - 1);
  }
  async ngOnInit() {

    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    (await this.lookupService.getTestCategory(loggedInUser.logon_id, loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.lookupTestCategory = utility.extractJsonData(apiResponse, 0);
      this.defaultTestCategory = this.lookupTestCategory[0].category_id;
    });
    await this.Index();
  }
  public async Index() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    this.retrieveFilter();
    //Fetch Group List
    (await this.projectService.getUserWiseProjectList(loggedInUser.logon_id, loggedInUser.site_id, loggedInUser.user_id)).subscribe((apiResponse: any) => {
      this.pModelList = utility.extractJsonData(apiResponse, 0);

      if (this.pModelList != null && this.pModelList.length > 0) {
        this.selectedProject = (this.selectedProject == null || this.selectedProject == undefined) ? this.pModelList[0].project_id : this.selectedProject;
        this.onSelectionChanged();
      };
    });
  }

  onItemSelect(selectedItem: any) {
    this.selectedProject = selectedItem['project_id'];
    this.pProjectTitle = selectedItem['project_title'];

    this.storeFilter();
    this.onSelectionChanged();
  }

  async onSelectionChanged() {
    if (this.selectedProject != null && this.selectedProject != undefined) {
      this.selectedJobIndex = null
      this.selectedItemIndex = null
      this.selectedDiscIndex = null
      this.selectedTestIndex = null

      this.selectFilteredProject = this.pModelList.filter((v) =>
        v.project_id === this.selectedProject);

      await this.populateJobByProject();
    }
    // if (e.selectedRowsData.length > 0) {
    //   this.selectedpInfo = e.selectedRowsData;
    //   this.selectedJobIndex = null
    //   this.selectedItemIndex = null
    //   this.selectedDiscIndex = null
    //   this.selectedTestIndex = null

    //   this.pProjectTitle = e.selectedRowsData[0].project_title;
    //   this.projectService.setSelectedInfo(this.selectedpInfo);
    //   await this.populateJobByProject();
    // }
  }
  async populateJobByProject() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    // if (this.selectedpInfo != null && this.selectedpInfo.length > 0) {
    //   var project_id = this.selectedpInfo[0].project_id;
    //   //Fetch Group List
    //   (await this.projectJobService.getAllProjectJobList(loggedInUser.logon_id, loggedInUser.site_id, project_id)).subscribe((apiResponse: any) => {
    //     this.pJobModelList = utility.extractJsonData(apiResponse, 0);
    //   });
    // }

    if (this.selectedProject != null && this.selectedProject != undefined) {
      //var project_id = this.selectedpInfo[0].project_id;
      //Fetch Group List
      (await this.projectJobService.getAllProjectJobList(loggedInUser.logon_id, loggedInUser.site_id, this.selectedProject)).subscribe((apiResponse: any) => {
        this.pJobModelList = utility.extractJsonData(apiResponse, 0);
      });
    }
  }
  async onJobSelectionChanged(e) {
    this.selectedJobIndex = e.selectedRowKeys;
    this.selectedjInfo = e.selectedRowsData;
    this.projectJobService.setSelectedInfo(this.selectedjInfo);
    this.pItemModelList = null;
    await this.populateItemByJob();
  }
  async populateItemByJob() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    if (this.selectedjInfo != null
      && this.selectedjInfo.length != undefined
      && this.selectedjInfo.length != 0) {
      //var project_id = this.selectedpInfo[0].project_id;
      var job_id = this.selectedjInfo[0].job_id;
      //Fetch Group List
      (await this.projectItemService.getAllProjectItemList(loggedInUser.logon_id, loggedInUser.site_id, this.selectedProject, job_id)).subscribe((apiResponse: any) => {
        this.pItemModelList = utility.extractJsonData(apiResponse, 0);
      });
    }
  }
  async onItemSelectionChanged(e) {
    this.selectedItemIndex = e.selectedRowKeys;
    this.selectediInfo = e.selectedRowsData;
    this.projectItemService.setSelectedInfo(this.selectediInfo);
    this.pDisciplineModelList = null;
    await this.populateDisciplineByItem();
  }

  //Discipline ==============================================================================================
  async populateDisciplineByItem() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    if (this.selectediInfo != null
      && this.selectediInfo.length != undefined
      && this.selectediInfo.length != 0) {

      //var project_id = this.selectedpInfo[0].project_id;
      var item_id = this.selectediInfo[0]?.item_id;

      //Fetch Discipline List
      (await this.projectDisciplineService.getAllProjectDisciplineList(loggedInUser.logon_id, loggedInUser.site_id, this.selectedProject, item_id)).subscribe((apiResponse: any) => {
        this.pDisciplineModelList = utility.extractJsonData(apiResponse, 0);
      });

      // if (this.selectedInfoTests == null && this.selectedInfoTests == undefined) {
      //   this.populateTestsByTestsCategory(this.defaultTestCategory);
      // }
    }

  }

  RouteToDisciplineDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    this.selecteddInfo[0] = e.row?.data;
    this.projectDisciplineService.setSelectedInfo(this.selecteddInfo[0]);

    this.openDisciplineDialog(false);
    e.event?.preventDefault();
  };

  openDisciplineDialog(isNew): void {
    if (isNew) {



      var commonObj = {
        project_id: this.selectFilteredProject[0]?.project_id,
        site_id: this.selectFilteredProject[0]?.site_id,
        item_id: this.selectediInfo[0]?.item_id,
        disc_id: this.selectediInfo[0]?.disc_id,
        disc_code: this.selectediInfo[0]?.disc_code,
        disc_title: this.selectediInfo[0]?.disc_title,
        freeze: false,
        is_active: true
      };

      this.projectDisciplineService.setSelectedInfo(commonObj);
    }
    if (this.selectediInfo == null || this.selectediInfo == undefined
      || this.selectediInfo.length <= 0) {
      alert("Please select a Item");
      return;
    }

    const dialogRef = this.matDialog.open(AddEditDisciplineComponent, {
      width: '400px',
      height: '250px',
      maxHeight: '600px',
      autoFocus: false,
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      //this.populateItemByJob();
      this.populateDisciplineByItem();
    });
  }

  async deleteDiscipline() {
    if (this.selecteddInfo == null || this.selecteddInfo == undefined) {
      alert("Please select Discipline");
      return;
    }

    if (!confirm("Are you sure to inactivate the selected Discipline?")) {
      return;
    }

    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    var projectDiscipline = {
      project_id: this.selectFilteredProject[0].project_id,
      site_id: this.selectFilteredProject[0].site_id,
      job_id: this.selectedjInfo[0].job_id,
      item_id: this.selectediInfo[0].item_id,
      project_disc_list: this.selecteddInfo,
      requester_id: loggedInUser.logon_id
    };
    (await this.projectDisciplineService.deleteProjectDiscipline(projectDiscipline)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.populateDisciplineByItem();
    });
  }

  async deleteTests() {
    if (this.selectedInfoTests == null || this.selectedInfoTests == undefined) {
      alert("Please select an Test");
      return;
    }
    if (!confirm("Are you sure to delete the selected Tests?")) {
      return;
    }
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    var projectTests = {
      project_id: this.selectFilteredProject[0].project_id,
      site_id: this.selectFilteredProject[0].site_id,
      job_id: this.selectedjInfo[0].job_id,
      item_id: this.selectediInfo[0].item_id,
      pd_id: this.selecteddInfo[0].pd_id,
      ti_id: this.selectedInfoTests[0].ti_id,
      project_test_list: this.selectedInfoTests,
      requester_id: loggedInUser.logon_id
    };

    (await this.projectTestsService.deleteProjectTests(projectTests)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.populateTestsByTestsCategory(this.currentSelectdCategory);
    });
  }
  async onDiscSelectionChanged(e) {
    this.selectedDiscIndex = e.selectedRowKeys;
    this.selecteddInfo = e.selectedRowsData;
    this.projectDisciplineService.setSelectedInfo(this.selecteddInfo);
    this.pTestsModelList = null;
    await this.populateTestsByTestsCategory(this.currentSelectdCategory || this.defaultTestCategory); //this.currentSelectdCategory);
  }

  async onTestsSelectionChanged(e) {
    this.selectedTestIndex = e.selectedRowKeys;
    this.selectedInfoTests = e.selectedRowsData;
    this.projectTestsService.setSelectedInfo(this.selectedInfoTests);

  }
  //End Discipline ---------------------------------------------------------------

  onProjectContentReady(e: DxTreeListTypes.ContentReadyEvent) {
    if (!e.component.getSelectedRowKeys().length) { e.component.selectRowsByIndexes([0]); }
  }
  onJobContentReadyHandler(e) {
    if (this.selectedJobIndex == null || this.selectedJobIndex == undefined) {
      e.component.selectRowsByIndexes(0);
    }
    else {
      e.component.selectRows(this.selectedJobIndex);
    }
  }
  openJobDialog(isNew): void {
    if (isNew) {
      var commonObj = {
        project_id: this.selectFilteredProject[0]?.project_id,
        site_id: this.selectFilteredProject[0]?.site_id,
        is_active: true
      };
      this.projectJobService.setSelectedInfo(commonObj);
    }
    if (!isNew && (this.selectedjInfo == null || this.selectedjInfo == undefined)) {
      alert("Please select a Job");
      return;
    }

    const dialogRef = this.matDialog.open(AddEditJobComponent, {
      width: '300px',
      height: '300px',
      maxHeight: '600px',
      autoFocus: false,
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.populateJobByProject();
    });
  }

  openTestsDialog(isNew): void {

    if (this.selecteddInfo == null || this.selecteddInfo == undefined
      || this.selecteddInfo.length <= 0
    ) {
      alert("Please select a Discipline");
      return;
    }

    if (isNew) {
      var commonObj = {
        project_id: this.selectFilteredProject[0]?.project_id,
        site_id: this.selectFilteredProject[0]?.site_id,
        item_id: this.selectediInfo[0]?.item_id,
        disc_id: this.selecteddInfo[0]?.disc_id,
        pd_id: this.selecteddInfo[0]?.pd_id,
        disc_code: this.selecteddInfo[0]?.disc_code,
        disc_title: this.selecteddInfo[0]?.disc_title,
        // category_id: this.selectedInfoTests[0]?.category_id,
        // test_type: this.selectedInfoTests[0]?.test_type,
        // test_procedure: this.selectedInfoTests[0]?.test_procedure,
        // acceptance_criteria: this.selectedInfoTests[0]?.acceptance_criteria,
        // report_counter: this.selectedInfoTests[0]?.report_counter,
        // test_code: this.selectedInfoTests[0]?.test_code,
        job_id: this.selectedjInfo[0]?.job_id
      };
      this.projectTestsService.setSelectedInfo(commonObj);
    }



    if (!isNew && (this.selectedInfoTests == null || this.selectedInfoTests == undefined)) {
      alert("Please select a Test");
      return;
    }

    const dialogRef = this.matDialog.open(AddEditTestsComponent, {
      width: '400px',
      height: '300px',
      maxHeight: '600px',
      autoFocus: false,
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.populateTestsByTestsCategory(this.currentSelectdCategory);
    });
  }

  RouteToJobDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    this.selectedjInfo[0] = e.row?.data;
    this.projectJobService.setSelectedInfo(this.selectedjInfo[0]);

    this.openJobDialog(false);
    e.event?.preventDefault();
  };
  openItemDialog(isNew): void {
    if (isNew) {
      var commonObj = {
        project_id: this.selectFilteredProject[0]?.project_id,
        site_id: this.selectFilteredProject[0]?.site_id,
        job_id: this.selectedjInfo[0]?.job_id || this.selectedjInfo?.job_id,
        freeze: false,
        is_active: true
      };

      this.projectItemService.setSelectedInfo(commonObj);
    }
    if (this.selectedjInfo == null || this.selectedjInfo == undefined
      || this.selectedjInfo.length <= 0) {
      alert("Please select a Job");
      return;
    }

    if (!isNew && (this.selectediInfo == null
      || this.selectediInfo == undefined
      || this.selectediInfo.length <= 0)) {
      alert("Please select an Item");
      return;
    }

    const dialogRef = this.matDialog.open(AddEditItemComponent, {
      width: '300px',
      height: '470px',
      maxHeight: '600px',
      autoFocus: false,
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.populateItemByJob();
    });
  }
  RouteToItemDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    this.selectediInfo[0] = e.row?.data;
    this.projectItemService.setSelectedInfo(this.selectediInfo[0]);

    this.openItemDialog(false);
    e.event?.preventDefault();
  };

  RouteToTestsDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    this.selectedInfoTests = e.row?.data;
    this.projectTestsService.setSelectedInfo(this.selectedInfoTests);
    this.openTestsDialog(false);
    e.event?.preventDefault();
  };

  onItemContentReadyHandler(e) {
    if (this.selectedItemIndex == null || this.selectedItemIndex == undefined || this.selectedItemIndex == '') {
      e.component.selectRowsByIndexes(0);
    }
    else {
      e.component.selectRows(this.selectedItemIndex);
    }
  }

  onDisciplineContentReadyHandler(e) {
    if (this.selectedDiscIndex == null || this.selectedDiscIndex == undefined || this.selectedDiscIndex == '') {
      e.component.selectRowsByIndexes(0);
    }
    else {
      e.component.selectRows(this.selectedDiscIndex);
    }
  }

  onTestsContentReadyHandler(e) {
    if (this.selectedTestIndex == null || this.selectedTestIndex == undefined) {
      e.component.selectRowsByIndexes(0);
    }
    else {
      e.component.selectRows(this.selectedTestIndex);
    }
  }
  async deleteJob() {
    if (this.selectedjInfo == null || this.selectedjInfo == undefined) {
      alert("Please select a Job");
      return;
    }

    if (!confirm("Are you sure to inactivate the selected Jobs?")) {
      return;
    }

    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    var projectJob = {
      project_id: this.selectFilteredProject[0].project_id,
      site_id: this.selectFilteredProject[0].site_id,
      project_job_list: this.selectedjInfo,
      requester_id: loggedInUser.logon_id
    };

    (await this.projectJobService.deleteProjectJob(projectJob)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.populateJobByProject();
    });
  }
  async deleteItem() {
    if (this.selectediInfo == null || this.selectediInfo == undefined) {
      alert("Please select an Item");
      return;
    }

    if (!confirm("Are you sure to inactivate the selected Items?")) {
      return;
    }

    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    var projectItem = {
      project_id: this.selectFilteredProject[0].project_id,
      site_id: this.selectFilteredProject[0].site_id,
      job_id: this.selectedjInfo[0].job_id,
      project_item_list: this.selectediInfo,
      requester_id: loggedInUser.logon_id
    };

    (await this.projectItemService.deleteProjectItem(projectItem)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.populateItemByJob();
    });
  }

  // Test Information tab
  async populateTestsByTestsCategory(e) {
    console.log("populateTestsByTestsCategory");
    this.currentSelectdCategory = e;
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    if (this.selecteddInfo != null
      && this.selecteddInfo.length != undefined
      && this.selecteddInfo.length != 0) {

      var project_id = this.selectFilteredProject[0].project_id;
      var item_id = this.selectediInfo[0]?.item_id;
      var pd_id = this.selecteddInfo[0].pd_id;
      var category_id = this.currentSelectdCategory;
      var job_id = this.selectedjInfo[0].job_id;

      (await this.projectTestsService.getAllProjectTestsList(loggedInUser.logon_id, loggedInUser.site_id, project_id, item_id, pd_id, category_id, job_id)).subscribe((apiResponse: any) => {
        this.pTestsModelList = utility.extractJsonData(apiResponse, 0);
      });


    }
  }

  public toolbarItems: ToolbarItem[] = [
    { label: 'Add Job', path: '', iconName: '', action: () => this.openJobDialog(true), hidden: false },
    { label: 'Add Item', path: '', iconName: '', action: () => this.openItemDialog(true), hidden: false },
    { label: 'Add Disc.', path: '', iconName: '', action: () => this.openDisciplineDialog(true), hidden: false },
    { label: 'Add Test.', path: '', iconName: '', action: () => this.openTestsDialog(true), hidden: false },
    { label: 'Inactivate Job', path: '', iconName: '', action: () => this.deleteJob(), hidden: false },
    { label: 'Inactivate Item', path: '', iconName: '', action: () => this.deleteItem(), hidden: false },
    { label: 'Inactivate Disc.', path: '', iconName: '', action: () => this.deleteDiscipline(), hidden: false },
    { label: 'Delete Test', path: '', iconName: '', action: () => this.deleteTests(), hidden: false }
  ];
  /* public toolbarItems: ToolbarItem[] = [
    { label: 'Add Job', path: '', iconName: '', action: () => this.openJobDialog(true), hidden: false },
    { label: 'Delete Job', path: '', iconName: '', action: () => this.deleteJob(), hidden: false }
  ];
  public itemsinfobtn: ToolbarItem[] = [
    { label: 'Add Item', path: '', iconName: '', action: () => this.openItemDialog(true), hidden: false },
    { label: 'Delete Item', path: '', iconName: '', action: () => this.deleteItem(), hidden: false }
  ]; */

  storeFilter() {
    var fProperty = {
      project_id: this.selectedProject
    };
    utility.setSelectedFilter(fProperty);
  }
  retrieveFilter() {
    var fProperty = utility.getSelectedFilter();
    this.selectedProject = fProperty.project_id;
  }
  resetlcFilter() {
    utility.setSelectedFilter(null);
  }
}