import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { utility } from '../../../../util';
import { MaterialModule } from '../../../../core/material-module';
import { ToolbarButtonsComponent } from '../../../../header/toolbar-buttons/toolbar-buttons.component';
import { UtilityService } from '../../../../APIServices/UtilityService';
import { WeldProcedureService } from '../../../../APIServices/weld/weldProcedure/weldProcedureService';
import { DevExtremeModule } from '../../../../core/devExtreme.module';

@Component({
  selector: 'app-weld-procedure-list',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    ToolbarButtonsComponent,
    DevExtremeModule
  ],
  templateUrl: './weld-procedure-list.component.html',
  styleUrl: './weld-procedure-list.component.scss'
})
export class WeldProcedureListComponent implements OnInit{
  title: any = "Weld Procedure";
  weldProcedureModelList: any;
  selectedInfo: any;
  loggedInUser: any;
  toolbarItems: any;
  currentUrl: string;
  buttonAccess: any;

  constructor(
    private router: Router,
    private utilService: UtilityService,
    private route: ActivatedRoute,
    private weldProcedureService: WeldProcedureService
  ) {}

  async ngOnInit() {
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.currentUrl = this.route.snapshot.url[0].path;
    this.getButtonsAccess(this.currentUrl);
    await this.index();
   // await this.populateResourceOptionList();
  }

  public async index() {
    (await this.weldProcedureService.getAllWeldProcedure()).subscribe((apiResponse: any) => {
      this.weldProcedureModelList = utility.extractJsonData(apiResponse, 0);
    });
  }

  async getButtonsAccess(currRoute: any) {
    (await this.utilService.getAllButtonAccess(currRoute)).subscribe((apiResponse: any) => {
      let resData = utility.extractJsonData(apiResponse, 0);
      this.buttonAccess = resData ? resData[0] : '';
      this.buttonInitialization(this.buttonAccess);
    });
}

 buttonInitialization(buttonAccess: any) {
  console.log('buttonAccess',buttonAccess.can_edit);
    this.toolbarItems = [
      { label: 'Add Weld Procedure', path: '', iconName: '', action: () => this.addWeldProcedure(), hidden: !buttonAccess?.can_add },
      { label: 'Inactivate Weld Procedure', path: '', iconName: '', action: () => this.deleteWeldProcedure(), hidden: !buttonAccess?.can_delete }
      /* { label: 'Map Process', path: '', iconName: '', action: () => this.mapProcessToProcedure(), hidden: false } */
    ];
  }

  onSelectionChanged(e){
    this.selectedInfo = e.selectedRowsData;
    this.weldProcedureService.setSelectedInfo(this.selectedInfo);
  }

  routeToDetails = (e: DxDataGridTypes.ColumnButtonClickEvent, fromMapProcess?: boolean) => {
    let selectedData = fromMapProcess ? e : e.row?.data;
    if (selectedData == null) {
      this.router.navigate(['./weldproceduredetails']);
    }
    var selectedID = selectedData.weld_procedure_id;
    let params = {
      weld_procedure_id: selectedData.weld_procedure_id,
      site_id: selectedData.site_id
    };
    this.router.navigate(['./weldproceduredetails'], { queryParams: params });
    var uModel;
    if (selectedData.site_id == undefined || selectedData.site_id == null) {

      var localUser = JSON.stringify(selectedData);
      uModel = <any>JSON.parse(localUser);
      uModel.site_id = this.loggedInUser.site_id;
      uModel.site_code = this.loggedInUser.site_code;
      uModel.site_description = this.loggedInUser.site_description;
    }
    else {
      var localUser = JSON.stringify(selectedData);
      uModel = <any>JSON.parse(localUser);
    }
    this.weldProcedureService.setSelectedInfo(uModel);
    e.event?.preventDefault();
  };

  addWeldProcedure(){
    // console.log('this.selectedResource',this.selectedResource);
     this.weldProcedureService.setSelectedInfo(this.selectedInfo);
     this.router.navigate(['./weldproceduredetails']);
   }
 
   async deleteWeldProcedure() {
     if (this.selectedInfo == null || this.selectedInfo == undefined ||
       this.selectedInfo.length == undefined || this.selectedInfo.length == 0) {
       alert("Please select a weld procedure");
       return;
     }
 
     if (!confirm("Are you sure to Inactivate selected weld procedures?")) {
       return;
     }
 
     var weldprocedureInfoDelete = {
       weld_procedure_list: this.selectedInfo,
       site_id: this.loggedInUser.site_id
     };
 
     (await this.weldProcedureService.deleteWeldProcedure(weldprocedureInfoDelete)).subscribe((apiResponse: any) => {
       utility.responseAlert(apiResponse);
       this.index();
     });
   }

   mapProcessToProcedure () {
    if (this.selectedInfo == null || this.selectedInfo == undefined ||
      this.selectedInfo.length == undefined || this.selectedInfo.length == 0) {
      alert("Please select a weld procedure");
      return;
    }
    this.routeToDetails(this.selectedInfo[0], true);
   }
}
