<form [formGroup]="pTestsForm" class="dialog-form">
    <div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <span class="dialogHeader">Add/Edit Tests</span>
        <button mat-icon-button class="close-button" (click)="closeDialog()">
            <mat-icon class="close-icon" color="warn">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <input type="hidden" formControlName="ti_id" />
        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label>Select Test Type</mat-label>
                <mat-select formControlName="test_type" (selectionChange)="fetchTestProcedure($event.value)">
                    <ng-container>
                        @for (item of lookupTestsType; track item) {
                        <mat-option [value]="item.test_id">{{item.test_description}}</mat-option>
                        }
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="pTestsForm.controls['test_type'].errors?.['required']">
                    This is required!
                </mat-error>
            </mat-form-field>
        </div>

        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label>Percentage</mat-label>
                <mat-select formControlName="percentage">
                    <ng-container>
                        @for (item of testPercenatgeList; track item) {
                        <mat-option [value]="item.test_id">{{item.test_description}}</mat-option>
                        }
                    </ng-container>
                </mat-select>
                <!-- <mat-error *ngIf="pTestsForm.controls['percentage'].errors?.['required']">
                    This is required!
                </mat-error> -->
            </mat-form-field>
        </div>

        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label>Test Procedure</mat-label>
                <input matInput type="text" id="txtweldprocedure" class="matInputCls toUpperCase" name="text"
                    formControlName="test_procedure_title" maxlength="25" appOnlyAlphanumeric>
                <mat-error class="text-danger" *ngIf="pTestsForm.controls['test_procedure_title'].errors?.['required']">
                    This is required!
                </mat-error>
            </mat-form-field>
        </div>

    </mat-dialog-content>
    <mat-divider></mat-divider>
    <mat-dialog-actions align="end" class="mr-16">
        <button mat-raised-button color="primary" (click)="saveTestsInfo()">Save</button>
    </mat-dialog-actions>
</form>