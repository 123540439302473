<div class="card">
    <mat-card appearance="outlined">
        <mat-card-header>
            <span class="card-title">Line Class Information</span>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="lcForm" novalidate class="form">
                <div class="flex-container">
                    <div class="side-container">
                        <div class="item-container disNone">
                            <mat-label class="matLabel">Line Class ID</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtLineClassID" name="text" class="matInputCls"
                                    formControlName="lineclass_id" maxlength="120">
                            </mat-form-field>
                        </div>

                        <div class="item-container mt-1e">
                            <mat-label class="matLabel asterix">Project</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="project_id">
                                    @for (item of pModelList; track item) {
                                    <mat-option
                                        [value]="item.project_id">{{item.project_code}}-{{item.project_title}}</mat-option>
                                    }
                                </mat-select>
                                <mat-error class="text-danger"
                                    *ngIf="lcForm.controls['project_id'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel asterix">Line Class Code</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtLCCode" name="text" class="matInputCls toUpperCase"
                                    formControlName="lineclass_code" maxlength="40" appOnlyAlphanumeric>
                                <mat-error class="text-danger"
                                    *ngIf="lcForm.controls['lineclass_code'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel asterix">Line Class Desc</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtLCDesc" name="text" class="matInputCls"
                                    formControlName="lineclass_desc" maxlength="100">
                                <mat-error class="text-danger"
                                    *ngIf="lcForm.controls['lineclass_desc'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="item-container">
                            <mat-label class="matLabel asterix">Material Type</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="material_type_id">
                                    @for (item of sModelList; track item) {
                                    <mat-option
                                        [value]="item.master_id">{{item.code}}-{{item.description}}</mat-option>
                                    }
                                </mat-select>
                                <mat-error class="text-danger"
                                    *ngIf="lcForm.controls['material_type_id'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="item-container">
                            <mat-label class="matLabel">Pressure</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtPressure" name="text" formControlName="pressure"
                                    maxlength="10" class="matInputCls">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="side-container">
                        <div class="ndt-container">
                            <fieldset>
                                <legend>NDT % Required</legend>
                                <div class="item-container mt-1e">
                                    <mat-label class="matLabel">RT</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput type="number" id="txtRT" name="text" formControlName="rt"
                                            maxlength="8" class="matInputCls" maxlength="6" max="100">
                                        <mat-error class="text-danger" *ngIf="lcForm.controls['rt'].errors">
                                            max should be 100
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="item-container">
                                    <mat-label class="matLabel">UT</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput type="number" id="txtUT" name="text" formControlName="ut"
                                            maxlength="8" class="matInputCls" maxlength="8" max="100">
                                        <mat-error class="text-danger" *ngIf="lcForm.controls['ut'].errors">
                                            max should be 100
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="item-container">
                                    <mat-label class="matLabel">DP</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput type="number" id="txtDP" name="text" formControlName="dp"
                                            maxlength="8" class="matInputCls" maxlength="8" max="100">
                                        <mat-error class="text-danger" *ngIf="lcForm.controls['dp'].errors">
                                            max should be 100
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="item-container">
                                    <mat-label class="matLabel">MT</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput type="number" id="txtMA" name="text" formControlName="ma"
                                            maxlength="8" class="matInputCls" maxlength="8" max="100">
                                        <mat-error class="text-danger" *ngIf="lcForm.controls['ma'].errors">
                                            max should be 100
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="item-container">
                                    <mat-label class="matLabel">PA</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput type="number" id="txtPA" name="text" formControlName="pa"
                                            maxlength="8" class="matInputCls" maxlength="7" max="100">
                                        <mat-error class="text-danger" *ngIf="lcForm.controls['pa'].errors">
                                            max should be 100
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </fieldset>
                        </div>
                        <div class="item-container">
                            <mat-label class="chkMatLabel">Is Active?</mat-label>
                            <mat-checkbox formControlName="is_active" class="matInputCls chkMatCheckbox"
                                id="chkIsActive">
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button (click)="backtoList()" class="bckBtn">Back</button>
            <button mat-raised-button color="primary" gap="20px" class="mainBtn" (click)="save()" [disabled]="!isSaveDisabled()">Save</button>
        </mat-card-actions>
    </mat-card>
</div>