import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { utility } from '../../../../util';
import { MaterialModule } from '../../../../core/material-module';
import { WeldProcedureToProcessService } from '../../../../APIServices/weld/weldProcedure/weldProcedureToProcessService';

@Component({
  selector: 'app-add-procedure-to-process',
  standalone: true,
  imports: [MaterialModule, CommonModule, FormsModule],
  templateUrl: './add-procedure-to-process.component.html',
  styleUrl: './add-procedure-to-process.component.scss'
})
export class AddProcedureToProcessComponent {
  selectedList: any[] = [];
  dModelList: any;
  selectedInfo: any;
  availableItems: any;
  newSelectedList: any;
  fileteredNewList: any[] = [];
  constructor(
    private dialogRef: MatDialogRef<AddProcedureToProcessComponent>,
    private router: Router,
    private weldProcedureToProcessService: WeldProcedureToProcessService,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data:any
    ) { 
      this.availableItems = data.availableItems;
    }

    async ngOnInit() {
      this.weldProcedureToProcessService.getSelectedInfo.subscribe(x => {
        if(utility.isObject(x)) {
          this.selectedInfo = x;
        } 
      });
      this.populateProcess();
    }

    
    async mapProcessToProcedure() {
      var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
      var processRequest = {
        site_id: loggedInUser.site_id,
        process_id_list: this.fileteredNewList.toString(),
        weld_procedure_id: this.route.snapshot.queryParams['weld_procedure_id'],
        is_active: true,
        requester_id: loggedInUser.logon_id,
      };
  
      //Call API to update mapping
      (await this.weldProcedureToProcessService.mapProcessToProcedure(processRequest)).subscribe((apiResponse: any) => {
        utility.responseAlert(apiResponse);
        this.populateProcess();
        this.closeDialog();
      });
  
    }

    getSelectedValues(event: any) {
      this.selectedList = event.value;
      if(this.availableItems!=null){
      this.fileteredNewList = this.selectedList.filter(
       (item) => !this.availableItems.includes(item));
      }else{
        this.fileteredNewList=this.selectedList;
      }
    }

    async populateProcess() {
      (await this.weldProcedureToProcessService.getActiveProcessList()).subscribe((apiResponse: any) => {
        this.dModelList = utility.extractJsonData(apiResponse, 0);
        this.selectedList = this.availableItems;
      });
    }
    
    isOptionDisabled(option) {
      return !this.availableItems?.includes(option);
    }
    closeDialog(): void {
      this.dialogRef.close();
    }
}
