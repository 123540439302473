import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, throwError } from 'rxjs';
import { utility } from '../../../util';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class WeldProjectProcedureMappingsService {

  private apiBaseUrl: string;
    private selectedInfo = new BehaviorSubject(null);
    getSelectedInfo = this.selectedInfo.asObservable();
    private environmentConfig = utility.getEnvironmentData();
    requester_id: any;
    site_id: any;
    weldApiBaseUrl: any;
    user_id: string | number | boolean;

    constructor(private httpClient: HttpClient, private router: Router) {
        this.apiBaseUrl = this.environmentConfig.apiBase;
        var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
        this.requester_id = loggedInUser.logon_id;
        this.site_id = loggedInUser.site_id;
        this.user_id = loggedInUser.user_id;
        this.weldApiBaseUrl = this.environmentConfig.weldApiBase;
    }
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }
    setSelectedInfo(paramData: any) {
        this.selectedInfo.next(paramData);
    }

  public getUserWiseProjectList() {
    const params = new HttpParams()
      .set('requester_id',this.requester_id)
      .set('site_id',this.site_id)
      .set('user_id',this.user_id);
      var apiMethod = "ProjectInfo/GetAll";
      var url = (this.apiBaseUrl +  apiMethod); // + param;
      return this.httpClient.get(url,{params: params});
  }

  /* WeldProcedure services START */
  public getAllProjectProcedureList(project_id: any) {
    const params = new HttpParams()
     /*  .set('requester_id',this.requester_id) */
      .set('site_id',this.site_id)
      .set('project_id',project_id);
      var apiMethod = "WeldProcedureProjectMapInfo/GetAll";
      var url = (this.weldApiBaseUrl +  apiMethod); // + param;
      return this.httpClient.get(url,{params: params});
}
  /* WeldProcedure services END */

  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
        errorMessage = error.error.message;
    } else {
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
}
}
