import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DevExtremeModule } from '../../../core/devExtreme.module';
import { MaterialModule } from '../../../core/material-module';
import { ActivatedRoute, Router } from '@angular/router';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';

import { ToolbarButtonsComponent } from '../../../header/toolbar-buttons/toolbar-buttons.component';
import { ToolbarItem } from '../../../header/toolbar-buttons/toolbar-item';
import { utility } from '../../../util';
import { LookupService } from '../../../APIServices/LookupService';
import { CommonModule } from '@angular/common';
import { UtilityService } from '../../../APIServices/UtilityService';
import { ResourceService } from '../../../APIServices/resource/resourceService';


@Component({
  selector: 'app-resource-list',
  standalone: true,
  imports: [DevExtremeModule, MaterialModule, FormsModule, ToolbarButtonsComponent, CommonModule],
  templateUrl: './resource-list.component.html',
  styleUrl: './resource-list.component.scss'
})
export class ResourceListComponent implements OnInit {
  title: any = "Resource Management";
  resourceModelList: any[] = [];
  resourceOriginalModelList: any[] = [];
  resourceOptionList: any[] = [];
  selectedResource: any;
  selectedStatusFilter: any;
  selectedResourceFilter: any = "";
  selectedInfo: any;
  uOriginalModelList: any[];
  loggedInUser: any;
  resStatusOptionList: any;
  selectedStatus: any;
  toolbarItems: any;
  currentUrl: string;
  buttonAccess: any;


  constructor(
    private router: Router,
    private lookupService: LookupService,
    private utilService: UtilityService,
    private route: ActivatedRoute,
    private resourceService: ResourceService
  ) { }


  async ngOnInit() {
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.currentUrl = this.route.snapshot.url[0].path;
    this.getButtonsAccess(this.currentUrl);
    this.retrieveFilter();
    await this.populateResourceOptionList();
    await this.index();
  }

  async getButtonsAccess(currRoute: any) {
    (await this.utilService.getAllButtonAccess(currRoute)).subscribe((apiResponse: any) => {
      let resData = utility.extractJsonData(apiResponse, 0);
      this.buttonAccess = resData[0];
      this.buttonInitialization(resData[0]);
    });
  }

  buttonInitialization(buttonAccess: any) {
    console.log('buttonAccess', buttonAccess);
    this.toolbarItems = [
      { label: 'Add Resource', path: '', iconName: '', action: () => this.addResource(), hidden: !buttonAccess.can_add },
      { label: 'Inactivate Resource', path: '', iconName: '', action: () => this.deleteResource(), hidden: !buttonAccess.can_delete },
    ];
  }

  async populateResourceOptionList() {
    (await this.lookupService.getResourceTypeList()).subscribe((apiResponse: any) => {
      this.resourceOptionList = utility.extractJsonData(apiResponse, 0);
      //this.resourceOptionList.push(this.sNew);

      if (this.resourceOptionList != null && this.resourceOptionList.length > 0) {
        this.selectedResource = (this.selectedResource == null || this.selectedResource == undefined) ? this.resourceOptionList[0].resource_type_id : this.selectedResource
        this.fetchDependentFieldsValue(this.selectedResource);
      }
    });
  }

  async fetchDependentFieldsValue(value: any) {
    let selectedResourceType = value;
    (await this.lookupService.getResStatusList(selectedResourceType)).subscribe((apiResponse: any) => {
      this.resStatusOptionList = utility.extractJsonData(apiResponse, 0);
      if (this.resStatusOptionList != null && this.resStatusOptionList.length > 0) {
        this.selectedStatusFilter = (this.selectedStatusFilter == null || this.selectedStatusFilter == undefined) ? this.resStatusOptionList[0].resource_status_id : this.selectedStatusFilter;
        this.filterCondition();
      }
    });
  }

  public async index() {
    (await this.resourceService.getAllResourceList(this.loggedInUser.logon_id, this.loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.resourceModelList = utility.extractJsonData(apiResponse, 0);
      this.resourceOriginalModelList = this.resourceModelList;
      this.applyFilter('STATUS');
    });
  }
  async filterCondition() {
    this.resourceModelList = this.resourceModelList.filter((v) =>
      v.res_type_id === (this.selectedResource == 0 ? null : this.selectedResource)
      && (this.selectedStatusFilter === "" || v.res_status_id === (this.selectedStatusFilter))
    );
    this.storeFilter();
  }

  async applyFilter(callFrom: any) {
    this.resetFilter();
    if (callFrom == 'TYPE' || callFrom == 'ALL') {
      this.selectedStatusFilter = null;
      await this.fetchDependentFieldsValue(this.selectedResource);
    }
    else {
      await this.filterCondition();
    }
  }
  resetFilter() {
    this.resourceModelList = this.resourceOriginalModelList;
  }

  onSelectionChanged(e) {
    this.selectedInfo = e.selectedRowsData;
    this.resourceService.setSelectedInfo(e.selectedRowsData);
  }

  routeToResourceDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    if (e.row?.data == null) {
      this.router.navigate(['./resourcedetails']);
    }
    let params = {
      resource_id: e.row?.data.resource_id,
      site_id: e.row?.data.site_id
    };
    this.router.navigate(['./resourcedetails'], { queryParams: params });
    var resourceModel;
    if (e.row?.data.site_id == undefined || e.row?.data.site_id == null) {

      var localUser = JSON.stringify(e.row?.data);
      resourceModel = <any>JSON.parse(localUser);
      resourceModel.site_id = this.loggedInUser.site_id;
      resourceModel.site_code = this.loggedInUser.site_code;
      resourceModel.site_description = this.loggedInUser.site_description;
    }
    else {
      var localUser = JSON.stringify(e.row?.data);
      resourceModel = <any>JSON.parse(localUser);
    }
    this.resourceService.setSelectedInfo(resourceModel);
    e.event?.preventDefault();
  };

  addResource() {
    // console.log('this.selectedResource',this.selectedResource);
    this.resourceService.setSelectedInfo(this.selectedResource);
    this.router.navigate(['./resourcedetails']);
  }

  async deleteResource() {
    if (this.selectedInfo == null || this.selectedInfo == undefined ||
      this.selectedInfo.length == undefined || this.selectedInfo.length == 0) {
      alert("Please select a Resource");
      return;
    }

    if (!confirm("Are you sure to Inactivate selected Resource?")) {
      return;
    }

    var resourceInfoDelete = {
      resource_list: this.selectedInfo,
      site_id: this.loggedInUser.site_id
    };

    (await this.resourceService.deleteResource(resourceInfoDelete)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.index();
    });
  }
  storeFilter() {
    var fProperty = {
      resource_type_id: this.selectedResource,
      resource_status_id: this.selectedStatusFilter
    };
    utility.setSelectedFilter(fProperty);
  }
  retrieveFilter() {
    var fProperty = utility.getSelectedFilter();
    this.selectedResource = fProperty.resource_type_id;
    this.selectedStatusFilter = fProperty.resource_status_id;
  }
  resetlcFilter() {
    utility.setSelectedFilter(null);
  }
}
