import { Component, Inject, NO_ERRORS_SCHEMA, OnInit } from '@angular/core';
import { MaterialModule } from '../../../core/material-module';
import { groupTreeModel } from '../../../models/groupModel';
import { GroupService } from '../../../APIServices/GroupService';
import { ActivatedRoute, Router } from '@angular/router';
import { utility } from '../../../util';
import { DevExtremeModule } from '../../../core/devExtreme.module';
import { AccessService } from '../../../APIServices/AccessService';
import { groupAccessMenuModel } from '../../../models/groupAccessModel';
import { AddEditAccessComponent } from '../add-edit-access/add-edit-access.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule, DOCUMENT } from '@angular/common';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { ToolbarButtonsComponent } from '../../../header/toolbar-buttons/toolbar-buttons.component';
import { UtilityService } from '../../../APIServices/UtilityService';
import { SearchableDropdownComponent } from '../../common/searchable-dropdown/searchable-dropdown.component';


@Component({
  selector: 'app-group-access-mgmt',
  standalone: true,
  imports: [MaterialModule, DevExtremeModule, ToolbarButtonsComponent, CommonModule, SearchableDropdownComponent],
  schemas: [],
  templateUrl: './group-access-mgmt.component.html',
  styleUrl: './group-access-mgmt.component.scss'
})
export class GroupAccessMgmtComponent implements OnInit {
  dModelList: groupTreeModel[] = [];
  dAccessModelList: groupAccessMenuModel[] = [];
  localStorage: any;
  selectedgInfo: any;
  selectedInfo: any;
  selectedGroup: any;

  title: any = "Group Wise Access Management";
  buttonAccess: any;
  toolbarItems: any;
  currentUrl: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private groupService: GroupService,
    private router: Router,
    private accessService: AccessService,
    private matDialog: MatDialog,
    private utilService: UtilityService,
    private route: ActivatedRoute
  ) {
    this.localStorage = document.defaultView?.localStorage;
  }

  async ngOnInit(): Promise<void> {
    this.currentUrl = this.route.snapshot.url[0].path;
    this.getButtonsAccess(this.currentUrl);
    this.Index();
  }

  public async Index() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    //Fetch Group List
    (await this.accessService.getAllGroupList(loggedInUser.logon_id, loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.dModelList = utility.extractJsonData(apiResponse, 0);
      this.retrieveFilter();
      if (this.dModelList != null && this.dModelList.length > 0) {
        this.selectedGroup = (this.selectedGroup == null || this.selectedGroup == undefined) ? this.dModelList[0].group_id : this.selectedGroup;

        this.selectedgInfo = this.dModelList.filter((v) =>
          v.group_id === this.selectedGroup);
        this.groupService.setSelectedInfo(this.selectedgInfo);

        this.populateGroupAccessList();
      };

    });
  }

  async getButtonsAccess(currRoute: any) {
    (await this.utilService.getAllButtonAccess(currRoute)).subscribe((apiResponse: any) => {
      let resData = utility.extractJsonData(apiResponse, 0);
      this.buttonAccess = resData ? resData[0] : '';
      this.buttonInitialization(this.buttonAccess);
    });
  }

  buttonInitialization(buttonAccess: any) {
    this.toolbarItems = [
      { label: 'Assign Access', path: '', iconName: '', action: () => this.openDialog(true), hidden: !buttonAccess?.can_add },
      { label: 'Inactivate Access', path: '', iconName: '', action: () => this.RemoveAccess(), hidden: !buttonAccess?.can_delete },
    ];
  }

  onItemSelect(selectedItem: any) {
    this.selectedGroup = selectedItem['group_id'];

    this.selectedgInfo = this.dModelList.filter((v) =>
      v.group_id === this.selectedGroup);
    this.groupService.setSelectedInfo(this.selectedgInfo);

    this.storeFilter();
    this.populateGroupAccessList();
  }

  async populateGroupAccessList() {
    if (this.selectedGroup != null && this.selectedGroup != undefined) {
      //Fetch Group Access List
      await this.getGroupAccessList(this.selectedGroup);
    }
    //this.selectedgInfo = e.selectedRowsData
    //this.groupService.setSelectedInfo(this.selectedgInfo);
  }
  async getGroupAccessList(group_id: any) {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    //Fetch Group Access List
    (await this.accessService.getGroupAccessList(loggedInUser.logon_id, loggedInUser.site_id, group_id)).subscribe((apiResponse: any) => {
      this.dAccessModelList = utility.extractJsonData(apiResponse, 0);
    });
  }
  onContentReadyHandler(e) {
    e.component.selectRowsByIndexes([0]);
  }
  openDialog(isNew: boolean): void {
    this.accessService.isNewAccess = isNew;
    if (!isNew) {
      if (this.selectedInfo == null || this.selectedInfo == undefined) {
        alert("Please select an access item");
        return;
      }
    }

    const dialogRef = this.matDialog.open(AddEditAccessComponent, {
      width: '350px',
      height: '350px',
      maxHeight: '600px',
      autoFocus: false,
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.getGroupAccessList(this.selectedgInfo[0].group_id);
      this.getGroupAccessList(this.selectedGroup);
    });
  }
  async onAccessSelectionChanged(e) {
    this.selectedInfo = e.selectedRowsData;
    this.accessService.setSelectedInfo(this.selectedInfo);
  }
  async RemoveAccess() {
    if (this.selectedGroup != null && this.selectedGroup == undefined) {
      alert("Please select a group");
      return;
    }

    if (this.selectedInfo == null || this.selectedInfo == undefined
      || this.selectedInfo.length == undefined || this.selectedInfo.length == 0) {
      alert("Please select an access item");
      return;
    }

    if (!confirm("Are you sure to delete the selected access from the selected group?")) {
      return;
    }

    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    var accessInfoDelete = {
      group_id: this.selectedGroup,
      site_id: loggedInUser.site_id,
      accessList: this.selectedInfo,
      requester_id: loggedInUser.logon_id
    };

    (await this.accessService.deleteGroupAccess(accessInfoDelete)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      //this.getGroupAccessList(this.selectedgInfo[0].group_id);
      this.getGroupAccessList(this.selectedGroup);
    });

  }
  RouteToDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    console.log(e.row?.data);
    if (e.row?.data == null) {
      alert("Please select an access item");
      return;
    }
    this.selectedInfo = e.row?.data;
    this.accessService.setSelectedInfo(this.selectedInfo);

    this.openDialog(false);
    e.event?.preventDefault();
  };

  storeFilter() {
    var fProperty = {
      group_id: this.selectedGroup
    };
    utility.setSelectedFilter(fProperty);
  }
  retrieveFilter() {
    var fProperty = utility.getSelectedFilter();
    this.selectedGroup = fProperty.group_id;
  }
  resetlcFilter() {
    utility.setSelectedFilter(null);
  }
}
