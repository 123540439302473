<!-- <button mat-raised-button color="primary" gap="20px" class="mainBtn" (click)="openDialog()">Search</button> -->
<app-toolbar-buttons *ngIf="toolbarItems" [items]="toolbarItems" [title]="title"></app-toolbar-buttons>

<!-- <app-search-projectjobitem (search)="onSearch($event)"
    (resetSearch)="onResetSearch($event)"></app-search-projectjobitem> -->

<div class="centered-outer-div">
    <dx-data-grid id="gridUserList" [dataSource]="tModelList" [allowColumnReordering]="true" class="centered-inner-div"
        [showBorders]="true" [showColumnLines]="true" [showRowLines]="true" [rowAlternationEnabled]="true"
        (onSelectionChanged)="onSelectionChanged($event)" width="1200px">
        <dxo-selection mode="multiple" showCheckBoxesMode="always">
        </dxo-selection>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column type="buttons" [width]="50">
            <dxi-button hint="Edit selected Resource" icon="edit" [visible]=true [disabled]=false
                [onClick]="routeToDetails"></dxi-button>
        </dxi-column>

        <dxi-column dataField="weld_id" [width]="100" caption="Drawing Weld ID" [visible]="false"></dxi-column>
        <dxi-column dataField="drawing_id" [width]="100" caption="Drawing ID" [visible]="false"></dxi-column>
        <dxi-column dataField="site_id" [width]="50" caption="Site ID" [visible]="false"></dxi-column>
        
        <dxi-column dataField="project_code" [width]="50" caption="project_code" [visible]="false"></dxi-column>
        <dxi-column dataField="project_title" [width]="50" caption="project_title" [visible]="false"></dxi-column>
        <dxi-column dataField="job_code" [width]="50" caption="job_code" [visible]="false"></dxi-column>
        <dxi-column dataField="job_title" [width]="50" caption="job_title" [visible]="false"></dxi-column>
        <dxi-column dataField="item_code" [width]="50" caption="item_code" [visible]="false"></dxi-column>
        <dxi-column dataField="item_title" [width]="50" caption="item_title" [visible]="false"></dxi-column>
        <dxi-column dataField="mcd_drawing_desc" [width]="50" caption="mcd_drawing_desc" [visible]="false"></dxi-column>

        <dxi-column dataField="mcd_drawing_code" [width]="200" caption="Drawing" [visible]="true"></dxi-column>
        <dxi-column dataField="recap_spool" [width]="100" caption="Recap/Spool" [visible]="true"></dxi-column>
        <dxi-column dataField="weld_code" [width]="100" caption="Weld" [visible]="true"
            sortOrder="asc"></dxi-column>
        <dxi-column dataField="suffix" [width]="50" caption="Suffix" [visible]="true"></dxi-column>
        <dxi-column dataField="sr_code" [width]="100" caption="S/R" [visible]="true"></dxi-column>
        <dxi-column dataField="is_active" [width]="90" caption="is Active?" [visible]="true"
            cssClass="dxCheckBoxFormat"></dxi-column>

        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 50, 'all']"
            [showNavigationButtons]="true" [visible]="true">
        </dxo-pager>
        <dxo-paging [pageSize]="10" [pageIndex]="0">
        </dxo-paging>
    </dx-data-grid>
</div>