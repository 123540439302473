import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { WeldSearchComponent } from '../../../common/weld-search/weld-search.component';
import { utility } from '../../../../util';
import { UtilityService } from '../../../../APIServices/UtilityService';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { DevExtremeModule } from '../../../../core/devExtreme.module';
import { ToolbarButtonsComponent } from '../../../../header/toolbar-buttons/toolbar-buttons.component';
import { CommonModule } from '@angular/common';
import { WeldRegisterService } from '../../../../APIServices/WeldNDT/WeldRegister/WeldRegisterService';
import { CommonService } from '../../../../APIServices/CommonService';

@Component({
  selector: 'app-weld-register-list',
  standalone: true,
  imports: [DevExtremeModule, ToolbarButtonsComponent, CommonModule],
  templateUrl: './weld-register-list.component.html',
  styleUrl: './weld-register-list.component.scss'
})
export class WeldRegisterListComponent implements OnInit {
  loggedInUser: any;
  tModelList: any;
  toolbarItems: any;
  buttonAccess: any = null;
  selectedInfo: any;
  selectedFilterInfo: any;
  currentUrl: string;
  searchCriteria: any;

  title: string = "Weld Register";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private utilService: UtilityService,
    private weldRegisterService: WeldRegisterService,
    private commonService: CommonService
  ) {
    //this.initializeForm(null);
  }
  async ngOnInit() {
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    this.currentUrl = this.route.snapshot.url[0].path;
    this.getButtonsAccess(this.currentUrl);
    this.searchCriteria = utility.getSelectedFilter();
    if (this.searchCriteria != null && (this.searchCriteria.drawing_id != null || this.searchCriteria.drawing_id != undefined)) {
      await this.onSearch(this.searchCriteria);
    }
  }

  async onSearch(fProperty: any) {
    if (fProperty.weld_id != null && fProperty.weld_id != undefined) {
      (await this.weldRegisterService.getSelectedWeld(fProperty.drawing_id,fProperty.weld_id)).subscribe((apiResponse: any) => {
        this.tModelList = utility.extractJsonData(apiResponse, 0);
      });
    }
    else {
      (await this.weldRegisterService.getAllWeldList(fProperty.drawing_id)).subscribe((apiResponse: any) => {
        this.tModelList = utility.extractJsonData(apiResponse, 0);
      });
    }

  }
  onSelectionChanged(e) {
    this.selectedInfo = e.selectedRowsData;
    this.weldRegisterService.setSelectedInfo(e.selectedRowsData);
  }

  openDialog(): void {

    var dProperty = {
      width: '400px',
      height: '500px',
      maxHeight: '600px',
      autoFocus: false,
      disableClose: true,
      data: {}
    }

    this.commonService.showDialog<WeldSearchComponent>(WeldSearchComponent, dProperty)
      .subscribe(result => {
        if (result != null && result != undefined) {
          console.log(result);
          this.onSearch(result);
        }
      });
  }

  routeToDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    console.log(e.row?.data);
    if (e.row?.data == null) {
      this.router.navigate(['./weldregisterdetails']);
    }
    let params = {
      drawing_id: e.row?.data.drawing_id,
      weld_id: e.row?.data.weld_id,
    };
    this.router.navigate(['./weldregisterdetails'], { queryParams: params });
    var dModel;
    if (e.row?.data.weld_id == undefined || e.row?.data.weld_id == null) {

      var localUser = JSON.stringify(e.row?.data);
      dModel = <any>JSON.parse(localUser);
      dModel.site_id = this.loggedInUser.site_id;
      dModel.site_code = this.loggedInUser.site_code;
      dModel.site_description = this.loggedInUser.site_description;
    }
    else {
      var localUser = JSON.stringify(e.row?.data);
      dModel = <any>JSON.parse(localUser);
    }
    this.weldRegisterService.setSelectedInfo(dModel);
    e.event?.preventDefault();
  };

  RouteToCreate() {

    var fltrData = utility.getSelectedFilter();
    var pModel = {
      site_id: this.loggedInUser.site_id,
      site_code: this.loggedInUser.site_code,
      site_description: this.loggedInUser.site_description,
      weld_id: null,
      project_id: fltrData.project_id,
      project_code: fltrData.project_code,
      project_title: fltrData.project_title,
      job_id: fltrData.job_id,
      job_code: fltrData.job_code,
      job_title: fltrData.job_title,
      item_id: fltrData.item_id,
      item_code: fltrData.item_code,
      item_title: fltrData.item_title,
      drawing_id: fltrData.drawing_id,
      mcd_drawing_code: fltrData.mcd_drawing_code,
      mcd_drawing_desc: fltrData.mcd_drawing_desc
    };

    this.weldRegisterService.setSelectedInfo(pModel);
    this.router.navigate(['./weldregisterdetails']);
  }

  async DeleteUser() {
    if (this.selectedInfo != null && this.selectedInfo.length == undefined) {
      alert("Please select a weld");
      return;
    }

    if (!confirm("Are you sure to Inactivate selected weld?")) {
      return;
    }

    var drawingInfoDelete = {
      drawing_list: this.selectedInfo
    };

    (await this.weldRegisterService.deleteWeld(drawingInfoDelete)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      // this.onSearch(this.selectedFilterInfo);
    });
  }

  async getButtonsAccess(currRoute: any) {
    (await this.utilService.getAllButtonAccess(currRoute)).subscribe((apiResponse: any) => {
      let resData = utility.extractJsonData(apiResponse, 0);
      this.buttonInitialization(resData);
      if (resData != null && resData != undefined) {
        this.buttonAccess = resData[0];
        this.buttonInitialization(resData[0]);
      }
    });
  }

  buttonInitialization(buttonAccess: any) {
    this.toolbarItems = [
      { label: 'Add Weld', path: '', iconName: '', action: () => this.RouteToCreate(), hidden: false },
      { label: 'Inactivate Weld', path: '', iconName: '', action: () => this.DeleteUser(), hidden: false },
      { label: 'Search', path: '', iconName: '', action: () => this.openDialog(), hidden: false }
    ];
  }
}
