import { Component, ComponentRef, OnInit } from '@angular/core';
import { utility } from '../../../util';
import { MaterialModule } from '../../../core/material-module';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../../../APIServices/ProjectService';
import { projectModel } from '../../../models/projectModel';
import { siteModel } from '../../../models/siteModel';
import { SiteService } from '../../../APIServices/SiteService';
import { CommonModule, DatePipe } from '@angular/common';
import { LookupService } from '../../../APIServices/LookupService';
import { OnlyAlphanumericDirective } from '../../../directives/only-alphanumeric.directive';

@Component({
  selector: 'app-project-details',
  standalone: true,
  imports: [MaterialModule, ReactiveFormsModule, CommonModule, OnlyAlphanumericDirective],
  providers: [DatePipe],
  templateUrl: './project-details.component.html',
  styleUrl: './project-details.component.scss'
})
export class ProjectDetailsComponent implements OnInit {
  selectedInfo: any;
  queryParams: any;
  pModelList: any;
  pModelItemDefault: any;
  sModelList: any;
  activeStatus: any = utility.activeStatus;
  pForm: FormGroup;
  isSiteDisable: boolean = true;
  L_ProjectStatus: any;
 // isSaveDisabled: boolean = false;
  isFormChanged: boolean = false;
  initialFormValues: any;

  constructor(
    private route: ActivatedRoute, private projectService: ProjectService,
      private lookupService: LookupService,
      private siteService: SiteService, 
      private router: Router, 
      private datePipe: DatePipe
  ) {
    this.initializeForm(null);
  }

  async ngOnInit() {
    await this.Index();
    await this.populateLookUp();
  }
  public async Index() {
    this.pModelItemDefault = {
      project_id: null,
      project_code: 'N',
      project_title: 'None'
    };
    await this.projectService.getSelectedInfo.subscribe(x =>
      this.selectedInfo = x);

    if (this.selectedInfo == null) {

      this.queryParams = this.route.snapshot.queryParams['project_id'];
      if (this.queryParams != undefined) {

        var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

        (await this.projectService.getSelectedProject(loggedInUser.logon_id, this.queryParams, loggedInUser.site_id)).subscribe((apiResponse: any) => {
          const jExtract = utility.extractJsonData(apiResponse, 0);
          this.selectedInfo = jExtract[0];
          this.selectedInfo.start_date = this.datePipe.transform(this.selectedInfo.start_date, 'yyyy-MM-dd');
          this.projectService.setSelectedInfo(this.selectedInfo);
          //Save button disabled 
         // this.isSaveDisabled = this.selectedInfo.project_status_code == 'C';
         this.isSaveDisabled(this.selectedInfo.project_status_code);
          this.initializeForm(this.selectedInfo);
        });
      }
    }
    else {
      this.selectedInfo.start_date = this.datePipe.transform(this.selectedInfo.start_date, 'yyyy-MM-dd');      
      //this.isSaveDisabled = this.selectedInfo.project_status_code == 'C';
      this.isSaveDisabled(this.selectedInfo.project_status_code);
      this.initializeForm(this.selectedInfo);
    }


  }
  async populateLookUp() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    (await this.siteService.getAllSiteList(loggedInUser.logon_id, loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.sModelList = utility.extractJsonData(apiResponse, 0);
      if (this.selectedInfo.length > 0)
        this.selectedInfo.site_id = this.selectedInfo[0].site_id;
    });

    (await this.projectService.getAllProjectList(loggedInUser.logon_id, loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.pModelList = utility.extractJsonData(apiResponse, 0);
      if (this.selectedInfo.project_id != null && this.selectedInfo.project_id != undefined) {
        //Adding default option
        this.pModelList.push(this.pModelItemDefault);

        if (this.selectedInfo.project_id != null && this.selectedInfo.project_id != undefined) {
          this.pModelList = this.pModelList.filter((v) => {
            return ((v.project_id != this.selectedInfo.project_id));
          });
          this.pModelList = this.pModelList.filter((v) => {
            return ((v.parent_project_id != this.selectedInfo.project_id));
          });
        }
      }
    });
      
     (await this.lookupService.getProjectStatus(loggedInUser.logon_id, loggedInUser.site_id)).subscribe(
        (apiResponse) => {
          this.L_ProjectStatus = utility.extractJsonData(apiResponse, 0);
        });
     
      // if (this.selectedInfo.length > 0)
      //   this.selectedInfo.parent_project_id = this.selectedInfo.parent_project_id;
   
  }
  async save() {
    this.pForm.markAllAsTouched();
    const isFormValid = this.pForm.valid;
    const formData = this.pForm.getRawValue();
    if (!isFormValid) {
      return;
    }
    formData.project_code = formData.project_code ? formData.project_code.toUpperCase() : null;
    if (this.pForm.value['project_id'] == null) {
      this.createProject(formData);
    } else {
      this.updateProject(formData);
    }
  }
  async createProject(formDataReq: any) {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    this.pForm.controls['requester_id'].setValue(loggedInUser.logon_id);
    (await this.projectService.createProject(formDataReq)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
    });
  }
  async updateProject(formDataReq: any) {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.pForm.controls['requester_id'].setValue(loggedInUser.logon_id);

    (await this.projectService.updateProject(formDataReq)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
    });
  }
  backtoList() {
    this.router.navigate(['./projectlist']);
  }
  async initializeForm(pInfo: any) {
    if (pInfo != null && pInfo.site_code == 'GLB') {
      this.isSiteDisable = false;
    }
    if (pInfo != null && pInfo.project_id != null) {
      
      this.pForm = new FormGroup({
        project_id: new FormControl(pInfo?.project_id),
        project_code: new FormControl({ value: pInfo?.project_code, disabled: true }, [Validators.required]),
        project_title: new FormControl(pInfo?.project_title, [Validators.required]),
        start_date: new FormControl(pInfo?.start_date),
        parent_project_id: new FormControl(pInfo?.parent_project_id),
        site_id: new FormControl({ value: pInfo?.site_id, disabled: this.isSiteDisable }, [Validators.required]),
        is_active: new FormControl({ value: pInfo?.is_active, disabled: pInfo?.is_active }),
        requester_id: new FormControl(''),
        project_status_code: new FormControl({value: pInfo?.project_status_code, disabled: pInfo?.project_status_code == 'C'}),
        status_modified_by: new FormControl({value: pInfo?.status_modified_by, disabled:true}),
        status_modified_dtm: new FormControl({value: this.datePipe.transform(pInfo?.status_modified_dtm,'yyyy-MM-dd HH:mm:ss'), disabled:true})
      });
      this.initialFormValues = this.pForm.getRawValue();
      this.pForm.valueChanges.subscribe(() => {
        this.isFormChanged = utility.checkIfFormChanged(this.pForm, this.initialFormValues);
      });
    }
    else {
      this.pForm = new FormGroup({
        project_id: new FormControl(null),
        project_code: new FormControl('', [Validators.required]),
        project_title: new FormControl('', [Validators.required]),
        start_date: new FormControl(''),
        parent_project_id: new FormControl(null),
        site_id: new FormControl({ value: pInfo?.site_id, disabled: this.isSiteDisable }, [Validators.required]),
        is_active: new FormControl({ value: true, disabled: true }),
        requester_id: new FormControl(''),
        project_status_code: new FormControl({value: 'L', disabled:true}),
        status_modified_by: new FormControl({value: pInfo?.status_modified_by, disabled:true}),
        status_modified_dtm: new FormControl({value: this.datePipe.transform(pInfo?.status_modified_dtm,'yyyy-MM-dd HH:mm:ss'), disabled:true})
      });
      this.pForm.valueChanges.subscribe(() => {
        this.isFormChanged = utility.checkIfFormChanged(this.pForm, this.initialFormValues);
      });
    }
  }

  isSaveDisabled(statusCode?:any) {
    if (statusCode === 'C'){
      return false;
    } else {
      return this.isFormChanged && this.pForm.valid;
    }
  }
}
function ngOnInit(): (target: ProjectDetailsComponent, propertyKey: "") => void {
  throw new Error('Function not implemented.');
}

