import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-report',
  standalone: true,
  imports: [],
  templateUrl: './report.component.html',
  styleUrl: './report.component.scss'
})
export class ReportComponent {
  reportUrl:SafeResourceUrl;
  sanitizedUrl


  constructor(private sanitizer: DomSanitizer){
    const url ='https://mcis-dev.mcdermott.com/ReportServer/Pages/ReportViewer.aspx?%2fMCIS_DEVREPORTS%2fFITUPReport&rs:Command=Render'
    this.reportUrl=this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
