<div class="group-card">
    <mat-card appearance="outlined">
        <mat-card-header>
            <span class="card-title">Process</span>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="pForm" novalidate class="form">
                <div class="flex-container">
                    <div class="side-container">
                        <div class="item-container disNone">
                            <mat-label class="matLabel">ID</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtprocessID" name="text" class="matInputCls"
                                    formControlName="process_id">
                            </mat-form-field>
                        </div>

                        <div class="item-container mt-1e">
                            <mat-label class="matLabel">Process Group</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="process_group_id">
                                    <ng-container>
                                        @for (Item of lookupProcessGroupList; track Item) {
                                        <mat-option [value]="Item.process_group_id">{{Item.process_group_code}}</mat-option>
                                        }
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        
                        <!-- Code -->
                        <div class="item-container">
                            <mat-label class="matLabel asterix mt-1e asterix">Code</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtprocesscode" name="text" class="matInputCls toUpperCase"
                                    formControlName="process_code" Maxlength="2" appOnlyAlphanumeric>
                                <mat-error class="text-danger" *ngIf="pForm.controls['process_code'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!-- Description -->
                        <div class="item-container">
                            <mat-label class="matLabel asterix">Description</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtprocessdescr" name="text" class="matInputCls"
                                    formControlName="process_title" maxlength="20">
                                <mat-error class="text-danger"
                                    *ngIf="pForm.controls['process_title'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">Discipline</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="disc_id">
                                    <ng-container>
                                        @for (Item of lookupDisciplineList; track Item) {
                                        <mat-option [value]="Item.disc_id">{{Item.disc_title}}</mat-option>
                                        }
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel" style="word-wrap: break-word; margin-top: -0.3em;">Weld Metal Deposited <br> per arc hr(Kgs)</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="number" id="txtweldmetaldeposit" name="text" class="matInputCls"
                                    formControlName="weld_metal_deposit">                                
                            </mat-form-field>
                        </div>

                        
                        
                        <div class="item-container">
                            <mat-label class="chkMatLabel">Is Active?</mat-label> 
                            <mat-checkbox formControlName="is_active" class="matInputCls chkMatCheckbox"
                                    id="chkIsActive">
                            </mat-checkbox>                 
                        </div> 
                    </div>
                </div>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button (click)="backtoList()" class="bckBtn">Back</button>
            <button mat-raised-button color="primary" gap="20px" class="mainBtn" (click)="save()" [disabled]="!isSaveDisabled()">Save</button>
        </mat-card-actions>
    </mat-card>
</div>