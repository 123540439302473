import { ComponentType } from "@angular/cdk/portal";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { observable, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class CommonService {
    constructor(private dialog: MatDialog) {

    }

    showDialog<T>(component: ComponentType<T>, dProperty: any): Observable<any> {
        return new Observable(observer => {
            this.dialog.open<T>(component, {
                width: dProperty.width,
                height: dProperty.height,
                maxHeight: dProperty.maxHeight,
                autoFocus: dProperty.autoFocus,
                disableClose: dProperty.disableClose,
                data: dProperty.data
            }).afterClosed().subscribe(value => {
                if (value) {
                    observer.next(value);
                }
                observer.complete();
            });
        });
    }
}