import { Component, OnInit } from '@angular/core';
import { MaterialModule } from '../../../../../core/material-module';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WeldMasterService } from '../../../../../APIServices/master/weld/WeldMasterService';
import { utility } from '../../../../../util';
import { CommonModule } from '@angular/common';
import { OnlyAlphanumericDirective } from '../../../../../directives/only-alphanumeric.directive';

@Component({
  selector: 'app-weld-masters-detail',
  standalone: true,
  imports: [MaterialModule, ReactiveFormsModule, CommonModule, OnlyAlphanumericDirective],
  templateUrl: './weld-masters-detail.component.html',
  styleUrl: './weld-masters-detail.component.scss'
})
export class WeldMastersDetailComponent implements OnInit {
  wmForm: FormGroup;
  selectedInfo: any;
  queryParams: any;
  bEditMode: boolean = false;
  masterTypeList: any = []
  selectedMasterType: any;
  selectedMasterTypeChild: any;
  parentList: any = []
  showParent: boolean = false;
  parentLabel: any;
  selectedMasterLabel: any;
  isFormChanged: boolean = false;
  initialFormValues: any;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private weldMasterService: WeldMasterService) {

    this.initializeForm(null)
  }

  async ngOnInit() {
    await this.Index();
  }

  async populateMasterTypeList() {
    (await this.weldMasterService.getAllMasterType()).subscribe((apiResponse: any) => {
      this.masterTypeList = utility.extractJsonData(apiResponse, 0);
      if (this.masterTypeList != null || this.masterTypeList != undefined) {
        this.populateParentList(this.masterTypeList);
      }
    });
  }

  async Index() {
    await this.weldMasterService.getSelectedInfo$.subscribe(x =>
      this.selectedInfo = x);

    if (this.selectedInfo == null) {
      this.queryParams = this.activatedRoute.snapshot.queryParams['master_id'];

      if (this.queryParams != undefined) {
        this.bEditMode = true;

        (await this.weldMasterService.getWeldMastersDetail(this.queryParams)).subscribe((apiResponse: any) => {
          const jExtract = utility.extractJsonData(apiResponse, 0);
          this.selectedInfo = jExtract[0];

          this.weldMasterService.setSelectedInfo(this.selectedInfo);
          this.initializeForm(this.selectedInfo);
          this.populateMasterTypeList();
        });
      }
      else {
        this.weldMasterService.getSelectedMasterTypeInfo$.subscribe((y) => {
          this.selectedMasterType = y ?? 4;
          this.initializeForm(null);
          this.populateMasterTypeList();
        });
        this.bEditMode = false;
      }
    }
    else {
      this.bEditMode = true;
      this.initializeForm(this.selectedInfo);
      this.populateMasterTypeList();
    }
  }

  async populateParentList(mtList: any) {
    var getMTId = this.selectedMasterType == null ? this.wmForm.controls['master_type_code'].value : this.selectedMasterType;
    this.populateAndDisplayParent(getMTId)
  }

  initializeForm(wmInfo: any) {
    if (wmInfo != null && (wmInfo.master_id != null && wmInfo.master_id != undefined)) {
      this.wmForm = new FormGroup(
        {
          master_id: new FormControl(wmInfo?.master_id),
          master_type_code: new FormControl({ value: wmInfo?.master_type_code, disabled: true }, [Validators.required]),
          code: new FormControl({ value: wmInfo?.code, disabled: this.bEditMode }, [Validators.required]),
          description: new FormControl(wmInfo?.description, [Validators.required]),
          parent_id: new FormControl(wmInfo?.parent_id),
          is_active: new FormControl({ value: wmInfo?.is_active, disabled: wmInfo?.is_active })
        }
      );
      this.initialFormValues = this.wmForm.getRawValue();
      this.detectFormValueChanges(this.initialFormValues);
    }
    else {
      this.wmForm = new FormGroup(
        {
          master_id: new FormControl(null,),
          master_type_code: new FormControl({ value: wmInfo?.master_type_code, disabled: false }, [Validators.required]),
          code: new FormControl({ value: null, disabled: false }, [Validators.required]),
          description: new FormControl({ value: null, disabled: false }, [Validators.required]),
          parent_id: new FormControl(null),
          is_active: new FormControl({ value: true, disabled: true })
        }
      );
      this.detectFormValueChanges();
    }
  }

  backtoList() {
    this.router.navigate(['./weldmasterslist']);
  }

  save() {
    this.wmForm.markAllAsTouched();
    const isFormValid = this.wmForm.valid;
    if (isFormValid) {
      if (this.wmForm.value['master_id'] == null) {
        this.createItem();
      } else {
        this.updateItem();
      }
      this.isFormChanged = false;
    } else {
      return;
    }
  }

  async createItem() {
    (await this.weldMasterService.CreateWeldMaster(this.wmForm.getRawValue())).subscribe((apiResponse: any) => {
      if (utility.responseAlert(apiResponse)) {
        this.backtoList();
      };
    });
  }

  async updateItem() {
    (await this.weldMasterService.UpdateWeldMaster(this.wmForm.getRawValue())).subscribe((apiResponse: any) => {
      if (utility.responseAlert(apiResponse)) {
        this.backtoList()
      };
    });
  }
  onSelectionChanged(e) {
    this.populateAndDisplayParent(e.value);
  }

  async populateAndDisplayParent(param_mtid: any) {
    var filteredParentList = this.masterTypeList.filter((v) =>
      (v.master_type_code === (param_mtid == 0 ? null : param_mtid))
    )

    var parent_code;
    if (filteredParentList != null && filteredParentList !== undefined && filteredParentList.length > 0) {
      parent_code = filteredParentList[0].parent_code;
      this.parentLabel = filteredParentList[0].parent_label;
      this.selectedMasterLabel = filteredParentList[0].parent_label;
      if (parent_code != null || parent_code != undefined) {
        this.showParent = true;
        (await this.weldMasterService.getAllByMasterType(parent_code)).subscribe((apiResponse: any) => {
          this.parentList = utility.extractJsonData(apiResponse, 0);
        });
      }
      else {
        this.showParent = false;
      }
    }
  }

  detectFormValueChanges(initialFormValues?: any) {
    this.wmForm.valueChanges.subscribe(() => {
      this.isFormChanged = utility.checkIfFormChanged(this.wmForm, initialFormValues);
    });
  }

  isSaveDisabled() {
    return this.isFormChanged && this.wmForm.valid;
  }
}
