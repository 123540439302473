import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { MaterialModule } from '../../../core/material-module';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-searchable-dropdown',
  standalone: true,
  imports: [MaterialModule, FormsModule],
  templateUrl: './searchable-dropdown.component.html',
  styleUrls: ['./searchable-dropdown.component.scss']
})
export class SearchableDropdownComponent implements OnChanges {
  @Input() options: any[] = []; // The list of options for the dropdown
  @Input() valueKey: string = 'id'; // The key to use for value binding
  @Input() displayKey: string = 'name'; // The key to display in the dropdown
  @Input() displayKey1: any = null; // The key to display in the dropdown
  @Input() label: string = 'Select'; // Label for the dropdown
  @Input() selectedValue: any; // Selected value from the dropdown
  
  @Output() selectionChange = new EventEmitter<any>(); // Emit the selected value

  searchTerm: string = ''; // Search term entered by the user
  filteredOptions: any[] = []; // Filtered list of options


  ngOnChanges(changes: SimpleChanges): void {
    // Initialize the filtered list with all options
    if (changes) {
      this.filteredOptions = [...this.options];
    }
    if ((this.options!=null && this.options.length>0) && 
    (this.selectedValue == null || this.selectedValue == undefined)) {
      this.selectedValue = this.options[0][this.valueKey]
      this.emitSelection(this.options[0]);
    }
  }

  // Filter options based on the search term
  filterOptions(): void {
    const searchValue = this.searchTerm.toLowerCase();
    this.filteredOptions = this.options.filter(option =>
      option[this.displayKey].toLowerCase().includes(searchValue)
    );
  }

  // Emit selected value when user selects an option
  onSelectionChange(event: any): void {
    const selectedOption = this.options.find(option => option[this.valueKey] === event.value);
    this.emitSelection(selectedOption);
  }

  private emitSelection(selectedOption: any): void {
    this.selectionChange.emit(selectedOption);
  }

}