<app-toolbar-buttons  *ngIf="toolbarItems" [items]="toolbarItems" [title]="title"></app-toolbar-buttons>

<div class="cls_drpdwn">
    <app-searchable-dropdown *ngIf="sModelList" [options]="sModelList" [valueKey]="'site_id'" [displayKey] ="'site_code'" [displayKey1]="'site_description'" [label]="'Select Site'" [selectedValue]="selectedsite" (selectionChange)="onItemSelect($event)"> </app-searchable-dropdown>
    
    <app-searchable-dropdown *ngIf="dStatusFilter" [options]="dStatusFilter" [valueKey]="'valueMenber'" [displayKey] ="'displayMember'" [label]="'Select Status'" [selectedValue]="selectedStatusFilter" (selectionChange)="onItemSelectStatus($event)"> </app-searchable-dropdown>
</div>
<mat-divider class="divider_cls"></mat-divider>
<div class="centered-outer-div">
    <dx-data-grid id="gridUserList" #dxUserList [dataSource]="uModelList"
        [allowColumnReordering]="true" class="centered-inner-div" [showBorders]="true"
        [showColumnLines]="true" [showRowLines]="true" [rowAlternationEnabled]="true" 
        (onSelectionChanged)="onSelectionChanged($event)" width="1200px">
        <dxo-selection mode="multiple" showCheckBoxesMode="always">
        </dxo-selection>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column type="buttons" [width]="40">
            <dxi-button hint="Edit selected User" icon="edit" [visible]=true [disabled]="!buttonAccess?.can_view"
                [onClick]="RouteToUserDetails"></dxi-button>
        </dxi-column>

        <dxi-column dataField="user_id" [width]="100" caption="User ID" [visible]="false"></dxi-column>
        <dxi-column dataField="site_id" [width]="100" caption="Site ID" [visible]="false"></dxi-column>
        <dxi-column dataField="site_code" [width]="100" caption="Site Code"
            [visible]="false"></dxi-column>
        <dxi-column dataField="merged_group_id" [width]="100" caption="Merged Group ID"
            [visible]="false"></dxi-column>

        <dxi-column dataField="logon_id" [width]="250" cssClass="WrappedColumnClass" caption="Logon ID" sortOrder="asc"></dxi-column>
        <dxi-column dataField="full_name" [width]="200" cssClass="" caption="Full Name"></dxi-column>
        <dxi-column dataField="merged_group_name" [width]="200" cssClass="" caption="Assigned Groups"></dxi-column>
        <dxi-column dataField="user_email" [width]="150" cssClass="" caption="EMail"></dxi-column>
        <dxi-column dataField="job_function" [width]="100" cssClass="" caption="Job Function"></dxi-column>
        <dxi-column dataField="address" [width]="200" cssClass="" caption="Address"></dxi-column>
        <dxi-column dataField="country" [width]="100" cssClass="" caption="Country"></dxi-column>
        <dxi-column dataField="zipcode" [width]="70" caption="Zipcode"></dxi-column>
        <dxi-column dataField="is_active" [width]="80" caption="is Active?"
            [visible]="true" cssClass="dxCheckBoxFormat"></dxi-column>
        <dxi-column dataField="is_current" [width]="80" caption="is Current?"
            [visible]="true" cssClass="dxCheckBoxFormat"></dxi-column>
        <dxi-column dataField="base_site_description" [width]="120" caption="Base Site" cssClass="dxCheckBoxFormat"></dxi-column>


        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 50, 'all']"
            [showNavigationButtons]="true" [visible]="true">
        </dxo-pager>
        <dxo-paging [pageSize]="10" [pageIndex]="0">
        </dxo-paging>
    </dx-data-grid>
</div>
<mat-divider class="divider_cls"></mat-divider>
<span class="card-title subtitle_cls">
    Associated Projects
</span>
<div class="centered-outer-div mt-1em">
    <dx-tree-list id="treeProjectList" [dataSource]="dModelList" keyExpr="project_id"
        parentIdExpr="parent_project_id" [showRowLines]="true" [showBorders]="true"
        class="centered-inner-div" (onSelectionChanged)="onSelectionChanged($event)" width="1200px">
        <dxo-filter-row [visible]="true"></dxo-filter-row>

        <dxi-column dataField="project_id" [width]="100" caption="Project ID"
            [visible]="false"></dxi-column>
        <dxi-column dataField="site_id" [width]="100" caption="Site ID" [visible]="false"></dxi-column>
        <dxi-column dataField="site_code" [width]="100" caption="Site Code"
            [visible]="false"></dxi-column>
        <dxi-column dataField="project_code" [width]="125" caption="Project Code"></dxi-column>
        <dxi-column dataField="project_title" [width]="300" caption="Project Title"></dxi-column>
        <dxi-column dataField="start_date" [width]="150" caption="Start Date" dataType="date"
            format="MM/dd/yyyy"></dxi-column>
        <dxi-column dataField="parent_project_id" [width]="200" caption="Parent Project ID"
            [visible]="false"></dxi-column>
        <dxi-column dataField="parent_project_code" [width]="200"
            caption="Parent Project Code"></dxi-column>
        <dxi-column dataField="parent_project_title" [width]="300"
            caption="Parent Project Title"></dxi-column>
        <dxi-column dataField="is_active" [width]="70" caption="Is Active?"
            [visible]="true"></dxi-column>

        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 50, 'all']"
            [showNavigationButtons]="true">
        </dxo-pager>
        <dxo-paging [pageSize]="10" [pageIndex]="0">
        </dxo-paging>
    </dx-tree-list>
</div>