import { Component, OnInit } from '@angular/core';
import { GroupService } from '../../../APIServices/GroupService';
import { MaterialModule } from '../../../core/material-module';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { utility } from '../../../util';
import { groupTreeModel } from '../../../models/groupModel';
import { siteModel } from '../../../models/siteModel';
import { SiteService } from '../../../APIServices/SiteService';
import { CommonModule } from '@angular/common';
import { OnlyAlphanumericDirective } from '../../../directives/only-alphanumeric.directive';

@Component({
  selector: 'app-group-details',
  standalone: true,
  imports: [MaterialModule, ReactiveFormsModule, CommonModule, OnlyAlphanumericDirective],
  templateUrl: './group-details.component.html',
  styleUrl: './group-details.component.scss'
})
export class GroupDetailsComponent {
  queryParams: any;
  dModelList: any;
  isSiteDisable: boolean = false;
  activeStatus: any = utility.activeStatus;
  selectedInfo: any;
  gForm: FormGroup;
  isGlobalUser: any = false;
  isFormChanged: boolean = false;
  initialFormValues: any;

  constructor(private route: ActivatedRoute, private groupService: GroupService
    , private siteService: SiteService, private router: Router) {
    this.initializeForm(null);
  }

  async ngOnInit() {
    await this.Index();
    await this.populateSite();
  }
  async Index() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    await this.groupService.getSelectedInfo.subscribe(x =>
      this.selectedInfo = x);

    if (this.selectedInfo == null) {
      this.queryParams = this.route.snapshot.queryParams['group_id'];
      if (this.queryParams != undefined) {

        (await this.groupService.getSelectedGroup(loggedInUser.logon_id, this.queryParams, loggedInUser.site_id)).subscribe((apiResponse: any) => {
          const jExtract = utility.extractJsonData(apiResponse, 0);
          this.selectedInfo = jExtract[0];
          this.groupService.setSelectedInfo(this.selectedInfo);
          this.initializeForm(this.selectedInfo);
        });
      }
    }
    else {
      this.initializeForm(this.selectedInfo);
    }
  }
  async save() {
    this.gForm.markAllAsTouched();
    const isFormValid = this.gForm.valid;
    const formData = this.gForm.getRawValue();
    if (!isFormValid) {
      return;
    }
    formData.group_code = formData.group_code ? formData.group_code.toUpperCase() : null;
    if (this.gForm.value['group_id'] == null) {
      await this.createGroup(formData);
    } else {
      await this.updateGroup(formData);
    }
  }
  async createGroup(formDataReq: any) {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.gForm.value['requester_id'] = loggedInUser.logon_id;

    (await this.groupService.createGroup(formDataReq)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
    });
  }
  async updateGroup(formDataReq: any) {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.gForm.value['requester_id'] = loggedInUser.logon_id;

    (await this.groupService.updateGroup(formDataReq)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
    });
  }

  backtoList() {
    this.router.navigate(['./grouplist']);
  }
  async populateSite() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    if (loggedInUser.site_id == 1 && loggedInUser.site_code == 'GLB') {
      this.isSiteDisable = false;
    }

    (await this.siteService.getActiveSiteList(loggedInUser.logon_id, loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.dModelList = utility.extractJsonData(apiResponse, 0);
    });
  }
  async initializeForm(gInfo: any) {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.isGlobalUser = (loggedInUser.site_id == 1 ? true : false);

    if (gInfo != null && ( gInfo?.group_id != undefined || gInfo?.group_id != null)) { //&& gInfo.site_code == 'GLB'
      this.gForm = new FormGroup({
        group_id: new FormControl(gInfo?.group_id),
        group_code: new FormControl({ value: gInfo?.group_code, disabled: true }, [Validators.required]),
        group_name: new FormControl(gInfo?.group_name, [Validators.required]),
        display_name: new FormControl(gInfo?.display_name),
        site_id: new FormControl({ value: gInfo?.site_id, disabled: this.isSiteDisable }, [Validators.required]),
        is_active: new FormControl({ value: gInfo?.is_active , disabled:gInfo?.is_active}),
        is_admin: new FormControl({ value: gInfo?.is_admin, disabled: true }),
        requester_id: new FormControl('')
      });

      this.initialFormValues = this.gForm.getRawValue();
      this.gForm.valueChanges.subscribe(() => {
        this.isFormChanged = utility.checkIfFormChanged(this.gForm, this.initialFormValues);
      });
    }
    else {
      this.gForm = new FormGroup({
        group_id: new FormControl(null),
        group_code: new FormControl('', [Validators.required]),
        group_name: new FormControl('', [Validators.required]),
        display_name: new FormControl(''),
        site_id: new FormControl({ value: gInfo?.site_id, disabled: this.isSiteDisable }, [Validators.required]),
        is_active: new FormControl({ value: true, disabled: true }),
        is_admin: new FormControl({ value: this.isGlobalUser, disabled: true }),
        requester_id: new FormControl('')
      });
      this.gForm.valueChanges.subscribe(() => {
        this.isFormChanged = utility.checkIfFormChanged(this.gForm, this.initialFormValues);
      });
    }
  }

  isSaveDisabled() {
    return this.isFormChanged && this.gForm.valid;
  }
}
