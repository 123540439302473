<app-toolbar-buttons *ngIf="toolbarItems" [items]="toolbarItems" [title]="title"></app-toolbar-buttons>

<div class="centered-outer-div">
    <dx-data-grid id="gridSiteList" [dataSource]="dModelList" keyExpr="site_id" [rowAlternationEnabled]="true"
        class="centered-inner-div" [showBorders]="true" [showColumnLines]="true" [showRowLines]="true">
        <dxi-column type="buttons" [width]="50">
            <dxi-button hint="Edit selected User" icon="edit" [visible]=true [disabled]=!buttonAccess?.can_edit
                [onClick]="RouteToDetails"></dxi-button>
        </dxi-column>

        <dxi-column dataField="site_id" [width]="100" caption="Site ID" [visible]="false"></dxi-column>
        <dxi-column dataField="site_code" [width]="100" caption="Site Code" sortOrder="asc"></dxi-column>
        <dxi-column dataField="site_description" [width]="200" caption="Site Description"></dxi-column>
        <dxi-column dataField="time_zone" [width]="200" caption="Time Zone"></dxi-column>
        <dxi-column dataField="is_active" [width]="70" caption="Is Active"></dxi-column>
    </dx-data-grid>
</div>