import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ProjectJobService } from '../../../APIServices/ProjectJobService';
import { CommonModule } from '@angular/common';
import { utility } from '../../../util';
import { MaterialModule } from '../../../core/material-module';
import { Router } from '@angular/router';
import { ProjectItemService } from '../../../APIServices/ProjectItemService';
import { OnlyAlphanumericDirective } from '../../../directives/only-alphanumeric.directive';

@Component({
  selector: 'app-add-edit-item',
  standalone: true,
  imports: [MaterialModule, ReactiveFormsModule, CommonModule, OnlyAlphanumericDirective],
  templateUrl: './add-edit-item.component.html',
  styleUrl: './add-edit-item.component.scss'
})
export class AddEditItemComponent implements OnInit {
  pItemForm: FormGroup
  activeStatus = utility.activeStatus;
  selectedInfo: any;
  isEditMode:any;
  isFormChanged: boolean = false;
  initialFormValues: any;

  constructor(private dialogRef: MatDialogRef<AddEditItemComponent>,
    private router: Router, private projectItemService: ProjectItemService
  ) {
    this.initializeForm(null);
  }
  async ngOnInit() {
    await this.projectItemService.getSelectedInfo.subscribe(x =>
      this.selectedInfo = x);

    if (this.selectedInfo.item_id == null || this.selectedInfo.item_id == 0
      || this.selectedInfo.item_id == undefined) {
      this.isEditMode = false;
    } else {
      this.isEditMode = true;
    } 

    if (this.selectedInfo != null) {
      this.initializeForm(this.selectedInfo);
    }
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  saveItemInfo() {
    this.pItemForm.markAllAsTouched();
    const isFormValid = this.pItemForm.valid;
    if (!isFormValid) {
      var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
      this.pItemForm.controls['requester_id'].setValue(loggedInUser.logon_id);
      const formData = this.pItemForm.getRawValue();
      formData.item_code = formData.item_code ? formData.item_code.toUpperCase() : null;
      if (this.pItemForm.value['item_id'] == null) {
        this.createItem(formData);
      }
      else {
        this.updateItem(formData);
      }
      this.isFormChanged = false;
    } else {
      return;
    }
  }
  async createItem(formDataReq: any) {
    (await this.projectItemService.createProjectItem(formDataReq)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.closeDialog();
    });
  }
  async updateItem(formDataReq: any) {
    (await this.projectItemService.updateProjectItem(formDataReq)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.closeDialog();
    });
  }
  async initializeForm(pItemInfo: any) {
    if (pItemInfo != null) {
      this.pItemForm = new FormGroup({
        item_id: new FormControl(pItemInfo?.item_id),
        job_id: new FormControl(pItemInfo?.job_id),
        site_id: new FormControl(pItemInfo?.site_id),
        project_id: new FormControl(pItemInfo?.project_id),
        item_code: new FormControl({value: pItemInfo?.item_code, disabled: this.isEditMode}, [Validators.required]),
        item_title: new FormControl(pItemInfo?.item_title, [Validators.required]),
        item_narrative: new FormControl(pItemInfo?.item_narrative, [Validators.required]),
        freeze: new FormControl(pItemInfo?.freeze, [Validators.required]),
        total_drawings: new FormControl(pItemInfo?.total_drawings),
        total_welds: new FormControl(pItemInfo?.total_welds),
        is_active: new FormControl({ value: pItemInfo?.is_active, disabled: pItemInfo?.is_active},  [Validators.required]),        
        requester_id: new FormControl('')
      });
      this.initialFormValues = this.pItemForm.getRawValue();
      this.detectFormValueChanges(this.initialFormValues);
    }
    else {
      this.pItemForm = new FormGroup({
        item_id: new FormControl(''),
        job_id: new FormControl(''),
        site_id: new FormControl(''),
        project_id: new FormControl(''),
        item_code: new FormControl({value: '', disabled: this.isEditMode}, [Validators.required]),
        item_title: new FormControl('', [Validators.required]),
        item_narrative: new FormControl('', [Validators.required]),
        freeze: new FormControl(false, [Validators.required]),
        total_drawings: new FormControl(''),
        total_welds: new FormControl(''),
        is_active: new FormControl(true, [Validators.required]),
        requester_id: new FormControl('')
      });
      this.detectFormValueChanges();
    }
  }

  detectFormValueChanges(initialFormValues?: any) {
    this.pItemForm.valueChanges.subscribe(() => {
      this.isFormChanged = utility.checkIfFormChanged(this.pItemForm, initialFormValues);
    });
  }

  isSaveDisabled() {
    return this.isFormChanged && this.pItemForm.valid;
  }
}