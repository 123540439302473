import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { utility } from '../../../util';

@Injectable({
  providedIn: 'root'
})
export class WeldProcedureToLineClassService {

  private apiBaseUrl: string;
  private selectedInfo = new BehaviorSubject(null);
  getSelectedInfo = this.selectedInfo.asObservable();
  private environmentConfig = utility.getEnvironmentData();
  requester_id: any;
  site_id: any;

  constructor(private httpClient: HttpClient, private router: Router) {
      var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
      this.requester_id = loggedInUser.logon_id
      this.site_id = loggedInUser.site_id      
      this.apiBaseUrl = this.environmentConfig.weldApiBase;
  }

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }

    setSelectedInfo(paramData: any) {
        this.selectedInfo.next(paramData);
    }
    
    getLineClassByProcdureID(weld_prcd_project_map_id: any): Observable<any> {
      const params = new HttpParams()
      .set('requester_id',this.requester_id)
      .set('site_id',this.site_id)
      .set('weld_prcd_project_map_id',weld_prcd_project_map_id);
      var apiMethod = "WeldProcedureLineClassMapInfo/GetAll";
      var url = (this.apiBaseUrl +  apiMethod);
      return this.httpClient.get(url,{params: params});
      
    }

   mapProcessToLineclass(request:any): Observable<any> {
      var apiMethod = "WeldProcedureLineClassMapInfo/Create";
      var url = (this.apiBaseUrl + apiMethod);
      return this.httpClient.post(url, JSON.stringify(request), this.httpOptions)
      .pipe(
          catchError(this.errorHandler)
      );
  } 

  getActiveLineClassList(){
    const params = new HttpParams()
      .set('requester_id',this.requester_id)
      .set('site_id',this.site_id)
      .set('is_active', true);

    var apiMethod = "LineClassInfo/GetAll";
    var url = (this.apiBaseUrl + apiMethod);
    return this.httpClient.get(url,{params: params});
}

  deleteLineClassFromProcedure(delData: any): Observable<any> {
    var apiMethod = "WeldProcedureLineClassMapInfo/delete";
    var url = (this.apiBaseUrl + apiMethod);
    return this.httpClient.put(url, JSON.stringify(delData), this.httpOptions)
    .pipe(
        catchError(this.errorHandler)
    );
}

  errorHandler(error: any) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        errorMessage = error.error.message;
      } else {
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      return throwError(errorMessage);
    }
}
