import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { utility } from '../../../util';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WeldProcedureToProcessService {

  private apiBaseUrl: string;
  private selectedInfo = new BehaviorSubject(null);
  getSelectedInfo = this.selectedInfo.asObservable();
  private environmentConfig = utility.getEnvironmentData();
  requester_id: any;
  site_id: any;
  weldProceApiEndPoint: string = "weldProcedureinfo/"

  constructor(private httpClient: HttpClient, private router: Router) {
      //this.apiBaseUrl = environment.apiBase;
      var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
      this.requester_id = loggedInUser.logon_id
      this.site_id = loggedInUser.site_id      
      this.apiBaseUrl = this.environmentConfig.weldApiBase;
  }

  httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      })
  }

  setSelectedInfo(paramData: any) {
      this.selectedInfo.next(paramData);
  }

  getProcessByProcdureID(weld_procedure_id: any): Observable<any> {
     // this.site_id = 1;
      const params = new HttpParams()
      .set('requester_id',this.requester_id)
      .set('site_id',this.site_id)
      .set('weld_procedure_id',weld_procedure_id);
      var apiMethod = "WeldProcedureProcessMapInfo/GetAll";
      var url = (this.apiBaseUrl +  apiMethod);
      return this.httpClient.get(url,{params: params});
      
  }

  deleteProcessByProcedureID(delData: any): Observable<any> {
      var apiMethod = "WeldProcedureProcessMapInfo/delete";
      var url = (this.apiBaseUrl + apiMethod);
      return this.httpClient.put(url, JSON.stringify(delData), this.httpOptions)
      .pipe(
          catchError(this.errorHandler)
      );
  }

  mapProcessToProcedure(request:any): Observable<any> {
      var apiMethod = "WeldProcedureProcessMapInfo/create";
      var url = (this.apiBaseUrl + apiMethod);
      return this.httpClient.post(url, JSON.stringify(request), this.httpOptions)
      .pipe(
          catchError(this.errorHandler)
      );
  } 

  getActiveProcessList(){
      const params = new HttpParams()
        .set('requester_id',this.requester_id)
        .set('site_id',this.site_id)
        .set('is_active', true);
  
      var apiMethod = "processInfo/getall";
      var url = (this.apiBaseUrl + apiMethod);
      return this.httpClient.get(url,{params: params});
  }

  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

}
