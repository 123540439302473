<div class="card">
    <mat-card appearance="outlined">
        <mat-card-header>
            <span class="card-title">Resource Information</span>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="resourceForm" novalidate class="form">
                <div class="flex-container">
                    <div class="side-container">
                        <div class="item-container disNone">
                            <mat-label class="matLabel">ID</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtresourcetypeID" name="text" class="matInputCls"
                                    formControlName="res_type_id" maxlength="120">
                            </mat-form-field>
                        </div>
                        <div class="item-container mt-1e">
                            <mat-label class="matLabel asterix">Type</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="res_type_id" (selectionChange)="fetchDependentFieldsValue($event.value)">
                                    @for (item of resourceOptionList; track item) {
                                        <mat-option [value]="item.resource_type_id">{{item.resource_type}}</mat-option>
                                    }
                                </mat-select>
                                <mat-error class="text-danger" *ngIf="resourceForm.controls['res_type_id'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel asterix">Code</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtLogon" class="matInputCls toUpperCase" name="text"
                                    formControlName="resource_code" maxlength="15" appOnlyAlphanumeric>
                                <mat-error class="text-danger" *ngIf="resourceForm.controls['resource_code'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel asterix">Employee Name</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtEmployeeName" name="text" class="matInputCls"
                                    formControlName="name" maxlength="30">
                                <mat-error class="text-danger" *ngIf="resourceForm.controls['name'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">Status</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="res_status_id">
                                    @for (item of resStatusOptionList; track item) {
                                        <mat-option [value]="item.resource_status_id">{{item.resource_status}}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel asterix">Employee Type</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="emp_type_id">
                                    @for (item of empTypeOptionList; track item) {
                                        <mat-option [value]="item.employee_type_id">{{item.employee_type_title}}</mat-option>
                                    }
                                </mat-select>
                                <mat-error class="text-danger" *ngIf="resourceForm.controls['emp_type_id'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel asterix">Work Location</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="location_id">
                                    @for (item of empLocationOptionList; track item) {
                                        <mat-option [value]="item.location_id">{{item.location_description}}</mat-option>
                                    }
                                </mat-select>
                                <mat-error class="text-danger" *ngIf="resourceForm.controls['location_id'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="chkMatLabel">Is Active?</mat-label>
                            <mat-checkbox formControlName="is_active" class="matInputCls chkMatCheckbox"
                                id="chkIsActive">
                            </mat-checkbox>
                        </div>
                    </div>
                    
                    <div class="side-container">
                        <div class="item-container mt-1e">
                            <mat-label class="matLabel">Type Craft</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="type_craft_id">
                                    @for (item of typeCraftOptionList; track item) {
                                        <mat-option [value]="item.type_craft_id">{{item.type_craft_title}}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel asterix">Start Date</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput [matDatepicker]="picker" type="text" id="txtStartDate" name="text" class="matInputCls"
                                    formControlName="start_date" maxlength="250">

                                <mat-datepicker-toggle style="width: 1em;" matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker style="width: 20px;" #picker></mat-datepicker>

                                <mat-error class="text-danger" *ngIf="resourceForm.controls['start_date'].errors?.['required']">
                                    This is required!
                                </mat-error>
                                <mat-error class="text-danger mat-date-error" *ngIf="resourceForm.errors?.['dateInvalid'] && resourceForm.get('start_date')?.touched">
                                    Start date cannot be greater than End date!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">End Date</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput [matDatepicker]="picker1" type="text" id="txtEndDate" name="text" class="matInputCls"
                                    formControlName="end_date" maxlength="250">
                                    
                                <mat-datepicker-toggle style="width: 1em;" matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker style="width: 20px;" #picker1></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel asterix">Badge ID</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtBadgeID" name="text" class="matInputCls"
                                    formControlName="badge_no" maxlength="15">
                                <mat-error class="text-danger" *ngIf="resourceForm.controls['badge_no'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel asterix">SAP ID</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtSAPID" name="text" class="matInputCls"
                                    formControlName="sap_id" maxlength="15">
                                <mat-error class="text-danger" *ngIf="resourceForm.controls['sap_id'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="fileUplaodLabel">Upload Image</mat-label>
                            <div class="fileUploadSmall">
                                <app-file-upload (imageChange)="onImageChange($event)" [form]="resourceForm" [imageOrDoc]="'I'" [fileType]="'image/*'" [isMultiSelect]="false" fControlName="resourceForm.get('res_image')"></app-file-upload>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button (click)="backtoList()" class="bckBtn">Back</button>
            <button mat-raised-button color="primary" gap="20px" class="mainBtn" id="saveButton" (click)="save()" [disabled]="!isSaveDisabled()">Save</button>
        </mat-card-actions>
    </mat-card>
</div>