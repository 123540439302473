import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError, throwError } from "rxjs";
import { Router } from "@angular/router";
import { utility } from "../../../util";

@Injectable({
    providedIn: 'root'
})

export class WeldRegisterService {
    private apiWeldNDTUrl: string;
    private apiBaseUrl: string;
    private apiWeldBaseUrl: string;
    private selectedInfo = new BehaviorSubject(null);
    getSelectedInfo = this.selectedInfo.asObservable();
    private environmentConfig = utility.getEnvironmentData();
    requester_id: any;
    site_id: any;
    apiEndPoint: string = "WeldRegisterInfo/"

    constructor(private httpClient: HttpClient, private router: Router) {
        var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
        this.requester_id = loggedInUser.logon_id
        this.site_id = loggedInUser.site_id
        this.apiWeldNDTUrl = this.environmentConfig.weldNDTApiUrl;
        this.apiBaseUrl = this.environmentConfig.apiBase;
        this.apiWeldBaseUrl = this.environmentConfig.weldApiBase;
    }

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }

    setSelectedInfo(paramData: any) {
        this.selectedInfo.next(paramData);
    }

    public async getAllWeldList(drawing_id: any) {
        var apiMethod = this.apiEndPoint + "GetAll";

        const params = new HttpParams()
            .set('requester_id', this.requester_id)
            .set('site_id', this.site_id)
            .set('drawing_id', drawing_id)
            

        var url = this.apiWeldNDTUrl + apiMethod;
        return this.httpClient.get(url, { params: params }).pipe(
            catchError(this.errorHandler)
        );
    }

    public async getSelectedWeld(drawing_id: any, weld_id: any) {
        const params = new HttpParams()
            .set('requester_id', this.requester_id)
            .set('site_id', this.site_id)
            .set('drawing_id', drawing_id)
            .set('weld_id', weld_id);

        var apiMethod = this.apiEndPoint + "detail";
        var url = (this.apiWeldNDTUrl + apiMethod);
        return this.httpClient.get(url, { params: params }).pipe(
            catchError(this.errorHandler)
        );
    }

    public async createWeld(post: any) {
        post.requester_id = this.requester_id;
        post.site_id = this.site_id;

        var apiMethod = this.apiEndPoint + "Create";
        var url = (this.apiWeldNDTUrl + apiMethod)
        return this.httpClient.post(url, JSON.stringify(post), this.httpOptions)
            .pipe(
                catchError(this.errorHandler)
            );
    }

    public async updateWeld(put: any) {
        put.requester_id = this.requester_id;
        put.site_id = this.site_id;

        var apiMethod = this.apiEndPoint + "Update";
        var url = (this.apiWeldNDTUrl + apiMethod)
        return this.httpClient.put(url, JSON.stringify(put), this.httpOptions)
            .pipe(
                catchError(this.errorHandler)
            );
    }

    public async deleteWeld(del: any) {
        var apiMethod = this.apiEndPoint + "Delete";
        var url = (this.apiWeldNDTUrl + apiMethod);
        return this.httpClient.put(url, JSON.stringify(del), this.httpOptions)
            .pipe(
                catchError(this.errorHandler)
            );
    }

    public async getDisciplineList() {
        var apiMethod = "Lookup/Discipline";
        const params = new HttpParams()
            .set('requester_id', this.requester_id)
            .set('site_id', this.site_id)
            .set('is_active', true);

        var url = this.apiBaseUrl + apiMethod;
        return this.httpClient.get(url, { params: params }).pipe(
            catchError(this.errorHandler)
        );
    }

    public async getAllLineClassList(project_id: any) {
        const params = new HttpParams()
            .set('requester_id', this.requester_id)
            .set('site_id', this.site_id)
            .set('project_id', project_id)
            .set('is_active', true);

        var apiMethod = "LineClassInfo/GetAll";
        var url = this.apiWeldBaseUrl + apiMethod;
        return this.httpClient.get(url, { params: params }).pipe(
            catchError(this.errorHandler)
        );
    }

    public async getDiscipline() {
        const params = new HttpParams()
            .set('requester_id', this.requester_id)
            .set('site_id', this.site_id);
        var apiMethod = "Lookup/Discipline";
        var url = (this.apiBaseUrl + apiMethod);
        return this.httpClient.get(url, { params: params });
    }

    public async getCategoryList(project_id:any) {
        const params = new HttpParams()
            .set('requester_id', this.requester_id)
            .set('site_id', this.site_id)
            .set('project_id', project_id);

        var apiMethod = "WeldCategory/GetAll";
        var url = (this.apiWeldNDTUrl + apiMethod);
        return this.httpClient.get(url, { params: params });
    }

    public async getWeldTypeList() {
        const params = new HttpParams()
            .set('requester_id', this.requester_id)
            .set('site_id', this.site_id);
        var apiMethod = "Lookup/Discipline";
        var url = (this.apiBaseUrl + apiMethod);
        return this.httpClient.get(url, { params: params });
    }

    public async getMaterialTypeList(site_id: any) {
        var apiMethod = "MaterialTypeInfo/GetAll";
        var param = "?site_id=" + site_id;
        var url = this.apiBaseUrl + apiMethod + param;
        return this.httpClient.get(url);
    }

    public async getAllMaterialGradeList(material_type_id: any) {
        const params = new HttpParams()
            .set('requester_id', this.requester_id)
            .set('site_id', this.site_id)
            .set('material_type_id', material_type_id)
        var apiMethod = this.apiEndPoint + "GetAll";
        var url = this.apiBaseUrl + apiMethod;
        return this.httpClient.get(url, { params: params });
    }

    getActiveJointTypeList() {
        const params = new HttpParams()
            .set('requester_id', this.requester_id)
            .set('site_id', this.site_id)
            .set('is_active', true);

        var apiMethod = "jointType/getall";
        var url = (this.apiBaseUrl + apiMethod);
        return this.httpClient.get(url, { params: params });
    }

    getNomSizeList() {
        const params = new HttpParams()
            .set('requester_id', this.requester_id)
            .set('site_id', this.site_id)
            .set('is_active', true);

        var apiMethod = "jointType/getall";
        var url = (this.apiBaseUrl + apiMethod);
        return this.httpClient.get(url, { params: params });
    }

    public async getTestsTypes() {
        const params = new HttpParams()
            .set('requester_id', this.requester_id)
            .set('site_id', this.site_id);
        //.set('is_active', true);

        var apiMethod = "ProjectTestInfo/testtype";
        var url = (this.apiBaseUrl + apiMethod);
        return this.httpClient.get(url, { params: params });
    }

    public async getTestPercentageList() {
        const params = new HttpParams()
            .set('requester_id', this.requester_id)
            .set('site_id', this.site_id);
        //.set('is_active', true);

        var apiMethod = "ProjectTestInfo/testtype";
        var url = (this.apiBaseUrl + apiMethod);
        return this.httpClient.get(url, { params: params });
    }

    public async deleteTestByTestID(testInfo: any) {

    }

    public async getAllActivePipeDimension() {
        const params = new HttpParams()
            .set('requester_id', this.requester_id)
            .set('site_id', this.site_id)
            .set('is_active', true)

        var apiMethod = "PipeDimension/GetAll";
        var url = (this.apiWeldNDTUrl + apiMethod);
        return this.httpClient.get(url, { params: params });
    }

    public async getAllActiveWeldCategory(project_id: any) {
        const params = new HttpParams()
            .set('requester_id', this.requester_id)
            .set('site_id', this.site_id)
            .set('project_id', project_id)

        var apiMethod = "WeldCategory/getall";
        var url = (this.apiWeldNDTUrl + apiMethod);
        return this.httpClient.get(url, { params: params });
    }

    errorHandler(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message;
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }
}