import { Injectable } from '@angular/core';
import { utility } from '../../../util';
import { BehaviorSubject, catchError, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class WeldMasterService {

  requester_id: string;
  site_id: string;
  site_code: string;
  apiWeldUrl: string;

  apiEndPoint: string = "WeldMasterInfo/"
  private environmentConfig = utility.getEnvironmentData();


  private selectedInfo = new BehaviorSubject<any>(null);
  getSelectedInfo$ = this.selectedInfo.asObservable();

  private selectedMasterTypeInfo = new BehaviorSubject<any>(null);
  getSelectedMasterTypeInfo$ = this.selectedMasterTypeInfo.asObservable();

  constructor(private httpClient: HttpClient,
    private router: Router
  ) {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.requester_id = loggedInUser.logon_id
    this.site_id = loggedInUser.site_id
    this.apiWeldUrl = this.environmentConfig.weldApiBase; //environment.weldApiBase;
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  setSelectedInfo(paramData: any) {
    this.selectedInfo.next(paramData)
  }

  setSelectedMasterTypeInfo(paramData: any) {
    this.selectedMasterTypeInfo.next(paramData)
  }


  getAll() {
    const params = new HttpParams()
      .set('requester_id', this.requester_id)
      .set('site_id', this.site_id)

    var apiMethod = this.apiEndPoint + "getall";
    var url = (this.apiWeldUrl + apiMethod);
    return this.httpClient.get(url, { params: params });
  }

  getAllByMasterType(master_type_code: any) {
    const params = new HttpParams()
      .set('requester_id', this.requester_id)
      .set('site_id', this.site_id)
      .set('master_type_code', master_type_code)
      .set('is_active', true);

    var apiMethod = this.apiEndPoint + "getall";
    var url = (this.apiWeldUrl + apiMethod);
    return this.httpClient.get(url, { params: params });
  }

  public async getWeldMastersDetail(id: any) {

    const params = new HttpParams()
      .set('requester_id', this.requester_id)
      .set('site_id', this.site_id)
      .set('master_id', id)

    var apiMethod = this.apiEndPoint + "detail";
    var url = (this.apiWeldUrl + apiMethod);
    return this.httpClient.get(url, { params: params });
  }

  public async CreateWeldMaster(data: any) {
    data.requester_id = this.requester_id;
    data.site_id = this.site_id;
    data.site_code = this.site_code;

    var apiMethod = this.apiEndPoint + "Create";
    var url = (this.apiWeldUrl + apiMethod)
    return this.httpClient.post(url, JSON.stringify(data), this.httpOptions)
  }

  public async UpdateWeldMaster(data: any) {
    data.requester_id = this.requester_id;
    data.site_id = this.site_id;
    data.site_code = this.site_code;

    var apiMethod = this.apiEndPoint + "Update";
    var url = (this.apiWeldUrl + apiMethod)
    return this.httpClient.put(url, JSON.stringify(data), this.httpOptions)
  }

  public async Delete(data: any) {
    data.requester_id = this.requester_id;
    data.site_id = this.site_id;
    data.site_code = this.site_code;

    var apiMethod = this.apiEndPoint + "Delete";
    var url = (this.apiWeldUrl + apiMethod)
    return this.httpClient.put(url, JSON.stringify(data), this.httpOptions).pipe(
      catchError(this.errorHandler)
    );

  }

  getAllMasterType() {
    const params = new HttpParams()
      .set('requester_id', this.requester_id)
    //.set('site_id',this.site_id)

    var apiMethod = this.apiEndPoint + "GetAllType";
    var url = (this.apiWeldUrl + apiMethod);
    return this.httpClient.get(url, { params: params });
  }

  public async getCommonMasterData(master_type_list: any) {
    var apiMethod = this.apiEndPoint + "GetAll";

    const params = new HttpParams()
      .set('requester_id', this.requester_id)
      .set('site_id', this.site_id)
      .set('master_type_list', master_type_list)
      .set('is_active', true)

    var url = this.apiWeldUrl + apiMethod;
    return this.httpClient.get(url, { params: params });
  }

  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
