import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MaterialModule } from '../../core/material-module';
import { DxTileViewModule, DxSelectBoxModule } from 'devextreme-angular';
import { MsalService } from '@azure/msal-angular';
import { MenuService } from '../../APIServices/MenuService';
import { utility } from '../../util';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-home-tile-menus',
  standalone: true,
  imports: [DxTileViewModule, DxSelectBoxModule, MaterialModule, CommonModule],
  templateUrl: './home-tile-menus.component.html',
  animations: [
    trigger('expandContractMenu', [
      transition(':enter', [
        style({ opacity: 0, height: '0px' }),
        animate('500ms ease-in-out', style({ opacity: 1, height: '*' }))
      ]),
      transition(':leave', [
        animate('500ms ease-in-out', style({ opacity: 0, height: '0px' }))
      ])
    ])
  ],
  styleUrl: './home-tile-menus.component.scss'
})
export class HomeTileMenusComponent implements OnInit{
  authmenu: any;
  subMenuList: any;
  selectedTile: any;

  constructor(
    private msalservice: MsalService, 
    private menuService: MenuService,
    private router: Router) {}

 async ngOnInit() {
    var userEmail = this.msalservice.instance.getActiveAccount()?.username;
    (await this.menuService.getAuthMenus(userEmail)).subscribe((apiResponse: any) => {
      const jData = utility.extractJsonData(apiResponse, 0);
      var rows: any;
      if (jData != null)
        rows = Object.values(jData);
      this.authmenu = rows;
      this.onitemClick(this.authmenu[0]);
    });
  } 

  onitemClick(selectedItem) {
    if(selectedItem.hasOwnProperty('children')){
      this.selectedTile = selectedItem;
      this.subMenuList = selectedItem.children;
    }
  }

  routeToPage(route) {
    this.router.navigate(['./'+route]);
  }
}    

