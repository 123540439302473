import { Component, OnInit } from '@angular/core';
import { DevExtremeModule } from '../../../core/devExtreme.module';
import { ToolbarButtonsComponent } from '../../../header/toolbar-buttons/toolbar-buttons.component';
import { ToolbarItem } from '../../../header/toolbar-buttons/toolbar-item';
import { Router } from '@angular/router';
import { AcceptanceCriteriaService } from '../../../APIServices/testacceptance/AcceptanceCriteriaService';
import { utility } from '../../../util';
import { SearchProjectjobitemComponent } from "../../common/search-projectjobitem/search-projectjobitem.component";
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';

@Component({
  selector: 'app-acceptance-criteria-list',
  standalone: true,
  imports: [DevExtremeModule, ToolbarButtonsComponent, SearchProjectjobitemComponent],
  templateUrl: './acceptance-criteria-list.component.html',
  styleUrl: './acceptance-criteria-list.component.scss'
})
export class AcceptanceCriteriaListComponent implements OnInit {
  aModelList: any;
  title: string = 'Acceptance Criteria Maintenance';
  selectedInfo: any;
  selectedFilterInfo: any;

  public toolbarItems: ToolbarItem[] = [
    { label: 'Add AcceptanceCriteria', path: '', iconName: '', action: () => this.RouteToCreate(), hidden: false },
    { label: 'Inactivate AcceptanceCriteria', path: '', iconName: '', action: () => this.DeActivate(), hidden: false }
  ];

  /**
   *
   */
  constructor(private router: Router,
    private acceptanceCriteriaService: AcceptanceCriteriaService) {

  }

  async ngOnInit() {
    await this.Index()
  }

  public async Index() {

    // this.acceptanceCriteriaService.getSelectedFilterInfo$.subscribe((x) =>
    //   this.selectedFilterInfo = x
    // );

    this.selectedFilterInfo = utility.getSelectedFilter();

    if (this.selectedFilterInfo != null) {
      var project_id = this.selectedFilterInfo?.project_id;
      var job_id = this.selectedFilterInfo?.job_id;
      var item_id = this.selectedFilterInfo?.item_id;

      (await this.acceptanceCriteriaService.getAll(project_id, job_id, item_id)).subscribe((apiResponse: any) => {
        this.aModelList = utility.extractJsonData(apiResponse, 0);
      });

    }
  }

  async onSearch(e: any) {
    if (e != null && e != undefined) {
      utility.setSelectedFilter(e);
      var project_id = e?.project_id;
      var job_id = e?.job_id;
      var item_id = e?.item_id;

      (await this.acceptanceCriteriaService.getAll(project_id, job_id, item_id)).subscribe((apiResponse: any) => {
        this.aModelList = utility.extractJsonData(apiResponse, 0);
      });
    }
  }

  async onResetSearch(e: any) {
    //this.aModelList = null;
  }

  RouteToDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    if (e.row?.data == null) {
      this.router.navigate(['./acceptancecriteriadetails']);
    }

    // var user_id=this.userService.LoggedInUserEmail();
    var selectedID = e.row?.data.ac_id;

    let params = {
      ac_id: selectedID
    };
    this.router.navigate(['./acceptancecriteriadetails'], { queryParams: params });
    var uModel;

    var detailData = JSON.stringify(e.row?.data);
    uModel = <any>JSON.parse(detailData);
    this.acceptanceCriteriaService.setSelectedInfo(uModel);
    //this.router.navigate(['./processsdetails'], { queryParams: params });   
    e.event?.preventDefault();
  }

  async RouteToCreate() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.selectedFilterInfo = utility.getSelectedFilter();
    if (this.selectedFilterInfo?.item_id === null || this.selectedFilterInfo?.item_id === undefined) {
      alert("Please search specific Project/Job/Item first.");
      return;
    }
    var item_id = this.selectedFilterInfo?.item_id;
    var project_id = this.selectedFilterInfo?.project_id;
    var job_id = this.selectedFilterInfo?.job_id;

    (await this.acceptanceCriteriaService.getSelectedProjectItem(project_id, job_id, item_id)).subscribe((apiResponse: any) => {
      var Selecteditem = utility.extractJsonData(apiResponse, 0);

      if (Selecteditem != null && Selecteditem != undefined && Selecteditem.length > 0)
        Selecteditem = Selecteditem[0]

      var uModel = {
        ac_id: null,
        project_id: Selecteditem?.project_id,
        job_id: Selecteditem?.job_id,
        job_code: Selecteditem?.job_code,
        item_id: Selecteditem?.item_id,
        item_code: Selecteditem?.item_code,
        disc_id: null,
        test_id: null,
        acceptance_criteria: null,
        status: 'A',
        created_by: loggedInUser?.logon_id,
        created_dtm: new Date(),
        deactivated_by: null,
        deactivated_dtm: null,
        is_active: true
      }
      this.acceptanceCriteriaService.setSelectedInfo(uModel);
      this.router.navigate(['./acceptancecriteriadetails']);

    });
  }

  onSelectionChanged(e: any) {
    this.selectedInfo = e.selectedRowsData;
    this.acceptanceCriteriaService.setSelectedInfo(this.selectedInfo);
  }

  async DeActivate() {
    if (this.selectedInfo == null || this.selectedInfo == undefined ||
      this.selectedInfo.length == undefined || this.selectedInfo.length == 0) {
      alert("Please select record");
      return;
    }

    if (!confirm("Are you sure to Inactivate selected Record(s)?")) {
      return;
    }

    let params = {
      acctc_cret_list: this.selectedInfo
    };

    (await this.acceptanceCriteriaService.delete(params)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.Index();
    });
  }




}
