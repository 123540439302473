<div class="card">
    <mat-card appearance="outlined">
        <mat-card-header>
            <span class="card-title">Weld Qualification Details</span>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="welderQualiForm" novalidate class="form">
                <div class="flex-container">
                    <div class="side-container">
                        <div class="item-container disNone">
                            <mat-label class="matLabel">ID</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtwelderQualificationID" name="text" class="matInputCls"
                                    formControlName="welder_qual_id" maxlength="120">
                            </mat-form-field>
                        </div>
                        <div class="item-container mt-1e">
                            <mat-label class="matLabel asterix">Resource</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="resource_id">
                                    @for (item of filteredResourceList; track item) {
                                        <mat-option [value]="item.resource_id">{{item.name}}</mat-option>
                                    }
                                </mat-select>
                                <mat-error class="text-danger" *ngIf="welderQualiForm.controls['resource_id'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="item-container">
                            <mat-label class="matLabel asterix">Qualification Code</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtqualiCode" class="matInputCls toUpperCase" name="text"
                                    formControlName="qualification_code" maxlength="10" appOnlyAlphanumeric>
                                <mat-error class="text-danger" *ngIf="welderQualiForm.controls['qualification_code'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        
                        <div class="item-container">
                            <mat-label class="matLabel asterix">Process</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="process_id">
                                    @for (item of processList; track item) {
                                        <mat-option [value]="item.process_id">{{item.process_code}} - {{item.process_title}}</mat-option>
                                    }
                                </mat-select>
                                <mat-error class="text-danger" *ngIf="welderQualiForm.controls['process_id'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="item-container">
                            <mat-label class="matLabel">Discipline</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="disc_id">
                                    @for (item of disciplineList; track item) {
                                        <mat-option [value]="item.disc_id">{{item.disc_code}} - {{item.disc_title}}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">Posn</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtPosn" class="matInputCls"
                                    formControlName="posn" maxlength="7">
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">Wtns.</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtWtns" class="matInputCls"
                                    formControlName="wtns" maxlength="4">
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">Weld Cd.</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtweld_cd" class="matInputCls"
                                    formControlName="weld_cd" maxlength="10">
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">Procedure</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="weld_procedure_id">
                                    @for (item of procedureList; track item) {
                                        <mat-option [value]="item.weld_procedure_id">{{item.weld_procedure_title}}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="item-container">
                            <mat-label class="matLabel">Thickness Start Range</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtthicknessstrng" class="matInputCls" name="text"
                                    formControlName="thickness_start_rng" maxlength="11" pattern="^\d{1,5}(\.\d{1,5})?$">
                                <mat-error class="text-danger" *ngIf="welderQualiForm.controls['thickness_start_rng'].errors?.['pattern']">
                                    Invalid Thickness!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="item-container">
                            <mat-label class="matLabel">Thickness End Range</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtthicknessendrng" class="matInputCls" name="text"
                                    formControlName="thickness_end_rng" maxlength="11" pattern="^\d{1,5}(\.\d{1,5})?$">
                                <mat-error class="text-danger" *ngIf="welderQualiForm.controls['thickness_end_rng'].errors?.['pattern'] || welderQualiForm.controls['thickness_end_rng'].errors?.['rangeInvalid']">
                                    Invalid Thickness!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        
                    
                        <div class="item-container">
                            <mat-label class="chkMatLabel">Is Active?</mat-label>
                            <mat-checkbox formControlName="is_active" class="matInputCls chkMatCheckbox"
                                id="chkIsActive">
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="side-container">
                        <div class="item-container mt-1e">
                            <mat-label class="matLabel">Diameter Start Range</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtdiameterstrng" class="matInputCls" name="text"
                                    formControlName="diameter_start_rng" maxlength="11" pattern="^\d{1,5}(\.\d{1,5})?$">
                                <mat-error class="text-danger" *ngIf="welderQualiForm.controls['diameter_start_rng'].errors?.['pattern']">
                                    Invalid Diameter!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">Diameter End Range</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtdiameterendrng" class="matInputCls" name="text"
                                    formControlName="diameter_end_rng" maxlength="11" pattern="^\d{1,5}(\.\d{1,5})?$">
                                <mat-error class="text-danger" *ngIf="welderQualiForm.controls['diameter_end_rng'].errors?.['pattern']">
                                    Invalid Diameter!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">Certi No.</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtborestrng" class="matInputCls" name="text"
                                    formControlName="certi_no" maxlength="15">
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">1st Qual Date</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput [matDatepicker]="picker1" type="text" name="text" class="matInputCls"
                                    formControlName="first_qual_date" maxlength="50">

                                <mat-datepicker-toggle style="width: 1em;" matIconSuffix
                                    [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker style="width: 20px;" #picker1></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">End Date</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput [matDatepicker]="picker2" type="text" name="text" class="matInputCls"
                                    formControlName="end_date" maxlength="50">

                                <mat-datepicker-toggle style="width: 1em;" matIconSuffix
                                    [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker style="width: 20px;" #picker2></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel asterix">Cyc</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtborestrng" class="matInputCls" name="text"
                                    formControlName="cyc" maxlength="10">
                                <mat-error class="text-danger" *ngIf="welderQualiForm.controls['cyc'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel asterix">Qual. Type</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="qual_type_code">
                                    @for (item of qualTypeList; track item) {
                                        <mat-option [value]="item.qual_type_code">{{item.qual_type_title}}</mat-option>
                                    }
                                </mat-select>
                                <mat-error class="text-danger" *ngIf="welderQualiForm.controls['qual_type_code'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!-- <div class="item-container"> -->
                            <!-- <mat-label class="matLabel">Prod. Weld Req.</mat-label> -->
                            <!-- <mat-form-field appearance="outline" class="small">  -->
                                <!-- <input matInput type="text" id="txtprod_weld_req" class="matInputCls"
                                    formControlName="prod_weld_req" maxlength="10"> -->                                  
                                <!-- <div class="border-div">
                                    <mat-radio-group formControlName="prod_weld_req">
                                        <mat-radio-button [value]="1"> Yes </mat-radio-button>
                                        <mat-radio-button [value]="2"> No </mat-radio-button>
                                    </mat-radio-group>    
                                </div>                           -->
                        <!-- </div> -->

                        <div class="ndt-container">
                            <fieldset class="p-0">
                                <legend>Prod. Weld Req.</legend>
                                <div class="item-container">
                                    <mat-radio-group formControlName="prod_weld_req">
                                        <mat-radio-button [value]="1"> Yes </mat-radio-button>
                                        <mat-radio-button [value]="2"> No </mat-radio-button>
                                    </mat-radio-group>      
                                </div> 
                            </fieldset>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">No. Of Prod. Weld</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtno_of_prod_weld" class="matInputCls"
                                    formControlName="no_of_prod_weld" maxlength="10">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button (click)="backtoList()" class="bckBtn">Back</button>
            <button mat-raised-button color="primary" gap="20px" class="mainBtn" (click)="save()">Save</button>
        </mat-card-actions>
    </mat-card>
</div>

<!-- Qualification to project mapping Accordian:START -->
<div class="card" [hidden]="!isEditMode"> 
    <mat-accordion class="example-headers-align" multi="true">
        <mat-expansion-panel [expanded]="step() === 0" (opened)="setStep(0)">
            <mat-expansion-panel-header>
                <mat-panel-title> Project Mapping </mat-panel-title>
                <mat-divider></mat-divider>
                <mat-panel-description class="action_Btn">
                    <button  matTooltip="Add Project" matTooltipPosition='above' 
                            mat-icon-button class="mat_icon_btn" (click)="addMappingItem($event)">
                        <mat-icon>add</mat-icon>
                    </button>
                    <button  matTooltip="Delete Project" matTooltipPosition='above' 
                            mat-icon-button class="mat_icon_btn_delete" (click)="deleteMappingItem($event)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <!-- Project mapping accordian -->
            <div>
                <dx-data-grid width="100%" id="gridSelectedProject" [dataSource]="QualificationProjectModelList"
                    keyExpr="project_id" [showBorders]="true" [showColumnLines]="true" [showRowLines]="true"
                    [rowAlternationEnabled]="true" (onSelectionChanged)="onSelectionChanged($event)"
                    >
                    <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>
                   <!--  <dxi-column type="buttons" [width]="50">
                        <dxi-button hint="Edit selected Job" icon="edit" [visible]=true [disabled]=false
                            [onClick]="ruteToDetails"></dxi-button>
                    </dxi-column> -->
                    <dxi-column dataField="weld_qual_project_map_id" [width]="10" caption="Weld Qual Project Map Id" [visible]="false"></dxi-column>
                    <dxi-column dataField="project_id" [width]="100" caption="Project ID" [visible]="false"></dxi-column>
                    <!-- <dxi-column dataField="weld_prcd_prss_map_code" [width]="100" caption="Map Code"></dxi-column> -->
                    <dxi-column dataField="project_code" [width]="100" caption="Project Code"></dxi-column>
                    <dxi-column dataField="project_title" [width]="100" caption="Project Title"></dxi-column>
                    <!-- <dxi-column dataField="is_active" [width]="100" caption="Is Active?"></dxi-column> -->
                </dx-data-grid>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
 <!-- Accordian:END -->

<!-- Grid Additional Qualification to project mapping Accordian:START -->
<div class="card" [hidden]="!isEditMode"> 
    <mat-accordion class="example-headers-align" multi="true">
        <mat-expansion-panel [expanded]="step() === 0" (opened)="setStep(0)">
            <mat-expansion-panel-header>
                <mat-panel-title> Additional Qualification - {{ currentProjectCode }} </mat-panel-title>
                <mat-divider></mat-divider>
                <mat-panel-description class="action_Btn">
                    <button  matTooltip="Add AdditionalQual" matTooltipPosition='above' 
                            mat-icon-button class="mat_icon_btn" (click)="addAdditionalQual($event)">
                        <mat-icon>add</mat-icon>
                    </button>
                    <button  matTooltip="Delete AdditionalQual" matTooltipPosition='above' 
                            mat-icon-button class="mat_icon_btn_delete" (click)="deleteAdditionalQual($event)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <!-- Project mapping accordian -->
            <div>
                <dx-data-grid width="100%" id="gridAdditionalQual" [dataSource]="AdditionalQualModelList"
                    keyExpr="adqual_mapping_id" [showBorders]="true" [showColumnLines]="true" [showRowLines]="true"
                    [rowAlternationEnabled]="true" (onSelectionChanged)="onAdditionalQualSelectionChanged($event)"
                    >
                    <dxo-selection mode="single" showCheckBoxesMode="none"></dxo-selection>
                    <dxi-column type="buttons" [width]="50">
                        <dxi-button hint="Edit selected Job" icon="edit" [visible]=true [disabled]=false
                            [onClick]="routeToAdditionalQualDetails"></dxi-button>
                    </dxi-column>
                    <dxi-column dataField="adqual_mapping_id" [width]="10" caption="Adqual Mapping Id" [visible]="false"></dxi-column>
                    <dxi-column dataField="project_code" [width]="110" caption="Project Code"></dxi-column>
                    <dxi-column dataField="certificate_no" [width]="150" caption="Certificate No"></dxi-column>                    
                    <dxi-column dataField="cyc" [width]="100" caption="Cycle(Month)"></dxi-column>
                    <dxi-column dataField="certification_date" dataType="date" format="MM/dd/yyyy" [width]="150" caption="Cert. Date"></dxi-column>
                    <dxi-column dataField="cert_expiry_date" [width]="150" dataType="date" format="MM/dd/yyyy" caption="Cert. Exp. Date"></dxi-column>
                    <dxi-column dataField="remarks" caption="Remark"></dxi-column>
                </dx-data-grid>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
 <!-- Accordian:END -->

 <!-- Additional Qualification Accordian:START -->
<!-- <div class="card" [hidden]="!isEditMode"> 
    <mat-accordion class="example-headers-align" multi="true">
        <mat-expansion-panel [expanded]="step() === 0" (opened)="setStep(0)">
            <mat-expansion-panel-header>
                <mat-panel-title> Additional Qualification </mat-panel-title>
            </mat-expansion-panel-header>
            <div>                
                    <mat-card appearance="outlined" style="width: 70%">
                        <mat-card-content>
                            <form [formGroup]="addiQualForm" novalidate class="form">
                                <div class="flex-container">
                                    <div class="side-container">
                                        
                                        <div class="item-container disNone">
                                            <mat-label class="matLabel mt-1e">Id</mat-label>
                                            <mat-form-field appearance="outline" class="small">
                                                <input matInput type="text" id="txtadqualmappingid" name="text" 
                                                        class="matInputCls"
                                                    formControlName="adqual_mapping_id">                                                
                                            </mat-form-field>
                                        </div>
                                        
                                        
                                        <div class="item-container mt-1e">
                                            <mat-label class="matLabel mt-1e asterix">Certificate Number</mat-label>
                                            <mat-form-field appearance="outline" class="small">
                                                <input matInput type="text" id="txtcertificatenumber" name="text" 
                                                        class="matInputCls"
                                                    formControlName="certificate_no">                                                
                                            </mat-form-field>
                                        </div>
                                        
                                        <div class="item-container">
                                            <mat-label class="matLabel mt-1e">Cycle (Month)</mat-label>
                                            <mat-form-field appearance="outline" class="small numeric">
                                                <input matInput type="number" id="txtcycle" name="text" 
                                                        class="matInputCls"
                                                    formControlName="cyc">                                                
                                            </mat-form-field>
                                        </div>                                        
                                        <div class="item-container">
                                            <mat-label class="matLabel">Certification Date</mat-label>
                                            <mat-form-field appearance="outline" class="small">
                                                <input matInput [matDatepicker]="picker3" type="text" id="txtcertificationdate" name="text" class="matInputCls"
                                                    formControlName="certification_date" maxlength="250">
                                                    
                                                <mat-datepicker-toggle style="width: 1em;" matIconSuffix [for]="picker3"></mat-datepicker-toggle>
                                                <mat-datepicker style="width: 20px;" #picker3></mat-datepicker>
                                            </mat-form-field>
                                        </div>  

                                       
                                        <div class="item-container">
                                            <mat-label class="matLabel mt-1e">Certificate exp. Date</mat-label>
                                            <mat-form-field appearance="outline" class="small">
                                                <input matInput type="text" id="txtcertexpirydate" name="text" 
                                                        class="matInputCls"
                                                    formControlName="cert_expiry_date">                                                
                                            </mat-form-field>
                                        </div>
                                       
                                        <div class="item-container">
                                            <mat-label class="matLabel mt-1e">Remarks</mat-label>
                                            <mat-form-field appearance="outline" class="small">
                                                <input matInput type="text" id="txtremarks" name="text" 
                                                        class="matInputCls"
                                                    formControlName="remarks">                                                
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </mat-card-content>
                        <mat-card-actions>                            
                            <button mat-raised-button color="primary" gap="20px" class="mainBtn" (click)="saveAdtQual()" >Save</button>
                            <button mat-raised-button color="primary" gap="20px" class="mainBtn" (click)="deleteAdtQual()" >Delete</button>
                        </mat-card-actions>
                    </mat-card>              
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div> -->
