import { InjectionToken } from '@angular/core';
 
export type EnvironmentConfiguration = {
    production:boolean;
    apiBase: string;
    weldApiBase: string,    
    apiKey: string;
    env: string;
    clientId: string;
    authority: string;
    redirectUri: string;
    baseURL: string;
};
export const ENVIRONMENT_CONFIG = new InjectionToken<any>(
  'EnvironmentConfiguration'
);

//Local config with API connecting to DNS Like 'https://mcisgeneapi-dev.mcdermott.com/api/'
//U2FsdGVkX18zxRdPBTLugwGIAKbKSf0pUJDWuSygwQqjM4EixMMacMmJKo0Nt2nMcuUMeXe4/x+jpQieknzj5Kc7fIFtjJQsnVu3Ib7PI0bEjH77l3VSAMTcrcyjW60iorqnOgapg32kDQEZLPlmkHklHSjpucBayu8fdhlAukGJiyKxVDsMt57T9PC7d1LgXTn4hsz0SJOGGHZaeqF74vAJ7xD203seWluETFqJeL1wQp5gllWiFY4kwt19QaWUgHt1yqvDaJd1EL/sEaeHdPXKFec8eLxv9vGIbNI//ezKN8yerTbnxgCNYNjdaGe/YflzEoN/h1/vDahqFwGUxdxA5J7DC+7eWxdP8TLe/2x3u6I9/ZBTJC5M7xq+Y77Yq1x7ASirtZSOl6FrZQr1efznpK38mCg+4/WuU6SWyYsOaTsj6yEmKpCHdjSoHS+0oQQTq0MwcAIWJ3oqPazJR3qeptV6eDC3/GEezxPvax+gKwzIVYYWhyeeBgEPxLwMBfOum+qvx80/AmaEABt+3SOwVRHaL2iUx4zo1MnKPuoNgFWBoIutkQRHaW1eUaUyOV01i2r+a7qab/kMao2mppHaoBWFWXc8LMxh+3hI7TUIHUOJYCh0tbhL0ftyXY9oYin6f5FPxVqHOKwNv5qFFniqmApbDJSPu5X/x+VjcNv1Fgd1bpZy2zPgPEE7qnQ8osKHCIGpo38Bj9zGj2Cky033eF3QIkPRyh11v3nqyNEXYHY9lonTHOKFCXJ7TIuEZqLhOco+Wh6zXR8XjiJznfLWmZVbDHHo2yCZgxtCmw0=

//Local config with API connecting to Local Like 'http://localhost:5212/api/'
//U2FsdGVkX1+xES0k84izzQjDAYoI2J4LdFKdDEnR0Gj+flLDaNbnbjiNflZOCR8XKVMd/BKnUg5hkpB6BaZ0sj3/SDh5jTGIuGWLX43DFHzH0HAunZcyfwP3JXlJlcnk7f3YDLZo2EgreVaXnENIjJFDCcGYQyjy4oby1s3rjdpkbP8kWwCe247mCq2/nhorJQeTmySQigmxKCY/TmeKNl6ewmQfF4ZuysmaenSIS83UwflY0FzYjQOgGeh6KVyggVjsyUe7YDVYjnlw2TPPnEF3hHwNq1qO9rEIW8rWXSTjy9AZT9/ySghuHP7pne33pGNbA4kODRS8UFmOC9Ud60zwOUDv7oVmO0dcPNeAneTgr9VGFvoLGVQUPH+H3DC6BBySxUU9FI/ITtjjs9wu3OlHs7cXPpsSWmacCsRtsLlViZ4LKzSvQ1MSH7+YMFStNG5Ug2w5/wQCNsHrMkO2NqHWpfqraJela8vc5Yxd2Sq/FQefG/IHDIkcHIB+aRAveSpyQupyh6yeDa0zP3M9iSkbMW0gqoIQwQgM+gi6DS5KTnSZlI8rlIQqFUC8ceGG24FiEjA7jeDsL3W1r7N02AkHxfTRCOzzqcVRQ4OeiUdFhZebx4Jxghcm+nJ8tDtK1zKwkj0Uni/1ECVKAIK22EAy7jSHg7ZbzfUYUtjsd4DW8q3UByqndU7CppivCrwZI9C0TwAQraI5TQjsPFfMatBU9a2FRK3YesGipdMdMpR2iEy8lkHHcs0DFaHQo92bi2dpCiu6uDNIM1b7lSvRGg==

//Development server Configuration
//U2FsdGVkX1+qggpRu3XUWDEgEZRTJqHIMzE4z7nC9aWIODxAfc/u5/UrRwbx2pe+JcLm8Sj9wlFS7AA0QL6VhTaFkRyXNnYeDJKkWbk8z08eIaqlvUWzinqLYyGzL5487FjYBSIlzdFgRWHSaeMPMi/sRfMupYN3an1hnhuvvq0GxNvvGYfCZ62BycOeJi4aYLCp1jcDXNQRW2A8J4OzKSvYq4v3SXCIEv/D7Ap9c4VCkOBM1K0CxGlwTDstJwA2owIAg3BTL5vZm9lj7ddGzFeBEYFyOflFFv6oE66UHnj9WQSF1CaIRq8+8hzBdIrZLdlI2RrjLdgO2QoTusgETj9K0wYa4nZx0zpnweXNXRcrHfHmdVKSSM+k91oOmDCFtakTfYs0YfeOMa97WtFMJvpNsRLSWma4CWSVkj3/5t5k0BCVjXCk9kXGS4SjU9JUy8d+DRr6Tly6jCfPu1h+KrUNKYaA2jrQbuF+vPW7OqltIKMsm9JoAH+lW4naAgT29jU57GvW3RRL9SF300L+hgsmVcIkrYjmN3rHgFhBbGs3w0pnnAsqK4dEKfYav5yWMZ385DntJCs29BnJCDCjEjomHJx10E7g+zpqS8efRQUYcpS6R8BKmZUFBuwNm6DPQF5O/9yC2yEyYesxgLmid/zFPHnUoy2343VmFBbKoXwew5G2F5jEX8v+f3tqVda7NqAAo+F1OXj4qOMsaZTsw3K2iez29YG6ZPS/JNsoKjo=

//QA server Configuration
//U2FsdGVkX18t/IKmegVUcnDM5wuC9KvCGOyw1t7fSa+AOt3gMyAZgoag3X9RwkiclYytBNii5fz0qL/dOWHbyDNrUeg8QS8eQ8JESzQ05lYDhOjHQYC18Y2J8XL/Q7UvXRN3c1KfXbIBgsb4HU2DJoO0Y0XxWKomuKWNdYTKF4ubX3hLgJ9UOBVR94yJLfV4VPpGpG2emJz4mc/qTIGYOWphJo+BCDNQewOh5lqxkOikAFQd/GxLQY47nXPoy7cxNU4/3CeCdwYnvDi2QtF+q2jWRBpTxn9Vf8juhOpe6Ep6vwW78QtXSrR5lCDD2CBLhQBRUlbJatO/HSy8baIaZrBDF90xO8rNi3fOZCrQyusFDvE7EsAcfr7Ns4+m0fCrBx5bWY9ySHWivE8QBbWQVrjDEB/pJbgOjK/1d7I3EraKNCU2r0UgSI6XOyOeSc1gGfCo1QZxIqPoO8wuegN5eXqg31LFIPGJ3JxpRpHSFgbK+4FrCTR8DUW04tQRkM3ZdYXbcJd3lKc54o3I9T400vf2K/4OXKF8OaiMFvR50kBHN8ppU9Y//KgOpzTvLYOMKRGx/WnwK78hHRR7z1axtQQEVbk39751oSm/08Wp23nVNqXtkTA3otZwhxptg7L+lP9pIj8HN0FCkEqGxEMlS0oL2Shdg3iLTyjkZ/kO8Hoxi1nIP0Tu9kY7I6Z+DvvVDb09epQIWj9vjeVbTaDqOg==

//Prod configurations
//U2FsdGVkX18DAxzwJkIOzKMmS40SntYfCP2ws3xTrZyg2f/M+JarkR7viXkN7ezLMefBE85RpDYlkeZJlKYY9Hjlciblfd/tas4iNg7EjikI6K/v3E0D1LGZgnxd67yQeDqUmO3qaoJ6yq+FlF8TlQSx+0MuGKJNuQ3SF3WCpoYdCtoIrxZ953uUcMA1S7a03Z8jeoPN+jEdlHdBxKPFdYH6jHw+jW3dQJsKv2lgYcSlaJjNlBuHtbwBsOOIKDMbk9dNH+OQX09FTm/FwQK1z5rIUM6Ibn6MCtrWWw4Ec0M5KFE08V3nt1q1pME0xw58J/V+WBCqBoH+Pp9KkY62j9qkVXEs30oUVfLhJ7vZ9htl01TpFBRkrZUuwRGPT15fFvQFqOxxXtFtC3lq1ulytQem3mZUbSjyrlOe+ly+Qiz2NCpSH2JCrrphwRiTlKtqH+xDE1142VjRfcTvbCs8w0CSV58FoSPG/gQJZC/u8wj0j7/bIOkbXFeRBmPrym7JxG0pnkzF+GSmVh91Q/iYxvg86Tk8BUCZcdu+g4k18S2HQ/rpb66tI2zREx+zhYxN12wOFDeuM7lkhV5ZCI/ykbt1+IEt9GXqFWGIhkqy09qG3o/XMXDzFXLSxFwuEdC1lf1kcJ7w7zvb6UehuxRcY0G5C3frZQtl27WESWGB/6E2Q302W0AaQ9T9zALPkau4