import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule, Routes } from '@angular/router';
import { ProjectListComponent } from './modules/project/project-list/project-list.component';
import { ProjectDetailsComponent } from './modules/project/project-details/project-details.component';
import { HomePageComponent } from './home/home-page/home-page.component';
import { TopNavBarComponent } from './header/top-nav-bar/top-nav-bar.component';
import { UserListComponent } from './modules/user/user-list/user-list.component';
import { UserDetailsComponent } from './modules/user/user-details/user-details.component';
import { SessionTimeoutComponent } from './home/session-timeout/session-timeout.component';
import { GroupListComponent } from './modules/group/group-list/group-list.component';
import { GroupDetailsComponent } from './modules/group/group-details/group-details.component';
import { GroupAccessMgmtComponent } from './modules/access/group-access-mgmt/group-access-mgmt.component';
import { SiteListComponent } from './modules/site/site-list/site-list.component';
import { SiteDetailsComponent } from './modules/site/site-details/site-details.component';
import { ProjectSetupComponent } from './modules/project/project-setup/project-setup.component';
import { AboutPageComponent } from './home/about-page/about-page.component';
import { FaqPageComponent } from './home/faq-page/faq-page.component';
import { NotificationPageComponent } from './home/notification-page/notification-page.component';
import { DataEncryptionComponent } from './core/data-encryption/data-encryption.component';
import { EmployeeTypeListComponent } from './modules/master/weld/employeetype/employee-type-list/employee-type-list.component';
import { EmployeeTypeDetailComponent } from './modules/master/weld/employeetype/employee-type-detail/employee-type-detail.component';
import { WorklocationListComponent } from './modules/master/weld/worklocation/worklocation-list/worklocation-list.component';
import { WorkLocationDetailComponent } from './modules/master/weld/worklocation/work-location-detail/work-location-detail.component';
import { ResourceListComponent } from './modules/resource/resource-list/resource-list.component';
import { ResourceDetailsComponent } from './modules/resource/resource-details/resource-details.component';
import { MaterialTypeListComponent } from './modules/master/weld/materialtype/material-type-list/material-type-list.component';
import { MaterialTypeDetailsComponent } from './modules/master/weld/materialtype/material-type-details/material-type-details.component';
import { LineClassListComponent } from './modules/master/weld/lineclass/line-class-list/line-class-list.component';
import { LineClassDetailsComponent } from './modules/master/weld/lineclass/line-class-details/line-class-details.component';
import { ProcessGroupListComponent } from './modules/master/weld/processgroup/process-group-list/process-group-list.component';
import { ProcessGroupDetailComponent } from './modules/master/weld/processgroup/process-group-detail/process-group-detail.component';
import { ProcessListComponent } from './modules/master/weld/process/process-list/process-list.component';
import { ProcessDetailComponent } from './modules/master/weld/process/process-detail/process-detail.component';
import { MaterialGradeListComponent } from './modules/master/weld/material-grade/material-grade-list/material-grade-list.component';
import { MaterialGradeDetailComponent } from './modules/master/weld/material-grade/material-grade-detail/material-grade-detail.component';
import { TestProcedureListComponent } from './modules/testacceptance/test-procedure-list/test-procedure-list.component';
import { TestProcedureDetailComponent } from './modules/testacceptance/test-procedure-detail/test-procedure-detail.component';
import { AcceptanceCriteriaListComponent } from './modules/testacceptance/acceptance-criteria-list/acceptance-criteria-list.component';
import { WeldMastersListComponent } from './modules/master/weld/weldmasters/weld-masters-list/weld-masters-list.component';
import { WeldMastersDetailComponent } from './modules/master/weld/weldmasters/weld-masters-detail/weld-masters-detail.component';
import { HomeTileMenusComponent } from './home/home-tile-menus/home-tile-menus.component';
import { DrawingListComponent } from './modules/weldndt/drawing/drawing-list/drawing-list.component';
import { DrawingDetailsComponent } from './modules/weldndt/drawing/drawing-details/drawing-details.component';
import { AcceptanceCriteriaDetailComponent } from './modules/testacceptance/acceptance-criteria-detail/acceptance-criteria-detail.component';
import { WeldNDTMastersListComponent } from './modules/master/weldndt/weldmasters/weldndt-masters-list/weldndt-masters-list.component';
import { WeldNDTMastersDetailComponent } from './modules/master/weldndt/weldmasters/weldndt-masters-detail/weldndt-masters-detail.component';
import { SearchableDropdownComponent } from './modules/common/searchable-dropdown/searchable-dropdown.component';
import { WeldRegisterDetailsComponent } from './modules/weldndt/weld-register/weld-register-details/weld-register-details.component';
import { WelderqualificationlistComponent } from './modules/weldengg/welderQualification/welder-qualification-list/welder-qualification-list.component';
import { WelderQualificationDetailsComponent } from './modules/weldengg/welderQualification/welder-qualification-details/welder-qualification-details.component';
import { WeldProcedureListComponent } from './modules/weldengg/weldProcedure/weld-procedure-list/weld-procedure-list.component';
import { WeldProcedureDetailsComponent } from './modules/weldengg/weldProcedure/weld-procedure-details/weld-procedure-details.component';
import { WeldRegisterListComponent } from './modules/weldndt/weld-register/weld-register-list/weld-register-list.component';
import { ReportComponent } from './modules/reports/report-viewer/report/report.component';
import { EmpreportComponent } from './modules/reports/report-viewer/empreport/empreport.component';
import { WeldProjectProcedureMappingsComponent } from './modules/weldengg/weldProjectProcedureMappings/weld-project-procedure-mappings/weld-project-procedure-mappings.component';

export const routes: Routes = [
  { path: '', component: HomePageComponent, pathMatch: 'full' },
  { path: 'projectlist', component: ProjectListComponent , pathMatch: 'full'},
  { path: 'projectdetails', component: ProjectDetailsComponent, pathMatch: 'full' },
  { path: 'userlist', component: UserListComponent, pathMatch: 'full' },
  { path: 'userdetails', component: UserDetailsComponent, pathMatch: 'full' },
  { path: 'sessiontimeout', component: SessionTimeoutComponent, pathMatch: 'full' },
  { path: 'grouplist', component: GroupListComponent, pathMatch: 'full' },
  { path: 'groupdetails', component: GroupDetailsComponent, pathMatch: 'full' },
  { path: 'groupaccess', component: GroupAccessMgmtComponent, pathMatch: 'full' },
  { path: 'sitelist', component: SiteListComponent, pathMatch: 'full' },
  { path: 'sitedetails', component: SiteDetailsComponent, pathMatch: 'full' },
  { path: 'projectsetup', component: ProjectSetupComponent, pathMatch: 'full' },
  { path: 'aboutpage', component: AboutPageComponent, pathMatch: 'full' },
  { path: 'faqpage', component: FaqPageComponent, pathMatch: 'full' },
  { path: 'notificationpage', component: NotificationPageComponent, pathMatch: 'full' },
  { path: 'resourcelist', component: ResourceListComponent, pathMatch: 'full' },
  { path: 'resourcedetails', component: ResourceDetailsComponent, pathMatch: 'full' },
  { path: 'dataencryption', component: DataEncryptionComponent, pathMatch: 'full' },
  { path: 'employeetypelist', component: EmployeeTypeListComponent, pathMatch: 'full' },
  { path: 'employeetypedetails', component: EmployeeTypeDetailComponent, pathMatch: 'full' },
  { path: 'worklocationlist', component: WorklocationListComponent, pathMatch: 'full' },
  { path: 'worklocationdetails', component: WorkLocationDetailComponent, pathMatch: 'full' },
  { path: 'materialtypelist', component: MaterialTypeListComponent, pathMatch: 'full' },
  { path: 'materialtypedetails', component: MaterialTypeDetailsComponent, pathMatch: 'full' },
  { path: 'processgrouplist', component: ProcessGroupListComponent, pathMatch: 'full' },
  { path: 'processgroupdetails', component: ProcessGroupDetailComponent, pathMatch: 'full' },
  { path: 'processlist', component: ProcessListComponent, pathMatch: 'full' },
  { path: 'processdetails', component: ProcessDetailComponent, pathMatch: 'full' },
  { path: 'lineclasslist', component: LineClassListComponent, pathMatch: 'full' },
  { path: 'lineclassdetails', component: LineClassDetailsComponent, pathMatch: 'full' },
  { path: 'materialgradelist', component: MaterialGradeListComponent, pathMatch: 'full' },
  { path: 'materialgradedetails', component: MaterialGradeDetailComponent, pathMatch: 'full' },
  { path: 'weldprocedurelist', component: WeldProcedureListComponent, pathMatch: 'full' },
  { path: 'weldproceduredetails', component: WeldProcedureDetailsComponent, pathMatch: 'full' },
  { path: 'testprocedurelist', component: TestProcedureListComponent, pathMatch: 'full' },
  { path: 'testproceduredetails', component: TestProcedureDetailComponent, pathMatch: 'full' },
  { path: 'acceptancecriterialist', component: AcceptanceCriteriaListComponent, pathMatch: 'full' },
  { path: 'weldmasterslist', component: WeldMastersListComponent, pathMatch: 'full' },
  { path: 'weldmastersdetails', component: WeldMastersDetailComponent, pathMatch: 'full' },
  { path: 'homemenus', component: HomeTileMenusComponent, pathMatch: 'full' },
  { path: 'drawinglist', component: DrawingListComponent, pathMatch: 'full' },
  { path: 'drawingdetails', component: DrawingDetailsComponent, pathMatch: 'full' },
  { path: 'welderqualificationlist', component: WelderqualificationlistComponent, pathMatch: 'full' },
  { path: 'welderqualificationdetails', component: WelderQualificationDetailsComponent, pathMatch: 'full' },
  { path: 'acceptancecriteriadetails', component: AcceptanceCriteriaDetailComponent, pathMatch: 'full' },
  { path: 'weldndtmasterslist', component: WeldNDTMastersListComponent, pathMatch: 'full' },
  { path: 'weldndtmastersdetails', component: WeldNDTMastersDetailComponent, pathMatch: 'full' },
  { path: 'weldregisterlist', component: WeldRegisterListComponent, pathMatch: 'full' },
  { path: 'weldregisterdetails', component: WeldRegisterDetailsComponent, pathMatch: 'full' },
  { path: 'report', component: ReportComponent, pathMatch: 'full' },
  { path: 'empreport', component: EmpreportComponent, pathMatch: 'full' },
  { path: 'weldProjProcMapping', component: WeldProjectProcedureMappingsComponent, pathMatch: 'full' },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(
      routes,
      { enableTracing: true } // <--Debugging purpose only
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
