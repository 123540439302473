<div class="card">
    <mat-card appearance="outlined">
        <mat-card-header>
            <span class="card-title">Test Procedure</span>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="tForm" novalidate class="form">
                <div class="flex-container">
                    <div class="side-container">
                        <div class="item-container disNone">
                            <mat-label class="matLabel">ID</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtpsID" name="text" class="matInputCls"
                                    formControlName="ps_id">
                            </mat-form-field>
                        </div>
                        <!-- Job -->
                        <div class="item-container mt-1e">
                            <mat-label class="matLabel asterix mt-1e asterix">Job</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtjobcode" name="text" class="matInputCls"
                                    formControlName="job_code" Maxlength="2">                                
                            </mat-form-field>
                        </div>

                        <!-- Item -->
                        <div class="item-container">
                            <mat-label class="matLabel asterix">Item</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtitemcode" name="text" class="matInputCls"
                                    formControlName="item_code" maxlength="20">                                
                                
                                    <mat-error class="text-danger" *ngIf="tForm.controls['item_code'].errors?.['required']">
                                        This is required!
                                    </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel asterix">Discipline</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="disc_id">
                                    <ng-container>
                                        @for (Item of lookupDisciplineList; track Item) {
                                        <mat-option [value]="Item.disc_id">{{Item.disc_title}}</mat-option>
                                        }
                                    </ng-container>
                                </mat-select>
                                <mat-error class="text-danger" *ngIf="tForm.controls['disc_id'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel asterix">Test Type</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="test_id">
                                    <ng-container>
                                        @for (Item of lookupTestTypeList; track Item) {
                                        <mat-option [value]="Item.test_id">{{Item.test_description}}</mat-option>
                                        }
                                    </ng-container>
                                </mat-select>
                                <mat-error class="text-danger" *ngIf="tForm.controls['test_id'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel asterix">Base Procedure</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtbaseprocedure" name="text" class="matInputCls toUpperCase"
                                    formControlName="base_procedure" maxlength="20" appOnlyAlphanumeric>
                                <mat-error class="text-danger" *ngIf="tForm.controls['base_procedure'].errors?.['required']">
                                        This is required!
                                </mat-error>                                
                            </mat-form-field>
                        </div>
                        <div class="item-container">
                            <mat-label class="matLabel asterix">Rev.</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txttestprocedurerev" name="text" class="matInputCls"
                                    formControlName="procedure_rev" maxlength="2" pattern="^\d+$">      
                                    <mat-error class="text-danger" *ngIf="tForm.controls['procedure_rev'].errors?.['required']">
                                        This is required!
                                </mat-error>    
                                <mat-error class="text-danger" *ngIf="tForm.controls['procedure_rev'].errors?.['pattern']">
                                    Invalid Revision number!
                                </mat-error>                          
                            </mat-form-field>
                        </div>
                        <div class="item-container">
                            <mat-label class="chkMatLabel">Is Active?</mat-label> 
                            <mat-checkbox formControlName="is_active" class="matInputCls chkMatCheckbox"
                                    id="chkIsActive">
                            </mat-checkbox>                 
                        </div> 
                    </div>

                    <div class="side-container">
                        <div class="item-container mt-1e">
                            <mat-label class="matLabel asterix">Test Procedure</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txttestprocedure" name="text" class="matInputCls toUpperCase"
                                    formControlName="test_procedure" maxlength="25" appOnlyAlphanumeric>  
                                <mat-error class="text-danger" *ngIf="tForm.controls['test_procedure'].errors?.['required']">
                                        This is required!
                                </mat-error>                               
                            </mat-form-field>
                        </div>                      

                       

                        <div class="item-container">
                            <mat-label class="matLabel">Status</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="status" (selectionChange)="eventStatusChanged($event)" >
                                    @for (item of lookupStatusFilter; track item) {
                                        <mat-option [value]="item.valueMenber">{{item.displayMember}}</mat-option>
                                    }
                                </mat-select>
                                                             
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">Start Date</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput [matDatepicker]="picker" type="text" id="txtStartDate" name="text" class="matInputCls"
                                    formControlName="start_dtm" maxlength="250">

                                <mat-datepicker-toggle style="width: 1em;" matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker style="width: 20px;" #picker></mat-datepicker>

                                <!-- <mat-error class="text-danger" *ngIf="tForm.controls['start_dtm'].errors?.['required']">
                                    This is required!
                                </mat-error> -->
                                <mat-error class="text-danger mat-date-error" *ngIf="tForm.errors?.['dateInvalid'] && tForm.get('start_dtm')?.touched">
                                    Startdate should less/equal than EndDate!                                    
                                </mat-error>
                                
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">End Date</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput [matDatepicker]="picker1" type="text" id="txtEndDate" name="text" class="matInputCls"
                                    formControlName="end_dtm" maxlength="250">
                                    
                                <mat-datepicker-toggle style="width: 1em;" matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker style="width: 20px;" #picker1></mat-datepicker>
                            </mat-form-field>
                        </div>                        

                        <div class="item-container mt-1e">
                            <mat-label class="matLabel">Created By</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtcreatedby" name="text" class="matInputCls"
                                    formControlName="created_by">                                
                            </mat-form-field>
                        </div>

                        <div class="item-container mt-1e">
                            <mat-label class="matLabel">Deactivated By</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtcreatedby" name="text" class="matInputCls"
                                    formControlName="deactivated_by">                                
                            </mat-form-field>
                        </div>

                    </div>
                </div>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button (click)="backtoList()" class="bckBtn">Back</button>
            <button mat-raised-button color="primary" gap="20px" class="mainBtn" (click)="save()" [disabled]="!isSaveDisabled()">Save</button>
        </mat-card-actions>
    </mat-card>
</div>