<app-toolbar-buttons [items]="toolbarItems" [title]="title"></app-toolbar-buttons>
<app-search-projectjobitem (search)="onSearch($event)" (resetSearch)="onResetSearch($event)" ></app-search-projectjobitem>
<div class="centered-outer-div">
    <dx-data-grid id="gridTestProcedure" [dataSource]="tModelList" keyExpr="ps_id" [rowAlternationEnabled]="true"
        class="centered-inner-div" (onSelectionChanged)="onSelectionChanged($event)" 
        [showBorders]="true" [showColumnLines]="true" [showRowLines]="true">

        <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column type="buttons" [width]="80" caption="Actions">
            <dxi-button hint="Edit selected Work Location" icon="edit" [visible]=true [disabled]=false
                [onClick]="RouteToDetails"></dxi-button>
        </dxi-column>
        
        <dxi-column dataField="ps_id" [width]="100" caption="ac id" [visible]="false"></dxi-column>
        <dxi-column dataField="job_code" [width]="100" caption="Job" sortOrder="asc"></dxi-column>
        <dxi-column dataField="item_code" [width]="100" caption="Item"></dxi-column>
        <dxi-column dataField="disc_title" [width]="100" caption="Discipline"></dxi-column>
        <dxi-column dataField="status" [width]="55" caption="Status"></dxi-column>        
        <dxi-column dataField="test_description" [width]="200" caption="Test Type"></dxi-column>
        <dxi-column dataField="test_procedure" [width]="300" caption="Test Prodedure" sortOrder="asc"></dxi-column>        
        <dxi-column dataField="is_active" [width]="70" caption="Is Active"></dxi-column>
        
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 50, 'all']"
            [showNavigationButtons]="true" [visible]="true">
        </dxo-pager>
        <dxo-paging [pageSize]="10" [pageIndex]="0">
        </dxo-paging>
    </dx-data-grid>
</div>