<form class="dialog-form">
    <div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <span class="dialogHeader">Map Process to Procedure</span>
        <button mat-icon-button class="close-button" (click)="closeDialog()">
            <mat-icon class="close-icon" color="warn">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <span></span>
        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label class="matInputCls">Select Process</mat-label>
                <mat-select [(ngModel)]="selectedList" (selectionChange)="getSelectedValues($event)" [ngModelOptions]="{standalone: true}" multiple>
                    <ng-container>
                        @for (gItem of dModelList; track gItem) {
                        <mat-option [value]="gItem.process_id" [disabled]="!isOptionDisabled(gItem.process_id)">{{gItem.process_code}} -
                            {{gItem.process_title}}</mat-option>
                        }
                    </ng-container>
                </mat-select>
        </mat-form-field>
        </div>
        <!-- <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label class="matInputCls">Process Title</mat-label>
                <input matInput type="text" id="txtprocessdescr" name="text" class="matInputCls"
                    formControlName="process_title" maxlength="30">
            </mat-form-field>
        </div> -->
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="mr-16">
        <button mat-raised-button color="primary" (click)="mapProcessToProcedure()">Map</button>
    </mat-dialog-actions>
</form>