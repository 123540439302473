import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule} from '@angular/forms';
import { utility } from '../../../util';
import { ProjectDisciplineService } from '../../../APIServices/project/ProjectDisciplineService';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { LookupService } from '../../../APIServices/LookupService';
import { Router } from '@angular/router';
import { MaterialModule } from '../../../core/material-module';

@Component({
  selector: 'app-add-edit-discipline',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, MaterialModule],
  templateUrl: './add-edit-discipline.component.html',
  styleUrl: './add-edit-discipline.component.scss'
})
export class AddEditDisciplineComponent implements OnInit {
  pDisciplineForm: FormGroup
  activeStatus = utility.activeStatus;
  selectedInfo: any;
  localStorage: any;
  lookupDisciplineList:any;
  isFormChanged: boolean = false;
  initialFormValues: any;

  constructor(private projectDisciplineService: ProjectDisciplineService
          ,private dialogRef: MatDialogRef<AddEditDisciplineComponent>
          ,private lookupService: LookupService
          ,private router: Router){
    
            this.initializeForm(null);            
  }

  async ngOnInit() {
    
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
      
      (await this.lookupService.getDiscipline(loggedInUser.logon_id, loggedInUser.site_id)).subscribe((apiResponse: any) => {
        this.lookupDisciplineList = utility.extractJsonData(apiResponse, 0);
      });

    await this.projectDisciplineService.getSelectedInfo.subscribe(x =>
      this.selectedInfo = x);

    if (this.selectedInfo != null) {
      this.initializeForm(this.selectedInfo);
    }
  }

  async initializeForm(pDisciplineInfo: any) {
      if (pDisciplineInfo != null) {
        this.pDisciplineForm = new FormGroup({
          pd_id: new FormControl(pDisciplineInfo?.pd_id),        
          site_id: new FormControl(pDisciplineInfo?.site_id),
          project_id: new FormControl(pDisciplineInfo?.project_id),
          item_id: new FormControl(pDisciplineInfo?.item_id),
          disc_id: new FormControl(pDisciplineInfo?.disc_id, [Validators.required]),
          disc_code: new FormControl(pDisciplineInfo?.disc_code),
          disc_title: new FormControl(pDisciplineInfo?.disc_title),        
          is_active: new FormControl({value: pDisciplineInfo?.is_active, disabled: pDisciplineInfo?.is_active}, [Validators.required]),
          requester_id: new FormControl('')
        });
        this.initialFormValues = this.pDisciplineForm.getRawValue();
        this.detectFormValueChanges(this.initialFormValues);
      }
        else {
          this.pDisciplineForm = new FormGroup({
            pd_id: new FormControl(''),          
            site_id: new FormControl(''),
            project_id: new FormControl(''),
            item_id:new FormControl(''),
            disc_id: new FormControl('',[Validators.required]),
            disc_code: new FormControl(''),
            disc_title: new FormControl(''),            
            is_active: new FormControl('',[Validators.required]),
            requester_id: new FormControl('')
          });
          this.detectFormValueChanges();
        }      
  }

  saveDiscInfo(){
    this.pDisciplineForm.markAllAsTouched();
    const isFormValid = this.pDisciplineForm.valid;
    if (isFormValid) {
      if (this.pDisciplineForm.value['pd_id'] == null) {
        this.createDisc();
      }
      else {
        this.updateDisc();
      }
      this.isFormChanged = false;
    } else {
      return;
    }
    
  }

  async createDisc() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    //this.pDisciplineForm.value['requester_id'] = loggedInUser.logon_id;
    this.pDisciplineForm.controls['requester_id'].setValue(loggedInUser.logon_id);

    (await this.projectDisciplineService.createProjectDiscipline(this.pDisciplineForm.getRawValue())).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.closeDialog();
    });
  }
  async updateDisc() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    //this.pDisciplineForm.value['requester_id'] = loggedInUser.logon_id;
    this.pDisciplineForm.controls['requester_id'].setValue(loggedInUser.logon_id);

    (await this.projectDisciplineService.updateProjectDiscipline(this.pDisciplineForm.getRawValue())).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.closeDialog();
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  detectFormValueChanges(initialFormValues?: any) {
    this.pDisciplineForm.valueChanges.subscribe(() => {
      this.isFormChanged = utility.checkIfFormChanged(this.pDisciplineForm, initialFormValues);
    });
  }

  isSaveDisabled() {
    return this.isFormChanged && this.pDisciplineForm.valid;
  }
}
