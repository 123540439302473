<!-- <app-toolbar-buttons *ngIf="toolbarItems" [items]="toolbarItems" [title]="title"></app-toolbar-buttons>
<table border=1>
    <tbody>
        <tr>
            <td width="30%">
                <div class="centered-outer-div">
                    <dx-data-grid id="gridGroupList" [dataSource]="dModelList" keyExpr="group_id"
                        [allowColumnReordering]="true" class="centered-inner-div" [showBorders]="true"
                        [showColumnLines]="true" [showRowLines]="true" [rowAlternationEnabled]="true"
                        (onSelectionChanged)="onSelectionChanged($event)"
                        (onContentReady)="onContentReadyHandler($event)">
                        <dxo-selection mode="single">
                        </dxo-selection>
                        <dxo-filter-row [visible]="true"></dxo-filter-row>
                        
                        <dxi-column dataField="group_id" [width]="100" caption="Group ID"
                            [visible]="false"></dxi-column>
                        <dxi-column dataField="site_id" [width]="100" caption="Site ID" [visible]="false"></dxi-column>
                        <dxi-column dataField="site_code" [width]="100" caption="Site Code"
                            [visible]="false"></dxi-column>
                        <dxi-column dataField="group_code" [width]="125" caption="Group Code"></dxi-column>
                        <dxi-column dataField="group_name" caption="Group Name"></dxi-column>
                        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 50, 'all']"
                            [showNavigationButtons]="true" [visible]="true">
                        </dxo-pager>
                        <dxo-paging [pageSize]="10" [pageIndex]="0">
                        </dxo-paging>
                    </dx-data-grid>
                </div>
            </td>

            <td width="70%">
                <div class="centered-outer-div">
                    <app-searchable-dropdown [options]="dModelList" [valueKey]="'group_id'" [displayKey] ="'group_code'" [displayKey1] ="'group_name'" [label]="'Select Group'" [selectedValue]="selectedGroup" (selectionChange)="onItemSelect($event)"> </app-searchable-dropdown>

                    <dx-data-grid id="gridAccessModelList" [dataSource]="dAccessModelList" keyExpr="leaf_menu_id"
                        [allowColumnReordering]="true" class="centered-inner-div" [showBorders]="true"
                        [showColumnLines]="true" [showRowLines]="true" [rowAlternationEnabled]="true"
                        (onSelectionChanged)="onAccessSelectionChanged($event)">
                        <dxo-selection mode="multiple" showCheckBoxesMode="always">
                        </dxo-selection>
                        <dxo-filter-row [visible]="true"></dxo-filter-row>
                        <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
                        <dxi-column type="buttons" [width]="50">
                            <dxi-button hint="Edit selected Access" icon="edit" [visible]=true [disabled]="!buttonAccess?.can_edit"
                                [onClick]="RouteToDetails"></dxi-button>
                        </dxi-column>
                        <dxi-column dataField="access_id" [width]="100" caption="Access ID" [visible]="false"></dxi-column>
                        <dxi-column dataField="group_id" [width]="100" caption="Group ID" [visible]="false"></dxi-column>
                        <dxi-column dataField="module_id" [width]="100" caption="Module ID" [visible]="false"></dxi-column>
                        <dxi-column dataField="module_name" [width]="150" caption="Module Name"></dxi-column>
                        <dxi-column dataField="parent_id" [width]="100" caption="Parent ID" [visible]="false"></dxi-column>
                        <dxi-column dataField="parent_name" [width]="100" caption="Parent Menu"></dxi-column>
                        <dxi-column dataField="leaf_menu_id" [width]="100" caption="Menu ID" [visible]="false"></dxi-column>
                        <dxi-column dataField="leaf_menu_name" [width]="150" caption="Page Name"></dxi-column>
                        <dxi-column dataField="can_view" [width]="50" caption="View"></dxi-column>
                        <dxi-column dataField="can_add" [width]="50" caption="Add"></dxi-column>
                        <dxi-column dataField="can_edit" [width]="50" caption="Edit"></dxi-column>
                        <dxi-column dataField="can_delete" [width]="70" caption="Delete"></dxi-column>

                        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 50, 'all']"
                            [showNavigationButtons]="true" [visible]="true">
                        </dxo-pager>
                        <dxo-paging [pageSize]="10" [pageIndex]="0">
                        </dxo-paging>
                    </dx-data-grid>
                </div>
            </td>
        </tr>
    </tbody>
</table> -->

<app-toolbar-buttons *ngIf="toolbarItems" [items]="toolbarItems" [title]="title"></app-toolbar-buttons>
<div class="cls_drpdwn">
    <app-searchable-dropdown *ngIf="dModelList" [options]="dModelList" [valueKey]="'group_id'" [displayKey] ="'group_code'" [displayKey1] ="'group_name'" [label]="'Select Group'" [selectedValue]="selectedGroup" (selectionChange)="onItemSelect($event)"></app-searchable-dropdown>
</div>
<mat-divider class="divider_cls"></mat-divider>
<div class="centered-outer-div">
    <dx-data-grid id="gridAccessModelList" [dataSource]="dAccessModelList" keyExpr="leaf_menu_id"
                        [allowColumnReordering]="true" class="centered-inner-div" [showBorders]="true"
                        [showColumnLines]="true" [showRowLines]="true" [rowAlternationEnabled]="true"
                        (onSelectionChanged)="onAccessSelectionChanged($event)">
                        <dxo-selection mode="multiple" showCheckBoxesMode="always">
                        </dxo-selection>
                        <dxo-filter-row [visible]="true"></dxo-filter-row>
                        <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
                        <dxi-column type="buttons" [width]="50">
                            <dxi-button hint="Edit selected Access" icon="edit" [visible]=true [disabled]="!buttonAccess?.can_edit"
                                [onClick]="RouteToDetails"></dxi-button>
                        </dxi-column>
                        <dxi-column dataField="access_id" [width]="100" caption="Access ID" [visible]="false"></dxi-column>
                        <dxi-column dataField="group_id" [width]="100" caption="Group ID" [visible]="false"></dxi-column>
                        <dxi-column dataField="module_id" [width]="100" caption="Module ID" [visible]="false"></dxi-column>
                        <dxi-column dataField="module_name" [width]="150" caption="Module Name" sortOrder="asc"></dxi-column>
                        <dxi-column dataField="parent_id" [width]="100" caption="Parent ID" [visible]="false"></dxi-column>
                        <dxi-column dataField="parent_name" [width]="100" caption="Parent Menu"></dxi-column>
                        <dxi-column dataField="leaf_menu_id" [width]="100" caption="Menu ID" [visible]="false"></dxi-column>
                        <dxi-column dataField="leaf_menu_name" [width]="150" caption="Page Name"></dxi-column>
                        <dxi-column dataField="can_view" [width]="50" caption="View"></dxi-column>
                        <dxi-column dataField="can_add" [width]="50" caption="Add"></dxi-column>
                        <dxi-column dataField="can_edit" [width]="50" caption="Edit"></dxi-column>
                        <dxi-column dataField="can_delete" [width]="70" caption="Delete"></dxi-column>

                        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 50, 'all']"
                            [showNavigationButtons]="true" [visible]="true">
                        </dxo-pager>
                        <dxo-paging [pageSize]="10" [pageIndex]="0">
                        </dxo-paging>
                    </dx-data-grid>
</div>