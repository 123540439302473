import { Component } from '@angular/core';
import { MaterialModule } from '../../../core/material-module';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AcceptanceCriteriaService } from '../../../APIServices/testacceptance/AcceptanceCriteriaService';
import { utility } from '../../../util';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-acceptance-criteria-detail',
  standalone: true,
  imports: [MaterialModule, ReactiveFormsModule, CommonModule],
  templateUrl: './acceptance-criteria-detail.component.html',
  styleUrl: './acceptance-criteria-detail.component.scss'
})
export class AcceptanceCriteriaDetailComponent {

  aForm: FormGroup;
  selectedInfo: any;
  queryParams: any;
  bEditMode: boolean = false;
  
    lookupDisciplineList: any[] = [];
    lookupTestTypeList: any[] = [];
    loggedInUser: any;
  
    lookupStatusFilter: any = [    
      {
        valueMenber: "A",
        displayMember: "Active"
      },
      {
        valueMenber: "I",
        displayMember: "Inactive"
      }];
      isFormChanged: boolean = false;
  initialFormValues: any;
  
    /**
     *
     */
    constructor(private router: Router,
      private activateRoute: ActivatedRoute,     
      private service: AcceptanceCriteriaService ) {  
      
        this.initializeForm(null);  
    }
  
    async ngOnInit(){
      this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
      this.populateDisciplineList()
      this.populateTestTypeList();
  
      await this.Index();
    }
  
    async Index(){
      await this.service.getSelectedInfo$.subscribe(x =>
        this.selectedInfo = x);
  
        this.queryParams = this.activateRoute.snapshot.queryParams['ac_id'];
        if (this.queryParams != undefined && this.queryParams != null) {
          this.bEditMode = true;

          if (this.selectedInfo == null){
            (await this.service.getAcceptanceCriteriaDetail(this.queryParams)).subscribe((apiResponse: any) => {
              const jExtract = utility.extractJsonData(apiResponse, 0);
              this.selectedInfo = jExtract[0];

              this.service.setSelectedInfo(this.selectedInfo);
              this.initializeForm(this.selectedInfo);
            });
          }
          else{
            await this.initializeForm(this.selectedInfo);
          }
  
  
        }
        else{
          this.bEditMode = false;          
          await this.initializeForm(this.selectedInfo); 
        }   
    }
  
    private async populateTestTypeList(){
      (await this.service.getTestsTypes()).subscribe((apiResponse: any) => {
        this.lookupTestTypeList = utility.extractJsonData(apiResponse, 0);
      });
    }
  
    private async populateDisciplineList(){
      (await this.service.getDiscipline()).subscribe((apiResponse: any) => {
        this.lookupDisciplineList = utility.extractJsonData(apiResponse, 0);
      });
    }
  
    initializeForm(aInfo: any){
      if (aInfo != null){
        this.aForm = new FormGroup(
          {
            ac_id: new FormControl(aInfo?.ac_id),
            project_id: new FormControl(aInfo?.project_id),
            job_id: new FormControl(aInfo?.job_id),
            job_code: new FormControl({ value: aInfo?.job_code , disabled: true }, Validators.required),
            item_id: new FormControl(aInfo?.item_id),
            item_code: new FormControl({value: aInfo?.item_code, disabled: true },Validators.required),
            disc_id: new FormControl({ value: aInfo?.disc_id, disabled: this.bEditMode },Validators.required),
            test_id: new FormControl({ value: aInfo?.test_id, disabled: this.bEditMode } ,Validators.required),
            acceptance_criteria: new FormControl(aInfo?.acceptance_criteria, Validators.required),          
            status: new FormControl({value: aInfo?.status, disabled: !this.bEditMode}),
            created_by: new FormControl({value: aInfo?.created_by, disabled: true}),
            deactivated_by: new FormControl({value: aInfo?.deactivated_by, disabled:true }),
            created_dtm: new FormControl({value: aInfo?.created_dtm, disabled: true}),
            deactivated_dtm: new FormControl({value: aInfo?.deactivated_dtm, disabled: true}),          
            is_active: new FormControl({value: aInfo?.is_active , disabled: aInfo?.is_active })
          }
        );
        this.initialFormValues = this.aForm.getRawValue();
        this.detectFormValueChanges(this.initialFormValues);
      }
      else{
        this.aForm = new FormGroup(
          {
            ac_id: new FormControl(null),
            job_code: new FormControl({ value: null, disabled: true}, Validators.required),
            item_code: new FormControl({ value: null, disabled: true}, Validators.required),
            disc_id: new FormControl(null,Validators.required),
            test_id: new FormControl(null),
            acceptance_criteria: new FormControl(''),             
            status: new FormControl(null),            
            created_by: new FormControl({ value: null, disabled: true }),
            deactivated_by: new FormControl({ value: null, disabled: true }),          
            created_dtm: new FormControl(null),
            deactivated_dtm: new FormControl(null),
            is_active: new FormControl({ value: true, disabled: true })
          }
        );
        this.detectFormValueChanges();
      }
  
      if(aInfo?.status === "I"){
        this.disableControlsForInactiveStatus();
      }
    }
    
    eventStatusChanged(e: any){
  
      if(e?.value === "A"){
        this.enableControlsForActiveStatus()
      }
      else{
        const endDTM =  this.aForm.controls["deactivated_dtm"].value;
        // if(endDTM == null || endDTM == undefined || endDTM == ''){
        //   this.aForm.controls["deactivated_dtm"].setValue(new Date());        
        // }
        //or fresh value ???
        this.aForm.controls["deactivated_dtm"].setValue(new Date()); 
        
        this.aForm.controls["deactivated_by"].setValue(this.loggedInUser?.logon_id);
        this.disableControlsForInactiveStatus()
      }
    }
  
    disableControlsForInactiveStatus(){    
      this.aForm.disable()
      this.aForm.controls["status"].enable();    
    }
    
    enableControlsForActiveStatus(){          
      this.aForm.controls["acceptance_criteria"].enable({onlySelf: true});

      this.aForm.controls["deactivated_dtm"].setValue(null);
      this.aForm.controls["deactivated_by"].setValue(null)
    }
  
    // dateRangeValidation: ValidatorFn = (control: AbstractControl) => {
    //     //console.log('in date range validator fun');
    //     const form = control as FormGroup;
    //     const sDate = form.get('start_dtm')?.value;
    //     const eDate = form.get('end_dtm')?.value;
    //     const stDateControl = form.get('start_dtm');
    //     if (sDate && eDate && new Date(sDate) > new Date(eDate)) {
    //       //console.log('invalid date');
    //       stDateControl?.setErrors({dateInvalid: true});
    //       return {dateInvalid: true };
    //     }
    //     return null;;
    //   }
  
    backtoList(){
      this.router.navigate(['./acceptancecriterialist']);
    }
  
    save(){
      this.aForm.markAllAsTouched();
      const isFormValid = this.aForm.valid;
      if (isFormValid) {
        if (this.aForm.value['ac_id'] === "" 
          || this.aForm.value['ac_id'] === null 
          || this.aForm.value['ac_id'] === undefined) {
            this.create();
        }
        else {
          this.update();
        }
        this.isFormChanged = false;
      } else {
        return;
      }
  
      
    }
  
    async create(){
  
      (await this.service.create(this.aForm.getRawValue())).subscribe((apiResponse: any) => {
        if ( utility.responseAlert(apiResponse)){
          this.backtoList();
        }
      });
    }
  
    async update(){
      (await this.service.update(this.aForm.getRawValue())).subscribe((apiResponse: any) => {
        if (utility.responseAlert(apiResponse)){
          this.backtoList()
        };
      });
    }

    detectFormValueChanges(initialFormValues?: any) {
      this.aForm.valueChanges.subscribe(() => {
        this.isFormChanged = utility.checkIfFormChanged(this.aForm, initialFormValues);
      });
    }
  
    isSaveDisabled() {
      return this.isFormChanged && this.aForm.valid;
    }
}
