<form [formGroup]="drForm" novalidate class="form">
    <div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <span class="dialogHeader">Supply Drawing File...</span>
        <button mat-icon-button class="close-button" (click)="closeDialog(false)">
            <mat-icon class="close-icon" color="warn">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <input type="hidden" formControlName="drawing_id" />
        <input type="hidden" formControlName="site_id" />
        <input type="hidden" formControlName="project_id" />
        <input type="hidden" formControlName="job_id" />
        <input type="hidden" formControlName="item_id" />

        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label class="matInputCls">Review/Edit Narrative</mat-label>
                <textarea matInput id="txtRecallRemark" rows="3" class="matInputCls" formControlName="recall_remark"
                    maxlength="500"></textarea>
                <mat-error *ngIf="drForm.controls['recall_remark'].errors?.['required']">
                    This is required!
                </mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="mr-16">
        <button mat-raised-button color="primary" class="mainBtn" (click)="save()"
            [disabled]="drForm.invalid">Submit</button>
    </mat-dialog-actions>
</form>