import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { utility } from '../../../../../util';
import { LookupService } from '../../../../../APIServices/LookupService';
import { MaterialModule } from '../../../../../core/material-module';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { ProcessService } from '../../../../../APIServices/master/weld/ProcessService';
import { OnlyAlphanumericDirective } from '../../../../../directives/only-alphanumeric.directive';

@Component({
  selector: 'app-process-detail',
  standalone: true,
  imports: [MaterialModule, ReactiveFormsModule, CommonModule, OnlyAlphanumericDirective],
  templateUrl: './process-detail.component.html',
  styleUrl: './process-detail.component.scss'
})
export class ProcessDetailComponent implements OnInit {
  pForm: FormGroup;
  selectedInfo: any;
  queryParams: any;
  bEditMode: boolean = false;
  lookupProcessGroupList: any[] = [];
  lookupDisciplineList: any[] = [];
  loggedInUser: any;
  selectedProcessGroup: any
  isFormChanged: boolean;
  initialFormValues: any;
  /**
   *
   */
  constructor(private router: Router,
    private activateRoute: ActivatedRoute,
    private lookupService: LookupService,
    private processService: ProcessService) {
    
      this.initializeForm(null);    
  }

  async ngOnInit() {
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.populateProcessGroupList();
    this.populateDisciplineList();
    
  }

  async Index(){
    await this.processService.getSelectedInfo$.subscribe(x =>
      this.selectedInfo = x);

      if (this.selectedInfo == null) {
          this.queryParams = this.activateRoute.snapshot.queryParams['process_id'];

          if (this.queryParams != undefined) {
              this.bEditMode = true;

            (await this.processService.getProcessDetail(this.queryParams)).subscribe((apiResponse: any) => {
              const jExtract = utility.extractJsonData(apiResponse, 0);
              this.selectedInfo = jExtract[0];
              
              this.processService.setSelectedInfo(this.selectedInfo);
              this.initializeForm(this.selectedInfo);
            });
          }
          else {
            this.processService.getSelectedProcessGroupInfo$.subscribe( (y) =>{             
              this.selectedProcessGroup = y??0;
              this.initializeForm(null);
            });

            this.bEditMode = false;        
          }
    }
    else {
      this.bEditMode = true;
      await this.initializeForm(this.selectedInfo);
      }
  }

  initializeForm(pInfo: any){
    if (pInfo != null){        
      this.pForm = new FormGroup(
        {
          process_id: new FormControl(pInfo?.process_id),
          process_code: new FormControl({ value: pInfo?.process_code , disabled: this.bEditMode }, [Validators.required]),
          process_title: new FormControl(pInfo?.process_title, [Validators.required]),
          disc_id: new FormControl(pInfo?.disc_id),
          weld_metal_deposit: new FormControl(pInfo?.weld_metal_deposit),
          process_group_id: new FormControl({value: pInfo?.process_group_id, disabled: pInfo?.process_group_id > 0}),
          is_active: new FormControl({value: pInfo?.is_active , disabled: pInfo?.is_active })
        }
      );
      this.initialFormValues = this.pForm.getRawValue();
      this.detectFormValueChanges(this.initialFormValues);
    }
    else{
      this.pForm = new FormGroup(
        {
          process_id: new FormControl(null),
          process_code: new FormControl({ value: '', disabled: this.bEditMode }, [Validators.required]),
          process_title: new FormControl({ value: '', disabled: false }, [Validators.required]),
          disc_id: new FormControl(null),
          weld_metal_deposit: new FormControl(null),
          process_group_id: new FormControl({value: this.selectedProcessGroup, disabled:pInfo?.process_group_id > 0}),
          is_active: new FormControl({ value: true, disabled: true })
        }
      );
      this.detectFormValueChanges();
    }
  }

  private async populateProcessGroupList(){
    (await this.processService.getAllProcessGroupList()).subscribe((apiResponse: any) => {
      this.lookupProcessGroupList = utility.extractJsonData(apiResponse, 0);
      this.lookupProcessGroupList.unshift({process_group_id: 0 ,process_group_code: "-Not Assigned-", process_group_title: "Not Assigned"});

      this.Index();
    });
  }

  private async populateDisciplineList(){
    (await this.lookupService.getDiscipline(this.loggedInUser.logon_id, this.loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.lookupDisciplineList = utility.extractJsonData(apiResponse, 0);
    });
  }

  backtoList(){
    this.router.navigate(['./processlist']);
  }

  save(){
    this.pForm.markAllAsTouched();
    const isFormValid = this.pForm.valid;
    if (isFormValid) {
      const formData = this.pForm.getRawValue();
      formData.process_code = formData.process_code ? formData.process_code.toUpperCase() : null;
      if (this.pForm.value['process_id'] == null) {
        this.createItem(formData);
      } else {
        this.updateItem(formData);
      }
      this.isFormChanged = false;
    } else {
      return;
    }  
  }

  async createItem(formDataReq: any) {   
    (await this.processService.CreateProcess(formDataReq)).subscribe((apiResponse: any) => {
      if (utility.responseAlert(apiResponse)){
        this.backtoList()
      };
    });    
  }

  async updateItem(formDataReq: any) {
    (await this.processService.UpdateProcess(formDataReq)).subscribe((apiResponse: any) => {
      if (utility.responseAlert(apiResponse)){
        this.backtoList();
      };
    });
  }

  detectFormValueChanges(initialFormValues?: any) {
    this.pForm.valueChanges.subscribe(() => {
      this.isFormChanged = utility.checkIfFormChanged(this.pForm, initialFormValues);
    });
  }

  isSaveDisabled() {
    return this.isFormChanged && this.pForm.valid;
  }
  
}
