<div class="group-card">
    <mat-card appearance="outlined">
        <mat-card-header>
            <span class="card-title">Master Detail - {{selectedMasterLabel}}</span>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="wmForm" novalidate class="form">
                <div class="flex-container">
                    <div class="side-container">
                        <div class="item-container disNone">
                            <mat-label class="matLabel">ID</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtmasterid" name="text" class="matInputCls"
                                    formControlName="master_id">
                            </mat-form-field>
                        </div>

                        <div class="item-container mt-1e">
                            <mat-label class="matLabel asterix">Master Type</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="master_type_code"
                                    (selectionChange)="onSelectionChanged($event)">
                                    <ng-container>
                                        @for (item of masterTypeList; track item;) {
                                        <mat-option
                                            [value]="item.master_type_code">{{item.display_description}}</mat-option>
                                        }
                                    </ng-container>
                                </mat-select>
                                <mat-error class="text-danger"
                                    *ngIf="wmForm.controls['master_type_code'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!-- Code -->
                        <div class="item-container">
                            <mat-label class="matLabel">Code</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtcode" name="text" class="matInputCls toUpperCase"
                                    formControlName="code" Maxlength="10" appOnlyAlphanumeric>
                                <mat-error class="text-danger" *ngIf="wmForm.controls['code'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!-- Description -->
                        <div class="item-container">
                            <mat-label class="matLabel asterix">Description</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtdescription" name="text" class="matInputCls"
                                    formControlName="description" maxlength="255">
                                <mat-error class="text-danger"
                                    *ngIf="wmForm.controls['description'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container" *ngIf="showParent">
                            <mat-label class="matLabel">{{parentLabel}}</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="parent_id">
                                    <ng-container>
                                        @for (item of parentList; track item) {
                                        <mat-option [value]="item.master_id">{{item.description}}</mat-option>
                                        }
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="chkMatLabel">Is Active?</mat-label>
                            <mat-checkbox formControlName="is_active" class="matInputCls chkMatCheckbox"
                                id="chkIsActive">
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button (click)="backtoList()" class="bckBtn">Back</button>
            <button mat-raised-button color="primary" gap="20px" class="mainBtn" (click)="save()">Save</button>
        </mat-card-actions>
    </mat-card>
</div>