import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MaterialModule } from '../../../../core/material-module';
import { WeldProcedureToLineClassService } from '../../../../APIServices/weld/weldProjectProcedureMappings/weldProcedureToLineclassService';
import { utility } from '../../../../util';

@Component({
  selector: 'app-add-procedure-to-lineclass',
  standalone: true,
  imports: [MaterialModule, CommonModule, FormsModule],
  templateUrl: './add-procedure-to-lineclass.component.html',
  styleUrl: './add-procedure-to-lineclass.component.scss'
})
export class AddProcedureToLineclassComponent {
  availableItems: any;
  fileteredNewList: any;
  dModelList: any;
  selectedList: any;
  selectedInfo: null;
  selectedWeldProcedureList: any;
  loggedInUser: any;

  constructor(
    private dialogRef: MatDialogRef<AddProcedureToLineclassComponent>,
    private router: Router,
    private weldProcedureToLineClassService: WeldProcedureToLineClassService,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data:any
    ) { 
      this.availableItems = data.availableItems;
      this.selectedWeldProcedureList = data.selectedWeldProcedureList;
    }

    async ngOnInit() {
      this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
      this.weldProcedureToLineClassService.getSelectedInfo.subscribe(x => {
        if(utility.isObject(x)) {
          this.selectedInfo = x;
        } 
      });
      this.populateLineclass();
    }

    async mapProcessToLineclass() {
      var weld_prcd_project_map_id_list = this.selectedWeldProcedureList?.map((item) => {return item.weld_prcd_project_map_id});
      var processRequest = {
        site_id: this.loggedInUser.site_id,
        lineclass_id_list: this.fileteredNewList?.toString(),
        weld_procedure_id: this.route.snapshot.queryParams['weld_procedure_id'],
        weld_prcd_project_map_id_list: weld_prcd_project_map_id_list.toString(),
        weld_prcd_project_line_class_map_id: null,
        is_active: true,
        requester_id: this.loggedInUser.logon_id,
      };
  
      //Call API to update mapping
      (await this.weldProcedureToLineClassService.mapProcessToLineclass(processRequest)).subscribe((apiResponse: any) => {
        //console.log(apiResponse);
        utility.responseAlert(apiResponse);
        this.populateLineclass();
        this.closeDialog();
      });
  
    }

    async populateLineclass() {
      (await this.weldProcedureToLineClassService.getActiveLineClassList()).subscribe((apiResponse: any) => {
        this.dModelList = utility.extractJsonData(apiResponse, 0);
        console.log('this.dModelList Line class',this.dModelList);
        this.selectedList = this.availableItems;
      });
    }

    getSelectedValues(event: any) {
      this.selectedList = event.value;
      //console.log('this.newSelectedList',this.selectedList);
     /*  this.fileteredNewList = this.selectedList.filter(
        (item) => !this.availableItems.includes(item)
      ); */
      if(this.availableItems!=null){
        this.fileteredNewList = this.selectedList.filter(
         (item) => !this.availableItems.includes(item));
        }else{
          this.fileteredNewList=this.selectedList;
        }
    }

    isOptionDisabled(option) {
      return !this.availableItems?.includes(option);
    }

    closeDialog(): void {
      this.dialogRef.close();
    }
    
}
