<form [formGroup]="addiQualForm" novalidate class="form">
    <div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <span class="dialogHeader">Add/Edit Additional Qualification</span>
        <button mat-icon-button class="close-button" (click)="closeDialog()">
            <mat-icon class="close-icon" color="warn">close</mat-icon>
        </button>
    </div>  
    <mat-dialog-content>        
        <div class="flex-container">
            <div class="side-container">                
                <div class="item-container disNone">
                    <mat-label class="matLabel">Id</mat-label>
                    <mat-form-field appearance="outline" class="small">
                        <input matInput type="text" id="txtadqualmappingid" name="text" 
                                class="matInputCls"
                            formControlName="adqual_mapping_id">                                                
                    </mat-form-field>
                </div>
                                
                <div class="item-container mt-1e">          
                    <mat-label class="matLabel asterix">Certificate Number</mat-label>          
                    <mat-form-field appearance="outline" class="small">                        
                        <input matInput type="text" id="txtcertificatenumber" name="text" 
                                class="matInputCls"
                            formControlName="certificate_no">                                                
                    </mat-form-field>
                </div>
                <!-- Cycle -->
                <div class="item-container clsFloatinglabel">
                    <mat-label class="matLabel">Cycle (Month)</mat-label>
                    <mat-form-field appearance="outline" class="small numeric">
                        <input matInput type="number" id="txtcycle" name="text" 
                                class="matInputCls"
                            formControlName="cyc">                                                
                    </mat-form-field>
                </div>                                        
                <div class="item-container clsFloatinglabel">
                    <mat-label class="matLabel">Certification Date</mat-label>
                    <mat-form-field appearance="outline" class="small">
                        <input matInput [matDatepicker]="picker3" type="text" id="txtcertificationdate" name="text" class="matInputCls"
                            formControlName="certification_date" maxlength="250">
                            
                        <mat-datepicker-toggle style="width: 1em;" matIconSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker style="width: 20px;" #picker3></mat-datepicker>
                    </mat-form-field>
                </div>  

                <!-- Certification Expiry Date -->
                <div class="item-container clsFloatinglabel">
                    <mat-label class="matLabel">Certificate exp. Date</mat-label>
                    <mat-form-field appearance="outline" class="small">
                        <input matInput type="text" id="txtcertexpirydate" name="text" 
                                class="matInputCls"
                            formControlName="cert_expiry_date">                                                
                    </mat-form-field>
                </div>
                <!-- Remarks -->
                <div class="item-container clsFloatinglabel">
                    <mat-label class="matLabel">Remarks</mat-label>
                    <mat-form-field appearance="outline" class="small">
                        <input matInput type="text" id="txtremarks" name="text" 
                                class="matInputCls"
                            formControlName="remarks">                                                
                    </mat-form-field>
                </div>
            </div>
        </div>       
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" (click)="saveAdtQual()">Save</button>
    </mat-dialog-actions>
</form>