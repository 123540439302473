<form [formGroup]="dsForm" novalidate class="form">
    <div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <span class="dialogHeader">Supply Drawing File...</span>
        <button mat-icon-button class="close-button" (click)="closeDialog(false)">
            <mat-icon class="close-icon" color="warn">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <input type="hidden" formControlName="drawing_id" />
        <input type="hidden" formControlName="site_id" />
        <input type="hidden" formControlName="project_id" />
        <input type="hidden" formControlName="job_id" />
        <input type="hidden" formControlName="item_id" />

        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">

                <!-- <div class="item-container mt-1e">
                    <mat-label class="fileUplaodLabel">Drawing File</mat-label>
                    <div class="fileUploadSmall">
                        <app-file-upload (imageChange)="onFileSelect($event)" [form]="dsForm"
                            [imageOrDoc]="'D'" [fileType]="ftype" [isMultiSelect]="false"
                            fControlName=""></app-file-upload>
                    </div>
                </div> -->

                <mat-label class="matInputCls">Drawing File</mat-label>
                <input matInput type="text" id="txtDFile" class="matInputCls" name="text"
                    formControlName="drawing_file" maxlength="120">
                <mat-error *ngIf="dsForm.controls['drawing_file'].errors?.['required']">
                    This is required!
                </mat-error>
            </mat-form-field>
        </div>

        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label>Inspector</mat-label>
                <mat-select formControlName="jrm_inspector">
                    <ng-container>
                        @for (item of inspList; track item) {
                        <mat-option [value]="item.inspector_id">{{item.inspector_code}} -
                            {{item.inspector_name}}</mat-option>
                        }
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="dsForm.controls['jrm_inspector'].errors?.['required']">
                    This is required!
                </mat-error>
            </mat-form-field>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions align="end" class="mr-16">
        <button mat-raised-button color="primary"  class="mainBtn" (click)="save()" [disabled]="dsForm.invalid">Submit</button>
    </mat-dialog-actions>
</form>