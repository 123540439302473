import { Component, Inject, Input } from '@angular/core';
import { MaterialModule } from '../../../core/material-module';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { utility } from '../../../util';
import { WeldSearchService } from '../../../APIServices/WeldNDT/WeldSearchService';

@Component({
  selector: 'app-weld-search',
  standalone: true,
  imports: [MaterialModule, ReactiveFormsModule, CommonModule],
  templateUrl: './weld-search.component.html',
  styleUrl: './weld-search.component.scss'
})
export class WeldSearchComponent {
  srForm: FormGroup;
  loggedInUser: any;

  projectList: any;
  jobList: any;
  itemList: any;
  drawingList: any;
  weldList: any;
  selectedProject: any;
  selectedJob: any;
  selectedItem: any;
  selectedDrawing: any;
  selectedWeld: any;
  selectedCriteria: any;

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<WeldSearchComponent>,
    private weldSearchService: WeldSearchService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.initializeForm(null);
  }

  async ngOnInit() {
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.selectedCriteria = utility.getSelectedFilter();
    this.initializeForm(this.selectedCriteria);
    await this.index();
  }

  async index() {
    await this.populateProject();
  }

  async populateProject() {
    (await this.weldSearchService.getAllActiveProjectList()).subscribe((apiResponse: any) => {
      this.projectList = utility.extractJsonData(apiResponse, 0);

      if (this.srForm.controls['project_id'].value != null
        && this.srForm.controls['project_id'].value != undefined) {
        this.onProjectChange(this.srForm.controls['project_id'].value);
      }
    });
  }
  async onProjectChange(e, isManual: any = false) {
    this.selectedProject = e.value == undefined ? e : e.value;

    if (isManual) {
      //Reset Control
      this.srForm.get('job_id')?.reset(null);
      this.srForm.get('job_code')?.reset(null);
      this.srForm.get('job_title')?.reset(null);
      this.srForm.get('item_id')?.reset(null);
      this.srForm.get('item_code')?.reset(null);
      this.srForm.get('item_title')?.reset(null);
      this.srForm.get('drawing_id')?.reset(null);
      this.srForm.get('mcd_drawing_code')?.reset(null);
      this.srForm.get('mcd_drawing_desc')?.reset(null);
      this.srForm.get('weld_id')?.reset(null);
      this.srForm.get('weld_code')?.reset(null);
      this.srForm.get('recap_spool')?.reset(null);
      this.srForm.get('suffix')?.reset(null);
      this.srForm.get('sr_code')?.reset(null);
      //this.selectedCriteria = this.srForm.getRawValue();
      //Reset Control
    }

    if (this.selectedProject != null && this.selectedProject != undefined
      && this.projectList != null && this.projectList.length > 0) {
      await this.populateJob();

      var selectedProjRecord = this.projectList.filter((v) =>
        v.project_id == this.selectedProject
      );
      if (selectedProjRecord != null && selectedProjRecord.length > 0) {
        this.srForm.get("project_code")?.setValue(selectedProjRecord[0].project_code);
        this.srForm.get("project_title")?.setValue(selectedProjRecord[0].project_title);
      }
    }
  }
  async populateJob() {
    (await this.weldSearchService.getAllActiveJobListByProject(this.selectedProject)).subscribe((apiResponse: any) => {
      this.jobList = utility.extractJsonData(apiResponse, 0);

      if (this.srForm.controls['job_id'].value != null
        && this.srForm.controls['job_id'].value != undefined) {
        //this.srForm.get("job_id")?.setValue(this.selectedCriteria.job_id);
        this.onJobChange(this.srForm.controls['job_id'].value);
      }
    });
  }

  async onJobChange(e, isManual: any = false) {
    this.selectedJob = e.value == undefined ? e : e.value;
    if (isManual) {
      //Reset Control
      this.srForm.get('item_id')?.reset(null);
      this.srForm.get('item_code')?.reset(null);
      this.srForm.get('item_title')?.reset(null);
      this.srForm.get('drawing_id')?.reset(null);
      this.srForm.get('mcd_drawing_code')?.reset(null);
      this.srForm.get('mcd_drawing_desc')?.reset(null);
      this.srForm.get('weld_id')?.reset(null);
      this.srForm.get('weld_code')?.reset(null);
      this.srForm.get('recap_spool')?.reset(null);
      this.srForm.get('suffix')?.reset(null);
      this.srForm.get('sr_code')?.reset(null);
      //this.selectedCriteria = this.srForm.getRawValue();
      //Reset Control
    }
    if (this.selectedJob != null && this.selectedJob != undefined
      && this.jobList != null && this.jobList.length > 0) {
      await this.populateItem();

      var selectedJobRecord = this.jobList.filter((v) =>
        v.project_id == this.selectedProject && v.job_id == this.selectedJob
      );
      if (selectedJobRecord != null && selectedJobRecord.length > 0) {
        this.srForm.get("job_code")?.setValue(selectedJobRecord[0].job_code);
        this.srForm.get("job_title")?.setValue(selectedJobRecord[0].job_title);
      }
    }
  }

  async populateItem() {
    (await this.weldSearchService.getAllActiveItemListByJob(this.selectedProject, this.selectedJob)).subscribe((apiResponse: any) => {
      this.itemList = utility.extractJsonData(apiResponse, 0);

      if (this.srForm.controls['item_id'].value != null
        && this.srForm.controls['item_id'].value != undefined) {
        //this.srForm.get("item_id")?.setValue(this.selectedCriteria.item_id);
        this.onItemChange(this.srForm.controls['item_id'].value);
      }
    });
  }

  async onItemChange(e, isManual: any = false) {
    this.selectedItem = e.value == undefined ? e : e.value;

    if (isManual) {
      //Reset Control
      this.srForm.get('item_code')?.reset(null);
      this.srForm.get('item_title')?.reset(null);
      this.srForm.get('drawing_id')?.reset(null);
      this.srForm.get('mcd_drawing_code')?.reset(null);
      this.srForm.get('mcd_drawing_desc')?.reset(null);
      this.srForm.get('weld_id')?.reset(null);
      this.srForm.get('weld_code')?.reset(null);
      this.srForm.get('recap_spool')?.reset(null);
      this.srForm.get('suffix')?.reset(null);
      this.srForm.get('sr_code')?.reset(null);
      //this.selectedCriteria = this.srForm.getRawValue();
      //Reset Control
    }

    if (this.selectedItem != null && this.selectedItem != undefined
      && this.itemList != null && this.itemList.length > 0) {
      await this.populateDrawing();

      var selectedItemRecord = this.itemList.filter((v) =>
        v.project_id == this.selectedProject && v.job_id == this.selectedJob && v.item_id == this.selectedItem
      );
      if (selectedItemRecord != null && selectedItemRecord.length > 0) {
        this.srForm.get("item_code")?.setValue(selectedItemRecord[0].item_code);
        this.srForm.get("item_title")?.setValue(selectedItemRecord[0].item_title);
      }
    }
  }

  async populateDrawing() {
    (await this.weldSearchService.getAllActiveDrawingList(this.selectedProject, this.selectedJob, this.selectedItem)).subscribe((apiResponse: any) => {
      this.drawingList = utility.extractJsonData(apiResponse, 0);

      if (this.srForm.controls['drawing_id'].value != null
        && this.srForm.controls['drawing_id'].value != undefined) {
        this.srForm.get("drawing_id")?.setValue(this.selectedCriteria.drawing_id);
        this.onDrawingChange(this.srForm.controls['drawing_id'].value);
      }
    });
  }

  async onDrawingChange(e, isManual: any = false) {
    this.selectedDrawing = e.value == undefined ? e : e.value;

    if (isManual) {
      //Reset Control
      // this.srForm.get('weld_id')?.reset(null);
      // this.srForm.get('weld_code')?.reset(null);
      // this.srForm.get('recap_spool')?.reset(null);
      // this.srForm.get('suffix')?.reset(null);
      // this.srForm.get('sr_code')?.reset(null);
      //this.selectedCriteria = this.srForm.getRawValue();
      //Reset Control
    }

    if (this.selectedDrawing != null && this.selectedDrawing != undefined
      && this.drawingList != null && this.drawingList.length > 0) {
      await this.populateWeld();

      var selectedDrawingRecord = this.drawingList.filter((v) =>
        v.project_id == this.selectedProject && v.job_id == this.selectedJob
        && v.item_id == this.selectedItem && v.drawing_id == this.selectedDrawing
      );
      if (selectedDrawingRecord != null && selectedDrawingRecord.length > 0) {
        this.srForm.get("mcd_drawing_code")?.setValue(selectedDrawingRecord[0].mcd_drawing_code);
        this.srForm.get("mcd_drawing_desc")?.setValue(selectedDrawingRecord[0].mcd_drawing_desc);
      }
    }
  }

  async populateWeld() {
    (await this.weldSearchService.getAllActiveWeldListByDrawing(this.selectedProject, this.selectedJob, this.selectedItem, this.selectedDrawing)).subscribe((apiResponse: any) => {
      this.weldList = utility.extractJsonData(apiResponse, 0);

      if (this.weldList != null && this.weldList.length > 0) {
        this.weldList.unshift({ weld_id: null, weld_code: null, description: "All" });
      }

      if (this.srForm.controls['weld_id'].value != null
        && this.srForm.controls['weld_id'].value != undefined) {
        this.srForm.get("weld_id")?.setValue(this.selectedCriteria.weld_id);
        this.onWeldChange(this.srForm.controls['weld_id'].value);
      }
    });
  }

  async onWeldChange(e) {
    this.selectedWeld = e.value == undefined ? e : e.value;

    if (this.selectedWeld != null && this.selectedWeld != undefined) {

      var selectedWeldRecord = this.weldList.filter((v) =>
        v.drawing_id == this.selectedDrawing
      );
      if (selectedWeldRecord != null && selectedWeldRecord.length > 0) {
        this.srForm.get("weld_code")?.setValue(selectedWeldRecord[0].weld_code);
      }
    }
  }

  closeDialog(srList: any): void {
    this.dialogRef.close(srList);
  }


  search() {
    this.srForm.markAllAsTouched();
    const isFormValid = this.srForm.valid;
    if (!isFormValid) {
      return;
    }
    var fProperty = this.srForm.getRawValue();
    utility.setSelectedFilter(fProperty);

    this.closeDialog(this.srForm.getRawValue());
  }

  async initializeForm(srInfo: any) {
    this.srForm = new FormGroup({
      site_id: new FormControl(this.loggedInUser?.site_id),
      project_id: new FormControl(srInfo?.project_id, [Validators.required]),
      project_code: new FormControl(srInfo?.project_code),
      project_title: new FormControl(srInfo?.project_title),
      job_id: new FormControl(srInfo?.job_id, [Validators.required]),
      job_code: new FormControl(srInfo?.job_code),
      job_title: new FormControl(srInfo?.job_title),
      item_id: new FormControl(srInfo?.item_id, [Validators.required]),
      item_code: new FormControl(srInfo?.item_code),
      item_title: new FormControl(srInfo?.item_title),
      drawing_id: new FormControl(srInfo?.drawing_id, [Validators.required]),
      mcd_drawing_code: new FormControl(srInfo?.mcd_drawing_code),
      mcd_drawing_desc: new FormControl(srInfo?.mcd_drawing_desc),
      recap_spool: new FormControl(srInfo?.recap_spool),
      weld_id: new FormControl(srInfo?.weld_id),
      weld_code: new FormControl(srInfo?.weld_code),
      suffix: new FormControl(srInfo?.suffix),
      sr_code: new FormControl(srInfo?.sr_code),
      requester_id: new FormControl()
    });
  }
}
