import { Component, Inject, OnInit } from '@angular/core';
import { MaterialModule } from '../../../../core/material-module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { WeldProcedureToProjectService } from '../../../../APIServices/weld/weldProjectProcedureMappings/weldProcedureToProjectService';
import { utility } from '../../../../util';

@Component({
  selector: 'app-add-procedure-project',
  standalone: true,
  imports: [MaterialModule, CommonModule, FormsModule],
  templateUrl: './add-procedure-project.component.html',
  styleUrl: './add-procedure-project.component.scss'
})
export class AddProcedureProjectComponent implements OnInit {
  availableItems: any;
  dModelList: any;
  selectedInfo: null;
  loggedInUser: any;
  selectedProjectID: any;
  selectedProcedureIdList: any;
  selectedList: any[] = [];
  fileteredNewList: any[] = [];

  constructor(
    private dialogRef: MatDialogRef<AddProcedureProjectComponent>,
    private router: Router,
    private route: ActivatedRoute,
    private weldProcedureProjectService: WeldProcedureToProjectService,
    @Inject(MAT_DIALOG_DATA) public data:any
    ) { 
      this.selectedProjectID = data.project_id;
      this.availableItems = data.availableItems;
     // this.selectedProcedureIdList = data.selectedProcedureList
    }

    ngOnInit() {
      this.weldProcedureProjectService.getSelectedInfo.subscribe(x => {
        if(utility.isObject(x)) {
          this.selectedInfo = x;
        } 
      });
      this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
      this.populateWeldProcedureList();
    }

    getSelectedValues(event: any) {
      this.selectedList = event.value;
      if(this.availableItems!=null){
      this.fileteredNewList = this.selectedList.filter(
       (item) => !this.availableItems.includes(item));
      }else{
        this.fileteredNewList=this.selectedList;
      }
    }

    populateWeldProcedureList() {
      this.weldProcedureProjectService.getActiveProceduresList().subscribe((apiResponse)=> {
        this.dModelList = utility.extractJsonData(apiResponse, 0);
        this.selectedList = this.availableItems;
      })
    }

    mapProcedureToProject() {
      var procedureRequest = {
        site_id: this.loggedInUser.site_id,
        project_id: this.selectedProjectID,
        weld_procedure_id_list: this.fileteredNewList.toString(),
        weld_prcd_project_map_id: null,
        is_active: true,
        requester_id: this.loggedInUser.logon_id,
      };
  
      //Call API to update mapping
      this.weldProcedureProjectService.mapProceduresToProject(procedureRequest).subscribe((apiResponse: any) => {
        //console.log(apiResponse);
        utility.responseAlert(apiResponse);
        this.closeDialog();
      });
    }

    isOptionDisabled(option) {
      return !this.availableItems?.includes(option);
    }

    closeDialog(): void {
      this.dialogRef.close();
    }
}
