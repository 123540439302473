import { Injectable } from '@angular/core';
import { utility } from '../../../util';
import { BehaviorSubject, catchError, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class WelderAdditionalQualificationService {

  requester_id: string;
    site_id: string;  
    apiWeldBaseUrl: string;
    apiGeneBaseUrl: string;
      
    apiEndPoint: string = "WelderAdditionalQualificationInfo/"
    
    private environmentConfig = utility.getEnvironmentData();
    private selectedInfo = new BehaviorSubject<any>(null);
      getSelectedInfo$ = this.selectedInfo.asObservable();
  
    constructor(private httpClient: HttpClient,
      private router: Router
    ) { 
      var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
            this.requester_id = loggedInUser.logon_id
            this.site_id = loggedInUser.site_id      
            this.apiWeldBaseUrl = this.environmentConfig.weldApiBase; //environment.weldApiBase;
            this.apiGeneBaseUrl = this.environmentConfig.apiBase; //environment.weldApiBase;
    }

    httpOptions = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json'
          })
      }
      
    setSelectedInfo(paramData: any) {   
         this.selectedInfo.next(paramData)
    }

    getAdditionalQual(project_id: any, welder_qual_id: any){
          const params = new HttpParams()
            .set('requester_id',this.requester_id)
            .set('site_id',this.site_id)
            .set('project_id',project_id)
            .set('welder_qual_id',welder_qual_id)
            .set('is_active', true);
      
            var apiMethod = this.apiEndPoint + "detail";
            var url = (this.apiWeldBaseUrl + apiMethod);
      return this.httpClient.get(url,{params: params});
    }

    public async Post(data : any){
        data.requester_id = this.requester_id;
        data.site_id = this.site_id;
        
    
        var apiMethod = this.apiEndPoint + "Create";
            var url = (this.apiWeldBaseUrl + apiMethod)
            return this.httpClient.post(url, JSON.stringify(data), this.httpOptions)        
      }
    
      public async Put(data : any){
        data.requester_id = this.requester_id;
        data.site_id = this.site_id;        
    
        var apiMethod = this.apiEndPoint + "Update";
            var url = (this.apiWeldBaseUrl + apiMethod)
            return this.httpClient.put(url, JSON.stringify(data), this.httpOptions).pipe(
              catchError(this.errorHandler)
          );
          
      }

      public async Delete(data : any){
        data.requester_id = this.requester_id;
        data.site_id = this.site_id;        
    
        var apiMethod = this.apiEndPoint + "Delete";
            var url = (this.apiWeldBaseUrl + apiMethod)

            const options = {
              headers: this.httpOptions.headers,
              body: JSON.stringify(data),
          };
  
          return this.httpClient.delete(url, options)
              .pipe(
                  catchError(this.errorHandler)
              );          
          
      }

      errorHandler(error: any) {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
              errorMessage = error.error.message;
          } else {
              errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }
          return throwError(errorMessage);
        }
}
