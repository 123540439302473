import { Component,OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { ActivatedRoute, Router } from '@angular/router';
import { utility } from '../../../../util';
import { FormsModule } from '@angular/forms';
import { DevExtremeModule } from 'devextreme-angular';
import { ToolbarButtonsComponent } from '../../../../header/toolbar-buttons/toolbar-buttons.component';
import { WelderQualificationService } from '../../../../APIServices/weld/welderQualification/WelderQualificationService';
import { UtilityService } from '../../../../APIServices/UtilityService';
import { MaterialModule } from '../../../../core/material-module';
import { SearchableDropdownComponent } from '../../../common/searchable-dropdown/searchable-dropdown.component';
import { MatDialog } from '@angular/material/dialog';
import { AddQualificationToProjectComponent } from '../add-qualification-to-project/add-qualification-to-project.component';

@Component({
  selector: 'app-welderqualificationlist',
  standalone: true,
  imports: [
    DevExtremeModule,
    ToolbarButtonsComponent,
    CommonModule,
    FormsModule,
    MaterialModule,
    SearchableDropdownComponent
  ],
  templateUrl: './welder-qualification-list.component.html',
  styleUrl: './welder-qualification-list.component.scss'
})
export class WelderqualificationlistComponent implements OnInit {
  WelderQualificationModelList: any;
  ResourceList: any[] = [];
  title: any = "Welder Qualification";
  selectedResource: any;
  selectedInfo: any;
  loggedInUser: any;
  searchTerm:string='';
  currentUrl: string;
  buttonAccess: any;
  toolbarItems: any;
  selectedRowKeys: any;

  constructor(
    private welderQualificationService: WelderQualificationService,
    private router: Router,
    private route: ActivatedRoute,
    private utilService: UtilityService,
    private matDialog: MatDialog
  ) {}

  async ngOnInit() {   
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.currentUrl = this.route.snapshot.url[0].path;
    this.getButtonsAccess(this.currentUrl);
    await this.populateResourceList();
  }

  async getButtonsAccess(currRoute: any) {
    (await this.utilService.getAllButtonAccess(currRoute)).subscribe((apiResponse: any) => {
        let resData = utility.extractJsonData(apiResponse, 0);
        this.buttonAccess = resData ? resData[0] : '';
        this.buttonInitialization(this.buttonAccess);
    });
}

  buttonInitialization(buttonAccess: any) {  
    this.toolbarItems = [
      { label: 'Map  Project', path: '', iconName: '', action: () => this.MapProject(), hidden: false },
      { label: 'Add  Qualification', path: '', iconName: '', action: () => this.createWelderQualification(), hidden: !buttonAccess?.can_add },
      { label: 'Inactivate Qualification', path: '', iconName: '', action: () => this.deactivateWelderQualification(), hidden: !buttonAccess?.can_delete },
    ];
  }
  
  async populateResourceList() {
    (await this.welderQualificationService.getAllActiveResourceList(this.loggedInUser.logon_id,this.loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.ResourceList = utility.extractJsonData(apiResponse, 0);
    });
  }

  async filterByResource() {
    (await this.welderQualificationService.getAllWelderQualification(this.selectedResource)).subscribe((apiResponse: any) => {
      this.WelderQualificationModelList = utility.extractJsonData(apiResponse, 0);
    });
  }

  onSelectionChanged(e){    
    this.selectedInfo = e.selectedRowsData;
    this.selectedRowKeys = e.selectedRowKeys;
    this.welderQualificationService.setSelectedInfo(this.selectedInfo);
  }

  onItemSelect(selectedResource:any){
    this.selectedResource =selectedResource['resource_id'];
    this.selectedInfo =selectedResource;
    this.filterByResource();
  }

  routeToDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    if (e.row?.data == null) {
      this.router.navigate(['./welderqualificationdetails']);
    }
    var selectedID = e.row?.data.welder_qual_id;
    let params = {
      welder_qual_id: selectedID
    };
    this.router.navigate(['./welderqualificationdetails'], { queryParams: params });
    var uModel;
    if (e.row?.data.site_id == undefined || e.row?.data.site_id == null) {

      var localUser = JSON.stringify(e.row?.data);
      uModel = <any>JSON.parse(localUser);
      uModel.site_id = this.loggedInUser.site_id;
      uModel.site_code = this.loggedInUser.site_code;
      uModel.site_description = this.loggedInUser.site_description;
    }
    else {
      var localUser = JSON.stringify(e.row?.data);
      uModel = <any>JSON.parse(localUser);
    }
      this.welderQualificationService.setSelectedInfo(uModel);
      e.event?.preventDefault();
  };

  createWelderQualification(){
    if (this.selectedResource == null || this.selectedResource == undefined) {
      alert('Please select a Welder');
      return;
    }
    var pModel = {
      site_id: this.loggedInUser.site_id,
      site_code: this.loggedInUser.site_code,
      site_description: this.loggedInUser.site_description,
      resource_id: this.selectedResource
    };

    this.welderQualificationService.setSelectedInfo(this.selectedResource);
    this.router.navigate(['./welderqualificationdetails']);
  }

  async deactivateWelderQualification(){
    if (this.selectedInfo == null || this.selectedInfo == undefined ||
      this.selectedInfo.length == undefined || this.selectedInfo.length == 0) {
      alert("Please select a Welder Qualification");
      return;
    }
    if (!confirm("Are you sure to Inactivate selected Welder Qualification?")) {
      return;
    }
    let params = {
      weld_qualification_list: this.selectedInfo
    };

    (await this.welderQualificationService.deleteWelderQualification(params)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      //this.Index();
      this.filterByResource();
    });
  }
  onRowPrepared(e:any){
    if(e.rowType==='data'){
      const expiryDate = e.data.end_date;
      const today = new Date();
//console.log(expiryDate);
      if (expiryDate && new Date(expiryDate) <= today) {
        e.rowElement.style.color='red';
      }else{
        e.rowElement.style.color='blue';
      }
    }
  };

 /*  public toolbarItems: ToolbarItem[] = [
    { label: 'Add  Qualification', path: '', iconName: '', action: () => this.createWelderQualification(), hidden: false },
    { label: 'Inactivate Qualification', path: '', iconName: '', action: () => this.deactivateWelderQualification(), hidden: false },
  ]; */

  MapProject(){
    if (this.selectedInfo == null || this.selectedInfo == undefined ||
      this.selectedInfo.length == undefined || this.selectedInfo.length == 0) {
        alert("Please select a Welder Qualification for mapping to project");
      return;
    }

    this.openDialog(true);
  }

  openDialog(isNew: boolean): void {
        const dialogRef = this.matDialog.open(AddQualificationToProjectComponent, {
          
          autoFocus: false,
          disableClose: true,
          data: {
            screenType: 1,
            availableItems: null,
            welder_qual_id: this.selectedRowKeys.toString()
          }  
        });
    
        dialogRef.afterClosed().subscribe(result => {          
        });
    }

}

