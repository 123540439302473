import { Injectable } from '@angular/core';
import { utility } from '../../util';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AcceptanceCriteriaService {

  requester_id: string;
  site_id: string;
  site_code : string;
  apiBaseUrl: string;
  apiGeneUrl: string;

  apiEndPoint: string = "AcceptanceCriteriaInfo/"
  private environmentConfig = utility.getEnvironmentData();


  private selectedInfo = new BehaviorSubject<any>(null);
  getSelectedInfo$ = this.selectedInfo.asObservable();

  private selectedFilterInfo = new BehaviorSubject<any>(null);
  getSelectedFilterInfo$ = this.selectedFilterInfo.asObservable();
  
  constructor(private httpClient: HttpClient,
    private router: Router) { 
    
      var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
      this.requester_id = loggedInUser.logon_id
      this.site_id = loggedInUser.site_id
      this.apiBaseUrl = this.environmentConfig.weldApiBase; //environment.weldApiBase;
      this.apiGeneUrl = this.environmentConfig.apiBase;
  }

  httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
  }

  setSelectedInfo(paramData: any) {   
    this.selectedInfo.next(paramData)
  }

  getAll(project_id: any, job_id: any, item_id: any){
    const params = new HttpParams()
      .set('requester_id',this.requester_id)
      .set('site_id',this.site_id)
      .set('job_id',job_id)
      .set('item_id',item_id) 

      var apiMethod = this.apiEndPoint + "GetAll";
      var url = (this.apiBaseUrl + apiMethod);
      return this.httpClient.get(url,{params: params});
  }

  public async getAcceptanceCriteriaDetail(id: any) {
    
    const params = new HttpParams()
        .set('requester_id',this.requester_id)
        .set('site_id',this.site_id)
        .set('ac_id',id)        

    var apiMethod = this.apiEndPoint + "detail";    
    var url = (this.apiBaseUrl + apiMethod);
    return this.httpClient.get(url,{params: params});    
  }

  public async create(data: any) {
    
    data.requester_id = this.requester_id;
    data.site_id = this.site_id;
    data.site_code = this.site_code;

    var apiMethod = this.apiEndPoint + "Create";
        var url = (this.apiBaseUrl + apiMethod)
        return this.httpClient.post(url, JSON.stringify(data), this.httpOptions)          
  }

  public async update(data: any) {    
    data.requester_id = this.requester_id;
    data.site_id = this.site_id;
    data.site_code = this.site_code;

    var apiMethod = this.apiEndPoint + "update";
        var url = (this.apiBaseUrl + apiMethod)
        return this.httpClient.put(url, JSON.stringify(data), this.httpOptions)          
  }

  public async delete(data: any) {    
    data.requester_id = this.requester_id;
    data.site_id = this.site_id;
    data.site_code = this.site_code;

    var apiMethod = this.apiEndPoint + "delete";
        var url = (this.apiBaseUrl + apiMethod)
        return this.httpClient.put(url, JSON.stringify(data), this.httpOptions)          
  }

  public async getSelectedProjectItem(project_id: any, job_id: any, item_id: any) {
    
    const params = new HttpParams()
      .set('requester_id',this.requester_id)
      .set('site_id',this.site_id)
      .set('project_id',project_id)
      .set('job_id',job_id)
      .set('item_id',item_id)
          
    var apiMethod = "ProjectItemInfo/detail";    
    var url = (this.apiGeneUrl + apiMethod);
    return this.httpClient.get(url,{params: params});    
  }

  public async getTestsTypes() {

    const params = new HttpParams()
      .set('requester_id',this.requester_id)
      .set('site_id',this.site_id)      
          
    var apiMethod = "ProjectTestInfo/testtype";    
    var url = (this.apiGeneUrl + apiMethod);
    return this.httpClient.get(url,{params: params}); 

    // var apiMethod = "ProjectTestInfo/testtype";
    // var param = "?requester_id=" + requester_id + "&site_id=" + site_id;
    // var url = this.apiBaseUrl + apiMethod + param;
    // return this.httpClient.get(url);
}

  public async getDiscipline() {
    const params = new HttpParams()
      .set('requester_id',this.requester_id)
      .set('site_id',this.site_id)      
          
      var apiMethod = "Lookup/Discipline";    
      var url = (this.apiGeneUrl + apiMethod);
      return this.httpClient.get(url,{params: params}); 
    
  }
}
