<app-toolbar-buttons *ngIf="toolbarItems" [items]="toolbarItems" [title]="title"></app-toolbar-buttons>

<div class="cls_drpdwn flex-container">
    <div>
        <mat-form-field appearance="outline" class="small clsFloatingField fltrElement">
            <mat-label>Select Resource</mat-label>
            <mat-select [(ngModel)]="selectedResource" (selectionChange)="applyFilter('TYPE')">
                <ng-container>
                    @for (item of resourceOptionList; track item) {
                    <mat-option [value]="item.resource_type_id">{{item.resource_type}}</mat-option>
                    }
                </ng-container>
            </mat-select>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field appearance="outline" class="small clsFloatingField fltrElement">
            <mat-label>Select Status</mat-label>
            <mat-select [(ngModel)]="selectedStatusFilter" (selectionChange)="applyFilter('STATUS')">
                <ng-container>
                    @for (item of resStatusOptionList; track item) {
                    <mat-option [value]="item.resource_status_id">{{item.resource_status}}</mat-option>
                    }
                </ng-container>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<mat-divider class="divider_cls"></mat-divider>
<div class="centered-outer-div">
    <dx-data-grid id="gridUserList" #dxUserList [dataSource]="resourceModelList" [allowColumnReordering]="true"
        class="centered-inner-div" [showBorders]="true" [showColumnLines]="true" [showRowLines]="true"
        [rowAlternationEnabled]="true" (onSelectionChanged)="onSelectionChanged($event)" width="1200px">
        <dxo-selection mode="multiple" showCheckBoxesMode="always">
        </dxo-selection>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column type="buttons" [width]="50">
            <dxi-button hint="Edit selected Resource" icon="edit" [visible]=true [disabled]=!buttonAccess?.can_edit
                [onClick]="routeToResourceDetails"></dxi-button>
        </dxi-column>

        <dxi-column dataField="resource_id" [width]="100" caption="Resource ID" [visible]="false"></dxi-column>
        <dxi-column dataField="resource_code" [width]="100" caption="Code" [visible]="true"
            sortOrder="asc"></dxi-column>
        <dxi-column dataField="name" [width]="150" caption="Employee Name" [visible]="true"></dxi-column>
        <dxi-column dataField="resource_status" [width]="100" caption="Status" [visible]="true"></dxi-column>
        <dxi-column dataField="resource_type" [width]="100" caption="Type" [visible]="true"></dxi-column>
        <dxi-column dataField="employee_type_title" [width]="120" cssClass="WrappedColumnClass"
            caption="Employee Type"></dxi-column>
        <dxi-column dataField="location_description" [width]="120" cssClass="WrappedColumnClass"
            caption="Work Location"></dxi-column>
        <dxi-column dataField="type_craft_title" [width]="120" cssClass="WrappedColumnClass"
            caption="Type Craft"></dxi-column>
        <dxi-column dataField="start_date" [width]="120" caption="Start Date" dataType="date"
            format="MM/dd/yyyy"></dxi-column>
        <dxi-column dataField="end_date" [width]="120" caption="End Date" [visible]="true" dataType="date"
            format="MM/dd/yyyy"></dxi-column>
        <dxi-column dataField="badge_no" [width]="120" cssClass="WrappedColumnClass" caption="Badge ID"></dxi-column>
        <dxi-column dataField="sap_id" [width]="120" cssClass="WrappedColumnClass" caption="SAP ID"></dxi-column>
        <dxi-column dataField="is_active" [width]="90" caption="is Active?" [visible]="true"
            cssClass="dxCheckBoxFormat"></dxi-column>


        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 50, 'all']"
            [showNavigationButtons]="true" [visible]="true">
        </dxo-pager>
        <dxo-paging [pageSize]="10" [pageIndex]="0">
        </dxo-paging>
    </dx-data-grid>
</div>