import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MaterialModule } from '../../core/material-module';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-file-upload',
  standalone: true,
  imports: [MaterialModule, CommonModule],
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss'
})
export class FileUploadComponent {
  @Input() form: FormGroup;
  //I=> Image | D=> Any Document(like pdf, doc, xlsx etc.)
  @Input() imageOrDoc: any;
  //true => multi select | false=> single select
  @Input() isMultiSelect: boolean;
  //Default '*' => all
  @Input() fControlName:any;
  @Input() fileType: string = "*";
  @Output() imageChange = new EventEmitter<any>();
  @Output() docChange = new EventEmitter<any>();
  currentFile?: File | null = null;

  imagePreview: any | null = null;
  imageError: boolean = false;

  docPreview: any | null = null;
  docError: boolean = false;

  ngOnInit(): void {
    this.imagePreview = this.form.controls['res_image']?.value ? this.form.controls['res_image'].value : '';
  }
  selectFile(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      console.log('file.size', file.size);
      if (file.size > 1048576) {
        this.imageError = true;
        this.imagePreview = null;
        this.imageChange.emit(null);
        return;
      }
      this.imageError = false;
      this.currentFile = file;
      // this.convertToBase64(file);
      this.convertToBase64(file).then(base64String => {
        this.form.patchValue({
          res_image: base64String
        });
        this.imagePreview = base64String; //set the base64 string to img preview
      }).catch(error => {
        console.log("Error Converting file to base64", error);
      });
    }
  }

  /*  convertToBase64(file: File): void {
     const reader = new FileReader();
     reader.onloadend = () => {
       this.imagePreview = reader.result as string;
       this.imageChange.emit(reader.result as string);
     };
     reader.readAsDataURL(file);
   } */
  convertToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.imagePreview = reader.result as string;
        this.imageChange.emit(reader.result as string);
        resolve(reader.result as string);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  removeImage(): void {
    this.form.patchValue({
      res_image: null
    });
    this.imagePreview = null;
    this.currentFile = null;
    this.imageError = false;
    this.imageChange.emit(null);

  }

  // convertBase64ToBinary(base64String: string): Blob {
  //  const base64Data = base64String.split(',')[1];
  //  let byteArr =  Buffer.alloc(base64Data);
  //  let myBlob = new Blob([byteArr], { type: 'image/png' });
  //  console.log('myBlob', myBlob);
  //  return myBlob;
  //  // const byteCharacters = atob(base64Data);
  //  //const byteArrays: Uint8Array[] = [];

  // /*  for (let offset = 0; offset<byteCharacters.length; offset+=1024) {
  //   const slice = byteCharacters.slice(offset, offset + 1024);
  //   const byteNumbers = new Array(slice.length);
  //   for (let i=0; i<slice.length; i++){
  //     byteNumbers[i] = slice.charCodeAt(i);
  //   }
  //   let byteArray = new Uint8Array(byteNumbers);
  //   byteArrays.push(byteArray);
  //  } */

  // // return new Blob(byteArrays, { type: 'image/png' });
  // }

  selectDocFile(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      console.log('file.size', file.size);
      if (file.size > 1048000) {
        console.log('size false');
        this.docError = true;
        this.docPreview = null;
        this.docChange.emit(null);
        return;
      }
      this.docError = false;
      this.currentFile = file;
      console.log('file', this.currentFile.name);
      console.log('file', this.currentFile);
      
      this.docChange.emit(file);
    }
  }
}
