import { Component, OnInit } from '@angular/core';
import { DevExtremeModule } from '../../../../../core/devExtreme.module';
import { ToolbarButtonsComponent } from '../../../../../header/toolbar-buttons/toolbar-buttons.component';
import { MaterialModule } from '../../../../../core/material-module';
import { ToolbarItem } from '../../../../../header/toolbar-buttons/toolbar-item';
import { Router } from '@angular/router';
import { WeldMasterService } from '../../../../../APIServices/master/weld/WeldMasterService';
import { utility } from '../../../../../util';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SearchableDropdownComponent } from '../../../../common/searchable-dropdown/searchable-dropdown.component';


@Component({
  selector: 'app-weld-masters-list',
  standalone: true,
  imports: [DevExtremeModule, ToolbarButtonsComponent, MaterialModule, FormsModule, CommonModule, SearchableDropdownComponent],
  templateUrl: './weld-masters-list.component.html',
  styleUrl: './weld-masters-list.component.scss'
})
export class WeldMastersListComponent implements OnInit {
  wmModelList: any;
    wmModelFilterList: any;
    wmModelFilterListByParent: any;
    title: string = 'Weld Masters';
    selectedInfo: any;
    lookupMasterTypeList: any = []
    selectedMasterType: any;
    selectedParent: any = -1;
    showParent: boolean = false;
    parentList: any = [];
    selectedParentList: any = [];
    parentLabel: any;
  
    public toolbarItems: ToolbarItem[] = [
      { label: 'Add', path: '', iconName: '', action: () => this.RouteToCreate(), hidden: false },
      { label: 'Inactivate', path: '', iconName: '', action: () => this.DeActivate(), hidden: false }
    ];
  
    constructor(private router: Router,
      private weldMasterService: WeldMasterService) {
    }
  
    async ngOnInit() {
      await this.populateLookups();
    }
  
    async populateLookups() {
      (await this.weldMasterService.getAllMasterType()).subscribe((apiResponse: any) => {
        this.lookupMasterTypeList = utility.extractJsonData(apiResponse, 0);
  
        if (this.lookupMasterTypeList != null && this.lookupMasterTypeList.length > 0) {
          this.selectedMasterType = this.lookupMasterTypeList[0].master_type_code;
          this.parentLabel = this.lookupMasterTypeList[0].parent_label;
          this.Index();
        };
      });
    }
  
    public async Index() {
      (this.weldMasterService.getAll()).subscribe((apiResponse: any) => {
        this.wmModelList = utility.extractJsonData(apiResponse, 0);
  
        this.retrieveFilter();
        this.applyFilter();
      });
  
    }
    resetFilter() {
      this.wmModelFilterList = this.wmModelList;
    }
  
    onItemSelect(selectedItem: any) {
      this.selectedMasterType = selectedItem['master_type_code'];
      this.storeFilter();
      this.applyFilter();
    }
  
    onItemSelectParent(selectedItem: any) {
      this.selectedParent = selectedItem['master_id'];
      this.storeFilter();
      this.applyAdditionalFilter();
    }
    
    async applyFilter() {
      if (this.wmModelList != null && this.wmModelList != undefined && this.wmModelList.length > 0) {
        this.wmModelFilterList = this.wmModelList.filter((v) =>
          v.master_type_code === (this.selectedMasterType == 0 ? null : this.selectedMasterType)
        );
  
        this.selectedParentList = this.lookupMasterTypeList.filter((v) =>
          (v.master_type_code === (this.selectedMasterType == 0 ? null : this.selectedMasterType))
          && (v.parent_code != null || v.parent_code != undefined)
        )
  
        this.wmModelFilterListByParent = this.wmModelFilterList
  
        if (this.selectedParentList.length > 0) {
          this.showParent = true;
          this.parentLabel = this.selectedParentList[0].parent_label;
          this.enableParentFilter(this.selectedParentList[0].parent_code);
          this.applyAdditionalFilter();
        }
        else {
          this.showParent = false;
          this.selectedParent = -1;
        }
      }
  
      this.resetlcFilter();
      this.storeFilter();
    }
  
    async enableParentFilter(master_type_code: any) {
      (this.weldMasterService.getAllByMasterType(master_type_code)).subscribe((apiResponse: any) => {
        this.parentList = utility.extractJsonData(apiResponse, 0);
        this.parentList.unshift({ master_id: 0, code: "NA", description: "Not Assigned" });
        this.parentList.unshift({ master_id: -1, code: "A", description: "All" });
      });
    }
  
    async applyAdditionalFilter() {
      if (this.wmModelList != null && this.wmModelList != undefined && this.wmModelList.length > 0) {
        if (this.selectedParent == -1) {
          this.wmModelFilterListByParent = this.wmModelFilterList;
        }
        else {
          this.wmModelFilterListByParent = this.wmModelFilterList;
          this.wmModelFilterListByParent = this.wmModelFilterListByParent.filter((v) =>
            v.parent_id === (this.selectedParent == 0 ? null : this.selectedParent)
          );
        }
      }
  
      //Assigning Filter property
      var fProperty = {
        master_type_code: this.selectedMasterType,
        parent_code: this.selectedParent
      };
      this.storeFilter();
    }
  
    onSelectionChanged(e) {
      this.selectedInfo = e.selectedRowsData;
      this.weldMasterService.setSelectedInfo(this.selectedInfo);
    }
  
    RouteToCreate() {
      let dModel = {
        master_type_code: this.selectedMasterType,
        parent_code: this.selectedParent
      };
  
      this.weldMasterService.setSelectedInfo(dModel);
      this.weldMasterService.setSelectedMasterTypeInfo(this.selectedMasterType);
      this.router.navigate(['./weldmastersdetails']);
    }
  
    RouteToDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
      if (e.row?.data == null) {
        this.router.navigate(['./weldmastersdetails']);
      }
  
      // var user_id=this.userService.LoggedInUserEmail();
      var selectedID = e.row?.data.master_id;
  
      let params = {
        master_id: selectedID
      };
      this.router.navigate(['./weldmastersdetails'], { queryParams: params });
      var uModel;
  
      var localUser = JSON.stringify(e.row?.data);
      uModel = <any>JSON.parse(localUser);
      this.weldMasterService.setSelectedInfo(uModel);
      e.event?.preventDefault();
    }
  
    async DeActivate() {
      if (this.selectedInfo == null || this.selectedInfo == undefined ||
        this.selectedInfo.length == undefined || this.selectedInfo.length == 0) {
        alert("Please select a record");
        return
      };
  
      if (!confirm("Are you sure to Inactivate selected record(s)?")) {
        return;
      }
  
      let params = {
        weld_master_list: this.selectedInfo
      };
  
      (await this.weldMasterService.Delete(params)).subscribe((apiResponse: any) => {
        utility.responseAlert(apiResponse);
        this.Index();
      });
  
    }
  
    storeFilter() {
      var fProperty = {
        master_type_code: this.selectedMasterType,
        parent_code: this.selectedParent
      };
      utility.setSelectedFilter(fProperty);
    }
    retrieveFilter() {
      var fProperty = utility.getSelectedFilter();
      this.selectedMasterType = fProperty.master_type_code;
      this.selectedParent = fProperty.parent_code;
    }
    resetlcFilter() {
      utility.setSelectedFilter(null);
    }
}
