<div class="card">
    <mat-card appearance="outlined">
        <mat-card-header>
            <span class="card-title">Weld Procedure</span>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="weldProcedureForm" novalidate class="form">
                <div class="flex-container">
                    <div class="side-container">
                        <div class="item-container disNone">
                            <mat-label class="matLabel">ID</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtweldProcedureID" name="text" class="matInputCls"
                                    formControlName="weld_procedure_id" maxlength="120">
                            </mat-form-field>
                        </div>
                        <div class="item-container mt-1e">
                            <mat-label class="matLabel asterix">Base Procedure</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtBaseProcedure" class="matInputCls toUpperCase" name="text"
                                    formControlName="base_procedure" maxlength="20" appOnlyAlphanumeric>
                                <mat-error class="text-danger" *ngIf="weldProcedureForm.controls['base_procedure'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="item-container">
                            <mat-label class="matLabel asterix">REV</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtrevno" class="matInputCls" name="text"
                                    formControlName="rev_no" maxlength="2" pattern="^\d+$">
                                <mat-error class="text-danger" *ngIf="weldProcedureForm.controls['rev_no'].errors?.['required']">
                                    This is required!
                                </mat-error>
                                <mat-error class="text-danger" *ngIf="weldProcedureForm.controls['rev_no'].errors?.['pattern']">
                                    Invalid Revision number!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="item-container">
                            <mat-label class="matLabel asterix">Weld Procedure</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtweldprocedure" class="matInputCls toUpperCase" name="text"
                                    formControlName="weld_procedure_title" maxlength="25" appOnlyAlphanumeric>
                                <mat-error class="text-danger" *ngIf="weldProcedureForm.controls['weld_procedure_title'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="item-container">
                            <mat-label class="matLabel asterix">Thickness Start Range</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtthicknessstrng" class="matInputCls" name="text"
                                    formControlName="thickness_start_rng" maxlength="11" pattern="^\d{1,5}(\.\d{1,5})?$">
                                <mat-error class="text-danger" *ngIf="weldProcedureForm.controls['thickness_start_rng'].errors?.['required']">
                                    This is required!
                                </mat-error>
                                <mat-error class="text-danger" *ngIf="weldProcedureForm.controls['thickness_start_rng'].errors?.['pattern']">
                                    Invalid Thickness!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="item-container">
                            <mat-label class="matLabel asterix">Thickness End Range</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtthicknessendrng" class="matInputCls" name="text"
                                    formControlName="thickness_end_rng" maxlength="11" pattern="^\d{1,5}(\.\d{1,5})?$" >
                                <mat-error class="text-danger" *ngIf="weldProcedureForm.controls['thickness_end_rng'].errors?.['required']">
                                    This is required!
                                </mat-error>
                                <mat-error class="text-danger" *ngIf="weldProcedureForm.controls['thickness_end_rng'].errors?.['pattern']">
                                    Invalid Thickness!
                                </mat-error>
                                <mat-error class="text-danger" *ngIf="weldProcedureForm.controls['thickness_end_rng'].errors?.['rangeInvalid']">
                                    end range >= start range
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="item-container">
                            <mat-label class="matLabel asterix">Hardness Start Range</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txthardnessstrng" class="matInputCls" name="text"
                                    formControlName="hardness_start_rng" maxlength="11" pattern="^\d{1,5}(\.\d{1,5})?$">
                                <mat-error class="text-danger" *ngIf="weldProcedureForm.controls['hardness_start_rng'].errors?.['required']">
                                    This is required!
                                </mat-error>
                                <mat-error class="text-danger" *ngIf="weldProcedureForm.controls['hardness_start_rng'].errors?.['pattern']">
                                    Invalid Hardness!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="item-container">
                            <mat-label class="matLabel asterix">Hardness End Range</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txthardnessendrng" class="matInputCls" name="text"
                                    formControlName="hardness_end_rng" maxlength="11" pattern="^\d{1,5}(\.\d{1,5})?$">
                                <mat-error class="text-danger" *ngIf="weldProcedureForm.controls['hardness_end_rng'].errors?.['required']">
                                    This is required!
                                </mat-error>
                                <mat-error class="text-danger" *ngIf="weldProcedureForm.controls['hardness_end_rng'].errors?.['pattern']">
                                    Invalid Hardness!
                                </mat-error>
                                <mat-error class="text-danger" *ngIf="weldProcedureForm.controls['hardness_end_rng'].errors?.['rangeInvalid']">
                                    end range >= start range
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="item-container">
                            <mat-label class="chkMatLabel">Is Active?</mat-label>
                            <mat-checkbox formControlName="is_active" class="matInputCls chkMatCheckbox"
                                id="chkIsActive">
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="side-container">
                        <div class="item-container mt-1e">
                            <mat-label class="matLabel asterix">Bore Start Range</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtborestrng" class="matInputCls" name="text"
                                    formControlName="bore_start_rng" maxlength="5" pattern="^\d+$">
                                <mat-error class="text-danger" *ngIf="weldProcedureForm.controls['bore_start_rng'].errors?.['required']">
                                    This is required!
                                </mat-error>
                                <mat-error class="text-danger" *ngIf="weldProcedureForm.controls['bore_start_rng'].errors?.['pattern']">
                                    Invalid Bore Range!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="item-container">
                            <mat-label class="matLabel asterix">Bore End Range</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txboreendrng" class="matInputCls" name="text"
                                    formControlName="bore_end_rng" maxlength="5" pattern="^\d+$">
                                <mat-error class="text-danger" *ngIf="weldProcedureForm.controls['bore_end_rng'].errors?.['required']">
                                    This is required!
                                </mat-error>
                                <mat-error class="text-danger" *ngIf="weldProcedureForm.controls['bore_end_rng'].errors?.['pattern']">
                                    Invalid Bore Range!
                                </mat-error>
                                <mat-error class="text-danger" *ngIf="weldProcedureForm.controls['bore_end_rng'].errors?.['rangeInvalid']">
                                    end range >= start range
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="item-container">
                            <mat-label class="matLabel asterix">Discipline</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="discipline_id">
                                    @for (item of disciplineList; track item) {
                                        <mat-option [value]="item.disc_id">{{item.disc_title}}</mat-option>
                                    }
                                </mat-select>
                                <mat-error class="text-danger" *ngIf="weldProcedureForm.controls['discipline_id'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="item-container">
                            <mat-label class="matLabel asterix">S/R Code</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="sr_code_id">
                                    @for (item of srCodeList; track item) {
                                        <mat-option [value]="item.sr_code_id">{{item.sr_code_val}}</mat-option>
                                    }
                                </mat-select>
                                <mat-error class="text-danger" *ngIf="weldProcedureForm.controls['sr_code_id'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="item-container">
                            <mat-label class="matLabel ">Status</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="status_id">
                                    @for (item of statusList; track item) {
                                        <mat-option [value]="item.status_id">{{item.status_val}}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="item-container">
                            <mat-label class="matLabel">Start Date</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput [matDatepicker]="picker" type="text" id="txtStartDate" name="text" class="matInputCls"
                                    formControlName="wp_start_date" maxlength="250">
                                <mat-datepicker-toggle style="width: 1em;" matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker style="width: 20px;" #picker></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">Closed Date</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput [matDatepicker]="picker1" type="text" id="txtEndDate" name="text" class="matInputCls"
                                    formControlName="wp_end_date" maxlength="250">
                                    
                                <mat-datepicker-toggle style="width: 1em;" matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker style="width: 20px;" #picker1></mat-datepicker>

                                <mat-error class="text-danger mat-date-error" *ngIf="weldProcedureForm.errors?.['dateInvalid'] && weldProcedureForm.get('wp_end_date')?.touched">
                                    Closed date cannot be less than Start date!
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button (click)="backtoList()" class="bckBtn">Back</button>
            <button mat-raised-button color="primary" gap="20px" class="mainBtn" (click)="save()" [disabled]="!isSaveDisabled()">Save</button>
        </mat-card-actions>
    </mat-card>
</div>

<!-- Procedure to process mapping Accordian:START -->
<div class="card" [hidden]="mappingAccordion"> 
    <mat-accordion class="example-headers-align" multi="true">
        <mat-expansion-panel [expanded]="step() === 0" (opened)="setStep(0)">
            <mat-expansion-panel-header>
                <mat-panel-title> Procedure to Process Mapping </mat-panel-title>
                <mat-divider></mat-divider>
                <mat-panel-description class="process_addBtn">
                    <button  matTooltip="Add Process" matTooltipPosition='above' 
                            mat-icon-button class="mat_icon_btn" (click)="addProcessToProcedure($event)">
                        <mat-icon>add</mat-icon>
                    </button>
                    <button  matTooltip="Delete Process" matTooltipPosition='above' 
                            mat-icon-button class="mat_icon_btn_delete" (click)="deleteProcessFromProcedure($event)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <!-- Process mapping accordian -->
            <div>
                <dx-data-grid width="100%" #WeldProcDataGrid id="gridSelectedWeldProcess" [dataSource]="weldProcedureProcessModelList"
                    keyExpr="process_id" [showBorders]="true" [showColumnLines]="true" [showRowLines]="true"
                    [rowAlternationEnabled]="true" (onSelectionChanged)="onSelectionChanged($event)"
                    >
                    <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>
                   <!--  <dxi-column type="buttons" [width]="50">
                        <dxi-button hint="Edit selected Job" icon="edit" [visible]=true [disabled]=false
                            [onClick]="ruteToDetails"></dxi-button>
                    </dxi-column> -->
                    <dxi-column dataField="process_id" [width]="100" caption="Process ID" [visible]="false"></dxi-column>
                    <!-- <dxi-column dataField="weld_prcd_prss_map_code" [width]="100" caption="Map Code"></dxi-column> -->
                    <dxi-column dataField="process_code" [width]="100" caption="Process Code"></dxi-column>
                    <dxi-column dataField="process_title" [width]="100" caption="Process Title"></dxi-column>
                    <!-- <dxi-column dataField="is_active" [width]="100" caption="Is Active?"></dxi-column> -->
                </dx-data-grid>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
 <!-- Procedure to process mapping Accordian:END -->