<mat-form-field appearance="outline" class="small clsFloatingField fltrElement" >
    <mat-label>{{ label }}</mat-label>
    <mat-select [(value)]="selectedValue" (selectionChange)="onSelectionChange($event)" panelClass="searchable-select">
      <!-- Search Box -->
      <div class="search-container">
        <input
          type="text"
          placeholder="Search..."
          [(ngModel)]="searchTerm"
          (input)="filterOptions()"
          class="search-input"
        />
      </div>
      @if((displayKey!=null && displayKey!=undefined && displayKey!="")
          &&(displayKey1!=null && displayKey1!=undefined && displayKey1!="")){
        <ng-container>
            @for (item of filteredOptions; track item)
            {
            <mat-option [value]="item[valueKey]">{{ item[displayKey] }} - {{item[displayKey1]}}</mat-option>
            }
        </ng-container>
      }
      @else if(displayKey1==null || displayKey1==undefined || displayKey1==""){
        @for (item of filteredOptions; track item)
            {
            <mat-option [value]="item[valueKey]">{{item[displayKey]}}</mat-option>
            }
      }
      @else
      {
        <ng-container>
            @for (item of filteredOptions; track item)
            {
            <mat-option [value]="item[valueKey]">{{ item[displayKey1] }}</mat-option>
            }
        </ng-container>
      }
    </mat-select>
  </mat-form-field>