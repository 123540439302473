<app-toolbar-buttons [items]="toolbarItems" [title]="title"></app-toolbar-buttons>

<div class="cls_drpdwn">
    <app-searchable-dropdown *ngIf="lookupMasterTypeList" [options]="lookupMasterTypeList" [valueKey]="'master_type_code'" [displayKey] ="'display_description'" [label]="'Master Type'" [selectedValue]="selectedMasterType" (selectionChange)="onItemSelect($event)"> </app-searchable-dropdown>
    
    <app-searchable-dropdown *ngIf="showParent" [options]="parentList" [valueKey]="'master_id'" [displayKey] ="'code'" [displayKey1] ="'description'" [label]="parentLabel" [selectedValue]="selectedParent" (selectionChange)="onItemSelectParent($event)"> </app-searchable-dropdown>
</div>
<mat-divider class="divider_cls"></mat-divider>
<div class="centered-outer-div">
    <dx-data-grid id="gridUserList" #dxUserList [dataSource]="wmModelFilterListByParent" [allowColumnReordering]="true"
        class="centered-inner-div" [showBorders]="true" [showColumnLines]="true" [showRowLines]="true"
        [rowAlternationEnabled]="true" (onSelectionChanged)="onSelectionChanged($event)">
        <dxo-selection mode="multiple" showCheckBoxesMode="always">
        </dxo-selection>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column type="buttons" [width]="50">
            <dxi-button hint="Edit selected Master" icon="edit" [visible]=true [disabled]=false
                [onClick]="RouteToDetails"></dxi-button>
        </dxi-column>

        <dxi-column dataField="master_id" [width]="100" caption="Master ID" [visible]="false"></dxi-column>
        <dxi-column dataField="code" [width]="120" caption="Code" [visible]="true" sortOrder="asc"></dxi-column>
        <dxi-column dataField="description" [width]="120" caption="Description" [visible]="true"></dxi-column>
        <dxi-column dataField="parent_id" [width]="100" caption="parent_id" [visible]="false"></dxi-column>
        <dxi-column dataField="parent_desc" [width]="100" [caption]="parentLabel" [(visible)]="showParent"></dxi-column>
        <dxi-column dataField="is_active" [width]="80" caption="is Active?" [visible]="true"
            cssClass="dxCheckBoxFormat"></dxi-column>


        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 50, 'all']"
            [showNavigationButtons]="true" [visible]="true">
        </dxo-pager>
        <dxo-paging [pageSize]="10" [pageIndex]="0">
        </dxo-paging>
    </dx-data-grid>
</div>