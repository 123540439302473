import { Component } from '@angular/core';
import { MaterialModule } from '../../../../core/material-module';
import { CommonModule } from '@angular/common';
import { DevExtremeModule } from '../../../../core/devExtreme.module';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { utility } from '../../../../util';
import { Router } from '@angular/router';
import { WeldRegisterService } from '../../../../APIServices/WeldNDT/WeldRegister/WeldRegisterService';

@Component({
  selector: 'app-add-ndt-test',
  standalone: true,
  imports: [MaterialModule, CommonModule, DevExtremeModule, ReactiveFormsModule],
  templateUrl: './add-ndt-test.component.html',
  styleUrl: './add-ndt-test.component.scss'
})
export class AddNDTTestComponent {
  pTestsForm: FormGroup;
  loggedInUser: any;
  lookupTestsType: any;
  percentageList: any;
  testPercenatgeList: any;

  constructor(
    private dialogRef: MatDialogRef<AddNDTTestComponent>,
    private router: Router,
    private weldRegisterService: WeldRegisterService
  ){
    this.initializeForm(null);
  }

  async ngOnInit() {
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.fetchTestsType();
    this.fetchTestPercentage();
  }

  async fetchTestsType() {
    (await this.weldRegisterService.getTestsTypes()).subscribe((apiResponse: any) => {
      this.lookupTestsType = utility.extractJsonData(apiResponse, 0);
      console.log('Test Type', this.lookupTestsType)
      /* if(this.projectTestModel != null) {
        this.fetchTestProcedure(this.projectTestModel.ps_id);
      }  */     
    });
  }

  async fetchTestPercentage() {
    (await this.weldRegisterService.getTestPercentageList()).subscribe((apiResponse: any) => {
      this.testPercenatgeList = utility.extractJsonData(apiResponse, 0);
      console.log('Test Percenatges', this.lookupTestsType)
      /* if(this.projectTestModel != null) {
        this.fetchTestProcedure(this.projectTestModel.ps_id);
      }  */     
    });
  }

  async fetchPercenatge() {
    (await this.weldRegisterService.getTestsTypes()).subscribe((apiResponse: any) => {
      this.percentageList = utility.extractJsonData(apiResponse, 0);
      console.log('Test Type', this.lookupTestsType)
      /* if(this.projectTestModel != null) {
        this.fetchTestProcedure(this.projectTestModel.ps_id);
      }  */     
    });
  }

  async fetchTestProcedure(e) {

  }

  saveTestsInfo() {

  }

  async initializeForm(pTestsInfo: any) {
    if (pTestsInfo != null) {
      this.pTestsForm = new FormGroup({
        test_type: new FormControl({ value: pTestsInfo?.test_type },Validators.required),
        test_code: new FormControl( pTestsInfo?.test_code),
        test_id: new FormControl({value: pTestsInfo?.test_id, disabled: false}, [Validators.required]),
        test_procedure: new FormControl(pTestsInfo?.test_procedure),
        percenatge: new FormControl(pTestsInfo?.percenatge),
        test_procedure_title: new FormControl({ value: pTestsInfo?.test_procedure_title },Validators.required),
  
        job_id: new FormControl(pTestsInfo?.job_id),
        item_id: new FormControl(pTestsInfo?.item_id),
        site_id: new FormControl(pTestsInfo?.site_id),
        disc_id: new FormControl(pTestsInfo?.disc_id),
        disc_code: new FormControl(pTestsInfo?.disc_code),
        project_id: new FormControl(pTestsInfo?.project_id),
        job_code: new FormControl(pTestsInfo?.job_code),
        
      });
    }
    else {
      this.pTestsForm = new FormGroup({
        test_type: new FormControl(''),
        test_procedure: new FormControl(''),
        percenatge: new FormControl(null),
        test_procedure_title: new FormControl(null),
        test_code: new FormControl(''),
        test_id: new FormControl({value: '', disabled: false}),

        job_id: new FormControl(''),
        item_id: new FormControl(''),
        disc_id: new FormControl(''),
        site_id: new FormControl(''),
        disc_code: new FormControl(''),
        project_id: new FormControl(''),
        job_code: new FormControl('')        
      });
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
