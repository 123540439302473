<form [formGroup]="srForm" novalidate class="form">
    <div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <span class="dialogHeader">Search</span>
        <button mat-icon-button class="close-button" (click)="closeDialog(false)">
            <mat-icon class="close-icon" color="warn">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <input type="hidden" formControlName="site_id" />

        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label>Project</mat-label>
                <mat-select formControlName="project_id" (selectionChange)="onProjectChange($event,true)">
                    <ng-container>
                        @for (item of projectList; track item) {
                        <mat-option [value]="item.project_id">{{item.project_code}} -
                            {{item.project_title}}</mat-option>
                        }
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="srForm.controls['project_id'].errors?.['required']">
                    This is required!
                </mat-error>
            </mat-form-field>
        </div>

        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label>Job</mat-label>
                <mat-select formControlName="job_id" (selectionChange)="onJobChange($event,true)">
                    <ng-container>
                        @for (item of jobList; track item) {
                        <mat-option [value]="item.job_id">{{item.job_code}} -
                            {{item.job_title}}</mat-option>
                        }
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="srForm.controls['job_id'].errors?.['required']">
                    This is required!
                </mat-error>
            </mat-form-field>
        </div>

        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label>Item</mat-label>
                <mat-select formControlName="item_id" (selectionChange)="onItemChange($event,true)">
                    <ng-container>
                        @for (item of itemList; track item) {
                        <mat-option [value]="item.item_id">{{item.item_code}} -
                            {{item.item_title}}</mat-option>
                        }
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="srForm.controls['item_id'].errors?.['required']">
                    This is required!
                </mat-error>
            </mat-form-field>
        </div>

        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label>Drawing</mat-label>
                <mat-select formControlName="drawing_id" (selectionChange)="onDrawingChange($event,true)">
                    <ng-container>
                        @for (item of drawingList; track item) {
                        <mat-option [value]="item.drawing_id">{{item.mcd_drawing_code}} -
                            {{item.mcd_drawing_desc}}</mat-option>
                        }
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="srForm.controls['drawing_id'].errors?.['required']">
                    This is required!
                </mat-error>
            </mat-form-field>
        </div>

        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label class="matInputCls">Spool</mat-label>
                <input matInput type="text" id="txtSpoolNo" class="matInputCls" name="text" formControlName="recap_spool"
                    maxlength="120">
            </mat-form-field>
        </div>

        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label>Weld</mat-label>
                <mat-select formControlName="weld_id" (selectionChange)="onWeldChange($event)">
                    <ng-container>
                        @for (item of weldList; track item) {
                        <mat-option [value]="item.weld_id">{{item.weld_code}}</mat-option>
                        }
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label class="matInputCls">Suffix</mat-label>
                <input matInput type="text" id="txtSuffix" class="matInputCls" name="text" formControlName="suffix"
                    maxlength="120">
            </mat-form-field>
        </div>

        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label class="matInputCls">S/R</mat-label>
                <input matInput type="text" id="txtSRCode" class="matInputCls" name="text" formControlName="sr_code"
                    maxlength="120">
            </mat-form-field>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions align="end" class="mr-16">
        <button mat-raised-button color="primary"  class="mainBtn" (click)="search()" [disabled]="srForm.invalid">Submit</button>
    </mat-dialog-actions>
</form>