import { Component, OnInit } from '@angular/core';
import { SearchProjectjobitemComponent } from '../../../common/search-projectjobitem/search-projectjobitem.component';
import { DevExtremeModule } from '../../../../core/devExtreme.module';
import { DrawingService } from '../../../../APIServices/WeldNDT/drawing/DrawingService';
import { ActivatedRoute, Router } from '@angular/router';
import { utility } from '../../../../util';
import { ToolbarButtonsComponent } from '../../../../header/toolbar-buttons/toolbar-buttons.component';
import { ToolbarItem } from '../../../../header/toolbar-buttons/toolbar-item';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { UtilityService } from '../../../../APIServices/UtilityService';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-drawing-list',
  standalone: true,
  imports: [DevExtremeModule, SearchProjectjobitemComponent, ToolbarButtonsComponent, CommonModule],
  templateUrl: './drawing-list.component.html',
  styleUrl: './drawing-list.component.scss'
})
export class DrawingListComponent implements OnInit {
  tModelList: any;
  toolbarItems: any;
  buttonAccess: any = null;
  loggedInUser: any;
  selectedInfo: any;
  selectedFilterInfo: any;
  currentUrl: string;

  title: string = "Drawing";
  constructor(
    private drawingService: DrawingService,
    private router: Router,
    private utilService: UtilityService,
    private route: ActivatedRoute
  ) {

  }
  async ngOnInit() {
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    this.currentUrl = this.route.snapshot.url[0].path;
    this.getButtonsAccess(this.currentUrl);

    this.selectedFilterInfo = utility.getSelectedFilter();
    if (this.selectedFilterInfo != null)
      this.onSearch(this.selectedFilterInfo);
    // await this.index();
    // await this.populateResourceOptionList();
  }
  async onSearch(e: any) {

    var fProperty = {
      project_id: e?.project_id,
      job_id: e?.job_id,
      item_id: e?.item_id
    };

    utility.setSelectedFilter(fProperty);

    (await this.drawingService.getAllDrawingList(fProperty.project_id, fProperty.job_id, fProperty.item_id)).subscribe((apiResponse: any) => {
      this.tModelList = utility.extractJsonData(apiResponse, 0);
    });

  }
  async onResetSearch(e: any) {
    //this.tModelList = null;
  }

  onSelectionChanged(e) {
    this.selectedInfo = e.selectedRowsData;
    this.drawingService.setSelectedInfo(e.selectedRowsData);
  }

  routeToDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    if (e.row?.data == null) {
      this.router.navigate(['./drawingdetails']);
    }
    let params = {
      drawing_id: e.row?.data.drawing_id,
    };
    this.router.navigate(['./drawingdetails'], { queryParams: params });
    var dModel;
    if (e.row?.data.drawing_id == undefined || e.row?.data.drawing_id == null) {

      var localUser = JSON.stringify(e.row?.data);
      dModel = <any>JSON.parse(localUser);
      dModel.site_id = this.loggedInUser.site_id;
      dModel.site_code = this.loggedInUser.site_code;
      dModel.site_description = this.loggedInUser.site_description;
    }
    else {
      var localUser = JSON.stringify(e.row?.data);
      dModel = <any>JSON.parse(localUser);
    }
    this.drawingService.setSelectedInfo(dModel);
    e.event?.preventDefault();
  };

  RouteToCreate() {

    var fltrData = utility.getSelectedFilter();
    var pModel = {
      site_id: this.loggedInUser.site_id,
      site_code: this.loggedInUser.site_code,
      site_description: this.loggedInUser.site_description,
      project_id: fltrData.project_id,
      job_id: fltrData.job_id,
      item_id: fltrData.item_id
    };

    this.drawingService.setSelectedInfo(pModel);
    this.router.navigate(['./drawingdetails']);
  }
  async DeleteUser() {
    if (this.selectedInfo != null && this.selectedInfo.length == undefined) {
      alert("Please select a drawing");
      return;
    }

    if (!confirm("Are you sure to Inactivate selected drawing?")) {
      return;
    }

    var drawingInfoDelete = {
      drawing_list: this.selectedInfo
    };

    (await this.drawingService.deleteDrawing(drawingInfoDelete)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.onSearch(this.selectedFilterInfo);
    });
  }

  async getButtonsAccess(currRoute: any) {
    (await this.utilService.getAllButtonAccess(currRoute)).subscribe((apiResponse: any) => {
      let resData = utility.extractJsonData(apiResponse, 0);
      this.buttonInitialization(resData);
      if (resData != null && resData != undefined) {
        this.buttonAccess = resData[0];
        this.buttonInitialization(resData[0]);
      }
    });
  }

  buttonInitialization(buttonAccess: any) {
    this.toolbarItems = [
      { label: 'Add Drawing', path: '', iconName: '', action: () => this.RouteToCreate(), hidden: false },
      { label: 'Inactivate Drawing', path: '', iconName: '', action: () => this.DeleteUser(), hidden: false }
    ];
  }
}
