import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { utility } from '../../util';

@Injectable({
    providedIn: 'root'
})
export class WeldSearchService {
    requester_id: string;
    site_id: string;
    site_code: string;
    apiBaseUrl: string;
    apiWeldNDTUrl: string;

    private environmentConfig = utility.getEnvironmentData();

    constructor(private httpClient: HttpClient,
        private router: Router
    ) {
        var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
        this.requester_id = loggedInUser.logon_id
        this.site_id = loggedInUser.site_id
        this.apiBaseUrl = this.environmentConfig.apiBase;
        this.apiWeldNDTUrl = this.environmentConfig.weldNDTApiUrl;
    }

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }
    public async getAllActiveProjectList() {
        var apiMethod = "ProjectInfo/GetAll";

        const params = new HttpParams()
            .set('requester_id', this.requester_id)
            .set('site_id', this.site_id)
            .set('is_active', true);

        var url = this.apiBaseUrl + apiMethod;
        return this.httpClient.get(url, { params: params }).pipe(
            catchError(this.errorHandler)
        );
    }

    public async getAllActiveJobListByProject(project_id: any) {
        var apiMethod = "ProjectJobInfo/GetAll";

        const params = new HttpParams()
            .set('requester_id', this.requester_id)
            .set('site_id', this.site_id)
            .set('project_id', project_id)
            .set('is_active', true);

        var url = this.apiBaseUrl + apiMethod;
        return this.httpClient.get(url, { params: params }).pipe(
            catchError(this.errorHandler)
        );
    }

    public async getAllActiveItemListByJob(project_id: any, job_id: any) {
        var apiMethod = "ProjectItemInfo/GetAll";

        const params = new HttpParams()
            .set('requester_id', this.requester_id)
            .set('site_id', this.site_id)
            .set('project_id', project_id)
            .set('job_id', job_id)
            .set('is_active', true);

        var url = this.apiBaseUrl + apiMethod;
        return this.httpClient.get(url, { params: params }).pipe(
            catchError(this.errorHandler)
        );
    }

    public async getAllActiveDrawingList(project_id: any, job_id: any, item_id: any) {
        var apiMethod = "DrawingInfo/GetAll";

        const params = new HttpParams()
            .set('requester_id', this.requester_id)
            .set('site_id', this.site_id)
            .set('project_id', project_id)
            .set('job_id', job_id)
            .set('item_id', item_id)

        var url = this.apiWeldNDTUrl + apiMethod;
        return this.httpClient.get(url, { params: params }).pipe(
            catchError(this.errorHandler)
        );
    }

    public async getAllActiveWeldListByDrawing(project_id: any, job_id: any, item_id: any, drawing_id: any) {
        var apiMethod = "WeldRegisterInfo/GetAll";

        const params = new HttpParams()
            .set('requester_id', this.requester_id)
            .set('site_id', this.site_id)
            .set('drawing_id', drawing_id)

        var url = this.apiWeldNDTUrl + apiMethod;
        return this.httpClient.get(url, { params: params }).pipe(
            catchError(this.errorHandler)
        );
    }

    errorHandler(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message;
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }
}