import { Component, OnInit } from '@angular/core';
import { DevExtremeModule } from '../../../core/devExtreme.module';
import { ToolbarButtonsComponent } from '../../../header/toolbar-buttons/toolbar-buttons.component';
import { ToolbarItem } from '../../../header/toolbar-buttons/toolbar-item';
import { Router } from '@angular/router';
import { TestProcedureService } from '../../../APIServices/testacceptance/TestProcedureService';
import { utility } from '../../../util';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { SearchProjectjobitemComponent } from "../../common/search-projectjobitem/search-projectjobitem.component";


@Component({
  selector: 'app-test-procedure-list',
  standalone: true,
  imports: [DevExtremeModule, ToolbarButtonsComponent, SearchProjectjobitemComponent],
  templateUrl: './test-procedure-list.component.html',
  styleUrl: './test-procedure-list.component.scss'
})
export class TestProcedureListComponent implements OnInit {
  tModelList: any;
  title: string = 'Test Procedure Maintenance';
  selectedInfo: any;
  selectedFilterInfo: any;

  public toolbarItems: ToolbarItem[] = [
    { label: 'Add Test', path: '', iconName: '', action: () => this.RouteToCreate(), hidden: false },
    { label: 'Inactivate Test', path: '', iconName: '', action: () => this.DeActivate(), hidden: false }
  ];

  /**
   *
   */
  constructor(private router: Router, 
    private testProcedureService : TestProcedureService) {    
    
  }

  async ngOnInit(){ 
    await this.Index()
  }

  public async Index() {    

    // this.testProcedureService.getSelectedFilterInfo$.subscribe((x) =>
    //   this.selectedFilterInfo = x
    // );

    this.selectedFilterInfo = utility.getSelectedFilter();
    
    if(this.selectedFilterInfo != null && this.selectedFilterInfo != undefined){
      var project_id = this.selectedFilterInfo?.project_id;
      var job_id = this.selectedFilterInfo?.job_id;
      var item_id = this.selectedFilterInfo?.item_id;

      (await this.testProcedureService.getAll(project_id,job_id, item_id)).subscribe((apiResponse: any) => {
        this.tModelList = utility.extractJsonData(apiResponse, 0);
      });    

    }
  }

  async onSearch(e: any){

    if(e != null && e!= undefined){
      //this.testProcedureService.setSelectedFilterInfo(e);
      utility.setSelectedFilter(e);
      this.selectedFilterInfo = e;

      var project_id = e?.project_id;
      var job_id = e?.job_id;
      var item_id = e?.item_id;
      console.log("search");
      (await this.testProcedureService.getAll(project_id,job_id, item_id)).subscribe((apiResponse: any) => {
        this.tModelList = utility.extractJsonData(apiResponse, 0);
      });
    }

  }

  async onResetSearch(e: any){
    console.log("search Reset");
    //this.tModelList = null;
  }

  RouteToDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    if (e.row?.data == null) {
      this.router.navigate(['./testproceduredetails']);
    }

    // var user_id=this.userService.LoggedInUserEmail();
    var selectedID = e.row?.data.ps_id;
    
    let params = {
      ps_id: selectedID
    };
    this.router.navigate(['./testproceduredetails'], { queryParams: params });
    var uModel;

      var detailData = JSON.stringify(e.row?.data);
      uModel = <any>JSON.parse(detailData);      
      this.testProcedureService.setSelectedInfo(uModel);
      //this.router.navigate(['./processsdetails'], { queryParams: params });   
      e.event?.preventDefault();
  }

  async RouteToCreate(){
      var loggedInUser = utility.getLoggedInUser(localStorage, this.router);    
      this.selectedFilterInfo = utility.getSelectedFilter();
      
      if(this.selectedFilterInfo?.item_id === null || this.selectedFilterInfo?.item_id === undefined ){
        alert("Please search specific Project/Job/Item first.");
        return;
      }
       var item_id = this.selectedFilterInfo?.item_id;
       var project_id = this.selectedFilterInfo?.project_id;
       var job_id = this.selectedFilterInfo?.job_id;

       (await this.testProcedureService.getSelectedProjectItem(project_id,job_id,item_id)).subscribe((apiResponse: any) => {
        var Selecteditem = utility.extractJsonData(apiResponse, 0);        
        
        if(Selecteditem != null && Selecteditem != undefined && Selecteditem.length > 0)
          Selecteditem =Selecteditem[0]

        var uModel = {
          ps_id: null,
          project_id: Selecteditem?.project_id,
          job_id: Selecteditem?.job_id,
          job_code: Selecteditem?.job_code,
          item_id: Selecteditem?.item_id,
          item_code: Selecteditem?.item_code,
          disc_id: null,
          test_id: null,
          base_procedure: null,
          test_procedure: null,
          procedure_rev: null,
          status: 'A',
          start_dtm: new Date(),
          end_dtm: null,
          created_by: loggedInUser?.logon_id,
          deactivated_by: null,
          is_active: true
        }
        this.testProcedureService.setSelectedInfo(uModel);    
        this.router.navigate(['./testproceduredetails']);

       });
  }

  onSelectionChanged(e: any){
    this.selectedInfo = e.selectedRowsData;
    this.testProcedureService.setSelectedInfo(this.selectedInfo);
  }

  async DeActivate(){
        if (this.selectedInfo == null || this.selectedInfo == undefined ||
          this.selectedInfo.length == undefined || this.selectedInfo.length == 0) {
          alert("Please select record");
          return;
        }
    
        if (!confirm("Are you sure to Inactivate selected Test Procedure?")) {
          return;
        }
    
        //var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
        //var empTypeList = this.selectedInfo;
        let params = {
          test_proc_list: this.selectedInfo
        };
    
        // var userInfoDelete = {
        //   userList: this.selectedInfo//,
        //   //requester_id: loggedInUser.logon_id
        // };
    
        (await this.testProcedureService.delete(params)).subscribe((apiResponse: any) => {
          utility.responseAlert(apiResponse);
          this.Index();
        });
    }
}
