<app-toolbar-buttons *ngIf="toolbarItems" [items]="toolbarItems" [title]="title"></app-toolbar-buttons>

<app-search-projectjobitem (search)="onSearch($event)"
    (resetSearch)="onResetSearch($event)"></app-search-projectjobitem>

<div class="centered-outer-div">
    <dx-data-grid id="gridUserList" #dxUserList [dataSource]="tModelList" [allowColumnReordering]="true"
        class="centered-inner-div" [showBorders]="true" [showColumnLines]="true" [showRowLines]="true"
        [rowAlternationEnabled]="true" (onSelectionChanged)="onSelectionChanged($event)" width="1200px">
        <dxo-selection mode="multiple" showCheckBoxesMode="always">
        </dxo-selection>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column type="buttons" [width]="50">
            <dxi-button hint="Edit selected Resource" icon="edit" [visible]=true [disabled]=false
                [onClick]="routeToDetails"></dxi-button>
        </dxi-column>

        <dxi-column dataField="drawing_id" [width]="100" caption="Drawing ID" [visible]="false"></dxi-column>
        <dxi-column dataField="project_id" [width]="100" caption="Project ID" [visible]="false"></dxi-column>
        <dxi-column dataField="job_id" [width]="50" caption="Job ID" [visible]="false"></dxi-column>
        <dxi-column dataField="item_id" [width]="50" caption="Item ID" [visible]="false"></dxi-column>
        <dxi-column caption="MCD Reference" [alignment]="'center'">
            <dxi-column dataField="mcd_drawing_code" tooltip="Test" [width]="120" caption="Drawing" [visible]="true" sortOrder="asc"></dxi-column>
            <dxi-column dataField="mcd_drawing_revision" [width]="50" caption="Rev" [visible]="true"></dxi-column>
            <dxi-column dataField="mcd_drawing_desc" [width]="200" caption="Description" [visible]="true"></dxi-column>
            <dxi-column dataField="mcd_date" [width]="100"  dataType="date" format="MM/dd/yyyy" caption="Date" [visible]="true"></dxi-column>
            <dxi-column dataField="lineclass_desc" [width]="120" caption="Line Class"></dxi-column>
            <dxi-column dataField="ndt_percent" [width]="80" caption="NDT%"></dxi-column>
        </dxi-column>
        <dxi-column caption="Client Reference" [alignment]="'center'">
            <dxi-column dataField="client_drawing_code" [width]="120" caption="Drawing" [visible]="true"></dxi-column>
            <dxi-column dataField="client_drawing_revision" [width]="50" caption="Rev" [visible]="true"></dxi-column>
            <dxi-column dataField="client_drawing_desc" [width]="200" caption="Description" [visible]="true"></dxi-column>
            <dxi-column dataField="client_date" [width]="100" dataType="date" format="MM/dd/yyyy" caption="Date" [visible]="true"></dxi-column>
        </dxi-column>
        <dxi-column dataField="line" [width]="100" caption="Line" [visible]="true"></dxi-column>
        <dxi-column dataField="line_desc" [width]="150" caption="Line Desc" [visible]="true"></dxi-column>
        <dxi-column dataField="type" [width]="100" caption="Type" [visible]="false"></dxi-column>
        <dxi-column dataField="type_desc" [width]="100" caption="Type" [visible]="true"></dxi-column>
        <dxi-column dataField="mtn_completed" [width]="100" caption="MTN Comp" [visible]="false"></dxi-column>
        <dxi-column dataField="mtn_completed_desc" [width]="100" caption="MTN Comp" [visible]="true"></dxi-column>
        <dxi-column dataField="markup_received" [width]="100" caption="MarkUp Recv" [visible]="false"></dxi-column>
        <dxi-column dataField="markup_received_desc" [width]="100" caption="MarkUp Recv" [visible]="true"></dxi-column>
        <dxi-column dataField="hydrotest" [width]="100" caption="Hydrotest" [visible]="true"></dxi-column>
        <dxi-column dataField="jrm_inspector" [width]="100" caption="JRM Insp" [visible]="true"></dxi-column>
        <dxi-column dataField="j_insp_sbmt_date" [width]="100" dataType="date" format="MM/dd/yyyy" caption="Sbmt to J. Insp" [visible]="true"></dxi-column>
        <dxi-column dataField="butts" [width]="100" caption="Butts" [visible]="true"></dxi-column>
        <dxi-column dataField="cleared_date" [width]="100" dataType="date" format="MM/dd/yyyy" caption="Cleared" [visible]="true"></dxi-column>
        <dxi-column dataField="client_sbmt_date" [width]="100" dataType="date" format="MM/dd/yyyy" caption="Sbmt to Client" [visible]="true"></dxi-column>
        <dxi-column dataField="last_weldno" [width]="100" caption="Last Weld No" [visible]="true"></dxi-column>
        <dxi-column dataField="recalled_date" [width]="100" dataType="date" format="MM/dd/yyyy" caption="Recalled" [visible]="true"></dxi-column>
        <dxi-column dataField="approved_date" [width]="100" dataType="date" format="MM/dd/yyyy" caption="Approved" [visible]="true"></dxi-column>
        <dxi-column dataField="narrative" [width]="200" caption="Narrative" [visible]="true"></dxi-column>
        <dxi-column dataField="is_active" [width]="90" caption="is Active?" [visible]="true"
            cssClass="dxCheckBoxFormat"></dxi-column>


        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 50, 'all']"
            [showNavigationButtons]="true" [visible]="true">
        </dxo-pager>
        <dxo-paging [pageSize]="10" [pageIndex]="0">
        </dxo-paging>
    </dx-data-grid>
</div>