import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { utility } from '../../../util';

@Injectable({
  providedIn: 'root'
})
export class WeldProcedureToProjectService {

    private apiBaseUrl: string;
    private selectedInfo = new BehaviorSubject(null);
    getSelectedInfo = this.selectedInfo.asObservable();
    private environmentConfig = utility.getEnvironmentData();
    requester_id: any;
    site_id: any;
    user_id: any;
    weldApiBaseUrl: any;

  constructor(private httpClient: HttpClient, private router: Router) {
      this.apiBaseUrl = this.environmentConfig.apiBase;
      var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
      this.requester_id = loggedInUser.logon_id;
      this.site_id = loggedInUser.site_id;
      this.user_id = loggedInUser.user_id;
      this.weldApiBaseUrl = this.environmentConfig.weldApiBase;
  }

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }

    setSelectedInfo(paramData: any) {
        this.selectedInfo.next(paramData);
    }
    getActiveProceduresList(){
      const params = new HttpParams()
        .set('requester_id',this.requester_id)
        .set('site_id',this.site_id)
        .set('is_active', true);
  
      var apiMethod = "weldProcedureinfo/getall";
      var url = (this.weldApiBaseUrl + apiMethod);
      return this.httpClient.get(url,{params: params});
    } 

    mapProceduresToProject(request:any): Observable<any> {
        var apiMethod = "WeldProcedureProjectMapInfo/create";
        var url = (this.weldApiBaseUrl + apiMethod);
        return this.httpClient.post(url, JSON.stringify(request), this.httpOptions)
        .pipe(
            catchError(this.errorHandler)
        );
    }

  deleteProceduresFromProject(delData: any): Observable<any> {
    var apiMethod = "WeldProcedureProjectMapInfo/delete";
    var url = (this.weldApiBaseUrl + apiMethod);
    return this.httpClient.put(url, JSON.stringify(delData), this.httpOptions)
    .pipe(
        catchError(this.errorHandler)
    );
} 

  errorHandler(error: any) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        errorMessage = error.error.message;
      } else {
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      return throwError(errorMessage);
    }
}
