<form class="dialog-form">
    <div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <span class="dialogHeader">Map Procedure to Project</span>
        <button mat-icon-button class="close-button" (click)="closeDialog()">
            <mat-icon class="close-icon" color="warn">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <span></span>
        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label class="matInputCls">Select Weld Procedures</mat-label>
                <mat-select [(ngModel)]="selectedList" (selectionChange)="getSelectedValues($event)" [ngModelOptions]="{standalone: true}" multiple>
                    <ng-container>
                        @for (item of dModelList; track item) {
                        <mat-option [value]="item.weld_procedure_id" [disabled]="!isOptionDisabled(item.weld_procedure_id)">{{item.base_procedure}} -
                            {{item.weld_procedure_title}}</mat-option>
                        }
                    </ng-container>
                </mat-select>
        </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="mr-16">
        <button mat-raised-button color="primary" (click)="mapProcedureToProject()">Map</button>
    </mat-dialog-actions>
</form>