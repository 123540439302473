import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-empreport',
  standalone: true,
  imports: [],
  templateUrl: './empreport.component.html',
  styleUrl: './empreport.component.scss'
})
export class EmpreportComponent {
  reportUrl:SafeResourceUrl;
  sanitizedUrl


  constructor(private sanitizer: DomSanitizer){
    const url ='https://mcis-dev.mcdermott.com/ReportServer/Pages/ReportViewer.aspx?%2fMCIS_DEVREPORTS%2fEmpReport&rs:Command=Render'
    this.reportUrl=this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
