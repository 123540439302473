<app-toolbar-buttons *ngIf="toolbarItems" [items]="toolbarItems" [title]="title"></app-toolbar-buttons>
<div class="cls_drpdwn">
    <app-searchable-dropdown *ngIf="projectModelList" 
        [options]="projectModelList"
        [valueKey]="'project_id'"
        [displayKey] ="'project_code'"
        [displayKey1] ="'project_title'"
        [label]="'Select Project'"
        [selectedValue]="selectedProject"
        (selectionChange)="onItemSelect($event)"> 
    </app-searchable-dropdown>
</div>
<mat-divider class="divider_cls"></mat-divider>
<div class="display-flex">
    <div class="pl-pr">
        <div class="example-action-buttons">
            <button mat-button class="action-btn" (click)="accordion().openAll()">Expand All</button>
            <button mat-button class="action-btn" (click)="accordion().closeAll()">Collapse All</button>
        </div>
        <mat-accordion class="example-headers-align" multi="true">
            <!-- Weld Procedure accordian -->
            <mat-expansion-panel [expanded]="step() === 0" (opened)="setStep(0)">
                <mat-expansion-panel-header>
                    <mat-panel-title class="cls_mat_panel_title"> Weld Procedure </mat-panel-title>
                    <mat-divider></mat-divider>
                    <mat-panel-description class="process_addBtn">
                        <button  matTooltip="Add Project" matTooltipPosition='above' 
                                mat-icon-button class="mat_icon_btn" (click)="addProcedureToProject($event)">
                            <mat-icon>add</mat-icon>
                        </button>
                        <button  matTooltip="Delete Procedure" matTooltipPosition='above' 
                                mat-icon-button class="mat_icon_btn_delete" (click)="deleteProcedureFromProject($event)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div>
                        <dx-data-grid width="100%" #weldProcedureDataGrid id="gridSelectedWeldProcedure" [dataSource]="weldProcedureModelList"
                            keyExpr="weld_procedure_id" [showBorders]="true" [showColumnLines]="true" [showRowLines]="true"
                            [rowAlternationEnabled]="true" (onSelectionChanged)="onWeldProcedureSelectionChanged($event)"
                            (onContentReady)="onProcedureContentReadyHandler($event)">
                            <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>
                            <!-- <dxi-column type="buttons" [width]="50">
                                <dxi-button hint="Edit selected Job" icon="edit" [visible]=true [disabled]=false
                                    [onClick]="RouteToJobDetails"></dxi-button>
                            </dxi-column> -->
                            <dxi-column dataField="weld_procedure_id" [width]="100" caption="Weld Procedure ID" [visible]="false"></dxi-column>
                            <dxi-column dataField="weld_prcd_project_map_id" [width]="100" caption="Weld Procedure Project Map Id" [visible]="false"></dxi-column>
                            <dxi-column dataField="site_id" [width]="100" caption="Site ID" [visible]="false"></dxi-column>
                            <dxi-column dataField="project_id" [width]="100" caption="Project ID" [visible]="false"></dxi-column>
                            <dxi-column dataField="base_procedure" [width]="130" caption="Base Procedure" sortOrder="asc"></dxi-column>
                            <dxi-column dataField="weld_procedure_title" caption="Weld Procedure Title"></dxi-column>
                            <!-- <dxi-column dataField="is_active" [width]="100" caption="Is Active?"></dxi-column> -->
                        </dx-data-grid>
                    </div>
            </mat-expansion-panel>

            <!-- Line Class Accordion -->
            <mat-expansion-panel [expanded]="step() === 0" (opened)="setStep(0)">
                <mat-expansion-panel-header>
                    <mat-panel-title class="cls_mat_panel_title"> Weld Procedure to Line class Mapping </mat-panel-title>
                    <mat-divider></mat-divider>
                    <mat-panel-description class="process_addBtn">
                        <button  matTooltip="Add Line class" matTooltipPosition='above' 
                                mat-icon-button class="mat_icon_btn" (click)="addLineClassToProcedure($event)">
                            <mat-icon>add</mat-icon>
                        </button>
                        <button  matTooltip="Delete Line class" matTooltipPosition='above' 
                                mat-icon-button class="mat_icon_btn_delete" (click)="deleteLineClassFromProcedure($event)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <!-- Line Class mapping accordian -->
                <div>
                    <dx-data-grid width="100%" #WeldLineClassDataGrid id="gridSelectedWeldProcess" [dataSource]="weldProcedureLineClassModelList"
                        keyExpr="lineclass_id" [showBorders]="true" [showColumnLines]="true" [showRowLines]="true"
                        [rowAlternationEnabled]="true" (onSelectionChanged)="onSelectionChangedLineClass($event)"
                        >
                        <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>
                       <!--  <dxi-column type="buttons" [width]="50">
                            <dxi-button hint="Edit selected Job" icon="edit" [visible]=true [disabled]=false
                                [onClick]="ruteToDetails"></dxi-button>
                        </dxi-column> -->
                        <dxi-column dataField="lineclass_id" [width]="100" caption="Line Class ID" [visible]="false"></dxi-column>
                        <!-- <dxi-column dataField="weld_prcd_prss_map_code" [width]="100" caption="Map Code"></dxi-column> -->
                        <dxi-column dataField="lineclass_code" [width]="130" caption="Line Class Code" sortOrder="asc"></dxi-column>
                        <dxi-column dataField="lineclass_desc" [width]="100" caption="Line Class Desc"></dxi-column>
                        <!-- <dxi-column dataField="is_active" [width]="100" caption="Is Active?"></dxi-column> -->
                    </dx-data-grid>
                </div>
            </mat-expansion-panel>

            <!-- Material Type Accordion -->
            <mat-expansion-panel [expanded]="step() === 0" (opened)="setStep(0)" style="display: none;">
                <mat-expansion-panel-header>
                    <mat-panel-title class="cls_mat_panel_title"> Weld Procedure to Material Type Mapping </mat-panel-title>
                    <mat-divider></mat-divider>
                    <mat-panel-description class="process_addBtn">
                        <button  matTooltip="Add Material Type" matTooltipPosition='above' 
                                mat-icon-button class="mat_icon_btn" (click)="addMaterialTypeToProcedure($event)">
                            <mat-icon>add</mat-icon>
                        </button>
                        <button  matTooltip="Delete Material Type" matTooltipPosition='above' 
                                mat-icon-button class="mat_icon_btn_delete" (click)="deleteMaterialTypeFromProcedure($event)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <!-- Material Type mapping accordian -->
                <div>
                    <dx-data-grid width="100%" #WeldMaterialTypeDataGrid id="gridSelectedMaterialType" [dataSource]="weldProcedureMaterialTypeModelList"
                        keyExpr="mat1_type_id" [showBorders]="true" [showColumnLines]="true" [showRowLines]="true"
                        [rowAlternationEnabled]="true" (onSelectionChanged)="onSelectionChangedMaterialType($event)"
                        >
                        <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>
                       <!--  <dxi-column type="buttons" [width]="50">
                            <dxi-button hint="Edit selected Job" icon="edit" [visible]=true [disabled]=false
                                [onClick]="ruteToDetails"></dxi-button>
                        </dxi-column> -->
                        <!-- Material Type I -->
                        <dxi-column caption="Material Type I" [alignment]="'center'">
                            <dxi-column dataField="mat1_type_id" [width]="100" caption="Material Type I Id" [visible]="false"></dxi-column>
                            <dxi-column dataField="mat1_type_code" [width]="120" caption="Code" sortOrder="asc"></dxi-column>
                            <dxi-column dataField="mat1_type_description" [width]="150" caption="Desc"></dxi-column>
                        </dxi-column>
                        <!-- Material Type II -->
                        <dxi-column caption="Material Type II" [alignment]="'center'">
                            <dxi-column dataField="mat2_type_id" [width]="100" caption="Material Type II Id" [visible]="false"></dxi-column>
                            <dxi-column dataField="mat2_type_code" [width]="120" caption="Code" sortOrder="asc"></dxi-column>
                            <dxi-column dataField="mat2_type_description" [width]="150" caption="Desc"></dxi-column>
                        </dxi-column>
                        <!-- Material Grade I -->
                        <dxi-column caption="Material Grade I" [alignment]="'center'">
                            <dxi-column dataField="mat1_grd_id" [width]="100" caption="Material Grade I Id" [visible]="false"></dxi-column>
                            <dxi-column dataField="mat1_grade_code" [width]="120" caption="Code" sortOrder="asc"></dxi-column>
                            <dxi-column dataField="mat1_grade_description" [width]="150" caption="Desc"></dxi-column>
                        </dxi-column>
                        <!-- Material Grade II -->
                        <dxi-column caption="Material Grade II" [alignment]="'center'">
                            <dxi-column dataField="mat2_grd_id" [width]="100" caption="Material Grade II Id" [visible]="false"></dxi-column>
                            <dxi-column dataField="mat2_grade_code" [width]="120" caption="Code" sortOrder="asc"></dxi-column>
                            <dxi-column dataField="mat2_grade_description" [width]="150" caption="Desc"></dxi-column>
                        </dxi-column>
                    </dx-data-grid>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>