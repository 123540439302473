import { Component, Inject, OnInit } from '@angular/core';
import { MaterialModule } from '../../../../core/material-module';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { WeldProcedureToMaterialTypeService } from '../../../../APIServices/weld/weldProjectProcedureMappings/weldProcedureToMaterialTypeService';
import { utility } from '../../../../util';
import { WeldMasterService } from '../../../../APIServices/master/weld/WeldMasterService';

@Component({
  selector: 'app-add-procedure-materialtype',
  standalone: true,
  imports: [MaterialModule, CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './add-procedure-materialtype.component.html',
  styleUrl: './add-procedure-materialtype.component.scss'
})
export class AddProcedureMaterialtypeComponent implements OnInit{
  availableItems: any;
  selectedWeldProcedureList: any;
  loggedInUser: any;
  selectedInfo: null;
  selectedList: any;
  fileteredNewList: any;
  dModelList: any;
  materialGradeList: any;
  materialGradeList2: any;
  materialTypeList: any;
  selectedMaterialType1: any;
  selectedMaterialType2: any;
  selectedMaterialGrade1: any;
  selectedMaterialGrade2: any;
  isFormChanged: any;
  matTypeGrdForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<AddProcedureMaterialtypeComponent>,
    private router: Router,
    private weldProcedureToMaterialTypeService: WeldProcedureToMaterialTypeService,
    private route: ActivatedRoute,
    private weldMasterService: WeldMasterService,
    @Inject(MAT_DIALOG_DATA) public data:any
    ) { 
      this.availableItems = data.availableItems;
      this.selectedWeldProcedureList = data.selectedWeldProcedureList;
      this.initializeForm(null);
    }

    ngOnInit() {
      this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
      this.weldProcedureToMaterialTypeService.getSelectedInfo.subscribe(x => {
        if(utility.isObject(x)) {
          this.selectedInfo = x;
        } 
      });
      this.populateCommonMasters();
    }
    

    /* async populateMaterialType() {
      (await this.weldProcedureToMaterialTypeService.getActiveLineClassList()).subscribe((apiResponse: any) => {
        this.materialTypeList = utility.extractJsonData(apiResponse, 0);
        console.log('this.dModelList Line class',this.dModelList);
        this.selectedList = this.availableItems;
      });
    } */

    async mType1SelectionChange(e) {
      if (e.value != null && e.value != undefined) {
        this.selectedMaterialType1 = e.value;
        console.log('selectedMaterialType1', this.selectedMaterialType1);
        await this.filterByMaterialType('MG', e.value, '1');
      }
    }

    async mType2SelectionChange(e) {
      if (e.value != null && e.value != undefined) {
        this.selectedMaterialType2 = e.value;
        console.log('selectedMaterialType2', this.selectedMaterialType2);
        await this.filterByMaterialType('MG', e.value, '2');
      }
    }

    mGrade1SelectionChange(e) {
      if (e.value != null && e.value != undefined) {
        this.selectedMaterialGrade1 = e.value;
        console.log('selectedMaterialGrade1', this.selectedMaterialGrade1);
      }
    }

    mGrade2SelectionChange(e) {
      if (e.value != null && e.value != undefined) {
        this.selectedMaterialGrade2 = e.value;
        console.log('selectedMaterialGrade2', this.selectedMaterialGrade2);
      }
    }

    async filterByMaterialType(cMstrTypeId: any, parent_id: any, mType: any) {
      (await this.weldMasterService.getCommonMasterData(cMstrTypeId)).subscribe((apiResponse: any) => {
        var cList = utility.extractJsonData(apiResponse, 0);
        if (cList != null) {
          //set value for material Grade Completed list
          if (mType == '1') {
            this.materialGradeList = utility.extractRecordFromCommonMaster(cList, cMstrTypeId, parent_id);
          }
          else {
            this.materialGradeList2 = utility.extractRecordFromCommonMaster(cList, cMstrTypeId, parent_id);
          }
        }
      });
    }
    async populateCommonMasters() {
      var cMstrTypeId = 'WT,JT,MT';
      (await this.weldMasterService.getCommonMasterData(cMstrTypeId)).subscribe((apiResponse: any) => {
        var cList = utility.extractJsonData(apiResponse, 0);
        if (cList != null) {
          //set value for Weld Type Completed list
        //  this.weldTypeList = utility.extractRecordFromCommonMaster(cList, 'WT');
  
          //set value for Joint Type Completed list
        //  this.jointTypeList = utility.extractRecordFromCommonMaster(cList, 'JT');
  
          //set value for Material Type Completed list
          this.materialTypeList = utility.extractRecordFromCommonMaster(cList, 'MT');
        }
      });
    }

    mapProcedureToMaterialType() {
      var weld_prcd_project_map_id_list = this.selectedWeldProcedureList?.map((item) => {return item.weld_prcd_project_map_id});
      var materialTypeRequest = {
        site_id: this.loggedInUser.site_id,
        mat1_type_id: this.selectedMaterialType1,
        mat2_type_id: this.selectedMaterialType2,
        mat1_grd_id: this.selectedMaterialGrade1,
        mat2_grd_id: this.selectedMaterialGrade2,
        weld_prcd_project_map_id: weld_prcd_project_map_id_list.toString(),
        weld_prcd_project_mat_map_id: null,
        is_active: true,
        requester_id: this.loggedInUser.logon_id,
      };
  
      //Call API to update mapping
      this.weldProcedureToMaterialTypeService.mapProcedureToMaterialType(materialTypeRequest).subscribe((apiResponse: any) => {
        //console.log(apiResponse);
        utility.responseAlert(apiResponse);
        //this.populateLineclass();
        this.closeDialog();
      });
    }
    
    initializeForm(wpInfo: any) {
      //if (wpInfo = null) {
        this.matTypeGrdForm = new FormGroup({
          mat_Type1: new FormControl('', [Validators.required]),
          mat_Type2: new FormControl('', [Validators.required]),
          mat_Grade1: new FormControl('', [Validators.required]),
          mat_Grade2: new FormControl('', [Validators.required])
        });
        this.detectFormValueChanges();
      //}
    }

    isSaveDisabled() {
      return this.isFormChanged && this.matTypeGrdForm.valid;
    }
    
    detectFormValueChanges(initialFormValues?: any) {
      this.matTypeGrdForm.valueChanges.subscribe(() => {
        this.isFormChanged = utility.checkIfFormChanged(this.matTypeGrdForm, initialFormValues);
      });
    }

    isOptionDisabled(option) {
      return !this.availableItems?.includes(option);
    }

    closeDialog(): void {
      this.dialogRef.close();
    }
}
