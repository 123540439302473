import { Component, OnInit, signal } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MaterialModule } from '../../../../core/material-module';
import { DevExtremeModule } from 'devextreme-angular';
import { OnlyAlphanumericDirective } from '../../../../directives/only-alphanumeric.directive';
import { WelderQualificationService } from '../../../../APIServices/weld/welderQualification/WelderQualificationService';
import { utility } from '../../../../util';
import { MatDialog } from '@angular/material/dialog';
import { AddQualificationToProjectComponent } from '../add-qualification-to-project/add-qualification-to-project.component';
import { WelderQualificationToProjectService } from '../../../../APIServices/weld/welderQualification/WelderQualificationToProjectService';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { format } from 'node:path';
import { WelderAdditionalQualificationService } from '../../../../APIServices/weld/welderQualification/WelderAdditionalQualificationService';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { AddEditAdditionalqualComponent } from '../add-edit-additionalqual/add-edit-additionalqual.component';


@Component({
  selector: 'app-welder-qualification-details',
  standalone: true,
  imports: [
    MaterialModule,
    CommonModule,
    DevExtremeModule,
    ReactiveFormsModule,
    OnlyAlphanumericDirective
  ],
  templateUrl: './welder-qualification-details.component.html',
  styleUrl: './welder-qualification-details.component.scss',
  providers:[DatePipe]
})
export class WelderQualificationDetailsComponent implements OnInit{
  welderQualiForm: FormGroup;
  loggedInUser: any;
  ResourceList: any[] = [];
  filteredResourceList: any[];
  selectedResource: any;
  WelderQualificationModelList: any;
  selectedInfo: null;
  queryParams: any;
  processList: any;
  disciplineList: any;
  procedureList: any;
  qualTypeList: any = [
    {
      'qual_type_code': 'M',
      'qual_type_title': 'Manual'
    }
  ]
  bEditMode: boolean;
  selectedResType: null;
  QualificationProjectModelList: any;
  mappedProjectItems: any;
  selectedProjectInfo: any;
  isEditMode: boolean = false;   

  AdditionalQualModelList: any;
  addiQualForm: FormGroup;
  addiQualList: any;
  currentProjectKey: any;
  currentProjectCode: any;
  selectedAdditionalQualItems: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private welderQualificationService: WelderQualificationService,
    private welderqualificationToProjectService: WelderQualificationToProjectService,
    private weldAddnlQualificationService: WelderAdditionalQualificationService,
    private datePipe: DatePipe
    ){
      this.initializeForm(null);
      this.InitializeAdditionalQualForm(null);
      
    }

  async ngOnInit() {    
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.welderQualificationService.getSelectedInfo.subscribe(x => {
      if(utility.isObject(x)) {
        this.selectedInfo = x;        
      } else {
        this.selectedResType = x;
      }
    });
    this.queryParams = this.route.snapshot.queryParams['welder_qual_id'];
    await this.index();
    await this.populateResourceList();
    await this.populateProcessList();
    await this.populateDisciplineList();
    await this.populateProcedureList();
    await this.getMappedProjectList();
    
  }

  async index() {
        
    if (this.queryParams != undefined && this.queryParams != null) {
      this.isEditMode = true;

      if (this.selectedInfo == null || this.selectedInfo == undefined || this.selectedInfo == '') {        
        (await this.welderQualificationService.getWelderQualificationDetail(this.queryParams)).subscribe((apiResponse: any) => {
          const jExtract = utility.extractJsonData(apiResponse, 0);
          this.selectedInfo = jExtract[0];
          this.welderQualificationService.setSelectedInfo(this.selectedInfo);
          this.initializeForm(this.selectedInfo);
        });        
      }else{
        await this.initializeForm(this.selectedInfo);
      }
    }
    else{
      this.bEditMode = false;                  
      await this.initializeForm(this.selectedInfo); 
    }
    // if (this.selectedInfo == null || this.selectedInfo == undefined || this.selectedInfo == '') {
    //       if (this.queryParams != undefined) {
    //         this.isEditMode = true;
    //         (await this.welderQualificationService.getWelderQualificationDetail(this.queryParams)).subscribe((apiResponse: any) => {
    //           const jExtract = utility.extractJsonData(apiResponse, 0);
    //           this.selectedInfo = jExtract[0];
    //           this.welderQualificationService.setSelectedInfo(this.selectedInfo);
    //           this.initializeForm(this.selectedInfo);
    //         });
    //       } else {
    //         this.isEditMode = false;
    //       }
    // } 
  }

  async populateResourceList() {
    (await this.welderQualificationService.getAllActiveResourceList(this.loggedInUser.logon_id,this.loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.ResourceList = utility.extractJsonData(apiResponse, 0);
      if (this.ResourceList != null && this.ResourceList.length > 0) {
        this.filteredResourceList = [...this.ResourceList];        
      }
    });
  }

  async populateProcessList() {
    (await this.welderQualificationService.getActiveProcessList()).subscribe((apiResponse: any) => {
      this.processList = utility.extractJsonData(apiResponse, 0);
    });
  }

  async populateDisciplineList() {
    (await this.welderQualificationService.getDisciplineList()).subscribe((apiResponse: any) => {
      this.disciplineList = utility.extractJsonData(apiResponse, 0);
    });
  }

  async populateProcedureList() {
    (await this.welderQualificationService.getActiveWeldProcedureList()).subscribe((apiResponse: any) => {
      this.procedureList = utility.extractJsonData(apiResponse, 0);
    });
  }

  async initializeForm(wpInfo: any) {
    if (wpInfo != null) {
      this.welderQualiForm = new FormGroup({
        weld_procedure_id: new FormControl(wpInfo?.weld_procedure_id),
        welder_qual_id: new FormControl(wpInfo?.welder_qual_id),
        resource_id: new FormControl({ value: wpInfo?.resource_id, disabled: true }, [Validators.required]),
        qualification_code: new FormControl({value:wpInfo?.qualification_code, disabled:true}, [Validators.required]),
        process_id: new FormControl(wpInfo?.process_id, [Validators.required]),
        disc_id: new FormControl(wpInfo?.disc_id),
        posn: new FormControl(wpInfo?.posn),
        wtns: new FormControl(wpInfo?.wtns),
        weld_cd: new FormControl(wpInfo?.weld_cd),
      //  weld_procedure_title: new FormControl(wpInfo?.weld_procedure_title, [Validators.required]),
        certi_no: new FormControl(wpInfo?.certi_no),
        first_qual_date: new FormControl(wpInfo?.first_qual_date),
        end_date: new FormControl(wpInfo?.end_date),
        cyc: new FormControl(wpInfo?.cyc, [Validators.required]),
        qual_type_code: new FormControl(wpInfo?.qual_type_code, [Validators.required]),
        prod_weld_req: new FormControl(wpInfo?.prod_weld_req),
        no_of_prod_weld: new FormControl(wpInfo?.no_of_prod_weld),
        thickness_start_rng: new FormControl(wpInfo?.thickness_start_rng),
        thickness_end_rng: new FormControl(wpInfo?.thickness_end_rng),
        diameter_start_rng: new FormControl(wpInfo?.diameter_start_rng),
        diameter_end_rng: new FormControl(wpInfo?.diameter_end_rng),
        site_id: new FormControl(wpInfo?.site_id),
        is_active: new FormControl({ value: wpInfo?.is_active, disabled: wpInfo?.is_active }),
        requester_id: new FormControl('')
    })
  }
    else {
      this.welderQualiForm = new FormGroup({
        weld_procedure_id: new FormControl(null),
        welder_qual_id: new FormControl(null),
        resource_id: new FormControl({value: this.selectedResType, disabled: true}, [Validators.required]),
        qualification_code: new FormControl('', [Validators.required]),
        process_id: new FormControl('', [Validators.required]),
        disc_id: new FormControl(null),
        posn: new FormControl(null),
        wtns: new FormControl(null),
        weld_cd: new FormControl(null),
      //  weld_procedure_title: new FormControl('', [Validators.required]),
        certi_no: new FormControl(null),
        first_qual_date: new FormControl(null),
        end_date: new FormControl(null),
        cyc: new FormControl(null, [Validators.required]),
        qual_type_code: new FormControl(null),
        prod_weld_req: new FormControl(2),
        no_of_prod_weld: new FormControl(null),
        thickness_start_rng: new FormControl(null),
        thickness_end_rng: new FormControl(null),
        diameter_start_rng: new FormControl(null),
        diameter_end_rng: new FormControl(null),
        site_id: new FormControl(null),
        is_active: new FormControl({ value: true, disabled: true }),
        requester_id: new FormControl('')
      })
    }
  }

  save(){
    this.welderQualiForm.markAllAsTouched();
    const isFormValid = this.welderQualiForm.valid;
    this.updateFormControls();
    const formDataVal = this.welderQualiForm.getRawValue();
    formDataVal.qualification_code = formDataVal.qualification_code ? formDataVal.qualification_code.toUpperCase() : null;
    if (!isFormValid) {
      return;
    }
    
    if (this.welderQualiForm.value['welder_qual_id'] === "" 
        || this.welderQualiForm.value['welder_qual_id'] === null 
        || this.welderQualiForm.value['welder_qual_id'] === undefined) {
          this.createWelderQualification(formDataVal);
    }
    else {
      this.updateWelderQualification(formDataVal);
    }
  }

  async createWelderQualification(formDataReq: any) {
     (await this.welderQualificationService.createWelderQualification(formDataReq)).subscribe((apiResponse: any) => {
       utility.responseAlert(apiResponse);
       this.backtoList();
     });
   }
   async updateWelderQualification(formDataReq: any) {
     (await this.welderQualificationService.updateWelderQualification(formDataReq)).subscribe((apiResponse: any) => {
       utility.responseAlert(apiResponse);
       this.backtoList();
     });
   }

  updateFormControls() {
    this.welderQualiForm.controls['requester_id'].setValue(this.loggedInUser.logon_id);
    this.welderQualiForm.controls['site_id'].setValue(this.loggedInUser.site_id);
   // this.resourceForm.controls['is_active'].setValue(this.loggedInUser.is_active);
  }

  backtoList() {
    this.router.navigate(['./welderqualificationlist']);
  }

  step = signal(0);
  
  setStep(index: number) {
    this.step.set(index);
  }

  addMappingItem(event: MouseEvent){
    event.stopPropagation();
    this.openDialog(true);
  }

  async deleteMappingItem(event: MouseEvent){
    event.stopPropagation();
    if (!this.selectedProjectInfo || this.selectedProjectInfo.length <= 0) {
      alert("Please select Project/s to remove Mapping !!");
      return;
    }

    if (!confirm("Are you sure to remove mapping of selected Project/s?")) {
      return;
    }

    var param = {
      //weld_qual_project_map_list: this.selectedProjectInfo      
      weld_qual_project_map_list: this.selectedProjectInfo.map(a => ({weld_qual_project_map_id: a.weld_qual_project_map_id}))
    };

    (await this.welderqualificationToProjectService.DeleteMapping(param)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.getMappedProjectList();
    });
  }

  async onSelectionChanged(e: any){
    this.selectedProjectInfo = e.selectedRowsData;    
    this.welderqualificationToProjectService.setSelectedInfo(e.selectedRowsData);

    //Taking latest selected project Key
    // this.currentProjectKey = e.currentSelectedRowKeys[0];
    
    
    // if(this.currentProjectKey != null && this.currentProjectKey != undefined){  
    //   await this.getAdditionalQualificationDetail(this.currentProjectKey);
    // }else if( e.selectedRowKeys != null && e.selectedRowKeys != undefined && e.selectedRowKeys.length > 0){             
    //   this.currentProjectKey = e.selectedRowKeys[e.selectedRowKeys.length - 1];
    //   await this.getAdditionalQualificationDetail(this.currentProjectKey);
    // }
    // else{
    //   this.currentProjectKey = null; 
    //   this.AdditionalQualModelList= null;     
    // }

    if(e.selectedRowsData && e.selectedRowsData.length > 0){
      const lastSelectedProject = e.selectedRowsData[e.selectedRowsData.length - 1];
      this.currentProjectKey = lastSelectedProject?.project_id;
      this.currentProjectCode = lastSelectedProject?.project_code;
      await this.getAdditionalQualificationDetail(this.currentProjectKey);
    }else{
      this.currentProjectKey = null; 
      this.currentProjectCode = null;
      this.AdditionalQualModelList= null;
    }

  }

  openDialog(isNew: boolean): void {
      const dialogRef = this.matDialog.open(AddQualificationToProjectComponent, {             
        autoFocus: false,
        disableClose: true,
        data: {
          screenType: 2,
          welder_qual_id: this.queryParams,
          availableItems: this.mappedProjectItems
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        this.getMappedProjectList();
      });
  }

  async getMappedProjectList() {
      if (this.queryParams != undefined) {
        this.welderqualificationToProjectService.getAll(this.queryParams).subscribe(data =>{
          this.QualificationProjectModelList = utility.extractJsonData(data, 0);
          if (this.QualificationProjectModelList) {
            this.mappedProjectItems = this.QualificationProjectModelList.map(item => item.project_id);
          } else {
            this.mappedProjectItems;
          }
        });
      }
    }

///////Additional Qual.----------------------------------------------------------------------------------------------------

    InitializeAdditionalQualForm(aqInfo: any){

      if(aqInfo != null){

        const formattedexpiryDate = this.datePipe.transform(aqInfo?.cert_expiry_date,'MM/dd/yyyy');
        
        this.addiQualForm = new FormGroup({
          adqual_mapping_id: new FormControl(aqInfo?.adqual_mapping_id),
          certificate_no: new FormControl(aqInfo?.certificate_no,[Validators.required]),
          cyc: new FormControl(aqInfo?.cyc,{updateOn: 'blur'}),
          certification_date: new FormControl(aqInfo?.certification_date,{updateOn: 'blur'}),
          cert_expiry_date: new FormControl({ value: formattedexpiryDate, disabled:true}),
          remarks: new FormControl(aqInfo?.remarks)
        })
      }else{
        this.addiQualForm = new FormGroup({
          adqual_mapping_id: new FormControl(null),
          certificate_no: new FormControl(null,{validators:Validators.required}),
          cyc: new FormControl(null,{updateOn: 'blur'}),
          certification_date: new FormControl(null),
          cert_expiry_date: new FormControl({value: null, disabled:true},{updateOn: 'blur'}),
          remarks: new FormControl(null),
        })
      } 
      
      this.certExpiryDateTrack()

    }

    async getAdditionalQualificationDetail(projectKey: any){
      (await this.weldAddnlQualificationService.getAdditionalQual(projectKey,this.queryParams)).subscribe((apiResponse: any) => {
        var extractData = utility.extractJsonData(apiResponse, 0);
        this.AdditionalQualModelList = extractData;
        
        if(extractData != null && extractData != undefined && extractData.length > 0)
          this.addiQualList = extractData[0];
        else
          this.addiQualList = null;
          
        this.InitializeAdditionalQualForm(this.addiQualList);
      });
    }

    async certExpiryDateTrack(){      
      this.addiQualForm.get('cyc')?.valueChanges.subscribe(() => this.changeExpirydate());
      this.addiQualForm.get('certification_date')?.valueChanges.subscribe(() => this.changeExpirydate());      
    }

    changeExpirydate(){      
      const certDate = this.addiQualForm.get('certification_date')?.value;
      const cyc = this.addiQualForm.get('cyc')?.value;

      if(certDate && cyc){
        const tempDate = new Date(certDate);
        tempDate.setMonth(tempDate.getMonth() + parseInt(cyc,10))

        const revisedExpiryDate = this.datePipe.transform(tempDate,'MM/dd/yyyy');
        this.addiQualForm.patchValue({cert_expiry_date: revisedExpiryDate});

      }else{
        this.addiQualForm.patchValue({cert_expiry_date: null});
      }

    }

    saveAdtQual(){
      if(this.currentProjectKey == null || this.currentProjectKey == undefined){
        alert("Please select Project to add certification !!");
        return;
      }
      
      this.addiQualForm.markAllAsTouched();
      const isFormValid = this.addiQualForm.valid;    
    
      const data = this.addiQualForm.getRawValue();
      if(data.cert_expiry_date != null)
        data.cert_expiry_date = new Date(data.cert_expiry_date);

      data.project_id = this.currentProjectKey;
      data.welder_qual_id = this.queryParams;

      if (!isFormValid) {
        return;
      }
      if (this.addiQualForm.value['adqual_mapping_id'] == null || this.addiQualForm.value['adqual_mapping_id'] == '' || this.addiQualForm.value['adqual_mapping_id'] == undefined) {
        this.createAdtQualItem(data);
      }else{
        this.updateAdtQualItem(data);
      }

    }

    async createAdtQualItem(formDataReq: any){      
      (await this.weldAddnlQualificationService.Post(formDataReq)).subscribe((apiResponse: any) => {
        utility.responseAlert(apiResponse);  
        if(apiResponse != null && apiResponse.response_error_id == "0"){
          this.getAdditionalQualificationDetail(this.currentProjectKey);
        }

        
      });
    }

    async updateAdtQualItem(formDataReq: any){
      //const data = this.addiQualForm.getRawValue();

      (await this.weldAddnlQualificationService.Put(formDataReq)).subscribe((apiResponse: any) => {
        utility.responseAlert(apiResponse);  
        if(apiResponse != null && apiResponse.response_error_id == "0"){
          this.getAdditionalQualificationDetail(this.currentProjectKey);
        }
        
      });
    }

    async deleteAdtQual(){
      if (this.addiQualForm.value['adqual_mapping_id'] == null || this.addiQualForm.value['adqual_mapping_id'] == '' || this.addiQualForm.value['adqual_mapping_id'] == undefined) {
        alert("Not any valid certification exist to delete !!!");
        return;
      }

      const formDataReq = this.addiQualForm.getRawValue();
      
      var param = {
        //weld_qual_project_map_list: this.selectedProjectInfo      
        adqual_mapping_id: formDataReq.adqual_mapping_id
      };
  

      (await this.weldAddnlQualificationService.Delete(param)).subscribe((apiResponse: any) => {
        utility.responseAlert(apiResponse);  
        if(apiResponse != null && apiResponse.response_error_id == "0"){
          this.InitializeAdditionalQualForm(null);
        }

      });
    }

    onAdditionalQualSelectionChanged(e: any){
      this.selectedAdditionalQualItems = e.selectedRowsData;
    }

    addAdditionalQual(e : any){
      e.stopPropagation();
      if(!this.currentProjectKey){
        alert("Please select project to add Additional Qualification !!!");
        return;
      }      
      this.selectedAdditionalQualItems = null;
      this.openAdditionalQualDialog(true);
        e.event?.preventDefault();
    }
  
    async deleteAdditionalQual(e: any){
        e.stopPropagation();

        if (!this.selectedAdditionalQualItems || this.selectedAdditionalQualItems.length <=0) {
          alert("Please select Additional qualificaiton/s to delete !!");
          return;
        }
    
        if (!confirm("Are you sure to delete record?")) {
          return;
        }

        var param = {        
          adqual_mapping_id: this.selectedAdditionalQualItems[0].adqual_mapping_id
        };


        (await this.weldAddnlQualificationService.Delete(param)).subscribe((apiResponse: any) => {
          utility.responseAlert(apiResponse);  
          if(apiResponse != null && apiResponse.response_error_id == "0"){
            this.getAdditionalQualificationDetail(this.currentProjectKey);
          }
        });    
    }

    routeToAdditionalQualDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
      this.selectedAdditionalQualItems = e.row?.data;
      this.openAdditionalQualDialog(false);
      e.event?.preventDefault();
    };

    openAdditionalQualDialog(isNew: boolean): void {
        const dialogRef = this.matDialog.open(AddEditAdditionalqualComponent, { 
          width: '475px',
          height: '450px',
          maxHeight: '600px',         
          autoFocus: false,
          disableClose: true,
          data: {
            screenType: 2,
            project_id: this.currentProjectKey,
            welder_qual_id: this.queryParams,            
            availableItems: this.selectedAdditionalQualItems
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          this.getAdditionalQualificationDetail(this.currentProjectKey);
        });
    }

}
