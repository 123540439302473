import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DrawingService } from '../../../../APIServices/WeldNDT/drawing/DrawingService';
import { utility } from '../../../../util';
import { Router } from '@angular/router';
import { MaterialModule } from '../../../../core/material-module';
import { FileUploadComponent } from '../../../../home/file-upload/file-upload.component';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { InspectorService } from '../../../../APIServices/WeldNDT/inspector/InspectorService';

@Component({
  selector: 'app-drawing-submit',
  standalone: true,
  imports: [MaterialModule, ReactiveFormsModule, FileUploadComponent, CommonModule],
  templateUrl: './drawing-submit.component.html',
  styleUrl: './drawing-submit.component.scss'
})
export class DrawingSubmitComponent implements OnInit {
  dsForm: FormGroup;
  selectedInfo: any;
  inspList: any[] = [];
  loggedInUser: any;
  ftype: string = 'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf'
  constructor(
    private drawingService: DrawingService, 
    private router: Router, 
    private dialogRef: MatDialogRef<DrawingSubmitComponent>,
    private inspectorService: InspectorService) {
    this.initializeForm(null);
  }

  async ngOnInit() {
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    await this.index();
    await this.populateInspectorList();
    // await this.populateCommonMasters();
  }
  async index() {
    await this.drawingService.getSelectedInfo.subscribe(x =>
      this.selectedInfo = x);

    if (this.selectedInfo != null) {
      this.initializeForm(this.selectedInfo)
    }
  }


  onFileSelect(selectedFile: File | null): void {
    //this.imageData = base64Image;
    console.log('selectedFile', selectedFile);
  }
  closeDialog(status:any): void {
    this.dialogRef.close(status);
  }

  async populateInspectorList() {
    (await this.inspectorService.getAllInspectorList()).subscribe((apiResponse: any) => {
      this.inspList = utility.extractJsonData(apiResponse, 0);
    });
  }

  save() {
    this.dsForm.markAllAsTouched();
    const isFormValid = this.dsForm.valid;
    if (!isFormValid) {
      return;
    }
    console.log(this.dsForm.getRawValue());
    this.updateItem();
  }
  async updateItem() {
    (await this.drawingService.updateDrawing(this.dsForm.getRawValue())).subscribe((apiResponse: any) => {
      var iRes = utility.responseAlert(apiResponse);
      if (iRes) this.closeDialog(iRes);
    });
  }

  async initializeForm(uInfo: any) {
    this.dsForm = new FormGroup({
      drawing_id: new FormControl(uInfo?.drawing_id),
      site_id: new FormControl(uInfo?.site_id),
      project_id: new FormControl(uInfo?.project_id),
      job_id: new FormControl(uInfo?.job_id),
      item_id: new FormControl(uInfo?.item_id),
      drawing_file: new FormControl(null, [Validators.required]),
      jrm_inspector: new FormControl(null, [Validators.required]),
      requester_id: new FormControl(),
      update_type: new FormControl('SUBMIT')
    });
  }

}
