
    @if(imageOrDoc=='I'){
    <span style="display: flex; gap: 20px; margin-bottom:10px">
      <button class="uploadIconBtn" mat-icon-button (click)="fileInput.click()">
          <mat-icon>upload</mat-icon>
      </button>
      @if(imagePreview){
        <span>
          <div class="img_box">
            <img [src]="imagePreview" alt="Image Preview" class="img-zoom" style=" width: 95px; height: 65px; max-width: 95px; margin-top: 0px;" />
          </div>
          
        </span>
        <span class="delete_span">
          <button matTooltip="Remove Image" class="deleteIconBtn" mat-icon-button (click)="removeImage()">
            <mat-icon class="delete_icon">delete</mat-icon>
          </button>
        </span> 
        
      }
      
    </span>
    @if(imageError) {
      <div class="text-danger"> Image size must be less than 1MB</div>
    }
    <input
        type="file"
        #fileInput
        style="display: none;"
        (change)="selectFile($event)"
        name="fileInput"
        [(accept)]="fileType"
        (formControl)="fControlName"
    />
    }
    @else{
      <span style="display: flex; gap: 20px; margin-bottom:10px">
        <button class="uploadIconBtn" mat-icon-button (click)="fileInput.click()">
            <mat-icon>upload</mat-icon>
        </button>
        @if(imagePreview){
          <span>
            <div class="img_box">
              <img [src]="imagePreview" alt="Image Preview" class="img-zoom" style=" width: 95px; height: 65px; max-width: 95px; margin-top: 0px;" />
            </div>
            
          </span>
          <span class="delete_span">
            <button matTooltip="Remove Image" class="deleteIconBtn" mat-icon-button (click)="removeImage()">
              <mat-icon class="delete_icon">delete</mat-icon>
            </button>
          </span> 
          
        }
        
      </span>
      @if(imageError) {
        <div class="text-danger"> Image size must be less than 1MB</div>
      }
      <input
          type="file"
          #fileInput
          style="display: none;"
          (change)="selectDocFile($event)"
          name="fileInput"
          [(accept)]="fileType"
          (formControl)="fControlName"
      />
    }