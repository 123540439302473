import { Component } from '@angular/core';
import { MaterialModule } from '../../../../core/material-module';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DrawingService } from '../../../../APIServices/WeldNDT/drawing/DrawingService';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { utility } from '../../../../util';

@Component({
  selector: 'app-drawing-recall',
  standalone: true,
  imports: [MaterialModule, ReactiveFormsModule, CommonModule],
  templateUrl: './drawing-recall.component.html',
  styleUrl: './drawing-recall.component.scss'
})
export class DrawingRecallComponent {
  drForm: FormGroup;
  selectedInfo: any;
  inspList: any[] = [];
  loggedInUser: any;
  ftype: string = 'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf'
  constructor(
    private drawingService: DrawingService, 
    private router: Router, 
    private dialogRef: MatDialogRef<DrawingRecallComponent>) {
    this.initializeForm(null);
  }

  async ngOnInit() {
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    await this.index();
  }
  async index() {
    await this.drawingService.getSelectedInfo.subscribe(x =>
      this.selectedInfo = x);

    if (this.selectedInfo != null) {
      this.initializeForm(this.selectedInfo)
    }
  }


  closeDialog(status): void {
    this.dialogRef.close(status);
  }

  save() {
    this.drForm.markAllAsTouched();
    const isFormValid = this.drForm.valid;
    if (!isFormValid) {
      return;
    }
    this.updateItem();
  }
  async updateItem() {
    (await this.drawingService.updateDrawing(this.drForm.getRawValue())).subscribe((apiResponse: any) => {
      var iRes = utility.responseAlert(apiResponse);
      if (iRes) this.closeDialog(iRes);
    });
  }

  async initializeForm(uInfo: any) {
    this.drForm = new FormGroup({
      drawing_id: new FormControl(uInfo?.drawing_id),
      site_id: new FormControl(uInfo?.site_id),
      project_id: new FormControl(uInfo?.project_id),
      job_id: new FormControl(uInfo?.job_id),
      item_id: new FormControl(uInfo?.item_id),
      recall_remark: new FormControl(null, [Validators.required]),
      requester_id: new FormControl(),
      update_type: new FormControl('RECALLED')
    });
  }
}
