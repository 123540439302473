import { Component, OnInit } from '@angular/core';
import { MaterialModule } from '../../../core/material-module';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { utility } from '../../../util';
import { ActivatedRoute, Router } from '@angular/router';
import { LookupService } from '../../../APIServices/LookupService';
import { CommonModule } from '@angular/common';
import { TestProcedureService } from '../../../APIServices/testacceptance/TestProcedureService';
import { OnlyAlphanumericDirective } from '../../../directives/only-alphanumeric.directive';

@Component({
  selector: 'app-test-procedure-detail',
  standalone: true,
  imports: [MaterialModule,ReactiveFormsModule, CommonModule,OnlyAlphanumericDirective],
  templateUrl: './test-procedure-detail.component.html',
  styleUrl: './test-procedure-detail.component.scss'
})
export class TestProcedureDetailComponent implements OnInit {
  tForm: FormGroup;
  selectedInfo: any;
  queryParams: any;
  bEditMode: boolean = false;

  lookupDisciplineList: any[] = [];
  lookupTestTypeList: any[] = [];
  loggedInUser: any;

  lookupStatusFilter: any = [    
    {
      valueMenber: "A",
      displayMember: "Active"
    },
    {
      valueMenber: "I",
      displayMember: "Inactive"
    }];
    isFormChanged: boolean = false;
  initialFormValues: any;

  /**
   *
   */
  constructor(private router: Router,
    private activateRoute: ActivatedRoute,
    private lookupService: LookupService,
    private testProcedureService: TestProcedureService ) {  
    
      this.initializeForm(null);  
  }

  async ngOnInit(){
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.populateDisciplineList()
    this.populateTestTypeList();
    await this.Index();

    this.tForm.controls['base_procedure'].valueChanges.subscribe(()=>this.setTestProcedureTitle())
    this.tForm.controls['procedure_rev'].valueChanges.subscribe(()=>this.setTestProcedureTitle())
  }

  async Index(){
    await this.testProcedureService.getSelectedInfo$.subscribe(x =>
      this.selectedInfo = x);

      this.queryParams = this.activateRoute.snapshot.queryParams['ps_id'];
      if (this.queryParams != undefined && this.queryParams != null) {
        this.bEditMode = true;
        
        if (this.selectedInfo == null){
          (await this.testProcedureService.getTestProcedureDetail(this.queryParams)).subscribe((apiResponse: any) => {
            const jExtract = utility.extractJsonData(apiResponse, 0);
            this.selectedInfo = jExtract[0];
            
            this.testProcedureService.setSelectedInfo(this.selectedInfo);
            this.initializeForm(this.selectedInfo);
          });
        }
        else{
          await this.initializeForm(this.selectedInfo);
        }
      }
      else{
        this.bEditMode = false;        
        await this.initializeForm(this.selectedInfo); 
      }   
  }

  private async populateTestTypeList(){
    (await this.testProcedureService.getTestsTypes()).subscribe((apiResponse: any) => {
      this.lookupTestTypeList = utility.extractJsonData(apiResponse, 0);
    });
  }

  private async populateDisciplineList(){
    (await this.testProcedureService.getDiscipline()).subscribe((apiResponse: any) => {
      this.lookupDisciplineList = utility.extractJsonData(apiResponse, 0);
    });
  }
  async setTestProcedureTitle() {
    const base_procedure=this.tForm.get('base_procedure')?.value || '';
    const rev_no=this.tForm.get('procedure_rev')?.value || '';
    const test_procedure= base_procedure ? base_procedure + '-R' + rev_no : '';
    this.tForm.get('test_procedure')?.setValue(test_procedure,{emitEvent:false});
  } 

  initializeForm(tInfo: any){    
    if (tInfo != null){        
      this.tForm = new FormGroup(
        {
          ps_id: new FormControl(tInfo?.ps_id),
          project_id: new FormControl(tInfo?.project_id),
          job_id: new FormControl(tInfo?.job_id),
          job_code: new FormControl({ value: tInfo?.job_code , disabled: true }, Validators.required),
          item_id: new FormControl(tInfo?.item_id),
          item_code: new FormControl({value: tInfo?.item_code, disabled: true },Validators.required),
          disc_id: new FormControl({ value: tInfo?.disc_id, disabled: this.bEditMode },Validators.required),
          test_id: new FormControl({ value: tInfo?.test_id, disabled: this.bEditMode } ,Validators.required),
          base_procedure: new FormControl(tInfo?.base_procedure, Validators.required),          
          test_procedure: new FormControl(tInfo?.test_procedure, Validators.required),
          procedure_rev: new FormControl(tInfo?.procedure_rev , Validators.required),
          status: new FormControl({value: tInfo?.status, disabled: !this.bEditMode}),
          start_dtm: new FormControl(tInfo?.start_dtm),
          end_dtm: new FormControl(tInfo?.end_dtm),
          created_by: new FormControl({value: tInfo?.created_by, disabled: true}),
          deactivated_by: new FormControl({value: tInfo?.deactivated_by, disabled:true }),
          is_active: new FormControl({value: tInfo?.is_active , disabled: tInfo?.is_active })
        },{validators: this.dateRangeValidation}
      );
      this.initialFormValues = this.tForm.getRawValue();
      this.detectFormValueChanges(this.initialFormValues);
    }
    else{
      this.tForm = new FormGroup( 
        {
          ps_id: new FormControl(null),
          job_code: new FormControl({ value: null, disabled: true}, Validators.required),
          item_code: new FormControl({ value: null, disabled: true}, Validators.required),
          disc_id: new FormControl(null,Validators.required),
          test_id: new FormControl(null, Validators.required),
          base_procedure: new FormControl(null, Validators.required),  
          test_procedure: new FormControl(null, Validators.required),
          procedure_rev: new FormControl(null, Validators.required),
          status: new FormControl(null),
          start_dtm: new FormControl(null),
          end_dtm: new FormControl(null),
          created_by: new FormControl({ value: null, disabled: true }),
          deactivated_by: new FormControl({ value: null, disabled: true }),          
          is_active: new FormControl({ value: true, disabled: true })
        },{validators: this.dateRangeValidation}
      );
      this.detectFormValueChanges();
    }

    if(tInfo?.status === "I"){
      this.disableControlsForInactiveStatus();
    }
  }
  
  eventStatusChanged(e: any){

    if(e?.value === "A"){
      this.tForm.controls["deactivated_by"].setValue(null);
      this.enableControlsForActiveStatus()
    }
    else{
      const endDTM =  this.tForm.controls["end_dtm"].value;
      // if(endDTM == null || endDTM == undefined || endDTM == ''){
      //   this.tForm.controls["end_dtm"].setValue(new Date());        
      // }
      //or Fresh value ???
      this.tForm.controls["end_dtm"].setValue(new Date());
      this.tForm.controls["deactivated_by"].setValue(this.loggedInUser?.logon_id);
      this.disableControlsForInactiveStatus()
    }
  }

  disableControlsForInactiveStatus(){    
    this.tForm.disable()
    this.tForm.controls["status"].enable();    
  }
  
  enableControlsForActiveStatus(){    
    this.tForm.controls["base_procedure"].enable({onlySelf: true});
    this.tForm.controls["test_procedure"].enable({onlySelf: true});
    this.tForm.controls["procedure_rev"].enable({onlySelf: true});
    this.tForm.controls["start_dtm"].enable({onlySelf: true});
    this.tForm.controls["end_dtm"].enable({onlySelf: true});    
    this.tForm.controls["status"].enable({onlySelf: true});
  }

  dateRangeValidation: ValidatorFn = (control: AbstractControl) => {
      //console.log('in date range validator fun');
      const form = control as FormGroup;
      const sDate = form.get('start_dtm')?.value;
      const eDate = form.get('end_dtm')?.value;
      const stDateControl = form.get('start_dtm');
      if (sDate && eDate && new Date(sDate) > new Date(eDate)) {
        //console.log('invalid date');
        stDateControl?.setErrors({dateInvalid: true});
        return {dateInvalid: true };
      }
      return null;;
    }

  backtoList(){
    this.router.navigate(['./testprocedurelist']);
  }

  save(){
    this.tForm.markAllAsTouched();
    const isFormValid = this.tForm.valid;
    if (isFormValid) {
      const formData = this.tForm.getRawValue();
      formData.base_procedure = formData.base_procedure ? formData.base_procedure.toUpperCase() : null;
      formData.test_procedure = formData.test_procedure ? formData.test_procedure.toUpperCase() : null;
      if (this.tForm.value['ps_id'] === "" 
        || this.tForm.value['ps_id'] === null 
        || this.tForm.value['ps_id'] === undefined) {
          this.create(formData);
      }
      else {
        this.update(formData);
      }
      this.isFormChanged = false;
    } else {
      return;
    }
  }

  async create(formData:any){

    (await this.testProcedureService.create(formData)).subscribe((apiResponse: any) => {
      if ( utility.responseAlert(apiResponse)){
        this.backtoList();
      }
    });
  }

  async update(formData:any){
    (await this.testProcedureService.update(formData)).subscribe((apiResponse: any) => {
      if (utility.responseAlert(apiResponse)){
        this.backtoList()
      };
    });
  }

  detectFormValueChanges(initialFormValues?: any) {
    this.tForm.valueChanges.subscribe(() => {
      this.isFormChanged = utility.checkIfFormChanged(this.tForm, initialFormValues);
    });
  }

  isSaveDisabled() {
    return this.isFormChanged && this.tForm.valid;
  }

}
