import { Component, OnInit } from '@angular/core';
import { MaterialModule } from '../../../../core/material-module';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { DrawingService } from '../../../../APIServices/WeldNDT/drawing/DrawingService';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { utility } from '../../../../util';
import { LookupService } from '../../../../APIServices/LookupService';
import { WeldNDTMasterService } from '../../../../APIServices/master/weldndt/WeldNDTMasterService';
import { MatDialog } from '@angular/material/dialog';
import { DrawingSubmitComponent } from '../drawing-submit/drawing-submit.component';
import { DrawingRecallComponent } from '../drawing-recall/drawing-recall.component';


@Component({
  selector: 'app-drawing-details',
  standalone: true,
  imports: [
    MaterialModule,
    ReactiveFormsModule,
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  templateUrl: './drawing-details.component.html',
  styleUrl: './drawing-details.component.scss'
})
export class DrawingDetailsComponent implements OnInit {
  dForm: FormGroup;
  selectedInfo: any;
  queryParams: any;
  lineClassList: any[] = [];
  discTypeList: any[] = [];
  mtnList: any[] = [];
  markupList: any[] = [];
  loggedInUser: any;
  bEditMode: boolean = false;
  formSubmitted: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private drawingService: DrawingService,
    private matDialog: MatDialog,
    private weldNDTMasterService: WeldNDTMasterService
  ) {
    this.initializeForm(null);
  }

  async ngOnInit() {
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    await this.index();
    await this.populateDisciplineList();
    await this.populateCommonMasters();
  }
  async index() {
    await this.drawingService.getSelectedInfo.subscribe(x =>
      this.selectedInfo = x);

    if (this.selectedInfo == null) {
      this.queryParams = this.route.snapshot.queryParams['drawing_id'];

      if (this.queryParams != undefined) {
        this.bEditMode = true;

        (await this.drawingService.getSelectedDrawing(this.queryParams)).subscribe((apiResponse: any) => {
          const jExtract = utility.extractJsonData(apiResponse, 0);
          this.selectedInfo = jExtract[0];
          this.drawingService.setSelectedInfo(this.selectedInfo);
          this.populateLineClassList(this.selectedInfo.project_id);
          this.initializeForm(this.selectedInfo);

          if (this.selectedInfo.jrm_inspector != null && this.selectedInfo.jrm_inspector != undefined) {
            this.dForm.disable();
            this.formSubmitted = true;
          }
        });
      }
      else {
        this.bEditMode = false;
        if (this.selectedInfo == null) {
          var fltrData = utility.getSelectedFilter();
          this.selectedInfo = fltrData;
          this.initializeForm(this.selectedInfo);
        }
        this.populateLineClassList(this.selectedInfo.project_id);
      }
    }
    else {
      this.bEditMode = true;
      this.populateLineClassList(this.selectedInfo.project_id);
      await this.initializeForm(this.selectedInfo);
      if (this.selectedInfo.jrm_inspector != null && this.selectedInfo.jrm_inspector != undefined) {
        this.dForm.disable();
        this.formSubmitted = true;
      }
      console.log(this.bEditMode);
    }

  }
  async populateDisciplineList() {
    (await this.drawingService.getDisciplineList()).subscribe((apiResponse: any) => {
      this.discTypeList = utility.extractJsonData(apiResponse, 0);
    });
  }
  async populateLineClassList(project_id) {
    (await this.drawingService.getAllLineClassList(project_id)).subscribe((apiResponse: any) => {
      this.lineClassList = utility.extractJsonData(apiResponse, 0);
    });
  }
  async populateCommonMasters() {
    var cMstrTypeId = 'MTNCOMP,MKUPREV';
    (await this.weldNDTMasterService.getCommonMasterData(cMstrTypeId)).subscribe((apiResponse: any) => {
      var cList = utility.extractJsonData(apiResponse, 0);
      if (cList != null) {
        //set value for MTN Completed list
        this.mtnList = cList.filter((v) =>
          v.master_type_code === 'MTNCOMP'
        );

        //set value for MarkUp Received list
        this.markupList = cList.filter((v) =>
          v.master_type_code === 'MKUPREV'
        );
      }
    });
  }
  async initializeForm(uInfo: any) {
    if (uInfo != null && (uInfo.drawing_id != null || uInfo.drawing_id != undefined)) {
      this.dForm = new FormGroup({
        drawing_id: new FormControl(uInfo?.drawing_id),
        site_id: new FormControl(uInfo?.site_id),
        project_id: new FormControl(uInfo?.project_id),
        job_id: new FormControl(uInfo?.job_id),
        item_id: new FormControl(uInfo?.item_id),
        mcd_drawing_code: new FormControl({ value: uInfo?.mcd_drawing_code, disabled: true },
          [Validators.required]),
        mcd_drawing_desc: new FormControl(uInfo?.mcd_drawing_desc),
        mcd_drawing_revision: new FormControl(uInfo?.mcd_drawing_revision),
        mcd_date: new FormControl(uInfo?.mcd_date),
        lineclass_id: new FormControl(uInfo?.lineclass_id),
        ndt_percent: new FormControl(uInfo?.ndt_percent),
        client_drawing_code: new FormControl(uInfo?.client_drawing_code),
        client_drawing_desc: new FormControl(uInfo?.mcd_drawing_desc),
        client_drawing_revision: new FormControl(uInfo?.mcd_drawing_revision),
        client_date: new FormControl(uInfo?.client_date),
        line: new FormControl(uInfo?.line),
        line_desc: new FormControl(uInfo?.line_desc),
        type: new FormControl(uInfo?.type),
        mtn_completed: new FormControl(uInfo?.mtn_completed),
        markup_received: new FormControl(uInfo?.markup_received),
        hydrotest: new FormControl(uInfo?.hydrotest),
        jrm_inspector_name: new FormControl({ value: uInfo?.jrm_inspector_name, disabled: true }),
        j_insp_sbmt_date: new FormControl({ value: uInfo?.j_insp_sbmt_date, disabled: true }),
        butts: new FormControl(uInfo?.butts),
        cleared_date: new FormControl({ value: uInfo?.cleared_date, disabled: true }),
        client_sbmt_date: new FormControl({ value: uInfo?.client_sbmt_date, disabled: true }),
        last_weldno: new FormControl(uInfo?.last_weldno),
        recalled_by: new FormControl({ value: uInfo?.recalled_by, disabled: true }),
        recalled_date: new FormControl({ value: uInfo?.recalled_date, disabled: true }),
        recall_remark: new FormControl({ value: uInfo?.recall_remark, disabled: true }),
        approved_date: new FormControl({ value: uInfo?.approved_date, disabled: true }),
        narrative: new FormControl(uInfo?.narrative),
        is_active: new FormControl({ value: uInfo?.is_active, disabled: uInfo?.is_active }),
        requester_id: new FormControl('')
      });
    }
    else {
      this.dForm = new FormGroup({
        drawing_id: new FormControl(null),
        site_id: new FormControl(uInfo?.site_id),
        project_id: new FormControl(uInfo?.project_id),
        job_id: new FormControl(uInfo?.job_id),
        item_id: new FormControl(uInfo?.item_id),
        mcd_drawing_code: new FormControl(null, [Validators.required]),
        mcd_drawing_desc: new FormControl(null),
        mcd_drawing_revision: new FormControl(null),
        mcd_date: new FormControl(null),
        lineclass_id: new FormControl(null),
        ndt_percent: new FormControl(null),
        client_drawing_code: new FormControl(null),
        client_drawing_desc: new FormControl(null),
        client_drawing_revision: new FormControl(null),
        client_date: new FormControl(null),
        line: new FormControl(null),
        line_desc: new FormControl(null),
        type: new FormControl(null),
        mtn_completed: new FormControl(null),
        markup_received: new FormControl(null),
        hydrotest: new FormControl(null),
        jrm_inspector_name: new FormControl({ value: null, disabled: true }),
        j_insp_sbmt_date: new FormControl({ value: null, disabled: true }),
        butts: new FormControl(null),
        cleared_date: new FormControl({ value: null, disabled: true }),
        client_sbmt_date: new FormControl({ value: null, disabled: true }),
        last_weldno: new FormControl(null),
        recalled_date: new FormControl({ value: null, disabled: true }),
        recalled_by: new FormControl({ value: null, disabled: true }),
        recall_remark: new FormControl({ value: null, disabled: true }),
        approved_date: new FormControl({ value: null, disabled: true }),
        narrative: new FormControl(null),
        is_active: new FormControl({ value: true, disabled: uInfo?.is_active }),
        requester_id: new FormControl('')
      });
    }
  }
  save() {
    this.dForm.markAllAsTouched();
    const isFormValid = this.dForm.valid;
    if (!isFormValid) {
      return;
    }
    if (this.dForm.value['drawing_id'] == null) {
      this.createItem();
    } else {
      this.updateItem();
    }
  }
  async createItem() {
    console.log(this.dForm.getRawValue());
    (await this.drawingService.createDrawing(this.dForm.getRawValue())).subscribe((apiResponse: any) => {
      var iRes = utility.responseAlert(apiResponse);
      if (iRes) this.backtoList();
    });
  }
  async updateItem() {
    (await this.drawingService.updateDrawing(this.dForm.getRawValue())).subscribe((apiResponse: any) => {
      var iRes = utility.responseAlert(apiResponse);
      if (iRes) this.backtoList();
    });
  }
  backtoList() {
    this.router.navigate(['./drawinglist']);
  }

  openDialog(): void {
    const dialogRef = this.matDialog.open(DrawingSubmitComponent, {
      width: '400px',
      height: '250px',
      maxHeight: '600px',
      autoFocus: false,
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.getGroupAccessList(this.selectedgInfo[0].group_id);
      //this.getGroupAccessList(this.selectedGroup);
      if (result) {
        this.dForm.disable();
        this.formSubmitted = true;
      }
      console.log('Dialog Closed', result);
    });
  }
  openRecallDialog(): void {
    if (!confirm("Are you sure you want to recall this package?")) {
      return;
    }

    const dialogRef = this.matDialog.open(DrawingRecallComponent, {
      width: '400px',
      height: '250px',
      maxHeight: '600px',
      autoFocus: false,
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dForm.enable();
        this.dForm.controls['jrm_inspector_name'].disable();
        this.dForm.controls['j_insp_sbmt_date'].disable();
        this.dForm.controls['cleared_date'].disable();
        this.dForm.controls['client_sbmt_date'].disable();
        this.dForm.controls['recalled_date'].disable();
        this.dForm.controls['approved_date'].disable();
        this.formSubmitted = false;
      }
    });
  }
}
