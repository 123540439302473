<div class="tileView_conatiner">
    <div class="tileView_content">
        <div class="main_tile">
            <dx-tile-view
            #tileView
            [height]="130"
            [baseItemHeight]="100"
            [baseItemWidth]="100"
            width="100%"
            [itemMargin]="4"
            direction="horizontal"
            class="tileViewCls"
            orientation="horizontal"
            >
                @for (item of authmenu; track $index;) {
                    <dxi-item class="dx_item" (click)="onitemClick(item)"
                   [ngClass]="item === selectedTile ? 'selected-tile' : 'not-selected-tile'">
                        <div class="tile_data">
                            <mat-icon class="tile_icon">{{item.icon_name}}</mat-icon>
                            <p class="item_name">{{ item.menu_name }}</p>
                        </div>
                    </dxi-item>
                } 
                
            </dx-tile-view>
           <!--  <ng-template #tileTemplate let-item="item">
                <div class="tile"
                 [ngClass]="{'selected-tile': item === selectedTile}">
                </div>
            </ng-template> -->
        </div>
<mat-divider class="tileView_divider"></mat-divider>
        <div class="main_tile_sm">
            @if (subMenuList) {
                <!-- <mat-card>
                    <mat-card-content>
                        <mat-list role="list">
                            @for (item of subMenuList; track $index) {
                                <mat-list-item role="listitem" (click)="routeToPage(item.route)">{{item.menu_name}}</mat-list-item>
                            }
                        </mat-list>
                    </mat-card-content>
                </mat-card> -->
                <!-- <div @expandContractMenu>
                    <mat-list role="list" class="list_cls">
                        @for (item of subMenuList; track $index) {
                            <mat-list-item role="listitem" class="list_item" (click)="routeToPage(item.route)">{{item.menu_name}}</mat-list-item>
                        }
                    </mat-list>
                </div> -->
                <dx-tile-view
                    #smallTileView
                    [height]="230"
                    [baseItemHeight]="65"
                    [baseItemWidth]="100"
                    width="100%"
                    [itemMargin]="10"
                    direction="vertical"
                    class="tileViewCls_sm"
                    >
                @for (item of subMenuList; track $index) {
                    <dxi-item class="dx-tile_sm" (click)="routeToPage(item.route)">
                        <div class="">
                            <mat-icon class="tile_icon_sm">{{item.icon_name}}</mat-icon>
                            <p class="item_name_sm">{{ item.menu_name }}</p>
                        </div>
                    </dxi-item>
                } 
                
            </dx-tile-view>
                
            }
        </div>
        <div class="options" hidden>
        <div class="caption">Options</div>
        <div class="option">
            <dx-select-box
            [items]="['horizontal', 'vertical']"
            [inputAttr]="{ 'aria-label': 'Direction' }"
            [(value)]="tileView.direction"
            >
            </dx-select-box>
        </div>
        </div>
    </div>
</div>