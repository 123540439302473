<app-toolbar-buttons *ngIf=toolbarItems [items]="toolbarItems" [title]="title"></app-toolbar-buttons>
<div class="cls_drpdwn flex-container">
    <div>
        <app-searchable-dropdown *ngIf="ResourceList" [options]="ResourceList" [valueKey]="'resource_id'" [displayKey]="'resource_code'" [displayKey1]="'name'" [label]="'Select Resource'" (selectionChange)="onItemSelect($event)"> </app-searchable-dropdown>
    </div>
</div>
<mat-divider class="divider_cls"></mat-divider>
<div class="centered-outer-div">
    <dx-data-grid id="gridSiteList" [dataSource]="WelderQualificationModelList" keyExpr="welder_qual_id" [rowAlternationEnabled]="true"
        class="centered-inner-div" [showBorders]="true" [showColumnLines]="true" [showRowLines]="true"
        (onSelectionChanged)="onSelectionChanged($event)"
        (onRowPrepared)="onRowPrepared($event)">
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>

        <dxi-column type="buttons" [width]="50">
            <dxi-button hint="Edit selected Welder Qualification" icon="edit" [visible]=true [disabled]=false
                [onClick]="routeToDetails"></dxi-button>
        </dxi-column>

        <dxi-column dataField="welder_qual_id" [width]="50" caption="Welder Qualification Id" [visible]="false"></dxi-column>
        <dxi-column dataField="qualification_code" [width]="150" caption="Qualification Code" sortOrder="asc"></dxi-column>
        <dxi-column dataField="process_group_code" [width]="110" caption="Process Group"></dxi-column>
        <dxi-column dataField="process_code" [width]="70" caption="Process"></dxi-column>
        <dxi-column dataField="disc_title" [width]="80" caption="Discipline"></dxi-column>
        <dxi-column dataField="posn" [width]="80" caption="Position"></dxi-column> 
        <dxi-column dataField="wtns" [width]="80" caption="Witness"></dxi-column> 
        <dxi-column dataField="weld_cd" [width]="80" caption="Weld CD"></dxi-column> 
        <dxi-column dataField="weld_procedure_title" [width]="120" caption="Weld Procedure"></dxi-column> 
        <dxi-column dataField="certi_no" [width]="120" caption="Certificate No."></dxi-column> 
        <dxi-column dataField="first_qual_date" [width]="100" caption="First Qual Date" dataType="date" format="MM/dd/yyyy"></dxi-column> 
        <dxi-column dataField="end_date" [width]="80" caption="End Date" dataType="date" format="MM/dd/yyyy"></dxi-column> 
        <dxi-column dataField="cyc" [width]="70" caption="Cycle"></dxi-column> 
        <dxi-column dataField="is_active" [width]="70" caption="Is Active"></dxi-column>

        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 50, 'all']"
        [showNavigationButtons]="true" [visible]="true">
    </dxo-pager>
    <dxo-paging [pageSize]="10" [pageIndex]="0">
    </dxo-paging>
    </dx-data-grid>
</div>