<app-toolbar-buttons [items]="toolbarItems" [title]="title"></app-toolbar-buttons>
<div class="cls_drpdwn flex-container">
    <div>
        <app-searchable-dropdown *ngIf="pMaterialTypeList" [options]="pMaterialTypeList" [valueKey]="'material_type_id'" [displayKey] ="'material_type_code'" [displayKey1]="'material_type_title'" [label]="'Select Material Type'" [selectedValue]="selectedMaterialType" (selectionChange)="onItemSelect($event)"> </app-searchable-dropdown>
    </div>
</div>
<mat-divider class="divider_cls"></mat-divider>
<div class="centered-outer-div">
    <dx-data-grid id="gridSiteList" [dataSource]="materialGradeModelList" keyExpr="material_grade_id" [rowAlternationEnabled]="true"
        class="centered-inner-div" [showBorders]="true" [showColumnLines]="true" [showRowLines]="true"
        (onSelectionChanged)="onSelectionChanged($event)"
        >
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>

        <dxi-column type="buttons" [width]="50">
            <dxi-button hint="Edit selected Material Grade" icon="edit" [visible]=true [disabled]=false
                [onClick]="routeToDetails"></dxi-button>
        </dxi-column>

        <dxi-column dataField="material_grade_id" [width]="50" caption="Material Grade Id" [visible]="false"></dxi-column>
        <dxi-column dataField="material_grade_code" [width]="150" caption="Code" sortOrder="asc"></dxi-column>
        <dxi-column dataField="material_grade_title" [width]="200" caption="Title"></dxi-column>
        <dxi-column dataField="material_type_title" [width]="100" caption="Material Type"></dxi-column> 
        <dxi-column dataField="is_active" [width]="70" caption="Is Active"></dxi-column>
    </dx-data-grid>
</div>