import { Component, Inject, OnInit } from '@angular/core';
import { MaterialModule } from '../../../../core/material-module';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WelderQualificationToProjectService } from '../../../../APIServices/weld/welderQualification/WelderQualificationToProjectService';
import { utility } from '../../../../util';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-add-qualification-to-project',
  standalone: true,
  imports: [MaterialModule, FormsModule, CommonModule],
  templateUrl: './add-qualification-to-project.component.html',
  styleUrl: './add-qualification-to-project.component.scss'
})
export class AddQualificationToProjectComponent implements OnInit {
  dModelList: any;
  selectedInfo: any;
  selectedList: any[] = [];
  fileteredNewList: any[] = [];
  availableItems: any;
  isMultipleSelection: boolean = false;
  qualificationToMap: any[] = []
  _screenType: any;
  welder_qual_id: any;

  /**
   *
   */
  constructor(
    private dialogRef: MatDialogRef<AddQualificationToProjectComponent>,
    private service: WelderQualificationToProjectService,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {    
      this.availableItems = data.availableItems;
      this.welder_qual_id = data.welder_qual_id;
      this._screenType = data.screenType;      
      if(data.screenType === 1)
        this.isMultipleSelection = false;
      else  
        this.isMultipleSelection = true;
  }

  ngOnInit(): void {
    this.service.getSelectedInfo$.subscribe(x => {
            if(utility.isObject(x)) {
              this.selectedInfo = x;
            } 
          });
          this.populateProjectList();
  }

  async populateProjectList(){
    (await this.service.getActiveProjectList()).subscribe((apiResponse: any) => {
      this.dModelList = utility.extractJsonData(apiResponse, 0);
      this.selectedList = this.availableItems;
    });
  }

 async mapItem(){
    //var project_id_list =  this.fileteredNewList.toString();
    
    // if(this.data.screenType === 1){
    //   console.log(this.welder_qual_id);
    // }else{
    //   console.log(this.welder_qual_id);    
    // }

    if(this.fileteredNewList == null || this.fileteredNewList.length <= 0){
      alert("Please select Project/s to Map !!");
      return;
    }

    var param = {
      project_id_list:  this.fileteredNewList.toString(),
      welder_qual_id_list: this.welder_qual_id
    };

    (await this.service.mapProjectToQualification(param)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.populateProjectList();
      this.closeDialog();
    });
  }

  getSelectedValues(event: any) {
    this.selectedList = event.value;    

    if(this.availableItems!=null){
      this.fileteredNewList = this.selectedList.filter(
     (item) => !this.availableItems.includes(item));
    }else{
      this.fileteredNewList=this.selectedList;
    }
  }

  isOptionDisabled(option) {    
    return !this.availableItems?.includes(option);
  }


  closeDialog(): void {
    this.dialogRef.close();
  }

}