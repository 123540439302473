import { Component, computed, effect, Input, input, OnInit, signal } from '@angular/core';
import { MaterialModule } from '../../core/material-module';
import { RouterModule } from '@angular/router';
import { MenuItem } from '../custom-sidenav/custom-sidenav.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { computeMsgId } from '@angular/compiler';

@Component({
  selector: 'app-menu-item',
  standalone: true,
  animations: [
    trigger('expandContractMenu', [
      transition(':enter', [
        style({ opacity: 0, height: '0px' }),
        animate('500ms ease-in-out', style({ opacity: 1, height: '*' }))
      ]),
      transition(':leave', [
        animate('500ms ease-in-out', style({ opacity: 0, height: '0px' }))
      ])
    ])
  ],
  imports: [MaterialModule, RouterModule],
  template: `
            <a 
              mat-list-item
              class="menu-item"
              [routerLink]="item().route"
              (click)="toggleNested(item())"
              routerLinkActive="selected-menu-item"
              #rla="routerLinkActive"
              [activated]="rla.isActive">
                <mat-icon class="side_icon" matListItemIcon>{{item().icon_name}}</mat-icon>
                <span matListItemTitle>{{item().menu_name}}</span>

                @if(item().children) {
                <span matListItemMeta>
                  @if(nestedMenuOpen()) {
                    <mat-icon>expand_less</mat-icon>
                  } @else {
                    <mat-icon>chevron_right</mat-icon>
                  }
                  </span>
                }
            </a>

            @if(item().children && nestedMenuOpen()) {
              <div @expandContractMenu>
                @for (subItem of item().children; track subItem.route) {
                  <app-menu-item [item]="subItem" [snav]="snav"/>
                }
              </div>
            }
  `,
  styles: [`
   
`
  ]
})
export class MenuItemComponent implements OnInit{
  // @Input() item: [] = [];
  @Input() snav;
  item = input.required<MenuItem>();
  nestedMenuOpen = signal(false);
  routeHistory = input('');
  logRoutes = effect(() => console.log("history:", this.routeHistory()));

  ngOnInit() {
    //console.log('item::::', this.item().menu_id);
  }
  // level = computed(() => this.routeHistory().split(''))
  toggleNested(item) {
    let route = item.route; 
    localStorage.setItem('current_menu_id', item.menu_id);
    if(route!=null && route!=undefined && route!='#' && route!=""){
      this.snav.toggle();
    }

    if (!this.item().children) {
      return;
    }
    this.nestedMenuOpen.set(!this.nestedMenuOpen());
  }
}
