import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { utility } from '../../../util';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WeldProcedureService {

  private apiBaseUrl: string;
  private selectedInfo = new BehaviorSubject(null);
  getSelectedInfo = this.selectedInfo.asObservable();
  private environmentConfig = utility.getEnvironmentData();
  requester_id: any;
  site_id: any;
  weldProceApiEndPoint: string = "weldProcedureinfo/"

  constructor(private httpClient: HttpClient, private router: Router) {
      //this.apiBaseUrl = environment.apiBase;
      var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
      this.requester_id = loggedInUser.logon_id
      this.site_id = loggedInUser.site_id      
      this.apiBaseUrl = this.environmentConfig.weldApiBase;
  }

  httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      })
  }

  setSelectedInfo(paramData: any) {
      this.selectedInfo.next(paramData);
  }

  public async getAllWeldProcedure(){
      //this.site_id = "1";
      const params = new HttpParams()
        //.set('requester_id',this.requester_id)
        .set('site_id',this.site_id);
  
      var apiMethod = this.weldProceApiEndPoint + "getall";
      var url = (this.apiBaseUrl +  apiMethod); // + param;
      return this.httpClient.get(url,{params: params});
    }

    public async getSelectedWeldProcedure(weld_procedure_id: any) {
      const params = new HttpParams()
      .set('requester_id',this.requester_id)
      .set('site_id',this.site_id)
      .set('weld_procedure_id',weld_procedure_id);
      var apiMethod = this.weldProceApiEndPoint + "getall";
      var url = (this.apiBaseUrl +  apiMethod); // + param;
      return this.httpClient.get(url,{params: params});
  }

  public async createWeldProcedure(post: any) {
      var apiMethod = this.weldProceApiEndPoint + "create";
      var url = (this.apiBaseUrl +  apiMethod); // + param;
      return this.httpClient.post(url, JSON.stringify(post), this.httpOptions)
      .pipe(
          catchError(this.errorHandler)
      );
  }

  public async updateWeldProcedure(put: any) {
      var apiMethod = this.weldProceApiEndPoint + "update";
      var url = (this.apiBaseUrl + apiMethod)
      return this.httpClient.put(url, JSON.stringify(put), this.httpOptions)
      .pipe(
          catchError(this.errorHandler)
      );
  }

  public async deleteWeldProcedure(del: any) {
      var apiMethod = this.weldProceApiEndPoint + "delete";
      var url = (this.apiBaseUrl + apiMethod);
      return this.httpClient.put(url, JSON.stringify(del), this.httpOptions)
      .pipe(
          catchError(this.errorHandler)
      );
  }

  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
