import { Component,OnInit } from '@angular/core';
import { ToolbarItem } from '../../../../../header/toolbar-buttons/toolbar-item';
import { DevExtremeModule } from '../../../../../core/devExtreme.module';
import { ToolbarButtonsComponent } from '../../../../../header/toolbar-buttons/toolbar-buttons.component';
import { CommonModule } from '@angular/common';
import { MaterialGradeService } from '../../../../../APIServices/master/weld/material-grade.service';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { Router } from '@angular/router';
import { utility } from '../../../../../util';
import { LookupService } from '../../../../../APIServices/LookupService';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../../../../core/material-module';
import { SearchableDropdownComponent } from '../../../../common/searchable-dropdown/searchable-dropdown.component';

@Component({
  selector: 'app-material-grade-list',
  standalone: true,
  imports: [DevExtremeModule, ToolbarButtonsComponent, CommonModule,FormsModule,MaterialModule,SearchableDropdownComponent],
  templateUrl: './material-grade-list.component.html',
  styleUrl: './material-grade-list.component.scss'
})
export class MaterialGradeListComponent implements OnInit {
  materialGradeModelList: any;
  pMaterialTypeList: any[] = [];
  title: any = "Material Grade";
  selectedMaterialType: any;
  selectedInfo: any;
  loggedInUser: any;

  constructor(
    private materialGradeService: MaterialGradeService,
    private lookupService: LookupService,
    private router: Router
  ) {}

  async ngOnInit() {   
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    await this.Index();
  }

  public async Index() {
    await this.populateMaterialType();
    // (await this.materialGradeService.getAllMaterialGrade()).subscribe((apiResponse: any) => {
    //   this.materialGradeModelList = utility.extractJsonData(apiResponse, 0);
    // });
  }


  async populateMaterialType() {
    (await this.lookupService.getMaterialTypeList(this.loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.pMaterialTypeList = utility.extractJsonData(apiResponse, 0);
      this.retrieveFilter();
      if (this.pMaterialTypeList != null && this.pMaterialTypeList.length > 0) {
        this.selectedMaterialType = (this.selectedMaterialType == null || this.selectedMaterialType == undefined) ? this.pMaterialTypeList[0].project_id : this.selectedMaterialType;
        this.filterByMaterialType();
      }
    });
  }

  async filterByMaterialType() {
    (await this.materialGradeService.getAllMaterialGradeList(this.selectedMaterialType)).subscribe((apiResponse: any) => {
      this.materialGradeModelList = utility.extractJsonData(apiResponse, 0);
    });
  }

  onSelectionChanged(e){
    this.selectedInfo = e.selectedRowsData;
    this.materialGradeService.setSelectedInfo(this.selectedInfo);
  }

  onItemSelect(selectedItem:any){
    this.selectedMaterialType =selectedItem['material_type_id'];
    this.selectedInfo =selectedItem;
    this.storeFilter();
    this.filterByMaterialType();
  }

  routeToDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    if (e.row?.data == null) {
      this.router.navigate(['./materialgradedetails']);
    }
    var selectedID = e.row?.data.material_grade_id;
    let params = {
      material_grade_id: selectedID
    };
    this.router.navigate(['./materialgradedetails'], { queryParams: params });
    var uModel;
    if (e.row?.data.site_id == undefined || e.row?.data.site_id == null) {

      var localUser = JSON.stringify(e.row?.data);
      uModel = <any>JSON.parse(localUser);
      uModel.site_id = this.loggedInUser.site_id;
      uModel.site_code = this.loggedInUser.site_code;
      uModel.site_description = this.loggedInUser.site_description;
    }
    else {
      var localUser = JSON.stringify(e.row?.data);
      uModel = <any>JSON.parse(localUser);
    }
      this.materialGradeService.setSelectedInfo(uModel);
      e.event?.preventDefault();
  };

  createMaterialGrade(){
    if (this.selectedMaterialType == null || this.selectedMaterialType == undefined) {
      alert('Please select a Material Type');
      return;
    }
    var pModel = {
      site_id: this.loggedInUser.site_id,
      site_code: this.loggedInUser.site_code,
      site_description: this.loggedInUser.site_description,
      material_type_id: this.selectedMaterialType
    };

    this.materialGradeService.setSelectedInfo(pModel);
    this.router.navigate(['./materialgradedetails']);
  }

  async deactivateMaterialGrade(){
    if (this.selectedInfo == null || this.selectedInfo == undefined ||
      this.selectedInfo.length == undefined || this.selectedInfo.length == 0) {
      alert("Please select a Material Grade record");
      return;
    }
    if (!confirm("Are you sure to Inactivate selected Material Grade?")) {
      return;
    }
    let params = {
      material_grade_list: this.selectedInfo
    };

    (await this.materialGradeService.deleteMaterialGrade(params)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.Index();
    });
  }

  storeFilter() {
    var fProperty = {
      material_type_id: this.selectedMaterialType
    };
    utility.setSelectedFilter(fProperty);
  }
  retrieveFilter() {
    var fProperty = utility.getSelectedFilter();
    this.selectedMaterialType = fProperty.material_type_id;
  }
  resetlcFilter() {
    utility.setSelectedFilter(null);
  }

  public toolbarItems: ToolbarItem[] = [
    { label: 'Add Grade', path: '', iconName: '', action: () => this.createMaterialGrade(), hidden: false },
    { label: 'Inactivate Grade', path: '', iconName: '', action: () => this.deactivateMaterialGrade(), hidden: false },
  ];

}
