import { Component, Inject, inject, OnInit, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WelderAdditionalQualificationService } from '../../../../APIServices/weld/welderQualification/WelderAdditionalQualificationService';
import { MaterialModule } from '../../../../core/material-module';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { utility } from '../../../../util';




@Component({
  selector: 'app-add-edit-additionalqual',
  standalone: true,
  imports: [MaterialModule, ReactiveFormsModule],
  templateUrl: './add-edit-additionalqual.component.html',
  styleUrl: './add-edit-additionalqual.component.scss',
  providers:[DatePipe]
})
export class AddEditAdditionalqualComponent implements OnInit {

  _screenType: any;
  _project_id: any;
  _welder_qual_id: any;
  _availableItems: any;

  addiQualForm: FormGroup
  
  constructor(private dialogRef: MatDialogRef<AddEditAdditionalqualComponent>,
    private service: WelderAdditionalQualificationService,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){
    this._screenType = data.screenType;   
    this._project_id = data.project_id;
    this._welder_qual_id = data.welder_qual_id;
    this._availableItems = data.availableItems;

    this.initializeForm(null);
  }

  async ngOnInit(){
    console.log(this._availableItems);
    if(this._availableItems != null && this._availableItems != undefined)
      this.initializeForm(this._availableItems);
  }

  initializeForm(aqInfo: any){
  
    if(aqInfo != null){

      const formattedexpiryDate = this.datePipe.transform(aqInfo?.cert_expiry_date,'MM/dd/yyyy');
      
      this.addiQualForm = new FormGroup({
        adqual_mapping_id: new FormControl(aqInfo?.adqual_mapping_id),
        certificate_no: new FormControl(aqInfo?.certificate_no,[Validators.required]),
        cyc: new FormControl(aqInfo?.cyc),
        certification_date: new FormControl(aqInfo?.certification_date),
        cert_expiry_date: new FormControl({ value: formattedexpiryDate, disabled:true}),
        remarks: new FormControl(aqInfo?.remarks)
      })
    }else{
      this.addiQualForm = new FormGroup({
        adqual_mapping_id: new FormControl(null),
        certificate_no: new FormControl(null,{validators:Validators.required}),
        cyc: new FormControl(null),
        certification_date: new FormControl(null),
        cert_expiry_date: new FormControl({value: null, disabled:true}),
        remarks: new FormControl(null),
      })
    } 
    
    this.certExpiryDateTrack()

  }
  
  step = signal(0);
    
  setStep(index: number) {
      this.step.set(index);
  }

  async certExpiryDateTrack(){      
    this.addiQualForm.get('cyc')?.valueChanges.subscribe(() => this.changeExpirydate());
    this.addiQualForm.get('certification_date')?.valueChanges.subscribe(() => this.changeExpirydate());      
  }

  changeExpirydate(){      
    const certDate = this.addiQualForm.get('certification_date')?.value;
    const cyc = this.addiQualForm.get('cyc')?.value;

    if(certDate && cyc){
      const tempDate = new Date(certDate);
      tempDate.setMonth(tempDate.getMonth() + parseInt(cyc,10))

      const revisedExpiryDate = this.datePipe.transform(tempDate,'MM/dd/yyyy');
      this.addiQualForm.patchValue({cert_expiry_date: revisedExpiryDate});

    }else{
      this.addiQualForm.patchValue({cert_expiry_date: null});
    }

  }


  saveAdtQual(){

    this.addiQualForm.markAllAsTouched();
    const isFormValid = this.addiQualForm.valid;

    if (!isFormValid)
      return;

    const data = this.addiQualForm.getRawValue();
      if(data.cert_expiry_date != null)
        data.cert_expiry_date = new Date(data.cert_expiry_date);

      data.project_id = this._project_id;
      data.welder_qual_id = this._welder_qual_id;

      if (this.addiQualForm.value['adqual_mapping_id'] == null || this.addiQualForm.value['adqual_mapping_id'] == '' || this.addiQualForm.value['adqual_mapping_id'] == undefined) {
        this.createAdtQualItem(data);
      }else{
        this.updateAdtQualItem(data);
      }     

  }

  async createAdtQualItem(formDataReq: any){      
        (await this.service.Post(formDataReq)).subscribe((apiResponse: any) => {
          utility.responseAlert(apiResponse);  
          if(apiResponse != null && apiResponse.response_error_id == "0"){
            this.closeDialog();
          }           
        });
  }
  
  async updateAdtQualItem(formDataReq: any){
        //const data = this.addiQualForm.getRawValue();
  
        (await this.service.Put(formDataReq)).subscribe((apiResponse: any) => {
          utility.responseAlert(apiResponse);  
          if(apiResponse != null && apiResponse.response_error_id == "0"){            
            this.closeDialog();
          }          
       });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

  

