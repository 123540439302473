import { Component, OnInit, signal } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AddProcedureToProcessComponent } from '../add-procedure-to-process/add-procedure-to-process.component';
import { AddProcedureToLineclassComponent } from '../../weldProjectProcedureMappings/add-procedure-to-lineclass/add-procedure-to-lineclass.component';
import { utility } from '../../../../util';
import { MaterialModule } from '../../../../core/material-module';
import { LookupService } from '../../../../APIServices/LookupService';
import { DevExtremeModule } from 'devextreme-angular';
import { OnlyAlphanumericDirective } from '../../../../directives/only-alphanumeric.directive';
import { WeldProcedureService } from '../../../../APIServices/weld/weldProcedure/weldProcedureService';
import { WeldProcedureToProcessService } from '../../../../APIServices/weld/weldProcedure/weldProcedureToProcessService';
import { WeldProcedureToLineClassService } from '../../../../APIServices/weld/weldProjectProcedureMappings/weldProcedureToLineclassService';


@Component({
  selector: 'app-weld-procedure-details',
  standalone: true,
  imports: [ 
    MaterialModule,
    ReactiveFormsModule,
    CommonModule,
    DevExtremeModule,
  OnlyAlphanumericDirective],
  templateUrl: './weld-procedure-details.component.html',
  styleUrl: './weld-procedure-details.component.scss'
})
export class WeldProcedureDetailsComponent {
  weldProcedureForm: FormGroup;
  selectedInfo: any;
  queryParams: any;
  loggedInUser: any;
  disciplineList: any[] = [];
  srCodeList:any[] = [];
  statusList:any[] = [];
  weldProcedureProcessModelList: any;
  selectedProcessLineclssInfo: any;
  mappedProcessCodes: any;
  mappingAccordion: boolean;
  weldProcedureLineClassModelList: any;
  mappedLineClassCodes: any;
  isFormChanged: boolean;
  initialFormValues: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private lookupService: LookupService,
    private weldProcedureService: WeldProcedureService,
    private matDialog: MatDialog,
    private weldProcedureToProcessService: WeldProcedureToProcessService,
    private weldProcedureToLineclassService: WeldProcedureToLineClassService
  ) {
      this.initializeForm(null);
  }
  
  step = signal(0);

  setStep(index: number) {
    this.step.set(index);
  }
  ngOnInit() {
    this.weldProcedureService.getSelectedInfo.subscribe(x => {
      if(utility.isObject(x)) {
        this.selectedInfo = x;
        console.log('this.selectedInfo on page procedure details', this.selectedInfo);
      } 
    });
    this.queryParams = this.route.snapshot.queryParams['weld_procedure_id'];
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.getMappedProcessList();
    this.index();
    this.weldProcedureForm.controls['base_procedure'].valueChanges.subscribe(()=>this.setWeldProcedureTitle())
    this.weldProcedureForm.controls['rev_no'].valueChanges.subscribe(()=>this.setWeldProcedureTitle())
    this.populateDisciplineOptionList();
    this.populateSRCodeOptionList();
    this.populatestatusOptionList();
    //this.getMappedProcessList();
    this.getMappedLineClassList();
  
  }

  async index() {
    this.initializeForm(this.selectedInfo);
    if (this.selectedInfo == null || this.selectedInfo == undefined || this.selectedInfo == '') {
          if (this.queryParams != undefined) {
            this.mappingAccordion = false;
            (await this.weldProcedureService.getSelectedWeldProcedure(this.queryParams)).subscribe((apiResponse: any) => {
              const jExtract = utility.extractJsonData(apiResponse, 0);
              this.selectedInfo = jExtract[0];
              this.weldProcedureService.setSelectedInfo(this.selectedInfo);
              this.initializeForm(this.selectedInfo);
            });
          } else {
            this.mappingAccordion = true;
          }
    } 
  }

   async populateDisciplineOptionList() {
    (await this.lookupService.getDiscipline(this.loggedInUser.logon_id, this.loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.disciplineList = utility.extractJsonData(apiResponse, 0);
    });
  } 
  async setWeldProcedureTitle() {
    const base_procedure=this.weldProcedureForm.get('base_procedure')?.value || '';
    const rev_no=this.weldProcedureForm.get('rev_no')?.value || '';
    const weld_procedure= base_procedure ? base_procedure + '-R' + rev_no : '';
    this.weldProcedureForm.get('weld_procedure_title')?.setValue(weld_procedure,{emitEvent:false});
  } 

   async populateSRCodeOptionList() {
    (await this.lookupService.getSRCodeList()).subscribe((apiResponse: any) => {
      this.srCodeList = utility.extractJsonData(apiResponse, 0);
    });
  } 
  async populatestatusOptionList() {
    this.statusList=utility.weldProcedureStatus;
  } 

  async initializeForm(wpInfo: any) {
    if (wpInfo != null) {
      this.weldProcedureForm = new FormGroup({
        weld_procedure_id: new FormControl(wpInfo?.weld_procedure_id),
        base_procedure: new FormControl({ value: wpInfo?.base_procedure, disabled: true }, [Validators.required]),
        rev_no: new FormControl({value:wpInfo?.rev_no,disabled:true}, [Validators.required]),
        weld_procedure_title: new FormControl({value:wpInfo?.weld_procedure_title, disabled: true }, [Validators.required]),
        thickness_start_rng: new FormControl(wpInfo?.thickness_start_rng,  [Validators.required]),
        thickness_end_rng: new FormControl(wpInfo?.thickness_end_rng, [Validators.required, this.rangeValidator('thickness_start_rng')]),
        hardness_start_rng: new FormControl(wpInfo?.hardness_start_rng, [Validators.required]),
        hardness_end_rng: new FormControl(wpInfo?.hardness_end_rng, [Validators.required, this.rangeValidator('hardness_start_rng')]),
        bore_start_rng: new FormControl(wpInfo?.bore_start_rng, [Validators.required]),
        bore_end_rng: new FormControl(wpInfo?.bore_end_rng, [Validators.required, this.rangeValidator('bore_start_rng')]),
        discipline_id: new FormControl(wpInfo?.discipline_id, [Validators.required]),
        sr_code_id: new FormControl(wpInfo?.sr_code_id, [Validators.required]),
        status_id: new FormControl({value:wpInfo?.status_id, disabled: (this.weldProcedureProcessModelList?.length > 0) ? false : true}),
        wp_start_date: new FormControl(wpInfo?.wp_start_date),
        wp_end_date: new FormControl(wpInfo?.wp_end_date),
        site_id: new FormControl(wpInfo?.site_id),
        is_active: new FormControl({ value: wpInfo?.is_active, disabled: (this.weldProcedureProcessModelList?.length > 0) ? false : true }),
        requester_id: new FormControl('')
    },{validators: this.dateRangeValidation});
      this.initialFormValues = this.weldProcedureForm.getRawValue();
      this.detectFormValueChanges(this.initialFormValues);
  }
    else {
      this.weldProcedureForm = new FormGroup({
        weld_procedure_id: new FormControl(null),
        base_procedure: new FormControl('', [Validators.required]),
        rev_no: new FormControl('', [Validators.required]),
        weld_procedure_title: new FormControl('', [Validators.required]),
        thickness_start_rng: new FormControl('',  [Validators.required]),
        thickness_end_rng: new FormControl('', [Validators.required, this.rangeValidator('thickness_start_rng')]),
        hardness_start_rng: new FormControl('', [Validators.required]),
        hardness_end_rng: new FormControl('', [Validators.required, this.rangeValidator('hardness_start_rng')]),
        bore_start_rng: new FormControl('', [Validators.required]),
        bore_end_rng: new FormControl('', [Validators.required, this.rangeValidator('bore_start_rng')]),
        discipline_id: new FormControl(null, [Validators.required]),
        sr_code_id: new FormControl(null, [Validators.required]),
        status_id: new FormControl({ value: 1, disabled: true }),
        wp_start_date: new FormControl(null),
        wp_end_date: new FormControl({ value: null, disabled: true }),
        site_id: new FormControl(null),
        is_active: new FormControl({ value: false, disabled: true }),
        requester_id: new FormControl('')
    },{validators: this.dateRangeValidation});
    this.detectFormValueChanges();
  }
}

  dateRangeValidation: ValidatorFn = (control: AbstractControl) => {
    const form = control as FormGroup;
    const sDate = form.get('wp_start_date')?.value;
    const eDate = form.get('wp_end_date')?.value;
    const endDateControl = form.get('wp_end_date');
    if (sDate && eDate && new Date(eDate) < new Date(sDate)) {
     endDateControl?.setErrors({dateInvalid: true});
      return {
        dateInvalid: true 
      };
    }
    return null;;
  }


  rangeValidator(startControlName: string) {
    return (control: AbstractControl) => {
      const form = control.parent; // Get the parent FormGroup
      if (!form) return null; // Return null if form is not yet initialized
      const startControl = form.get(startControlName);
      if(control.value==""){return null;}
      if (!startControl ||  parseFloat(startControl.value) <= parseFloat(control.value)) {
        return null; // Valid
      }
      return { rangeInvalid: true }; // Invalid
    };
  }

  save() {
    this.weldProcedureForm.markAllAsTouched();
    this.updateFormControls();
    const isFormValid = this.weldProcedureForm.valid;
    if (isFormValid) {
      const formData = this.weldProcedureForm.getRawValue();
      formData.base_procedure = formData.base_procedure ? formData.base_procedure.toUpperCase() : null;
      formData.weld_procedure_title = formData.weld_procedure_title ? formData.weld_procedure_title.toUpperCase() : null;
      if (this.weldProcedureForm.value['weld_procedure_id'] === "" 
          || this.weldProcedureForm.value['weld_procedure_id'] === null 
          || this.weldProcedureForm.value['weld_procedure_id'] === undefined) {
            this.createWeldProcedure(formData);
      }
      else {
        this.updateWeldProcedure(formData);
      }
      this.isFormChanged = true;
    } else {
      return;
    }
  }

  async createWeldProcedure(formDataReq: any) {
    (await this.weldProcedureService.createWeldProcedure(formDataReq)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.backtoList();
    });
  }
  async updateWeldProcedure(formDataReq: any) {
    (await this.weldProcedureService.updateWeldProcedure(formDataReq)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.backtoList();
    });
  }

  updateFormControls() {
    this.weldProcedureForm.controls['requester_id'].setValue(this.loggedInUser.logon_id);
    this.weldProcedureForm.controls['site_id'].setValue(this.loggedInUser.site_id);
  }

  backtoList() {
    this.router.navigate(['./weldprocedurelist']);
  }

  getMappedProcessList() {
    if (this.queryParams != undefined) {
      this.weldProcedureToProcessService.getProcessByProcdureID(this.queryParams).subscribe(data =>{
        this.weldProcedureProcessModelList = utility.extractJsonData(data, 0);
        if (this.weldProcedureProcessModelList) {
          this.mappedProcessCodes = this.weldProcedureProcessModelList.map(item => item.process_id);
        } else {
          this.mappedProcessCodes;
        }
      });
    }
  }

  getMappedLineClassList() {
    if (this.queryParams != undefined) {
      this.weldProcedureToLineclassService.getLineClassByProcdureID(this.queryParams).subscribe(data =>{
        this.weldProcedureLineClassModelList = utility.extractJsonData(data, 0);
        console.log('weldProcedureLineClassModelList:', this.weldProcedureLineClassModelList);
        if (this.weldProcedureLineClassModelList) {
          this.mappedLineClassCodes = this.weldProcedureLineClassModelList.map(item => item.lineclass_id);
        } else {
          this.mappedLineClassCodes;
        }
      });
    }
  }
  
  onSelectionChanged(e) {
    this.selectedProcessLineclssInfo = e.selectedRowsData;
    this.weldProcedureService.setSelectedInfo(e.selectedRowsData);
  }

  addProcessToProcedure(event: MouseEvent){
    event.stopPropagation();
    this.openDialog(true);
  }

  deleteProcessFromProcedure(event: MouseEvent) {
    event.stopPropagation();
    if (!this.selectedProcessLineclssInfo) {
      alert("Please select Process to delete !!");
      return;
    }

    if (!confirm("Are you sure to Delete selected Process?")) {
      return;
    }

    var processInfoDelete = {
      weld_prcd_prss_map_list: this.selectedProcessLineclssInfo,
      site_id: this.loggedInUser.site_id
    };

    this.weldProcedureToProcessService.deleteProcessByProcedureID(processInfoDelete).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.getMappedProcessList();
    });
  }

  addLineClassToProcedure(event: MouseEvent) {
    event.stopPropagation();
    this.openLineClassDialog(true);
  }

  /* deleteLineClassFromProcedure(event: MouseEvent) {
    event.stopPropagation();
    if (!this.selectedProcessLineclssInfo) {
      alert("Please select Line Class to delete !!");
      return;
    }

    if (!confirm("Are you sure to Delete selected Line Class?")) {
      return;
    }

    var lineclassInfoDelete = {
      weld_prcd_line_class_map_list: this.selectedProcessLineclssInfo,
      site_id: this.loggedInUser.site_id
    };

    this.weldProcedureToLineclassService.deleteProcessByProcedureID(lineclassInfoDelete).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.getMappedLineClassList();
    });
  } */

  openDialog(isNew: boolean): void {
    const dialogRef = this.matDialog.open(AddProcedureToProcessComponent, {
      
      autoFocus: false,
      disableClose: true,
      data: {
        availableItems: this.mappedProcessCodes
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getMappedProcessList();
    });
  }

  openLineClassDialog(isNew: boolean): void {
    const dialogRef = this.matDialog.open(AddProcedureToLineclassComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        availableItems: this.mappedLineClassCodes
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getMappedLineClassList();
    });
  }

  detectFormValueChanges(initialFormValues?: any) {
    this.weldProcedureForm.valueChanges.subscribe(() => {
      this.isFormChanged = utility.checkIfFormChanged(this.weldProcedureForm, initialFormValues);
    });
  }

  isSaveDisabled() {
    return this.isFormChanged && this.weldProcedureForm.valid;
  }

}
