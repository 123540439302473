<form [formGroup]="pTestsForm" class="dialog-form">
    <div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <span class="dialogHeader">Add/Edit Tests</span>
        <button mat-icon-button class="close-button" (click)="closeDialog()">
            <mat-icon class="close-icon" color="warn">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <input type="hidden" formControlName="ti_id" />
        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label>Select Test Type</mat-label>
                <mat-select formControlName="test_id" (selectionChange)="fetchTestProcedure($event.value)">
                    <ng-container>
                        @for (Item of lookupTestsType; track Item) {
                        <mat-option [value]="Item.test_id">{{Item.test_description}}</mat-option>
                        }
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="pTestsForm.controls['test_id'].errors?.['required']">
                    This is required!
                </mat-error>
            </mat-form-field>
        </div>

        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label>Select Test Procedure</mat-label>
                <mat-select formControlName="ps_id" (selectionChange)="fetchAcceptanceCriteria($event.value)">
                    <ng-container>
                        @for (Item of lookupTestProcedure; track Item) {
                        <mat-option [value]="Item.ps_id">{{Item.test_procedure}}</mat-option>
                        }
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="pTestsForm.controls['ps_id'].errors?.['required']">
                    This is required!
                </mat-error>
            </mat-form-field>
        </div>

        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label>Select Acceptance Criteria</mat-label>
                <mat-select formControlName="ac_id">
                    <ng-container>
                        @for (Item of lookupAcceptanceCriteria; track Item) {
                        <mat-option [value]="Item.ac_id">{{Item.acceptance_criteria}}</mat-option>
                        }
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="pTestsForm.controls['ac_id'].errors?.['required']">
                    This is required!
                </mat-error>
            </mat-form-field>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions align="end" class="mr-16">
        <button mat-raised-button color="primary" (click)="saveTestsInfo()" [disabled]="!isSaveDisabled()">Save</button>
        <!-- <button mat-raised-button (click)="closeDialog()">Close</button> -->
    </mat-dialog-actions>
</form>