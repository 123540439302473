import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { utility } from "../util";
import { BehaviorSubject, catchError, throwError } from "rxjs";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class ProjectService {
    requester_id: string;
    site_id: string;

    private apiBaseUrl: string;
    private selectedInfo = new BehaviorSubject(null);
    getSelectedInfo = this.selectedInfo.asObservable();
    private environmentConfig = utility.getEnvironmentData();

    constructor(private httpClient: HttpClient, private router: Router) {
        var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
        this.requester_id = loggedInUser.logon_id
        this.site_id = loggedInUser.site_id
        this.apiBaseUrl = this.environmentConfig.apiBase;
    }
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }
    setSelectedInfo(paramData: any) {
        this.selectedInfo.next(paramData);
    }
    public async getAllProjectList(requester_id: any, site_id: any) {
        var apiMethod = "ProjectInfo/GetAll";
        var param = "?requester_id=" + requester_id + "&site_id=" + site_id;
        var url = this.apiBaseUrl + apiMethod + param;
        return this.httpClient.get(url).pipe(
            catchError(this.errorHandler)
        );
    }
    public async getUserWiseProjectList(requester_id: any, site_id: any, user_id: any) {
        var apiMethod = "ProjectInfo/GetAll";
        var param = "?requester_id=" + requester_id + "&site_id=" + site_id + "&user_id=" + user_id;
        var url = this.apiBaseUrl + apiMethod + param;
        return this.httpClient.get(url).pipe(
            catchError(this.errorHandler)
        );
    }
    public async getSelectedProject(requester_id: any, project_id: any, site_id: any) {
        var apiMethod = "ProjectInfo/detail";
        var param = "?requester_id=" + requester_id + "&project_id=" + project_id + "&site_id=" + site_id;
        var url = (this.apiBaseUrl + apiMethod) + param;
        return this.httpClient.get(url).pipe(
            catchError(this.errorHandler)
        );
    }
    public async createProject(data: any) {
        data.requester_id = this.requester_id;
        data.site_id = this.site_id;
        
        var apiMethod = "ProjectInfo/Create";
        var url = (this.apiBaseUrl + apiMethod)
        return this.httpClient.post(url, JSON.stringify(data), this.httpOptions)
            .pipe(
                catchError(this.errorHandler)
            );
    }
    public async updateProject(data: any) {
        data.requester_id = this.requester_id;
        data.site_id = this.site_id;

        var apiMethod = "ProjectInfo/Update";
        var url = (this.apiBaseUrl + apiMethod)
        return this.httpClient.put(url, JSON.stringify(data), this.httpOptions)
            .pipe(
                catchError(this.errorHandler)
            );
    }

    public async deleteProject(data: any) {
        data.requester_id = this.requester_id;
        data.site_id = this.site_id;

        var apiMethod = "ProjectInfo/Delete";
        var url = (this.apiBaseUrl + apiMethod);
        return this.httpClient.put(url, JSON.stringify(data), this.httpOptions)
            .pipe(
                catchError(this.errorHandler)
            );
    }
    errorHandler(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message;
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }
}