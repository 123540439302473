import { Component, Inject, OnInit } from '@angular/core';
import { AccessService } from '../../../APIServices/AccessService';
import { utility } from '../../../util';
import { MatDialogRef } from '@angular/material/dialog';
import { MaterialModule } from '../../../core/material-module';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule, DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { GroupService } from '../../../APIServices/GroupService';

@Component({
  selector: 'app-add-edit-access',
  standalone: true,
  imports: [MaterialModule, ReactiveFormsModule, CommonModule,],
  templateUrl: './add-edit-access.component.html',
  styleUrl: './add-edit-access.component.scss'
})
export class AddEditAccessComponent implements OnInit {
  dMenuListParent: any;
  dMenuListLeaf: any;
  dModuleList: any;
  dAccessModel: any;
  aForm: FormGroup;
  selectedInfo: any;
  selectedgInfo: any;
  bDisableItems: boolean = false;

  constructor(@Inject(DOCUMENT) private document: Document, private dialogRef: MatDialogRef<AddEditAccessComponent>,
    private accessService: AccessService, private router: Router, private groupService: GroupService) {
    this.initializeForm(null);
  }

  async ngOnInit() {
    await this.accessService.getSelectedInfo.subscribe(x =>
      this.selectedInfo = x);
    await this.groupService.getSelectedInfo.subscribe(x =>
      this.selectedgInfo = x);

    await this.fetchModuleList();

    if (this.accessService.isNewAccess == true) {
      return;
    }

    if (this.selectedInfo != undefined && this.selectedInfo != null) {
      this.dAccessModel = Object.assign({}, this.selectedInfo);
      this.bDisableItems  =  true;
      this.initializeForm(this.selectedInfo);

      // this.dAccessModel.can_view = (String(this.dAccessModel.can_view) == 'Y' || String(this.dAccessModel.can_view) == 'true') ? true : false;
      // this.dAccessModel.can_add = (String(this.dAccessModel.can_add) == 'Y' || String(this.dAccessModel.can_add) == 'true') ? true : false;
      // this.dAccessModel.can_edit = (String(this.dAccessModel.can_edit) == 'Y' || String(this.dAccessModel.can_edit) == 'true') ? true : false;
      // this.dAccessModel.can_delete = (String(this.dAccessModel.can_delete) == 'Y' || String(this.dAccessModel.can_delete) == 'true') ? true : false;
    }
    else
    {
      this.bDisableItems  =  false;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  //Fetch Module List
  async fetchModuleList() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    (await this.accessService.getModuleList(loggedInUser.logon_id, loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.dModuleList = utility.extractJsonData(apiResponse, 0);
      if (this.dAccessModel != null) {
        this.fetchParentNode(this.dAccessModel.module_id);
      }
    });
  }

  //Fetch Parent Node List
  async fetchParentNode(e) {
    var module_id: any = e;

    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    (await this.accessService.getParentMenuList(loggedInUser.logon_id, loggedInUser.site_id, module_id)).subscribe((apiResponse: any) => {
      this.dMenuListParent = utility.extractJsonData(apiResponse, 0);
      if (this.dAccessModel != null) {
        this.fetchLeafNode(this.dAccessModel.parent_id);
      }
    });
  }
  //Fetch Leaf Node List
  async fetchLeafNode(e) {
    var parent_id: any = e;
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    (await this.accessService.getLeafMenuList(loggedInUser.logon_id, loggedInUser.site_id, parent_id)).subscribe((apiResponse: any) => {
      this.dMenuListLeaf = utility.extractJsonData(apiResponse, 0);
    });
  }
  async saveGroupAccess() {
    this.aForm.markAllAsTouched();
    const isFormValid = this.aForm.valid;
    if (!isFormValid) {
      return;
    }

    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    //this.aForm.value['requester_id'] = loggedInUser.logon_id;
    //this.aForm.value['group_id'] = this.selectedgInfo[0].group_id;

    this.aForm.controls['requester_id'].setValue(loggedInUser.logon_id);
    this.aForm.controls['group_id'].setValue(this.selectedgInfo[0].group_id);
    // this.dAccessModel.requester_id = loggedInUser.logon_id;
    // this.dAccessModel.group_id = this.selectedgInfo[0].group_id;

    (await this.accessService.saveGroupAccess(this.aForm.getRawValue())).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.closeDialog();
    });
  }
  validateInput(): boolean {

    var errorMsg: string = "";

    if (this.dAccessModel == null) {
      alert('Invalid input. Please check');
      return false;
    }
    for (let key of Object.keys(this.dAccessModel)) {
      if (this.dAccessModel[key] != null) {
        this.dAccessModel[key] = utility.removeUnknownCharLoop(this.dAccessModel[key])
      }
    }

    if (this.dAccessModel.module_id == null || this.dAccessModel.module_id == undefined
      || this.dAccessModel.module_id == 0) {
      errorMsg = errorMsg !== "" ? (errorMsg + "\n") : errorMsg
      errorMsg += 'Please select a module';
    }
    if (this.dAccessModel.parent_id == null || this.dAccessModel.parent_id == undefined
      || this.dAccessModel.parent_id == 0) {
      errorMsg = errorMsg !== "" ? (errorMsg + "\n") : errorMsg
      errorMsg += 'Please select a menu';
    }

    if (this.dAccessModel.leaf_menu_id == null || this.dAccessModel.leaf_menu_id == undefined
      || this.dAccessModel.leaf_menu_id == 0) {
      errorMsg = errorMsg !== "" ? (errorMsg + "\n") : errorMsg
      errorMsg += 'Please select a page';
    }

    if (errorMsg != "") {
      alert(errorMsg);
      return false;
    }

    return true;
  }
  async initializeForm(aInfo: any) {
    if (aInfo != null) {
      this.aForm = new FormGroup({
        access_id: new FormControl(aInfo?.access_id),
        group_id: new FormControl(aInfo?.group_id),
        module_id: new FormControl({value: aInfo?.module_id, disabled: this.bDisableItems}, [Validators.required]),
        parent_id: new FormControl({value: aInfo?.parent_id, disabled: this.bDisableItems}, [Validators.required]),
        leaf_menu_id: new FormControl({value: aInfo?.leaf_menu_id, disabled: this.bDisableItems},[Validators.required]),
        can_view: new FormControl(aInfo?.can_view),
        can_add: new FormControl(aInfo?.can_add),
        can_edit: new FormControl(aInfo?.can_edit),
        can_delete: new FormControl(aInfo?.can_delete),
        is_active: new FormControl(aInfo?.is_active),
        requester_id: new FormControl('')
      });
    }
    else {
      this.aForm = new FormGroup({
        access_id: new FormControl(null),
        group_id: new FormControl(''),
        module_id: new FormControl({value: '', disabled: this.bDisableItems}, [Validators.required]),
        parent_id: new FormControl({value: '', disabled: this.bDisableItems}, [Validators.required]),
        leaf_menu_id: new FormControl({value: '', disabled: this.bDisableItems}, [Validators.required]),
        can_view: new FormControl(false),
        can_add: new FormControl(false),
        can_edit: new FormControl(false),
        can_delete: new FormControl(false),
        is_active: new FormControl(false),
        requester_id: new FormControl('')
      });
    }
    //console.log(this.aForm.value);
  }
}
