<div class="card">
    <mat-card appearance="outlined">
        <mat-card-header>
            <span class="card-title">Drawing Information</span>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="dForm" novalidate class="form">
                <div class="flex-container">
                    <div class="side-container">
                        <div class="ndt-container">
                            <fieldset>
                                <legend>MCD Reference</legend>

                                <div class="item-container">
                                    <mat-label class="matLabel asterix">Drawing</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput type="text" id="txtDrawingCode" class="matInputCls toUpperCase"
                                            name="text" formControlName="mcd_drawing_code" maxlength="40"
                                            appOnlyAlphanumeric>
                                        <mat-error class="text-danger"
                                            *ngIf="dForm.controls['mcd_drawing_code'].errors?.['required']">
                                            This is required!
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="item-container">
                                    <mat-label class="matLabel asterix">Drawing Desc</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput type="text" id="txtDrawingDesc" name="text" class="matInputCls"
                                            formControlName="mcd_drawing_desc" maxlength="100">
                                    </mat-form-field>
                                </div>

                                <div class="item-container">
                                    <mat-label class="matLabel asterix">Rev</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput type="number" id="txtRev" name="text"
                                            formControlName="mcd_drawing_revision" maxlength="4" class="matInputCls">
                                    </mat-form-field>
                                </div>

                                <div class="item-container">
                                    <mat-label class="matLabel">Date</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput [matDatepicker]="picker1" type="text" id="txtMCDDate"
                                            name="text" class="matInputCls" formControlName="mcd_date" maxlength="50">

                                        <mat-datepicker-toggle style="width: 1em;" matIconSuffix
                                            [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker style="width: 20px;" #picker1></mat-datepicker>
                                    </mat-form-field>
                                </div>

                                <div class="item-container">
                                    <mat-label class="matLabel">Line Class</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <mat-select formControlName="lineclass_id" class="matInputCls">
                                            @for (item of lineClassList; track item) {
                                            <mat-option [value]="item.lineclass_id">{{item.lineclass_code}} -
                                                {{item.lineclass_desc}}</mat-option>
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="item-container">
                                    <mat-label class="matLabel">NDT%</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput type="number" id="txtNdtPercent" name="text"
                                            formControlName="ndt_percent" maxlength="5" max="100" class="matInputCls">

                                        <mat-error class="text-danger" *ngIf="dForm.controls['ndt_percent'].errors">
                                            max should be 100
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </fieldset>
                        </div>
                        <div class="ndt-container">
                            <fieldset>
                                <legend>Client Reference</legend>
                                <div class="item-container">
                                    <mat-label class="matLabel">Drawing</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput type="text" id="txtClientDrawingCode" name="text"
                                            class="matInputCls" formControlName="client_drawing_code" maxlength="40">
                                    </mat-form-field>
                                </div>

                                <div class="item-container">
                                    <mat-label class="matLabel">Drawing Desc</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput type="text" id="txtClientDrawingDesc" name="text"
                                            class="matInputCls" formControlName="client_drawing_desc" maxlength="100">
                                    </mat-form-field>
                                </div>

                                <div class="item-container">
                                    <mat-label class="matLabel">Rev</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput type="number" id="txtClientRev" name="text"
                                            formControlName="client_drawing_revision" maxlength="4" class="matInputCls">
                                    </mat-form-field>
                                </div>

                                <div class="item-container">
                                    <mat-label class="matLabel">Date</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput [matDatepicker]="picker2" type="text" id="txtClientDate"
                                            name="text" class="matInputCls" formControlName="client_date"
                                            maxlength="50">

                                        <mat-datepicker-toggle style="width: 1em;" matIconSuffix
                                            [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker style="width: 20px;" #picker2></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </fieldset>
                        </div>
                        <div class="ndt-container">
                            <fieldset>
                                <div class="item-container">
                                    <mat-label class="matLabel">Line</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput type="text" id="txtLine" name="text" class="matInputCls"
                                            formControlName="line" maxlength="40">
                                    </mat-form-field>
                                </div>

                                <div class="item-container">
                                    <mat-label class="matLabel">Line Desc</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput type="text" id="txtLineDesc" name="text" class="matInputCls"
                                            formControlName="line_desc" maxlength="100">
                                    </mat-form-field>
                                </div>

                                <div class="item-container">
                                    <mat-label class="chkMatLabel">Is Active?</mat-label>
                                    <mat-checkbox formControlName="is_active" class="matInputCls chkMatCheckbox"
                                        id="chkIsActive">
                                    </mat-checkbox>
                                </div>

                            </fieldset>
                        </div>
                    </div>

                    <div class="side-container">
                        <div class="ndt-container">
                            <fieldset>
                                <div class="item-container mt-1e">
                                    <mat-label class="matLabel">Type</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <mat-select formControlName="type" class="matInputCls">
                                            @for (item of discTypeList; track item) {
                                            <mat-option [value]="item.disc_id">{{item.disc_code}} -
                                                {{item.disc_title}}</mat-option>
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="item-container">
                                    <mat-label class="matLabel">MTN Completed</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <mat-select formControlName="mtn_completed" class="matInputCls">
                                            @for (item of mtnList; track item) {
                                            <mat-option [value]="item.master_id">{{item.description}}</mat-option>
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="item-container">
                                    <mat-label class="matLabel">MarkUp Received</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <mat-select formControlName="markup_received" class="matInputCls">
                                            @for (item of markupList; track item) {
                                            <mat-option [value]="item.master_id">{{item.description}}</mat-option>
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="item-container">
                                    <mat-label class="matLabel">Hydrotest</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput type="text" id="txtHydrotest" class="matInputCls"
                                            formControlName="hydrotest" maxlength="10">
                                    </mat-form-field>
                                </div>

                                <div class="item-container">
                                    <mat-label class="matLabel">JRM Insp.</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput type="text" id="txtJrmInsp" class="matInputCls"
                                            formControlName="jrm_inspector_name" maxlength="10">
                                    </mat-form-field>
                                </div>

                                <div class="item-container">
                                    <mat-label class="matLabel">Smbt to J. Insp</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput [matDatepicker]="picker3" type="text" name="text"
                                            class="matInputCls" formControlName="j_insp_sbmt_date" maxlength="50">

                                        <mat-datepicker-toggle style="width: 1em;" matIconSuffix
                                            [for]="picker3"></mat-datepicker-toggle>
                                        <mat-datepicker style="width: 20px;" #picker3></mat-datepicker>
                                    </mat-form-field>
                                </div>

                                <div class="item-container">
                                    <mat-label class="matLabel">Butts</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input  type="number" matInput id="txtButts" name="text" class="matInputCls"
                                            formControlName="butts" maxlength="10">
                                    </mat-form-field>
                                </div>

                                <div class="item-container">
                                    <mat-label class="matLabel">Cleared</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput [matDatepicker]="picker4" type="text" name="text"
                                            class="matInputCls" formControlName="cleared_date" maxlength="50">

                                        <mat-datepicker-toggle style="width: 1em;" matIconSuffix
                                            [for]="picker4"></mat-datepicker-toggle>
                                        <mat-datepicker style="width: 20px;" #picker4></mat-datepicker>
                                    </mat-form-field>
                                </div>

                                <div class="item-container">
                                    <mat-label class="matLabel">Smbt to Client</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput [matDatepicker]="picker5" type="text" name="text"
                                            class="matInputCls" formControlName="client_sbmt_date" maxlength="50">

                                        <mat-datepicker-toggle style="width: 1em;" matIconSuffix
                                            [for]="picker5"></mat-datepicker-toggle>
                                        <mat-datepicker style="width: 20px;" #picker5></mat-datepicker>
                                    </mat-form-field>
                                </div>

                                <div class="item-container">
                                    <mat-label class="matLabel">Last Weld No.</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput type="number" id="txtLastWeldNo" name="number" class="matInputCls"
                                            formControlName="last_weldno" maxlength="10">
                                    </mat-form-field>
                                </div>

                                <div class="item-container">
                                    <mat-label class="matLabel">Recalled</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput [matDatepicker]="picker6" type="text" name="text"
                                            class="matInputCls" formControlName="recalled_date" maxlength="50">

                                        <mat-datepicker-toggle style="width: 1em;" matIconSuffix
                                            [for]="picker6"></mat-datepicker-toggle>
                                        <mat-datepicker style="width: 20px;" #picker6></mat-datepicker>
                                    </mat-form-field>
                                </div>

                                <div class="item-container">
                                    <mat-label class="matLabel">Approved</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput [matDatepicker]="picker7" type="text" name="text"
                                            class="matInputCls" formControlName="approved_date" maxlength="50">

                                        <mat-datepicker-toggle style="width: 1em;" matIconSuffix
                                            [for]="picker7"></mat-datepicker-toggle>
                                        <mat-datepicker style="width: 20px;" #picker7></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </fieldset>
                        </div>
                        <div class="ndt-container" *ngIf="this.dForm.controls['recalled_date'].value!=null">
                            <fieldset>
                                <legend>Recall Details</legend>
                                <div class="item-container">
                                    <mat-label class="matLabel">Recalled By</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput type="text" id="txtRecalledBy" class="matInputCls"
                                            formControlName="recalled_by" maxlength="120">
                                    </mat-form-field>
                                </div>

                                <div class="item-container">
                                    <mat-label class="matLabel">Recall Remark</mat-label>
                                    <mat-form-field appearance="outline" class="small">
                                        <input matInput type="text" id="txtRecallRemark" class="matInputCls"
                                            formControlName="recall_remark" maxlength="255">
                                    </mat-form-field>
                                </div>
                            </fieldset>
                        </div>
                        <div class="item-container">
                            <mat-label class="matLabel">Narrative</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <textarea matInput id="txtNarrative" rows="3" class="matInputCls"
                                    formControlName="narrative" maxlength="500"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button (click)="backtoList()" class="bckBtn">Back</button>
            <button mat-raised-button color="primary" gap="20px" class="mainBtn" (click)="save()"
                *ngIf="!formSubmitted">Save</button>
            <button mat-raised-button color="primary" gap="20px" class="mainBtn" (click)="openDialog()"
                *ngIf="!formSubmitted">Submit</button>
            <button mat-raised-button color="primary" gap="20px" class="mainBtn" (click)="openRecallDialog()"
                *ngIf="formSubmitted">Recall</button>
        </mat-card-actions>
    </mat-card>
</div>