<form [formGroup]="matTypeGrdForm" class="dialog-form">
    <div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <span class="dialogHeader">Map Procedure to Line Class</span>
        <button mat-icon-button class="close-button" (click)="closeDialog()">
            <mat-icon class="close-icon" color="warn">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <!-- MAterial I and Material II -->
        <div class="ndt-container">
            <fieldset>
                <legend>Material I</legend>
                <!-- Material Type -->
                <div class="item-container">
                    <mat-label class="matLabel mr-3em mt-0 asterix">Type</mat-label>
                    <mat-form-field appearance="outline" class="small mr-3em">
                        <mat-select formControlName="mat_Type1" (selectionChange)="mType1SelectionChange($event)">
                            <ng-container>
                                @for (item of materialTypeList; track item) {
                                <mat-option
                                    [value]="item.master_id">{{item.code}}-{{item.description}}</mat-option>
                                }
                            </ng-container>
                        </mat-select>
                        <mat-error class="text-danger" *ngIf="matTypeGrdForm.controls['mat_Type1'].errors?.['required']">
                            This is required!
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- Material Grade -->
                <div class="item-container">
                    <mat-label class="matLabel asterix mt-0 mr-3em">Grade</mat-label>
                    <mat-form-field appearance="outline" class="small mr-3em">
                        <mat-select formControlName="mat_Grade1" (selectionChange)="mGrade1SelectionChange($event)">
                            <ng-container>
                                @for (item of materialGradeList; track item) {
                                <mat-option
                                    [value]="item.master_id">{{item.code}}-{{item.description}}</mat-option>
                                }
                            </ng-container>
                        </mat-select>
                         <mat-error class="text-danger" *ngIf="matTypeGrdForm.controls['mat_Grade1'].errors?.['required']">
                            This is required!
                        </mat-error>
                    </mat-form-field>
                </div>
            </fieldset>

            <fieldset>
                <legend>Material II</legend>
                <!-- Material Type -->
                <div class="item-container">
                    <mat-label class="matLabel asterix mt-0 mr-3em">Type</mat-label>
                    <mat-form-field appearance="outline" class="small mr-3em">
                        <mat-select formControlName="mat_Type2" (selectionChange)="mType2SelectionChange($event)">
                            <ng-container>
                                @for (item of materialTypeList; track item) {
                                <mat-option
                                    [value]="item.master_id">{{item.code}}-{{item.description}}</mat-option>
                                }
                            </ng-container>
                        </mat-select>
                         <mat-error class="text-danger" *ngIf="matTypeGrdForm.controls['mat_Type2'].errors?.['required']">
                            This is required!
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- Material Grade -->
                <div class="item-container">
                    <mat-label class="matLabel asterix mt-0 mr-3em">Grade</mat-label>
                    <mat-form-field appearance="outline" class="small mr-3em">
                        <mat-select formControlName="mat_Grade2" (selectionChange)="mGrade2SelectionChange($event)">
                            <ng-container>
                                @for (item of materialGradeList2; track item) {
                                <mat-option
                                    [value]="item.master_id">{{item.code}}-{{item.description}}</mat-option>
                                }
                            </ng-container>
                        </mat-select>
                        <mat-error class="text-danger" *ngIf="matTypeGrdForm.controls['mat_Grade2'].errors?.['required']">
                            This is required!
                        </mat-error>
                    </mat-form-field>
                </div>
            </fieldset>
           
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="mr-16">
        <button mat-raised-button color="primary" (click)="mapProcedureToMaterialType()" [disabled]="!isSaveDisabled()">Map</button>
    </mat-dialog-actions>
</form>